import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { notification } from "antd";
import Iconos_tarjeta1 from "../../assets/images/Iconos_tarjeta1.png";
import Iconos_tarjeta2 from "../../assets/images/Iconos_tarjeta2.png";
import Iconos_tarjeta3 from "../../assets/images/Iconos_tarjeta3.png";
import Iconos_Vendedor_tarjeta1 from "../../assets/images/Iconos_Vendedor_tarjeta1.png";
import Iconos_Vendedor_tarjeta2 from "../../assets/images/Iconos_Vendedor_tarjeta2.png";
import Iconos_Vendedor_tarjeta3 from "../../assets/images/Iconos_Vendedor_tarjeta3.png";
import HeaderDashboard from "./components/HeaderDashboard";
import { myFetchGet } from "../../services/services";
import { useSelector } from "react-redux";
import { useReduxToken } from "../../hooks/useReduxToken";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import TopCards from "../../components/dashboard/TopCards";
import MidCards from "../../components/dashboard/MidCards";
import BottomCards from "../../components/dashboard/BottomCards";
import MidCardSupervisor from "../../components/dashboard/MidCardSupervisor";
import BottomCardsSupervisor from "../../components/dashboard/BottomCardsSupervisor";
import Spinner from "../../components/Spinner/Spinner";

export default function Dashboard() {
	const token = useReduxToken();

	const [loading, setLoading] = useState(true);
	const [showBtn, setShowBtn] = useState(true);
	const [usuarios, setUsuarios] = useState({});
	const [operadores, setOperadores] = useState({});
	const [vendedores, setVendedores] = useState({});
	const [vendido, setVendido] = useState({});
	const [relationVentas, setRelationVentas] = useState({});
	const [ventasDirectas, setVentasDirectas] = useState({});
	const [ventasApplication, setVentasApplication] = useState({});
	const [usuariosComprado, setUsuariosComprado] = useState({});
	const [ventasCulminadas, setVentasCulminadas] = useState({});
	const [relationOperadores, setRelationOperadores] = useState({});
	const [relationAgendamiento, setRelationAgendamiento] = useState([]);
	const [relationVentasGraph, setRelationVentasGraph] = useState([]);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [reportType, setReportType] = useState("all_sellers");
	const [timeDateFilterStart, setTimeDateFilterStart] = useState("");
	const [timeDateFilterEnd, setTimeDateFilterEnd] = useState("");
	const [filteredDaysCount, setFilteredDaysCount] = useState(7);
	const [usuariosGraph, setUsuariosGraph] = useState([]);
	const [operatorGraph, setOperatorGraph] = useState([]);
	const [salesGraph, setSalesGraph] = useState([]);
	const [ventasVendedor, setVentasVendedor] = useState({});
	const [leads, setLeads] = useState({});
	const [prospects, setProspects] = useState({});
	const [ventasAppVendedor, setVentasAppVendedor] = useState({});
	const [ventasDia, setVentasDia] = useState({});
	const [percentCard4, setPercentCard4] = useState({});
	const [planMasVendido, setPlanMasVendido] = useState({});
	const [planesMasVendidos, setPlanesMasVendidos] = useState({});
	const [graphVentasDia, setGraphVentasDia] = useState([]);
	const [totalVendedores, setTotalVendedores] = useState({});
	const [vendedoresGraph, setVendedoresGraph] = useState([]);
	const [ventasConsolidadas, setVentasConsolidadas] = useState({});
	const [ventasConsolidadoGraph, setVentasConsolidadoGraph] = useState([]);
	const [relationProspects, setRelationProspects] = useState({});
	const [totalSales, setTotalSales] = useState("");
	const [porcentajeTotalSales, setPorcentajeTotalSales] = useState("");

	const dataState = useSelector((state) => state.authState);

	async function initial() {
		setLoading(true);
		try {
			const data = await myFetchGet(
				`api/v1/dashboard/get_dashboard/?inicio_fecha=${
					startDate || timeDateFilterStart
				}&fin_fecha=${endDate || timeDateFilterEnd}&for_role=${reportType}`,
				token
			);
			// Rol administrador y Rol multifiber
			setUsuarios(data.usuarios);
			setOperadores(data.operadores);
			setVendedores(data.vendedores);
			setRelationVentas(data.ventas);
			setVentasApplication(data.ventas_aplicacion);
			setVentasDirectas(data.ventas_directas);
			setUsuariosComprado(data.usuarios_comprado);
			setRelationOperadores(data.relacion_operadores);
			setRelationAgendamiento(data.relacion_agendaimiento);

			//Rol administrador y vendedor
			setVendido(data.plan_mas_vendido !== null ? data.plan_mas_vendido : "");
			setVentasCulminadas(data.ventas_culminadas);

			//Rol vendedor
			setVentasVendedor(data.total_ventas);
			setProspects(data.total_prospectos);
			setLeads(data.total_leads);
			setVentasAppVendedor(data.ventas_app);
			setVentasDia(data.ventas_del_dia);
			setPercentCard4(data.plan_mas_vendido);
			setPlanMasVendido(
				data.plan_mas_vendido.plan !== null ? data.plan_mas_vendido.plan : ""
			);
			setPlanesMasVendidos(data?.planes_mas_vendidos);

			// Rol supervisor
			setTotalVendedores(data.relacion_ventas);
			setVentasConsolidadas(data.ventas_consolidadas);
			setRelationProspects(data.Relacion_prospecto);

			if (
				dataState.rol === "administrador" ||
				dataState.rol === "multifiber" ||
				dataState.rol === "multifiber_comercial"
			) {
				setRelationVentasGraph(data.relacion_ventas);

				if (data.usuarios_comprado.grafica.length === 1) {
					const dataUsuario = data.usuarios_comprado.grafica[0].usuarios;

					setUsuariosGraph([
						{
							usuarios: dataUsuario,
						},
						{
							usuarios: dataUsuario,
						},
					]);
				} else {
					setUsuariosGraph(data.usuarios_comprado.grafica);
				}

				if (data.relacion_operadores.grafica.length === 1) {
					const dataOperadores = data.relacion_operadores.grafica[0].operadores;

					setOperatorGraph([
						{
							operadores: dataOperadores,
						},
						{
							operadores: dataOperadores,
						},
					]);
				} else {
					setOperatorGraph(data.relacion_operadores.grafica);
				}
			}

			if (
				dataState.rol === "vendedor" ||
				dataState.rol === "supervisor_comercial" ||
				dataState.rol === "administrador_isp"
			) {
				setGraphVentasDia(data.ventas_del_dia.grafica);
			}

			if (
				dataState.rol === "supervisor_comercial" ||
				dataState.rol === "administrador_isp"
			) {
				setSalesGraph(data.total_ventas.grafica);
				setTotalSales(data.total_ventas.total);
				setPorcentajeTotalSales(data.total_ventas.porcentaje);

				if (data.relacion_ventas.grafica.length === 1) {
					const dataSales = data.relacion_ventas.grafica[0].vendedores;

					setVendedoresGraph([
						{
							vendedores: dataSales,
						},
						{
							vendedores: dataSales,
						},
					]);
				} else {
					setVendedoresGraph(data.relacion_ventas.grafica);
				}

				if (data.ventas_consolidadas.grafica.length === 1) {
					const dataSales = data.ventas_consolidadas.grafica[0].venta;

					setVentasConsolidadoGraph([
						{
							venta: dataSales,
						},
						{
							venta: dataSales,
						},
					]);
				} else {
					setVentasConsolidadoGraph(data.ventas_consolidadas.grafica);
				}
			}

			setLoading(false);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	}

	useEffect(() => {
		if (
			startDate !== "" ||
			endDate !== "" ||
			timeDateFilterStart !== "" ||
			timeDateFilterEnd !== ""
		) {
			initial();
		}
	}, [startDate, endDate, timeDateFilterEnd, timeDateFilterStart, reportType]);

	// pdf

	const hiddingBtn = () => {
		setShowBtn(false);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowBtn(true);
		}, 10000);

		return () => clearTimeout(timer);
	}, [showBtn]);

	async function generatePDF() {
		await hiddingBtn();
		const selectedArea = document.getElementById("selected_pdf_area");
		html2canvas(selectedArea).then((canvas) => {
			const imgData = canvas.toDataURL("image/png");

			const pdf = new jsPDF("l", "mm", [420, 380]);
			const width = pdf.internal.pageSize.getWidth() - 10;
			const height = pdf.internal.pageSize.getHeight() - 10;
			pdf.addImage(imgData, "PNG", 10, 10, width, height);
			pdf.save("Oraculo_Metrics.pdf");
		});
	}

	return (
		<div id="selected_pdf_area">
			<Container>
				<HeaderDashboard
					showBtn={showBtn}
					generatePDF={generatePDF}
					setStartDate={setStartDate}
					setEndDate={setEndDate}
					setReportType={setReportType}
					setTimeFilterStart={setTimeDateFilterStart}
					setTimeFilterEnd={setTimeDateFilterEnd}
					setFilteredDaysCount={setFilteredDaysCount}
				/>
				{loading === false ? (
					<>
						{(dataState.rol === "administrador" ||
							dataState.rol === "multifiber" ||
							dataState.rol === "multifiber_comercial") && (
							<>
								<TopCards
									card1Title={"Usuarios"}
									card1Total={usuarios.total}
									card1Percent={usuarios.porcentaje}
									card2Title={"Operadores"}
									card2Total={operadores.total}
									card2Percent={operadores.porcentaje}
									card3Title={"Vendedores"}
									card3Total={vendedores.total}
									card3Percent={vendedores.porcentaje}
									card4Operator={vendido.plan?.plan__operator__name}
									card4Plan={vendido.plan?.plan__tradename}
									card4Percent={vendido.porcentaje}
									img1={Iconos_tarjeta1}
									img2={Iconos_tarjeta2}
									img3={Iconos_tarjeta3}
									filteredDaysCount={filteredDaysCount}
									startDate={startDate}
									endDate={endDate}
								/>
								<MidCards
									titleVentas={"Relación de ventas"}
									totalVentas={relationVentas.total}
									porcentajeVentas={relationVentas.porcentaje}
									porcentajeApplication={ventasApplication.porcentaje}
									porcentajeDirectas={ventasDirectas.porcentaje}
									porcentajeTotalComprado={usuariosComprado.porcentaje}
									porcentajeRespectoComprado={
										usuariosComprado.porcentaje_dia_anterior
									}
									totalCulminadas={ventasCulminadas.porcentaje}
									porcentajeTotalCulminadas={
										ventasCulminadas.porcentaje_dia_anterior
									}
									dataGraphRelation={relationVentasGraph}
									dataGraphUser={usuariosGraph}
									dataGraphSales={salesGraph}
									pieChartData={relationAgendamiento}
								/>
								<BottomCards
									TotalOperadores={relationOperadores.total_operadores}
									porcentajeOperadores={
										relationOperadores.porcentaje_dia_anterior
									}
									operator={relationOperadores.operadores}
								/>

								<div id="image"></div>
							</>
						)}

						{dataState.rol === "vendedor" && (
							<>
								<TopCards
									card1Title={"Ventas"}
									card1Total={ventasVendedor.total}
									card1Percent={ventasVendedor.porcentaje}
									card2Title={"Prospectos"}
									card2Total={prospects.total}
									card2Percent={prospects.porcentaje}
									card3Title={"Leads"}
									card3Total={leads.total}
									card3Percent={leads.porcentaje}
									card4Operator={planMasVendido.plan__operator__name}
									card4Plan={planMasVendido.plan__tradename}
									card4Percent={percentCard4.porcentaje}
									img1={Iconos_Vendedor_tarjeta1}
									img2={Iconos_Vendedor_tarjeta2}
									img3={Iconos_Vendedor_tarjeta3}
									filteredDaysCount={filteredDaysCount}
									startDate={startDate}
									endDate={endDate}
								/>
								<MidCardSupervisor
									ventasDia={ventasDia.total}
									porcentajeVentas={ventasDia.porcentaje}
									porcentajeApplication={ventasAppVendedor.porcentaje}
									porcentajeDirectas={ventasDirectas?.porcentaje}
									totalCulminadas={ventasCulminadas.total}
									porcentajeTotalCulminadas={ventasCulminadas.porcentaje}
									dataGraphRelation={graphVentasDia}
									dataGraphSales={salesGraph}
									mostSoldPlans={planesMasVendidos}
								/>
							</>
						)}

						{(dataState.rol === "supervisor_comercial" ||
							dataState.rol === "administrador_isp") && (
							<>
								<TopCards
									card1Title={"Ventas"}
									card1Total={ventasVendedor.total}
									card1Percent={ventasVendedor.porcentaje}
									card2Title={"Prospectos"}
									card2Total={prospects.total}
									card2Percent={prospects.porcentaje}
									card3Title={"Leads"}
									card3Total={leads.total}
									card3Percent={leads.porcentaje}
									card4Operator={planMasVendido?.plan__operator__name}
									card4Plan={planMasVendido.plan__tradename}
									card4Percent={percentCard4.porcentaje}
									img1={Iconos_Vendedor_tarjeta1}
									img2={Iconos_Vendedor_tarjeta2}
									img3={Iconos_Vendedor_tarjeta3}
									filteredDaysCount={filteredDaysCount}
									startDate={startDate}
									endDate={endDate}
								/>
								<MidCardSupervisor
									ventasDia={ventasDia.total}
									porcentajeVentas={ventasDia.porcentaje}
									porcentajeApplication={ventasAppVendedor.porcentaje}
									porcentajeDirectas={ventasDirectas?.porcentaje}
									totalCulminadas={ventasCulminadas.total}
									porcentajeTotalCulminadas={ventasCulminadas.porcentaje}
									dataGraphRelation={graphVentasDia}
									dataGraphSales={salesGraph}
									totalVentas={totalSales}
									porcentajeTotalVentas={porcentajeTotalSales}
								/>
								<BottomCardsSupervisor
									totalVendedores={totalVendedores.total}
									percentVendedores={totalVendedores.porcentaje}
									vendedoresTable={totalVendedores.vendedores}
								/>
							</>
						)}
					</>
				) : (
					<div className="spinner-container">
						<Spinner />
					</div>
				)}
			</Container>
		</div>
	);
}

const Container = styled.div`
	display: flex;
	flex-flow: column;
	flex: 1;

	h1,
	p {
		margin: 0 !important;
		padding: 0 !important;
	}

	.column {
		flex-flow: column;
	}

	.row {
		display: flex;
		margin: 0 !important;
		padding: 0 !important;
	}

	.center {
		align-items: center;
	}

	.space-between {
		justify-content: space-between;
	}

	.full-width {
		width: 100%;
	}

	.spinner-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 60vh;
	}

	// -----------RESPONSIVE---------

	@media (max-width: 1440px) {
		.mid_section .recents .description .circle_icon {
			margin-left: 28%;
		}
	}

	@media (max-width: 1220px) {
		.mid_section .recents .description .circle_icon {
			margin-left: 15%;
		}
	}

	@media (max-width: 1024px) {
		.mid_section,
		.bottom_section {
			flex-flow: column;
			justify-content: center;
			align-items: center;
		}
		.mid_section .recents,
		.bottom_section .left {
			width: 100%;
		}
		.mid_section .donut_graph,
		.bottom_section .right {
			width: 100%;
		}
	}
`;
