import React from "react";
import { TabsPanel } from "../../components/tabs/Tabs";
import { Panel, PanelHeader } from "../../components/panel/panel";
import { Tab, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import GeneralSalePanel from "./components/GeneralSalePanel";
import SalesGallery from "./components/SalesGallery";
import Compass from "./components/Compass";
import HistoryPanel from "./components/HistoryPanel";
import { useLocation, useHistory } from "react-router-dom";
import ManualScheduling from "./components/ManualScheduling";
import dayjs from "dayjs";
import CustomTooltip from "../../components/customTooltip/CustomTooltip";
import styles from "./DetailsSales.module.css";

const DetailsSales = () => {
	const location = useLocation();
	const history = useHistory();

	const { is_brujula, created } = location?.state?.params;

	return (
		<Panel>
			<PanelHeader>
				<div className={`${styles["row"]} ${styles["space-between"]}`}>
					<div className={styles["column"]}>
						<div className={`${styles["row"]} ${styles["align-center"]}`}>
							<span className={styles["header-title"]}>Detalle de Venta</span>

							<CustomTooltip
								subtitle={
									"Información detallada del registro de venta según corresponda."
								}
							/>
							<div className={styles["header-breadcrumb"]}>
								Ventas |
								<b
									className={styles["header-breadcrumb-text"]}
									onClick={() => history.push("/listadoDeVentas")}
								>
									Listado de ventas
								</b>
								| Detalle
							</div>
						</div>
					</div>
					<div className={`${styles["column"]} ${styles["justify-center"]}`}>
						<span className={styles["created-date"]}>
							<b>Fecha de creación:</b>{" "}
							{dayjs(created, "DD/MM/YYYY").format("DD MMMM YYYY")}
						</span>
					</div>
				</div>
			</PanelHeader>

			<TabsPanel selectedTabClassName={`is-selected ${styles["selected-tab"]}`}>
				<div className={styles["details-container"]}>
					<TabList className="TabsList">
						<Tab className="Tab">General</Tab>
						<Tab className="Tab">Documentos</Tab>
						{is_brujula === "Sí" || is_brujula === true ? (
							<Tab className="Tab">Brújula</Tab>
						) : (
							(is_brujula === "No" || is_brujula === false) && (
								<Tab className="Tab">Agendamiento manual</Tab>
							)
						)}
						<Tab className="Tab">Histórico</Tab>
					</TabList>

					<TabPanel>
						<GeneralSalePanel data={location?.state?.params} />
					</TabPanel>
					<TabPanel>
						<SalesGallery data={location?.state?.params} />
					</TabPanel>
					<TabPanel>
						{is_brujula === "Sí" || is_brujula === true ? (
							<Compass data={location?.state?.params} />
						) : (
							(is_brujula === "No" || is_brujula === false) && (
								<ManualScheduling data={location?.state?.params} />
							)
						)}
					</TabPanel>
					<TabPanel>
						<HistoryPanel data={location?.state?.params} />
					</TabPanel>
				</div>
			</TabsPanel>
		</Panel>
	);
};

export default DetailsSales;
