import { useEffect } from "react";
import {
	GeoSearchControl,
	OpenStreetMapProvider,
	GoogleProvider,
} from "leaflet-geosearch";
import { useMap } from "react-leaflet";
import "leaflet-geosearch/dist/geosearch.css";
import { useSelector } from "react-redux";

function MapSearch() {
	const map = useMap();
	const dataState = useSelector((state) => state.authState);

	const serverName = process.env.REACT_APP_SERVER;
	const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

	useEffect(() => {
		const searchControl = new GeoSearchControl({
			provider:
				serverName === "PRODUCTION"
					? new GoogleProvider({ apiKey: googleApiKey })
					: serverName === "DEVELOPMENT" || serverName === "STAGE"
					? new OpenStreetMapProvider({
							params: { countrycodes: dataState.operatorCountryIso },
					  })
					: null,
			style: "bar",
			autoCompleteDelay: 2000,
			showMarker: true,
			showPopup: false,
			autoClose: true,
			retainZoomLevel: false,
			animateZoom: true,
			keepResult: true,
			searchLabel: "Ingrese la dirección a buscar",
			notFoundMessage:
				"No pudimos encontrar esa dirección, por favor ingrese otra",
		});

		map.addControl(searchControl);

		return () => {
			map.removeControl(searchControl);
		};
	}, [map]);

	return null;
}

export default MapSearch;
