import React from "react";
import styled from "styled-components";
import devices from "../../helpers/sizes";
import { smallSizes, mediumSizes, largeSizes } from "../../helpers/sizes";

export default function TableText({ value, style }) {
	return (
		<TableTextContainer>
			<span className="table-text" style={style}>
				{value}
			</span>
		</TableTextContainer>
	);
}

const TableTextContainer = styled.div`
	.table-text {
		color: #172b4d;
		font-size: ${smallSizes.tableTextContainer.tableText};
	}

	@media screen and (min-width: ${devices.smallDesk}) {
		.table-text {
			font-size: ${smallSizes.tableTextContainer.tableText};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}) {
		.table-text {
			font-size: ${mediumSizes.tableTextContainer.tableText};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}) {
		.table-text {
			font-size: ${largeSizes.tableTextContainer.tableText};
		}
	}
`;
