import React from "react";
import { TabsPanel } from "../../components/tabs/Tabs";
import { Panel, PanelHeader } from "../../components/panel/panel";
import { Tab, TabList, TabPanel } from "react-tabs";
import GeneralUserPanel from "./components/GeneralUserPanel";
import AddressesPanel from "./components/AddressesPanel";
import HistoryPanel from "./components/HistoryPanel";
import PurchasesPanel from "./components/PurchasesPanel";
import { useLocation, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import CustomTooltip from "../../components/customTooltip/CustomTooltip";
import styles from "./DetailsUser.module.css";

const DetailsUser = () => {
	const location = useLocation();
	const history = useHistory();

	const { created, full_name } = location?.state?.params;

	return (
		<Panel>
			<PanelHeader>
				<div className={`${styles["row"]} ${styles["space-between"]}`}>
					<div className={styles["column"]}>
						<div className={`${styles["row"]} ${styles["align-center"]}`}>
							<span className={styles["header-title"]}>
								Usuario {full_name}
							</span>
							<CustomTooltip
								subtitle={
									"Información detallada del usuario según corresponda."
								}
							/>
							<div className={styles["header-breadcrumb"]}>
								Usuarios |
								<span
									className={styles["header-breadcrumb-text"]}
									onClick={() => history.push("/listadoDeUsuarios")}
								>
									Listado de usuarios
								</span>
								| Detalle
							</div>
						</div>
					</div>
					<div className={`${styles["column"]} ${styles["justify-center"]}`}>
						<span className={styles["created-date"]}>
							<b>Fecha de creación:</b>{" "}
							{dayjs(created, "DD/MM/YYYY").format("DD MMMM YYYY")}
						</span>
					</div>
				</div>
			</PanelHeader>

			<TabsPanel selectedTabClassName={`is-selected ${styles["selected-tab"]}`}>
				<div className={styles["details-container"]}>
					<TabList className="TabsList">
						<Tab className="Tab">General</Tab>
						<Tab className="Tab">Direcciones</Tab>
						<Tab className="Tab">Compras</Tab>
						<Tab className="Tab">Histórico</Tab>
					</TabList>

					<TabPanel>
						<GeneralUserPanel data={location?.state?.params} />
					</TabPanel>
					<TabPanel>
						<AddressesPanel data={location?.state?.params} />
					</TabPanel>
					<TabPanel>
						<PurchasesPanel data={location?.state?.params} />
					</TabPanel>
					<TabPanel>
						<HistoryPanel data={location?.state?.params} />
					</TabPanel>
				</div>
			</TabsPanel>
		</Panel>
	);
};

export default DetailsUser;
