import React from "react";
import styled from "styled-components";
import { Skeleton, Space } from "antd";

export function Loading({ count = 5, size = "large" }) {
	const inputSkeletons = Array.from({ length: count }, (_, i) => (
		<Skeleton.Input size={size} active={true} block={true} key={i} />
	));

	return (
		<Space
			direction="vertical"
			size={"middle"}
			style={{ margin: "5% 0 0 0", padding: "0 42px", width: "100%" }}
		>
			{inputSkeletons}
		</Space>
	);
}

export function LoadingForm({ count = 4, buttons = 2, showButtons = true }) {
	const skeletonForm = Array.from({ length: count }, (_, i) => (
		<div className="row space-between" key={i}>
			<div className="column flex">
				<Space direction="vertical" size={"small"} style={{ width: "95%" }}>
					<Skeleton.Input size="small" active={true} />
					<Skeleton.Input size="default" active={true} block={true} />
				</Space>
			</div>
			<div className="column flex">
				<Space direction="vertical" size={"small"} style={{ width: "100%" }}>
					<Skeleton.Input size="small" active={true} />
					<Skeleton.Input size="default" active={true} block={true} />
				</Space>
			</div>
		</div>
	));

	const skeletonFormButtons = Array.from({ length: buttons }, (_, i) => (
		<Skeleton.Button size="large" active={true} key={i} />
	));

	return (
		<Container>
			<Space direction="vertical" size={30} style={{ width: "100%" }}>
				<Skeleton.Input size="small" active={true} />
				{skeletonForm}
				{showButtons ? (
					<div className="row row_end">
						<Space size={"middle"}>{skeletonFormButtons}</Space>
					</div>
				) : null}
			</Space>
		</Container>
	);
}

export function LoadingDetail({
	direction = "left",
	buttons = 2,
	showButtons = true,
}) {
	const skeletonDetail = Array.from({ length: 6 }, (_, i) => (
		<div className="row space-between" key={i}>
			<div className="column flex">
				<Space direction="vertical" size={"small"} style={{ width: "95%" }}>
					<Skeleton.Input size="small" active={true} />
					<Skeleton.Input size="default" active={true} block={true} />
				</Space>
			</div>
			<div className="column flex">
				<Space direction="vertical" size={"small"} style={{ width: "100%" }}>
					<Skeleton.Input size="small" active={true} />
					<Skeleton.Input size="default" active={true} block={true} />
				</Space>
			</div>
		</div>
	));

	const skeletonDetailButtons = Array.from({ length: buttons }, (_, i) => (
		<Skeleton.Button size="large" active={true} key={i} />
	));

	return (
		<Container>
			<Space direction="vertical" size={15} style={{ width: "100%" }}>
				{showButtons ? (
					<div className="row row_end">
						<Space size={"middle"}>{skeletonDetailButtons}</Space>
					</div>
				) : null}

				<div className="row">
					{direction === "left" ? (
						<>
							<div
								className="column"
								style={{ width: "60%", marginRight: "5%" }}
							>
								<Space direction="vertical" size={30} style={{ width: "100%" }}>
									{skeletonDetail}
								</Space>
							</div>
							<div className="column" style={{ width: "35%" }}>
								<Skeleton active={true} paragraph={{ rows: 6 }} />
							</div>
						</>
					) : direction === "right" ? (
						<>
							<div
								className="column"
								style={{ width: "35%", marginRight: "5%" }}
							>
								<Skeleton active={true} paragraph={{ rows: 6 }} />
							</div>
							<div className="column" style={{ width: "60%" }}>
								<Space direction="vertical" size={30} style={{ width: "100%" }}>
									{skeletonDetail}
								</Space>
							</div>
						</>
					) : null}
				</div>
			</Space>
		</Container>
	);
}

export function LoadingButton() {
	return <Skeleton.Button size="default" active={true} block={true} />;
}

export function LoadingHistory({ count = 8 }) {
	const skeletonHistory = Array.from({ length: count }, (_, i) => (
		<div className="row" key={i}>
			<div className="column" style={{ width: "25%", paddingRight: "5%" }}>
				<Skeleton.Input size="small" active={true} block={true} />
			</div>
			<div className="column" style={{ width: "35%" }}>
				<Skeleton.Input size="small" active={true} block={true} />
			</div>
		</div>
	));

	return (
		<Container>
			<Space direction="vertical" size={20} style={{ width: "100%" }}>
				{skeletonHistory}
			</Space>
		</Container>
	);
}

const Container = styled.div`
	.row {
		margin: 0 !important;
		margin-top: 5px;
		display: flex;
	}

	.row_end {
		justify-content: flex-end;
	}

	.space-between {
		justify-content: space-between;
		margin: 0 !important;
	}

	.column {
		display: flex;
		flex-flow: column;
	}

	.flex {
		flex: 1;
	}
`;
