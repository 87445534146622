import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Panel, PanelHeader } from "../../components/panel/panel";
import TableData from "../../components/table/TableData";
import { myFetchGet, myFetch, myExportData } from "../../services/services";
import dayjs from "dayjs";
import { Select, DatePicker, Input, Button, notification } from "antd";
import { useHistory } from "react-router-dom";
import CustomModal from "../../components/modal/CustomModal";
import FormSale from "./components/FormSale";
import { Loading } from "../../components/loading/Loading";
import { useSelector } from "react-redux";
import { useReduxToken } from "../../hooks/useReduxToken";
import ColorCircle from "../../components/colorCircle/ColorCircle";
import TextColored from "../../components/textColored/TextColored";
import TableText from "../../components/tableText/TableText";
import CustomTooltip from "../../components/customTooltip/CustomTooltip";
import fileDownload from "js-file-download";
import styles from "./Sales.module.css";
import TablePagination from "../../components/tablePagination/TablePagination";

const Sales = () => {
	const token = useReduxToken();
	const dataState = useSelector((state) => state.authState);

	const addSale = dataState.permissions.includes("add_sales");
	const viewSale = dataState.permissions.includes("datatable_view_sales");

	const [loadingTable, setLoadingTable] = useState(true);
	const [tableColumns, setTableColumns] = useState([]);
	const [totalTableSize, setTotalTableSize] = useState(0);
	const [tableRows, setTableRows] = useState([]);
	const [paginationSettings, setPaginationSettings] = useState([1, 10]);
	const [rowsParams, setRowsParams] = useState({ offset: 10, start: 0 });
	const [isVisibleForm, setIsVisibleForm] = useState(false);

	const [operatorsList, setOperatorsList] = useState();
	const [operatorID, setOperatorID] = useState(
		dataState.rol === "administrador_isp" ||
			dataState.rol === "supervisor_comercial" ||
			dataState.rol === "vendedor"
			? dataState.operatorID
			: undefined
	);
	const [channel, setChannel] = useState();
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [searchData, setSearchData] = useState();
	const [sorterOrder, setSorterOrder] = useState("desc");
	const [columnFilterValue, setColumnFilterValue] = useState([]);
	const [exportFilters, setExportFilters] = useState({});

	const clearData = undefined;

	const { RangePicker } = DatePicker;
	const { Search } = Input;

	const initial = async () => {
		const operator = `["operator","equal","` + operatorID + `"]`;
		const channelID = `["channel","equal","` + channel + `"]`;
		const period =
			`["created","gt","` +
			startDate +
			` 00:00"], ["created","lte","` +
			endDate +
			` 23:59"]`;
		const head = `[`;
		const tail = `]`;
		let filters = {};

		if (
			operatorID !== undefined &&
			channel === undefined &&
			startDate === undefined &&
			endDate === undefined &&
			searchData === undefined
		) {
			//console.log("1");
			filters = {
				filters: head + operator + tail,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			channel !== undefined &&
			operatorID === undefined &&
			startDate === undefined &&
			endDate === undefined &&
			searchData === undefined
		) {
			//console.log("2");
			filters = {
				filters: head + channelID + tail,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			startDate !== undefined &&
			endDate !== undefined &&
			operatorID === undefined &&
			channel === undefined &&
			searchData === undefined
		) {
			//console.log("3");
			filters = {
				filters: head + period + tail,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			searchData !== undefined &&
			operatorID === undefined &&
			channel === undefined &&
			startDate === undefined &&
			endDate === undefined
		) {
			//console.log("4");
			filters = {
				search: searchData,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			operatorID !== undefined &&
			channel !== undefined &&
			startDate === undefined &&
			endDate === undefined &&
			searchData === undefined
		) {
			//console.log("1 y 2");
			filters = {
				filters: head + operator + `, ` + channelID + tail,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			operatorID !== undefined &&
			startDate !== undefined &&
			endDate !== undefined &&
			searchData === undefined &&
			channel === undefined
		) {
			//console.log("1 y 3");
			filters = {
				filters: head + operator + `, ` + period + tail,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			operatorID !== undefined &&
			searchData !== undefined &&
			startDate === undefined &&
			endDate === undefined &&
			channel === undefined
		) {
			//console.log("1 y 4");
			filters = {
				filters: head + operator + tail,
				search: searchData,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			channel !== undefined &&
			startDate !== undefined &&
			endDate !== undefined &&
			operatorID === undefined &&
			searchData === undefined
		) {
			//console.log("2 y 3");
			filters = {
				filters: head + channelID + `, ` + period + tail,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			channel !== undefined &&
			searchData !== undefined &&
			operatorID === undefined &&
			startDate === undefined &&
			endDate === undefined
		) {
			//console.log("2 y 4");
			filters = {
				filters: head + channelID + tail,
				search: searchData,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			startDate !== undefined &&
			endDate !== undefined &&
			searchData !== undefined &&
			channel === undefined &&
			operatorID === undefined
		) {
			//console.log("3 y 4");
			filters = {
				filters: head + period + tail,
				search: searchData,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			operatorID !== undefined &&
			channel !== undefined &&
			startDate !== undefined &&
			endDate !== undefined &&
			searchData === undefined
		) {
			//console.log("1, 2 y 3");
			filters = {
				filters: head + operator + `, ` + channelID + `, ` + period + tail,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			operatorID !== undefined &&
			startDate !== undefined &&
			endDate !== undefined &&
			searchData !== undefined &&
			channel === undefined
		) {
			//console.log("1, 3 y 4");
			filters = {
				filters: head + operator + `, ` + period + tail,
				search: searchData,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			channel !== undefined &&
			startDate !== undefined &&
			endDate !== undefined &&
			searchData !== undefined &&
			operatorID === undefined
		) {
			//console.log("2, 3 y 4");
			filters = {
				filters: head + channelID + `, ` + period + tail,
				search: searchData,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			operatorID !== undefined &&
			channel !== undefined &&
			startDate !== undefined &&
			endDate !== undefined &&
			searchData !== undefined
		) {
			// console.log("1, 2, 3 y 4");
			filters = {
				filters: head + operator + `, ` + channelID + `, ` + period + tail,
				search: searchData,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			dataState.rol === "administrador_isp" ||
			dataState.rol === "supervisor_comercial" ||
			dataState.rol === "vendedor"
		) {
			filters = {
				filters: head + operator + tail,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else {
			filters = {
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		}

		let newFilters = {
			...filters,
			order_field: "ID",
			order_type: sorterOrder,
		};

		if (columnFilterValue.length === 1 && !newFilters.filters) {
			newFilters = {
				...newFilters,
				filters: `[["status","equal","` + columnFilterValue[0] + `"]]`,
			};
		} else if (
			(columnFilterValue.length === 0 && !newFilters.filters) ||
			(columnFilterValue.length === 0 && newFilters.filters)
		) {
			newFilters = newFilters;
		} else if (columnFilterValue.length === 1 && newFilters.filters) {
			newFilters.filters = newFilters.filters.replace(
				"]",
				`], ` + `["status","equal","` + columnFilterValue[0] + `"]`
			);
		}

		setExportFilters(newFilters);

		try {
			const response = await myFetch(
				"api/v1/sales/datatables/",
				token,
				newFilters
			);
			if (response) {
				if (response.size) {
					setTotalTableSize(response.size);
				}
				if (response.data) {
					const rows = response.data.map((row) => {
						const schedulingDateFormat = dayjs(row.scheduling_date).format(
							"DD/MM/YYYY"
						);
						const createdFormat = dayjs(row.created, "DD-MM-YYYY").format(
							"DD/MM/YYYY"
						);
						let isBrujulaFormat = "";
						let channel = "";
						let rowID = "";
						if (row?.is_brujula === true) {
							isBrujulaFormat = "Sí";
						} else if (row?.is_brujula === false) {
							isBrujulaFormat = "No";
						}
						if (row?.channel === 1) {
							channel = "Directa";
						} else if (row?.channel === 2) {
							channel = "Aplicación";
						}
						if (
							dataState.rol === "administrador_isp" ||
							dataState.rol === "supervisor_comercial" ||
							dataState.rol === "vendedor"
						) {
							rowID = row.ID;
						} else {
							rowID = row.id_unique;
						}
						return {
							...row,
							ID: rowID,
							key: rowID,
							created: createdFormat,
							scheduling_date: schedulingDateFormat,
							is_brujula: isBrujulaFormat,
							channel: channel,
						};
					});
					setTableRows(rows);
				}
			}
			setLoadingTable(false);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const updateRowsParams = (keyToUpdate, newValue) => {
		setRowsParams((prevState) => ({
			...prevState,
			[keyToUpdate]: newValue,
		}));
	};

	const modifyPagination = (page, offset) => {
		if (offset !== rowsParams.offset) {
			updateRowsParams("offset", offset);
		} else {
			updateRowsParams("start", page * offset - offset);
		}
		setPaginationSettings([page, offset]);
	};

	const history = useHistory();

	const rowSelection = (item) => {
		history.push("/detalleVentas", { params: item });
	};

	const getColumns = async () => {
		try {
			const data = await myFetchGet("api/v1/sales/datatables_struct", token);
			const columnsTittles = Object.keys(data.columns);
			const columns = [];
			columnsTittles.forEach((columnsTittle) => {
				columns.push({
					onCell:
						columnsTittle === "ID"
							? (record, rowIndex) => {
									return {
										onClick: (event) => {
											rowSelection(record);
										},
										style: { cursor: "pointer" },
										className: "hover-class",
									};
							  }
							: null,
					sorter: columnsTittle === "ID" ? true : null,
					filters:
						columnsTittle === "Estatus"
							? [
									{
										text: "Sin atender",
										value: 1,
									},
									{
										text: "En Instalación",
										value: 5,
									},
									{
										text: "Agendada",
										value: 2,
									},
									{
										text: "Instalada",
										value: 3,
									},
									{
										text: "Cancelada",
										value: 4,
									},
							  ]
							: null,
					filterMultiple: false,
					title: columnsTittle,
					dataIndex: data.columns[columnsTittle].field,
					key: data.columns[columnsTittle].field,
					align: "left",
					width:
						columnsTittle === "ID"
							? "70px"
							: columnsTittle === "Canal"
							? "90px"
							: columnsTittle === "Estatus"
							? "150px"
							: "",
					render:
						columnsTittle === "ID"
							? (value) => <TextColored value={value} />
							: columnsTittle === "Estatus"
							? (value) => (
									<span
										style={{
											display: "flex",
											alignItems: "center",
										}}
									>
										{value === "Sin Atender" ? (
											<ColorCircle color={"#1659e7"} />
										) : value === "Agendada" ? (
											<ColorCircle color={"#F2A900"} />
										) : value === "Instalada" ? (
											<ColorCircle color={"#58E933"} />
										) : value === "Cancelada" ? (
											<ColorCircle color={"#F00A0A"} />
										) : value === "En Instalación" ? (
											<ColorCircle color={"#C424F5"} />
										) : (
											<ColorCircle color={"grey"} />
										)}
										<TableText value={value} />
									</span>
							  )
							: (value) => <TableText value={value} />,
				});
			});
			setTableColumns(columns);
		} catch (e) {
			console.log("error", e);
		}
	};

	const tableChangeHandler = (pagination, filter, sorter, extra) => {
		if (sorter.order === "ascend") {
			setSorterOrder("asc");
		} else if (sorter.order === "descend" || sorter.order === undefined) {
			setSorterOrder("desc");
		}
		if (filter.status) {
			setColumnFilterValue(filter.status);
		} else {
			setColumnFilterValue([]);
		}
	};

	const getOperators = async () => {
		try {
			const operators = await myFetchGet("api/v1/operador/", token);
			const operatorsFilter = operators.filter((obj) => !obj.deleted);
			setOperatorsList(operatorsFilter);
		} catch (e) {
			console.log("error", e);
		}
	};

	useEffect(() => {
		initial();
		getColumns();
	}, [
		operatorID,
		channel,
		startDate,
		endDate,
		searchData,
		rowsParams,
		sorterOrder,
		columnFilterValue,
	]);

	useEffect(() => {
		getOperators();
	}, []);

	useEffect(() => {
		if (viewSale === false) {
			history.replace("/inicio");
		}
	}, []);

	const selectOperatorHandler = (value) => {
		setOperatorID(value);
	};

	const selectChannelHandler = (value) => {
		setChannel(value);
	};

	const dateFormat = "DD/MM/YYYY";

	const dateSelectHandler = (dates, dateStrings) => {
		if (dateStrings[0] === "" && dateStrings[1] === "") {
			setStartDate(clearData);
			setEndDate(clearData);
		} else {
			setStartDate(dateStrings[0]);
			setEndDate(dateStrings[1]);
		}
	};

	const disabledDate = (current) => {
		return current > dayjs().endOf("day");
	};

	const searchHandler = (value) => {
		if (value === "") {
			setSearchData(clearData);
		} else {
			setSearchData(value);
		}
	};

	const onCloseModal = () => {
		setIsVisibleForm(false);
	};

	const onExportData = async () => {
		try {
			const file = await myExportData(
				"api/v1/sales/datatables_download/",
				token,
				exportFilters
			);
			fileDownload(file, "Listado de Ventas.xlsx");
		} catch (e) {
			console.log("error", e);
		}
	};

	return (
		<Panel>
			<PanelHeader>
				<div className={styles["header-container"]}>
					<div className={`${styles["row"]} ${styles["align-center"]}`}>
						<span className={styles["header-title"]}>
							Listado de ventas consolidadas
						</span>
						<CustomTooltip
							subtitle={"Ventas registradas mediante los distintos canales"}
						/>
						<span className={styles["header-breadcrumb"]}>
							Ventas | Listado de ventas
						</span>
					</div>

					{addSale && (
						<Button
							className={styles["create-sale-button"]}
							onClick={setIsVisibleForm}
							icon={
								<i
									className={`fa-solid fa-plus ${styles["create-sale-icon"]}`}
								></i>
							}
						>
							Crear venta
						</Button>
					)}
				</div>
			</PanelHeader>
			<div className={styles["table-container"]}>
				<FilterContainer>
					<span className={styles["filters-title"]}>Opciones de filtrado</span>
					<div className={styles["row"]}>
						{dataState.rol === "administrador_isp" ||
						dataState.rol === "supervisor_comercial" ||
						dataState.rol === "vendedor" ? null : (
							<div className={styles["column"]}>
								<Select
									className={styles["select"]}
									placeholder="Operador"
									onChange={selectOperatorHandler}
									allowClear={true}
									onClear={selectOperatorHandler}
								>
									{operatorsList?.map((item) => (
										<Select.Option key={item?.ID} value={item?.ID}>
											{item?.name}
										</Select.Option>
									))}
								</Select>
							</div>
						)}
						{/* <div className={styles["column"]}>
							<Select
								className={styles["select-channel"]}
								placeholder="Canal"
								onChange={selectChannelHandler}
								allowClear={true}
								onClear={selectChannelHandler}
							>
								<Select.Option value={1}>Directa</Select.Option>
								<Select.Option value={2}>Aplicación</Select.Option>
							</Select>
						</div> */}
						<div className={styles["column"]}>
							<RangePicker
								className={styles["date-picker"]}
								placeholder={["Fecha Inicio", "Fecha Fin"]}
								onChange={dateSelectHandler}
								format={dateFormat}
								disabledDate={disabledDate}
							/>
						</div>
						<div className={`${styles["column"]} ${styles["position-end"]}`}>
							<Search
								className={styles["search-input"]}
								placeholder="Realizar búsqueda"
								allowClear
								onSearch={searchHandler}
							/>
						</div>
					</div>
				</FilterContainer>
				{isVisibleForm && (
					<CustomModal onClick={onCloseModal}>
						<FormSale
							visible={isVisibleForm}
							setVisible={setIsVisibleForm}
							initial={initial}
						/>
					</CustomModal>
				)}

				{loadingTable ? (
					<Loading />
				) : (
					<>
						<TableData
							rows={tableRows}
							columns={tableColumns}
							onChange={tableChangeHandler}
						/>

						<div
							className={`${styles["row"]} ${styles["pagination-container"]}`}
						>
							<TablePagination
								paginationSettings={paginationSettings}
								totalTableSize={totalTableSize}
								onChange={modifyPagination}
								showTotal={(total, range) => (
									<div className={styles["column"]}>
										<span className={styles["pagination"]}>
											Mostrando del {range[0]} al {range[1]} de un total de{" "}
											{total} registros
										</span>
										<span
											className={styles["export-button"]}
											onClick={onExportData}
										>
											<i
												className={`fa-solid fa-file-excel ${styles["export-button-icon"]}`}
											></i>
											Exportar data
										</span>
									</div>
								)}
							/>
						</div>
					</>
				)}
			</div>
		</Panel>
	);
};

export default Sales;

const FilterContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 53px 0 42px;

	.ant-select-selector {
		border-radius: 5px !important;
		border: 1px solid #e0e0e0 !important;
		display: flex;
		align-items: center;
		height: 38px !important;
		font-size: 12px;
	}

	.ant-select-clear {
		display: flex;
		align-items: center;
	}

	.ant-picker .ant-picker-input > input {
		font-size: 12px;
	}

	.ant-input-affix-wrapper:not(:last-child) {
		border-radius: 5px 0 0 5px !important;
		border: 1px solid #e0e0e0;
		height: 38px;
		font-size: 12px;
	}

	.ant-input-affix-wrapper .ant-input-clear-icon {
		display: flex;
	}

	.ant-input-search
		> .ant-input-group
		> .ant-input-group-addon:last-child
		.ant-input-search-button {
		border-radius: 0 5px 5px 0 !important;
		border: 1px solid #e0e0e0;
		height: 38px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
	}

	@media screen and (min-width: 1280px) {
		.ant-select-selector,
		.ant-picker .ant-picker-input > input,
		.ant-input-affix-wrapper:not(:last-child),
		.ant-input-search
			> .ant-input-group
			> .ant-input-group-addon:last-child
			.ant-input-search-button {
			font-size: 12px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-select-selector,
		.ant-picker .ant-picker-input > input,
		.ant-input-affix-wrapper:not(:last-child),
		.ant-input-search
			> .ant-input-group
			> .ant-input-group-addon:last-child
			.ant-input-search-button {
			font-size: 13.5px;
		}
	}

	@media screen and (min-width: 1920px) {
		.ant-select-selector,
		.ant-picker .ant-picker-input > input,
		.ant-input-affix-wrapper:not(:last-child),
		.ant-input-search
			> .ant-input-group
			> .ant-input-group-addon:last-child
			.ant-input-search-button {
			font-size: 15.18px;
		}
	}
`;
