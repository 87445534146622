import React from "react";
import styled from "styled-components";
import devices from "../../helpers/sizes";

export default function CustomLabel({ value }) {
	return (
		<>
			{value === 0 ? (
				<CustomLabelContainer style={{ backgroundColor: "#F2A900" }}>
					<i className="fa-solid fa-star icon"></i>
				</CustomLabelContainer>
			) : value === 1 ? (
				<CustomLabelContainer style={{ backgroundColor: "#426DA9" }}>
					<i className="fa-solid fa-star icon"></i>
				</CustomLabelContainer>
			) : null}
		</>
	);
}

const CustomLabelContainer = styled.div`
	border-radius: 50%;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	.icon {
		font-size: 10px;
		color: white;
	}

	@media screen and (min-width: ${devices.smallDesk}) {
		width: 20px;
		height: 20px;

		.icon {
			font-size: 10px;
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}) {
		width: 20px;
		height: 20px;

		.icon {
			font-size: 10px;
		}
	}

	@media screen and (min-width: ${devices.largeDesk}) {
		width: 25px;
		height: 25px;

		.icon {
			font-size: 13px;
		}
	}
`;
