import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { myFetchGet, myFetch } from "../../../services/services";
import { FormOutlined } from "@ant-design/icons";
import {
	Input,
	Select,
	Space,
	Button,
	Switch,
	Popconfirm,
	notification,
} from "antd";
// import { useHistory } from "react-router-dom";
import { useReduxToken } from "../../../hooks/useReduxToken";
import { useSelector } from "react-redux";
import { regex } from "../../../helpers/regex";
import dayjs from "dayjs";
import {
	LoadingButton,
	LoadingDetail,
} from "../../../components/loading/Loading";
import styles from "./GeneralUserPanel.module.css";
import Br from "../../../components/Br/Br";

const GeneralUserPanel = ({ data }) => {
	const token = useReduxToken();
	const dataState = useSelector((state) => state.authState);
	const [loading, setLoading] = useState(true);

	const changeUserApp = dataState.permissions.includes("change_userapp");

	/*Lógica de la vista */
	const [modify, setModify] = useState(false);
	const [operatorList, setOperatorList] = useState([]);

	/*Datos de los inputs */
	const [originalData, setOriginalData] = useState({});
	const [name, setName] = useState();
	const [lastName, setLastName] = useState();
	const [phone, setPhone] = useState();
	const [email, setEmail] = useState();
	const [email2, setEmail2] = useState();
	const [password, setPassword] = useState();
	const [seller, setSeller] = useState(false);
	const [phoneCountry, setPhoneCountry] = useState("+56");
	const [lastLogin, setLastLogin] = useState();
	const [loginMethod, setLoginMethod] = useState();
	const [mobileDevice, setMobileDevice] = useState();
	const [operator, setOperator] = useState();
	const [userOperators, setUserOperators] = useState([]);

	/*Validaciones */
	const [errors, setErrors] = useState({
		name: "",
		lastName: "",
		phone: "",
		email: "",
		email2: "",
	});
	const [errorStatus, setErrorStatus] = useState({
		name: false,
		lastName: false,
		phone: false,
		email: false,
		email2: false,
	});

	const userPhone = phoneCountry + " " + phone;

	const { id_unique } = data;

	// const history = useHistory();

	const initial = async () => {
		setLoading(true);
		try {
			const data = await myFetchGet(`api/v1/userapp/${id_unique}/`, token);
			setOriginalData(data);
			setSeller(data.is_seller);
			setName(data.name);
			setLastName(data.last_name);
			setEmail(data.email);
			setEmail2(data.email2);
			setLastLogin(data.last_login);
			setLoginMethod(data.login_method);
			setMobileDevice(data.mobile_device);
			setOperator(data.operator);
			if (data.phone) {
				userPhoneHandler(data.phone);
			}
			setUserOperators(data.operators);

			getOperators();
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const getOperators = async () => {
		try {
			const operators = await myFetchGet("api/v1/operador/", token);
			setOperatorList(operators);
			setLoading(false);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	useEffect(() => {
		let nameError = "";
		let lastNameError = "";
		let phoneError = "";
		let emailError = "";
		let email2Error = "";

		if (!name) {
			nameError = "Ingrese un nombre";
		} else if (regex.regexNames.test(name) === false) {
			nameError = "Debe ingresar solo letras";
		}

		if (!lastName) {
			lastNameError = "Ingrese un apellido";
		} else if (regex.regexNames.test(lastName) === false) {
			lastNameError = "Debe ingresar solo letras";
		}

		if (!phone) {
			phoneError = "Ingrese un número de teléfono";
		} else if (regex.regexNumbers.test(phone) === false) {
			phoneError = "Debe ingresar solo números";
		}

		if (!email) {
			emailError =
				"Ingrese una dirección de correo para autenticarse en Oráculo";
		} else if (regex.regexSimpleEmail.test(email) === false) {
			emailError = "Ingrese una dirección de correo valida";
		}

		if (!email2) {
			email2Error = "Ingrese una dirección de correo personal";
		} else if (regex.regexSimpleEmail.test(email2) === false) {
			email2Error = "Ingrese una dirección de correo valida";
		}

		setErrors({
			name: nameError,
			lastName: lastNameError,
			phone: phoneError,
			email: emailError,
			email2: email2Error,
		});
	}, [name, lastName, phone, email, email2, errorStatus]);

	const validateInput = () => {
		if (errors.name) {
			setErrorStatus((prevState) => ({ ...prevState, name: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, name: false }));
		}

		if (errors.lastName) {
			setErrorStatus((prevState) => ({ ...prevState, lastName: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, lastName: false }));
		}

		if (errors.phone) {
			setErrorStatus((prevState) => ({ ...prevState, phone: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, phone: false }));
		}

		if (errors.email) {
			setErrorStatus((prevState) => ({ ...prevState, email: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, email: false }));
		}

		if (errors.email2) {
			setErrorStatus((prevState) => ({ ...prevState, email2: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, email2: false }));
		}

		setErrorStatus({
			name: false,
			lastName: false,
			phone: false,
			email: false,
			email2: false,
		});
	};

	const onSubmit = async (data) => {
		if (Object.values(errors).some((value) => value !== "")) {
			validateInput();
			notification.warning({
				className: "notification",
				message: "Por favor, completa todos los campos obligatorios",
				placement: "bottomLeft",
				duration: 2.5,
			});
			return;
		}

		setErrorStatus({
			name: false,
			lastName: false,
			phone: false,
			email: false,
			email2: false,
		});

		try {
			await myFetch(`api/v1/userapp/${id_unique}/`, token, data, "PATCH");
			notification.success({
				className: "notification",
				message: "Información modificada exitosamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
			setTimeout(() => {
				initial();
				modifyButtonHandler();
			}, 2000);
			// history.push("/listadoDeUsuarios", { replace: true });
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message: "Se ha producido un error inesperado",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	useEffect(() => {
		initial();
	}, []);

	const modifyButtonHandler = () => {
		setName(originalData.name);
		setLastName(originalData.last_name);
		setEmail(originalData.email);
		setEmail2(originalData.email2);
		setSeller(originalData.is_seller);
		setUserOperators(originalData.operators);
		userPhoneHandler(originalData.phone);

		setModify(!modify);
	};

	const modifyButtonDynamicStyle = {
		background: modify ? "#1D428A" : "#DDE4EB",
		color: modify ? "white" : "black",
		border: modify ? "none" : "1px solid #426DA9",
	};

	const dynamicColorIcon = {
		color: modify ? "white" : "#426DA9",
	};

	const userPhoneHandler = (e) => {
		const phone = e.split(" ");
		setPhoneCountry(phone[0]);
		setPhone(phone[1]);
	};

	const switchHandler = (checked) => {
		if (checked === true) {
			setSeller(checked);
		} else {
			setSeller(checked);
			setOperator(null);
		}
	};

	const operatorSelectHandler = (value) => {
		if (operator !== value[0]) {
			setOperator(value[0]);
		}
		setUserOperators(value);
	};

	const phoneCountryHandler = (value) => {
		setPhoneCountry(value);
	};

	return (
		<Container>
			<div className={styles["section-container"]}>
				<div
					className={`${styles["row"]} ${styles["space-between"]} ${styles["align-center"]}`}
				>
					<span className={styles["section-title"]}>
						Ficha general con información básica asociada al usuario.
					</span>

					{changeUserApp && (
						<>
							{loading ? (
								<div style={{ width: "10%" }}>
									<LoadingButton />
								</div>
							) : (
								<Button
									style={modifyButtonDynamicStyle}
									className={styles["button-modify"]}
									onClick={modifyButtonHandler}
									icon={<FormOutlined style={dynamicColorIcon} />}
								>
									Modificar
								</Button>
							)}
						</>
					)}
				</div>

				<Br />

				{loading ? (
					<LoadingDetail showButtons={false} />
				) : (
					<>
						<div className={styles["row"]}>
							<div className={`${styles["column"]} ${styles["column-left"]}`}>
								<div className={styles["row"]}>
									<span className={styles["form-section-title"]}>
										Datos personales
									</span>
								</div>

								<Br />

								<div className={`${styles["row"]} ${styles["space-between"]}`}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Nombre <span className={styles["blue"]}>*</span>
										</span>
										<Input
											className={styles["form-input"]}
											style={{
												width: "95%",
											}}
											value={name}
											onChange={(e) => setName(e?.target?.value)}
											onBlur={validateInput}
											disabled={modify ? false : true}
											status={errorStatus.name ? "error" : ""}
											maxLength={25}
										/>
										{errorStatus.name && (
											<div className={styles["position-relative"]}>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.name}
												</span>
											</div>
										)}
									</div>

									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Apellido <span className={styles["blue"]}>*</span>
										</span>
										<Input
											className={styles["form-input"]}
											value={lastName}
											onChange={(e) => setLastName(e?.target?.value)}
											onBlur={validateInput}
											disabled={modify ? false : true}
											status={errorStatus.lastName ? "error" : ""}
											maxLength={25}
										/>
										{errorStatus.lastName && (
											<div className={styles["position-relative"]}>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.lastName}
												</span>
											</div>
										)}
									</div>
								</div>

								<Br />

								<div className={`${styles["row"]} ${styles["space-between"]}`}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Número de teléfono{" "}
											<span className={styles["blue"]}>*</span>
										</span>
										<div className={styles["row"]}>
											<Select
												className={styles["form-input"]}
												disabled={modify ? false : true}
												style={{
													width: "30%",
													marginRight: "5%",
													backgroundColor: modify ? "white" : "#ececec",
												}}
												bordered={false}
												onChange={phoneCountryHandler}
												value={phoneCountry}
											>
												<Select.Option key={1} value={"+56"}>
													<img
														src={`https://flagcdn.com/28x21/cl.png`}
														width="20"
														height="15"
														alt="Chile"
														style={{ margin: "0 5px 5px 0" }}
													/>
													+56
												</Select.Option>
												<Select.Option key={2} value={"+58"}>
													<img
														src={`https://flagcdn.com/28x21/ve.png`}
														width="20"
														height="15"
														alt="Venezuela"
														style={{ margin: "0 5px 5px 0" }}
													/>
													+58
												</Select.Option>
												<Select.Option key={3} value={"+55"}>
													<img
														src={`https://flagcdn.com/28x21/br.png`}
														width="20"
														height="15"
														alt="Brasil"
														style={{ margin: "0 5px 5px 0" }}
													/>
													+55
												</Select.Option>
												<Select.Option key={4} value={"+51"}>
													<img
														src={`https://flagcdn.com/28x21/pe.png`}
														width="20"
														height="15"
														alt="Perú"
														style={{ margin: "0 5px 5px 0" }}
													/>
													+51
												</Select.Option>
											</Select>
											<div
												className={styles["column"]}
												style={{ width: "60%" }}
											>
												<Input
													className={styles["form-input"]}
													value={phone}
													onChange={(e) => setPhone(e?.target?.value)}
													onBlur={validateInput}
													disabled={modify ? false : true}
													status={errorStatus.phone ? "error" : ""}
												/>
												{errorStatus.phone && (
													<div className={styles["position-relative"]}>
														<span
															className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
														>
															{errors.phone}
														</span>
													</div>
												)}
											</div>
										</div>
									</div>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Correo electrónico{" "}
											<span className={styles["blue"]}>*</span>
										</span>
										<Input
											className={styles["form-input"]}
											value={email2}
											disabled={modify ? false : true}
											onChange={(e) => setEmail2(e?.target?.value)}
											onBlur={validateInput}
											status={errorStatus.email2 ? "error" : ""}
											maxLength={25}
										/>
										{errorStatus.email2 && (
											<div className={styles["position-relative"]}>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.email2}
												</span>
											</div>
										)}
									</div>
								</div>

								<Br />
								<Br />

								<div className={styles["row"]}>
									<div
										className={`${styles["column"]} ${styles["justify-center"]}`}
									>
										<span className={styles["form-input-name"]}>
											<b>Tipo de usuario vendedor</b>
										</span>
									</div>
									<div
										className={`${styles["column"]} ${styles["switch-container"]}`}
									>
										<div
											className={`${styles["row"]} ${styles["space-between"]}`}
										>
											<span className={styles["switch-text"]}>No</span>
											<Switch
												onChange={switchHandler}
												checked={seller ? true : false}
												disabled={modify ? false : true}
											/>
											<span className={styles["switch-text"]}>Sí</span>
										</div>
									</div>
								</div>

								<Br />

								{seller && operatorList.length > 0 && (
									<div
										className={`${styles["row"]} ${styles["space-between"]}`}
									>
										<div className={`${styles["column"]} ${styles["flex"]}`}>
											<span className={styles["form-input-name"]}>
												Operadores
											</span>
											<Select
												defaultValue="Seleccione"
												className={styles["form-input"]}
												disabled={modify ? false : true}
												bordered={false}
												onChange={operatorSelectHandler}
												value={
													userOperators && userOperators.length > 0
														? userOperators
														: [operator]
												}
												style={{
													backgroundColor: modify ? "white" : "#ececec",
												}}
												mode="multiple"
												maxTagCount={"responsive"}
											>
												{operatorList.map((operator) => (
													<Select.Option key={operator.ID} value={operator.ID}>
														{operator.name}
													</Select.Option>
												))}
											</Select>
										</div>
									</div>
								)}
							</div>

							<div
								className={`${styles["column"]} ${styles["flex"]} ${styles["column-right"]}`}
							>
								<div className={styles["row"]}>
									<span className={styles["form-section-title"]}>
										Credenciales de acceso al sistema
									</span>
								</div>

								<Br />

								<span className={styles["form-input-name"]}>
									Correo electrónico <span className={styles["blue"]}>*</span>
								</span>
								<Input
									className={styles["form-input"]}
									value={email}
									disabled={modify ? false : true}
									onChange={(e) => setEmail(e?.target?.value)}
									onBlur={validateInput}
									status={errorStatus.email ? "error" : ""}
									maxLength={25}
								/>
								{errorStatus.email && (
									<div className={styles["position-relative"]}>
										<span
											className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
										>
											{errors.email}
										</span>
									</div>
								)}

								<Br />

								<span className={styles["form-input-name"]}>
									Contraseña <span className={styles["blue"]}>*</span>
								</span>
								<Input
									type="text"
									className={styles["form-input"]}
									placeholder="********"
									disabled
								/>

								<Br />

								<span className={styles["important-info-title"]}>
									Información importante
								</span>
								<span className={styles["important-info-paragraph"]}>
									Los datos que se muestran a continuación se obtienen
									directamente de la aplicación del Marketplace.
								</span>

								<Br />

								<div className={`${styles["row"]} ${styles["space-between"]}`}>
									<div
										className={`${styles["column"]} ${styles["flex"]} ${styles["justify-end"]}`}
									>
										<span className={styles["info-title"]}>
											Fecha de última conexión
										</span>
										{lastLogin && (
											<span className={styles["info-text"]}>
												{dayjs(lastLogin, "DD-MM-YYYY").format(
													"dddd DD/MM/YYYY"
												)}
											</span>
										)}
									</div>

									<div
										className={`${styles["column"]} ${styles["flex"]} ${styles["justify-end"]}`}
									>
										<span className={styles["info-title"]}>Dispositivo</span>
										{mobileDevice ? (
											<span className={styles["info-text"]}>
												{mobileDevice.dispositivo} {mobileDevice.version}
											</span>
										) : (
											<span className={styles["info-text"]}>
												Sin información de dispositivo
											</span>
										)}
									</div>
								</div>

								<Br />

								<div className={styles["row"]}>
									<div
										className={`${styles["column"]} ${styles["flex"]} ${styles["justify-end"]}`}
									>
										<span className={styles["info-title"]}>
											Método de autenticación
										</span>

										<div
											className={`${styles["row"]} ${styles["align-center"]}`}
										>
											{loginMethod === "Gmail" ? (
												<span
													className={`${styles["dot"]} ${styles["gmail"]}`}
												></span>
											) : loginMethod === "Facebook" ? (
												<span
													className={`${styles["dot"]} ${styles["facebook"]}`}
												></span>
											) : (
												loginMethod === "SMS" && (
													<span
														className={`${styles["dot"]} ${styles["sms"]}`}
													></span>
												)
											)}
											<span className={styles["form-input-name"]}>
												{loginMethod}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<Br />

						<div className={`${styles["row"]} ${styles["justify-end"]}`}>
							<Space>
								{modify && (
									<Space>
										<Button
											type="default"
											className={`${styles["action-button"]} ${styles["action-button-cancel"]}`}
											onClick={modifyButtonHandler}
										>
											Cancelar
										</Button>
										<Popconfirm
											placement="topRight"
											icon={""}
											title="¿Modificar información?"
											description="Los cambios realizados se sincronizaran automáticamente con las demás aplicaciones."
											onConfirm={() => {
												onSubmit({
													name: name,
													last_name: lastName,
													email: email,
													email2: email2,
													phone: userPhone,
													is_seller: seller,
													operator: operator,
													operators: userOperators,
												});
											}}
											okText="Guardar"
											cancelText="Cancelar"
										>
											<Button
												type="default"
												className={`${styles["action-button"]} ${styles["action-button-save"]}`}
											>
												Guardar
											</Button>
										</Popconfirm>
									</Space>
								)}
							</Space>
						</div>
					</>
				)}
			</div>
		</Container>
	);
};

export default GeneralUserPanel;

const Container = styled.div`
	.ant-select-selector {
		font-size: 12px;
	}

	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		padding: 0;
	}

	@media screen and (min-width: 1280px) {
		.ant-select-selector {
			font-size: 12px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-select-selector {
			font-size: 13.5px;
		}
	}

	@media screen and (min-width: 1920px) {
		.ant-select-selector {
			font-size: 15.18px;
		}
	}
`;
