/*Este archivo contiene los valores que se pasan dinámicamente a todo
el sistema, la constante devices contiene los diferentes tamaños que se tomaron
como base

Para hacer el cálculo se realiza la siguiente operación:
Se toma como base el valor del prototipo y se coloca en el objeto smallSizes,
luego para escalarlo a mediumSizes se toma cualquier valor base y se multiplica
por 1.125. Para escalar de mediumSizes a largeSizes se hace la misma operación
pero el valor que se toma es el de mediumSizes y luego se multiplica*/
const devices = {
	smallDesk: "1280px",
	mediumDesk: "1366px",
	largeDesk: "1920px",
};

export const smallSizes = {
	/*Clases de los mensajes de error de las validaciones*/
	errorMessages: {
		formErrorMessage: "12px",
	},

	/*Clases del login*/
	login: {
		textContentH3: "38px",
		textContentB: "45px",
		textContentP: "12px",
		rightContentWidth: "418px",
		textLogin: "25px",
		textLoginP: "12px",
		textLoginB: "12px",
		texLoginPre: "12px",
		rightContentInput: "12px",
		rightContentButton: "12px",
		rightContentButtonHeight: "34px",
	},

	/*Clases del header*/
	topHeader: {
		headerHeight: "65px",
		helpText: "12px",
		userDialogWidth: "126.86px",
		userDialogHeight: "25px",
		userDialogTop: "20px",
		userDialogLeft: "-134.48px",
		tagText: "12px",
		headerAvatar: "35px",
		droppableContent: "12px",
		visibleBoxWidth: "165px",
		visibleBoxBottom: "-112px",
		logOutTitle: "13px",
		logOutText: "12px",
	},

	/*Clases del sidebar*/
	sidebarMenu: {
		systemLogo: "150px",
		operatorBoxHeight: "80px",
		sidebarAvatar: "49px",
		operatorTag: "14px",
		operatorBoxName: "18px",
		subtitle: "16px",
	},

	/*Clases de las tablas generales y 
		los detalles*/
	tableData: {
		tableHead: "14px",
	},
	paginationContainer: {
		pagination: "12px",
		exportText: "14px",
	},
	filterContainer: {
		text: "14px",
		select: "150px",
		date: "200px",
		antSelector: "12px",
		antPicker: "12px",
		brjSelect: "93px",
		channelSelect: "93px",
		searchInput: "185px",
		antInputAffix: "12px",
		antInputSearch: "12px",
	},
	headerContainer: {
		title: "24px",
		breadcrumb: "12px",
		infoIcon: "12px",
		createdDate: "12px",
	},
	subHeaderContainer: {
		text: "14px",
	},
	buttonContainer: {
		btn: "12px",
	},
	tooltipContainer: {
		title: "14px",
		subtitle: "12px",
		dotHeight: "10px",
		dotWidth: "10px",
	},
	textColoredContainer: {
		textColored: "12px",
	},
	tableTextContainer: {
		tableText: "12px",
	},
	colorCircleContainer: {
		circleWidth: "10px",
		circleHeight: "10px",
	},

	/*Clases de los tabs de los detalles*/

	tabsPanel: {
		tab: "12px",
	},

	/*Clases de los detalles generales*/
	container: {
		btnModifyFont: "12px",
		btnModifyWidth: "101px",
		btnModifyHeight: "34px",
		subheaderTitle: "14px",
		title: "14px",
		subtitle: "12px",
		importantInfo: "12px",
		importantInfoDescription: "10px",
		infoTitle: "12px",
		infoSubtitle: "12px",
		dotHeight: "10px",
		dotWidth: "10px",
		switchText: "12px",
		logoImgWidth: "105.5px",
		logoImgHeight: "69.5px",
		operatorLogoName: "12px",
		logoSubtitle: "12px",
		logoText: "10px",
		logoRequired: "9px",
		uploaderBoxHeight: "150px",
		uploadIcon: "25px",
		tempImageHeight: "150px",
		clearTempImageWidth: "25px",
		clearTempImageHeight: "25px",
		clearTempImageFont: "16px",
		verified: "14px",
		saleHistory: "12px",
		input: "12px",
		antSelector: "12px",
		antPicker: "12px",
		btnVerifyWidth: "146px",
		btnVerifyHeight: "34px",
		btnVerifyFont: "12px",
		btnCoverage: "12px",
		btnBack: "14px",
		btnSaveAndCancelWidth: "84px",
		btnSaveAndCancelHeight: "34px",
		btnSaveAndCancelFont: "12px",
		btnAddContactHeight: "34px",
		btnAddContactFont: "12px",
		contactName: "15px",
		contactDetails: "12px",
		chargeTagWidth: "100px",
		chargeTagHeight: "30px",
		chargeDetails: "12px",
		trashIcon: "12px",
		extraInfoChecks: "12px",
		tagContainerWidth: "140px",
		tagContainerHeight: "25px",
		tagText: "10px",
		installationDate: "11px",
		saleStatusText: "22px",
		saleInfoTitle: "12px",
		saleInfoText: "14px",
		planCharacteristics: "14px",
	},

	/*Clases de los históricos*/
	historical: {
		timelineLabel: "12px",
		timelineLabelWidth: "21%",
		timelineHeadLeft: "22%",
		timelineHeadWidth: "12px",
		timelineHeadHeight: "12px",
		timelineContent: "12px",
		timelineContentLeft: "21%",
		timelineTailLeft: "22%",
		title: "14px",
		subtitle: "14px",
		addressHistoryIcon: "10px",
		addressHistoryChoice: "9px",
		addressHistoryOperator: "12px",
		addressHistoryPlan: "12px",
		configBoxTitle: "14px",
		configBoxCheckbox: "12px",
		btnClean: "12px",
		btnConfirm: "8px",
		btnConfirmHeight: "25px",
	},

	/*Clases de los formularios*/
	forms: {
		mainTitle: "20px",
		closeIcon: "14px",
		title: "12px",
		subtitle: "14px",
		promotionBreadcrumbHeader: "14px",
		promotionBreadcrumb: "12px",
		input: "12px",
		checkbox: "12px",
		innerCheckbox: "14px",
		collapsePanelTitle: "15px",
		collapseCaret: "12px",
		planName: "14px",
		planTech: "12px",
		planCharacteristics: "14px",
		antSelector: "12px",
		antPicker: "12px",
		antInputNumber: "12px",
		subtitle: "14px",
		logoText: "10px",
		logoRequired: "9px",
		uploaderBoxHeight: "150px",
		tempImageHeight: "150px",
		clearTempImageWidth: "25px",
		clearTempImageHeight: "25px",
		clearTempImageFont: "16px",
		salesGalleryTempImageWidth: "105.5px",
		salesGalleryTempImageHeight: "69.5px",
		infoIcon: "12px",
		btnSaveAndCancelWidth: "84px",
		btnSaveAndCancelHeight: "34px",
		btnSaveAndCancelFont: "12px",
		subtitleVerify: "14px",
		textDescription: "12px",
		imgWidthVerify: "144px",
		imgHeightVerify: "131px",
		wizardPaginationText: "14px",
		wizardNoDataText: "12px",
		wizardPlanPriceContainerWidth: "32%",
		wizardPlanPriceHeight: "43px",
		wizardPromotionsTag: "122px",
		wizardPromotionsFont: "14px",
		wizardPromotionPriceWidth: "106px",
		wizardPromotionPriceHeight: "43px",
		wizardPlanPrice: "14px",
		wizardPlanInstallationPrice: "10px",
		wizardPlanType: "12px",
		wizardPlanDescription: "12px",
		wizardPromotionName: "14px",
		wizardPromotionDiscountType: "12px",
		wizardPromotionDiscount: "12px",
		wizardPromotionBase: "10px",
		wizardPromotionPeriod: "11px",
		wizardPromotionDescription: "11px",
		wizardAddressName: "12px",
		wizardPlanDetail: "14px",
		wizardViewMore: "12px",
		wizardChecks: "12px",
	},

	/*Clases de la galería de ventas*/
	salesGallery: {
		dateFormat: "15px",
		userFormat: "12px",
		imgWidth: "116px",
		imgHeight: "76px",
		caption: "12px",
		blueText: "12px",
		descriptionText: "12px",
	},

	/* Clases dashboard*/
	dashboardTop: {
		text: "14px",
		number: "22px",
		circleWidth: "80px",
		circleHeight: "80px",
		iconSizeWidth: "60px",
		iconSizeHeight: "60px",
		percent: "12px",
		arrowIcon: "16px",
		textRespect: "10px",
		operatorWidth: "133.33px",
		operatorHeight: "27px",
		textOperator: "12px",
		textPlan: "20px",
	},

	dashboardMid: {
		titleCards: "15px",
		circleTooltip: "12px",
		textDescriptionAplication: "14px",
		textDescriptionDirect: "14px",
		numberSales: "20px",
		percentSales: "12px",
		textRespect: "10px",
		textSalesDay: "12px",
		textDataSale: "12px",
		textSales: "14px",
		textSalesCompliment: "10px",
		percent: "12px",
		arrowIcon: "16px",
		percentCards: "20px",
		percentAverage: "14px",
		textDescriptionCard: "10px",
		heightGraphs: "42%",
		marginBottonGraph: "22%",
		percentCompleted: "20px",
	},
	dashboardBottom: {
		titleCards: "15px",
		circleTooltip: "12px",
		textOperator: "20px",
		percent: "14px",
		textCardSales: "12px",
		iconSizeWidth: "24px",
		iconSizeHeight: "24px",
		nameOperator: "12px",
		salesOperator: "12px",
		status: "12px",
		showMore: "12px",
		squareWidth: "12px",
		squareHeight: "12px",
		textItemsGraph: "12px",
		listSales: "12px",
	},
};

export const mediumSizes = {
	/*Clases de los mensajes de error de las validaciones*/
	errorMessages: {
		formErrorMessage: "13.5px",
	},

	/*Clases del login*/
	login: {
		textContentH3: "42.75px",
		textContentB: "50.62px",
		textContentP: "13.5px",
		rightContentWidth: "470.25px",
		textLogin: "28.12px",
		textLoginP: "13.5px",
		textLoginB: "13.5px",
		texLoginPre: "13.5px",
		rightContentInput: "13.5px",
		rightContentButton: "13.5px",
		rightContentButtonHeight: "38.25px",
	},

	/*Clases del header*/
	topHeader: {
		headerHeight: "73.12px",
		helpText: "13.5px",
		userDialogWidth: "142.72px",
		userDialogHeight: "28.12px",
		userDialogTop: "22.5px",
		userDialogLeft: "-151.29px",
		tagText: "13.5px",
		headerAvatar: "39.37px",
		droppableContent: "13.5px",
		visibleBoxWidth: "185.62px",
		visibleBoxBottom: "-118px",
		logOutTitle: "14.62px",
		logOutText: "13.5px",
	},

	/*Clases del sidebar*/
	sidebarMenu: {
		systemLogo: "168.75px",
		operatorBoxHeight: "90px",
		sidebarAvatar: "55.12px",
		operatorTag: "15.75px",
		operatorBoxName: "20.25px",
		subtitle: "18px",
	},

	/*Clases de los módulos generales y 
		los detalles*/
	tableData: {
		tableHead: "15.75px",
	},
	paginationContainer: {
		pagination: "13.5px",
		exportText: "15.75px",
	},
	filterContainer: {
		text: "15.75px",
		select: "168.75px",
		date: "225px",
		antSelector: "13.5px",
		antPicker: "13.5px",
		brjSelect: "104.62px",
		channelSelect: "104.62px",
		searchInput: "208.12px",
		antInputAffix: "13.5px",
		antInputSearch: "13.5px",
	},
	headerContainer: {
		title: "27px",
		breadcrumb: "13.5px",
		infoIcon: "13.5px",
		createdDate: "13.5px",
	},
	subHeaderContainer: {
		text: "15.75px",
	},
	buttonContainer: {
		btn: "13.5px",
	},
	tooltipContainer: {
		title: "15.75px",
		subtitle: "13.5px",
		dotHeight: "11.25px",
		dotWidth: "11.25px",
	},
	textColoredContainer: {
		textColored: "13.5px",
	},
	tableTextContainer: {
		tableText: "13.5px",
	},
	colorCircleContainer: {
		circleWidth: "11.25px",
		circleHeight: "11.25px",
	},

	/*Clases de los tabs de los detalles*/

	tabsPanel: {
		tab: "13.5px",
	},

	/*Clases de los detalles generales*/
	container: {
		btnModifyFont: "13.5px",
		btnModifyWidth: "113.62px",
		btnModifyHeight: "38.25px",
		subheaderTitle: "15.75px",
		title: "15.75px",
		subtitle: "13.5px",
		importantInfo: "13.5px",
		importantInfoDescription: "11.25px",
		infoTitle: "13.5px",
		infoSubtitle: "13.5px",
		dotHeight: "11.25px",
		dotWidth: "11.25px",
		switchText: "13.5px",
		logoImgWidth: "118.68px",
		logoImgHeight: "78.18px",
		operatorLogoName: "13.5px",
		logoSubtitle: "13.5px",
		logoText: "11.25px",
		logoRequired: "10.12px",
		uploaderBoxHeight: "168.75px",
		uploadIcon: "28.12px",
		tempImageHeight: "168.75px",
		clearTempImageWidth: "28.12px",
		clearTempImageHeight: "28.12px",
		clearTempImageFont: "18px",
		verified: "15.75px",
		saleHistory: "13.5px",
		input: "13.5px",
		antSelector: "13.5px",
		antPicker: "13.5px",
		btnVerifyWidth: "164.25px",
		btnVerifyHeight: "38.25px",
		btnVerifyFont: "13.5px",
		btnCoverage: "13.5px",
		btnBack: "15.75px",
		btnSaveAndCancelWidth: "94.50px",
		btnSaveAndCancelHeight: "38.25px",
		btnSaveAndCancelFont: "13.5px",
		btnAddContactHeight: "38.25px",
		btnAddContactFont: "13.5px",
		contactName: "16.87px",
		contactDetails: "13.5px",
		chargeTagWidth: "112.5px",
		chargeTagHeight: "33.75px",
		chargeDetails: "13.5px",
		trashIcon: "13.5px",
		extraInfoChecks: "13.5px",
		tagContainerWidth: "157.5px",
		tagContainerHeight: "28.12px",
		tagText: "11.25px",
		installationDate: "12.37px",
		saleStatusText: "24.75px",
		saleInfoTitle: "13.5px",
		saleInfoText: "15.75px",
		planCharacteristics: "15.75px",
	},

	/*Clases de los históricos*/
	historical: {
		timelineLabel: "13.5px",
		timelineLabelWidth: "21%",
		timelineHeadLeft: "22%",
		timelineHeadWidth: "13.5px",
		timelineHeadHeight: "13.5px",
		timelineContent: "13.5px",
		timelineContentLeft: "21%",
		timelineTailLeft: "22%",
		title: "15.75px",
		subtitle: "15.75px",
		addressHistoryIcon: "11.25px",
		addressHistoryChoice: "10.12px",
		addressHistoryOperator: "13.5px",
		addressHistoryPlan: "13.5px",
		configBoxTitle: "15.75px",
		configBoxCheckbox: "13.5px",
		btnClean: "13.5px",
		btnConfirm: "9px",
		btnConfirmHeight: "28.12px",
	},

	/*Clases de los formularios*/
	forms: {
		mainTitle: "22.5px",
		closeIcon: "15.75px",
		title: "13.5px",
		subtitle: "15.75px",
		promotionBreadcrumbHeader: "15.75px",
		promotionBreadcrumb: "13.5px",
		input: "13.5px",
		checkbox: "13.5px",
		innerCheckbox: "15.75px",
		collapsePanelTitle: "16.87px",
		collapseCaret: "13.5px",
		planName: "15.75px",
		planTech: "13.5px",
		planCharacteristics: "15.75px",
		antSelector: "13.5px",
		antPicker: "13.5px",
		antInputNumber: "13.5px",
		subtitle: "15.75px",
		logoText: "11.25px",
		logoRequired: "10.12px",
		uploaderBoxHeight: "168.75px",
		tempImageHeight: "168.75px",
		clearTempImageWidth: "28.12px",
		clearTempImageHeight: "28.12px",
		clearTempImageFont: "18px",
		salesGalleryTempImageWidth: "118.68px",
		salesGalleryTempImageHeight: "78.18px",
		infoIcon: "13.5px",
		btnSaveAndCancelWidth: "94.50px",
		btnSaveAndCancelHeight: "38.25px",
		btnSaveAndCancelFont: "13.5px",
		subtitleVerify: "15.75px",
		textDescription: "13.5px",
		imgWidthVerify: "162px",
		imgHeightVerify: "147.37px",
		wizardPaginationText: "15.75px",
		wizardNoDataText: "13.5px",
		wizardPlanPriceContainerWidth: "34%",
		wizardPlanPriceHeight: "48.37px",
		wizardPromotionsTag: "137.25px",
		wizardPromotionsFont: "15.75px",
		wizardPromotionPriceWidth: "119.25px",
		wizardPromotionPriceHeight: "48.37px",
		wizardPlanPrice: "15.75px",
		wizardPlanInstallationPrice: "11.25px",
		wizardPlanType: "13.5px",
		wizardPlanDescription: "13.5px",
		wizardPromotionName: "15.75px",
		wizardPromotionDiscountType: "13.5px",
		wizardPromotionDiscount: "13.5px",
		wizardPromotionBase: "11.25px",
		wizardPromotionPeriod: "12.37px",
		wizardPromotionDescription: "12.37px",
		wizardAddressName: "13.5px",
		wizardPlanDetail: "15.75px",
		wizardViewMore: "13.5px",
		wizardChecks: "13.5px",
	},

	/*Clases de la galería de ventas*/
	salesGallery: {
		dateFormat: "16.87px",
		userFormat: "13.5px",
		imgWidth: "130.5px",
		imgHeight: "85.5px",
		caption: "13.5px",
		blueText: "13.5px",
		descriptionText: "13.5px",
	},

	/* Clases dashboard*/
	dashboardTop: {
		text: "15.75px",
		number: "24.75px",
		circleWidth: "90px",
		circleHeight: "90px",
		iconSizeWidth: "67.5px",
		iconSizeHeight: "67.5px",
		percent: "13.5px",
		arrowIcon: "18px",
		textRespect: "11.25px",
		operatorWidth: "150px",
		operatorHeight: "30.375px",
		textOperator: "13.5px",
		textPlan: "22.5px",
	},

	dashboardMid: {
		titleCards: "16.875px",
		circleTooltip: "13.5px",
		textDescriptionAplication: "15.75px",
		textDescriptionDirect: "15.75px",
		numberSales: "22.5px",
		percentSales: "13.5px",
		textRespect: "11.25px",
		textSalesDay: "13.5px",
		textDataSale: "13.5px",
		textSales: "15.75px",
		textSalesCompliment: "11.25px",
		percent: "13.5px",
		arrowIconDown: "18px",
		percentCards: "22.5px",
		percentAverage: "15.75px",
		textDescriptionCard: "11.25px",
		heightGraphs: "41%",
		marginBottonGraph: "23.5%",
		percentCompleted: "22.5",
	},

	dashboardBottom: {
		titleCards: "16.87px",
		circleTooltip: "13.5px",
		textOperator: "22.5px",
		percent: "15.75px",
		textCardSales: "13.5px",
		iconSizeWidth: "25.875px",
		iconSizeHeight: "25.875px",
		nameOperator: "13.5px",
		salesOperator: "13.5px",
		status: "13.5px",
		showMore: "13.5px",
		squareWidth: "13.5px",
		squareHeight: "13.5px",
		textItemsGraph: "13.5px",
		listSales: "13.5px",
	},
};

export const largeSizes = {
	/*Clases de los mensajes de error de las validaciones*/
	errorMessages: {
		formErrorMessage: "15.18px",
	},

	/*Clases del login*/
	login: {
		textContentH3: "48.09px",
		textContentB: "56.94px",
		textContentP: "15.18px",
		rightContentWidth: "529.03px",
		textLogin: "31.63px",
		textLoginP: "15.18px",
		textLoginB: "15.18px",
		texLoginPre: "15.18px",
		rightContentInput: "15.18px",
		rightContentButton: "15.18px",
		rightContentButtonHeight: "43.03px",
	},

	/*Clases del header*/
	topHeader: {
		headerHeight: "82.26px",
		helpText: "15.18px",
		tagText: "15.18px",
		userDialogWidth: "160.56px",
		userDialogHeight: "31.63px",
		userDialogTop: "25.31px",
		userDialogLeft: "-170.21px",
		headerAvatar: "44.29px",
		droppableContent: "15.18px",
		visibleBoxWidth: "208.82px",
		visibleBoxBottom: "-126px",
		logOutTitle: "16.44px",
		logOutText: "15.18px",
	},

	/*Clases del sidebar*/
	sidebarMenu: {
		systemLogo: "189.84px",
		operatorBoxHeight: "101.25px",
		sidebarAvatar: "62.01px",
		operatorTag: "17.71px",
		operatorBoxName: "22.78px",
		subtitle: "20.25px",
	},

	/*Clases de los módulos generales y 
		los detalles*/
	tableData: {
		tableHead: "17.71px",
	},
	paginationContainer: {
		pagination: "15.18px",
		exportText: "17.71px",
	},
	filterContainer: {
		text: "17.71px",
		select: "189.84px",
		date: "253.12px",
		antSelector: "15.18px",
		antPicker: "15.18px",
		brjSelect: "117.69px",
		channelSelect: "117.69px",
		searchInput: "234.13px",
		antInputAffix: "15.18px",
		antInputSearch: "15.18px",
	},
	headerContainer: {
		title: "30.37px",
		breadcrumb: "15.18px",
		infoIcon: "15.18px",
		createdDate: "15.18px",
	},
	subHeaderContainer: {
		text: "17.71px",
	},
	buttonContainer: {
		btn: "15.18px",
	},
	tooltipContainer: {
		title: "17.71px",
		subtitle: "15.18px",
		dotHeight: "12.65px",
		dotWidth: "12.65px",
	},
	textColoredContainer: {
		textColored: "15.18px",
	},
	tableTextContainer: {
		tableText: "15.18px",
	},
	colorCircleContainer: {
		circleWidth: "12.65px",
		circleHeight: "12.65px",
	},

	/*Clases de los tabs de los detalles*/

	tabsPanel: {
		tab: "15.18px",
	},

	/*Clases de los detalles generales*/
	container: {
		btnModifyFont: "15.18px",
		btnModifyWidth: "127.82px",
		btnModifyHeight: "43px",
		subheaderTitle: "17.71px",
		title: "17.71px",
		subtitle: "15.18px",
		importantInfo: "15.18px",
		importantInfoDescription: "12.65px",
		infoTitle: "15.18px",
		infoSubtitle: "15.18px",
		dotHeight: "12.65px",
		dotWidth: "12.65px",
		switchText: "15.18px",
		logoImgWidth: "133.51px",
		logoImgHeight: "89.95px",
		operatorLogoName: "15.18px",
		logoSubtitle: "15.18px",
		logoText: "12.65px",
		logoRequired: "11.38px",
		uploaderBoxHeight: "189.84px",
		uploadIcon: "31.63px",
		tempImageHeight: "189.84px",
		clearTempImageWidth: "31.63px",
		clearTempImageHeight: "31.63px",
		clearTempImageFont: "20.25px",
		verified: "17.71px",
		saleHistory: "15.18px",
		input: "15.18px",
		antSelector: "15.18px",
		antPicker: "15.18px",
		btnVerifyWidth: "184.78px",
		btnVerifyHeight: "43px",
		btnVerifyFont: "15.18px",
		btnCoverage: "15.18px",
		btnBack: "17.71",
		btnSaveAndCancelWidth: "106.31px",
		btnSaveAndCancelHeight: "43px",
		btnSaveAndCancelFont: "15.18px",
		btnAddContactHeight: "43px",
		btnAddContactFont: "15.18px",
		contactName: "18.97px",
		contactDetails: "15.18px",
		chargeTagWidth: "126.56px",
		chargeTagHeight: "37.96px",
		chargeDetails: "15.18px",
		trashIcon: "15.18px",
		extraInfoChecks: "15.18px",
		tagContainerWidth: "177.18px",
		tagContainerHeight: "31.63px",
		tagText: "12.65px",
		installationDate: "13.91px",
		saleStatusText: "27.84px",
		saleInfoTitle: "15.18px",
		saleInfoText: "17.71px",
		planCharacteristics: "17.71px",
	},

	/*Clases de los históricos*/
	historical: {
		timelineLabel: "15.18px",
		timelineLabelWidth: "17%",
		timelineHeadLeft: "16%",
		timelineHeadWidth: "15.18px",
		timelineHeadHeight: "15.18px",
		timelineContent: "15.18px",
		timelineContentLeft: "16%",
		timelineTailLeft: "16.2%",
		title: "17.71px",
		subtitle: "17.71px",
		addressHistoryIcon: "12.65px",
		addressHistoryChoice: "11.38px",
		addressHistoryOperator: "15.18px",
		addressHistoryPlan: "15.18px",
		configBoxTitle: "17.71px",
		configBoxCheckbox: "15.18px",
		btnClean: "15.18px",
		btnConfirm: "10.12px",
		btnConfirmHeight: "31.63px",
	},

	/*Clases de los formularios*/
	forms: {
		mainTitle: "25.31px",
		closeIcon: "17.71px",
		title: "15.18px",
		subtitle: "17.71px",
		promotionBreadcrumbHeader: "17.71px",
		promotionBreadcrumb: "15.18px",
		input: "15.18px",
		checkbox: "15.8px",
		innerCheckbox: "17.71px",
		collapsePanelTitle: "18.97px",
		collapseCaret: "15.18px",
		planName: "17.71px",
		planTech: "15.18px",
		planCharacteristics: "17.71px",
		antSelector: "15.18px",
		antPicker: "15.8px",
		antInputNumber: "15.8px",
		subtitle: "17.71px",
		logoText: "12.65px",
		logoRequired: "11.38px",
		uploaderBoxHeight: "189.84px",
		tempImageHeight: "189.84px",
		clearTempImageWidth: "31.63px",
		clearTempImageHeight: "31.63px",
		clearTempImageFont: "20.25px",
		salesGalleryTempImageWidth: "133.51px",
		salesGalleryTempImageHeight: "89.95px",
		infoIcon: "15.18px",
		btnSaveAndCancelWidth: "106.31px",
		btnSaveAndCancelHeight: "43px",
		btnSaveAndCancelFont: "15.18px",
		subtitleVerify: "17.71px",
		textDescription: "15.18px",
		imgWidthVerify: "182.25px",
		imgHeightVerify: "165.79px",
		wizardPaginationText: "17.71px",
		wizardNoDataText: "15.18px",
		wizardPlanPriceContainerWidth: "26%",
		wizardPlanPriceHeight: "54.41px",
		wizardPromotionsTag: "154.40px",
		wizardPromotionsFont: "17.71px",
		wizardPromotionPriceWidth: "134.15px",
		wizardPromotionPriceHeight: "54.41px",
		wizardPlanPrice: "17.71px",
		wizardPlanInstallationPrice: "12.65px",
		wizardPlanType: "15.18px",
		wizardPlanDescription: "15.18px",
		wizardPromotionName: "17.71px",
		wizardPromotionDiscountType: "15.18px",
		wizardPromotionDiscount: "15.18px",
		wizardPromotionBase: "12.65px",
		wizardPromotionPeriod: "13.91px",
		wizardPromotionDescription: "13.91px",
		wizardAddressName: "15.18px",
		wizardPlanDetail: "17.71px",
		wizardViewMore: "15.18px",
		wizardChecks: "15.18px",
	},

	/*Clases de la galería de ventas*/
	salesGallery: {
		dateFormat: "18.97px",
		userFormat: "15.18px",
		imgWidth: "146.81px",
		imgHeight: "96.18px",
		caption: "15.18px",
		blueText: "15.18px",
		descriptionText: "15.18px",
	},

	dashboardTop: {
		text: "17.71px",
		number: "27.84px",
		circleWidth: "101.25px",
		circleHeight: "101.25px",
		iconSizeWidth: "75.93px",
		iconSizeHeight: "75.93px",
		percent: "15.18px",
		arrowIcon: "20.25px",
		textRespect: "12.65px",
		operatorWidth: "168.75px",
		operatorHeight: "34.17px",
		textOperator: "15.81px",
		textPlan: "25.31px",
	},

	dashboardMid: {
		titleCards: "18.9px",
		circleTooltip: "15.18px",
		textDescriptionAplication: "17.71px",
		textDescriptionDirect: "17.71px",
		numberSales: "25.31px",
		percentSales: "15.18px",
		textRespect: "12.65px",
		textSalesDay: "15.18px",
		textDataSale: "15.18px",
		textSales: "17.71px",
		textSalesCompliment: "12.65px",
		percent: "15.18px",
		arrowIconDown: "20.25px",
		percentCards: "25.31px",
		percentAverage: "17.71px",
		textDescriptionCard: "12.65px",
		heightGraphs: "44%",
		marginBottonGraph: "11%",
		percentCompleted: "25.31",
	},

	dashboardBottom: {
		titleCards: "18.9px",
		circleTooltip: "15.18px",
		textOperator: "25.3px",
		percent: "17.71px",
		textCardSales: "15.18px",
		iconSizeWidth: "25.3px",
		iconSizeHeight: "25.3px",
		nameOperator: "15.18px",
		salesOperator: "15.18px",
		status: "15.18px",
		showMore: "15.18px",
		squareWidth: "15.18px",
		squareHeight: "15.18px",
		textItemsGraph: "15.18px",
		listSales: "15.18px",
	},
};

export default devices;
