import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onSelectOperator } from "../store/auth/authSlice";
import { myFetch, myFetchGet } from "../services/services";
import { useReduxToken } from "./useReduxToken";

export function useOperator() {
	const dataState = useSelector((state) => state.authState);
	const token = useReduxToken();

	const [operators, setOperators] = useState(dataState.userOperators);
	const [storedOperatorID, setStoredOperatorID] = useState(null);

	const dispatch = useDispatch();

	const getReduxOperatorID = () => {
		const reduxOperatorID = dataState.operatorID;
		setStoredOperatorID(Number(reduxOperatorID));
	};

	async function getOperatorsList() {
		try {
			const data = await myFetchGet("api/v1/userapp/list_operators/", token);
			setOperators(data);
		} catch (e) {
			console.log("error", e);
		}
	}

	async function changeOperator(operatorID) {
		try {
			const data = await myFetch(
				`api/v1/userapp/change_operator/?operator=${operatorID}`,
				token
			);
			dispatch(
				onSelectOperator({
					operatorID: data.operator_datail.ID,
					operatorCountryIso: data.operator_datail.country_iso,
					operatorCountryID: data.operator_datail.country_id,
					operatorCreated: data.operator_datail.created,
					operatorLogo: data.operator_datail.logo,
					operatorName: data.operator_datail.name,
				})
			);
			window.location.reload();
		} catch (e) {
			console.log("error", e);
		}
	}

	useEffect(() => {
		getReduxOperatorID();
		getOperatorsList();
	}, []);

	return {
		storedOperatorID,
		operators,
		changeOperator,
	};
}
