import React, { useState, useEffect } from "react";
import { Divider, Timeline } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { myFetchGet } from "../../../services/services";
import moment from "moment/moment";
import { LoadingHistory } from "../../../components/loading/Loading";
import { largeSizes, mediumSizes, smallSizes } from "../../../helpers/sizes";
import devices from "../../../helpers/sizes";
import { useReduxToken } from "../../../hooks/useReduxToken";
import Br from "../../../components/Br/Br";

export default function AddressHistory({
	visible,
	setVisible,
	data,
	userID,
	cancel,
}) {
	const token = useReduxToken();

	const [history, setHistory] = useState([]);
	const [loading, setLoading] = useState(true);

	const { id_unique } = data;

	const onCloseModal = () => {
		setVisible(false);
		cancel();
	};

	async function initial() {
		try {
			const response = await myFetchGet(
				`api/v1/service/?address=${id_unique}&creator=${userID}`,
				token
			);
			setHistory(response.reverse());
			setLoading(false);
		} catch (e) {
			console.log("error", e);
		}
	}

	useEffect(() => {
		initial();
	}, []);

	if (visible) {
		return (
			<Container>
				<div className="formContent animate__animated animate__fadeInRight custom_scroll">
					<div className="row space-between align-center">
						<h3>Histórico de dirección</h3>
						<CloseOutlined className="close-icon" onClick={onCloseModal} />
					</div>

					<Divider />

					<b className="address">{data.address}</b>

					<Br />

					{loading ? (
						<LoadingHistory />
					) : (
						<Timeline mode="left">
							{history.map((item) => (
								<Timeline.Item
									label={moment(item?.created).format("DD/MM/YYYY h:m a")}
									key={item?.ID}
									color={"#00388B"}
								>
									<div className="column">
										<span className="operator">{item?.operator?.name}</span>
										<span className="plan">{item?.plan?.tradename}</span>
										{item?.status === "1" ? (
											<div className="row align-center">
												<i className="fa-solid fa-file-lines icon"></i>
												<span className="choice">Selección de plan</span>
											</div>
										) : item?.status === "2" ? (
											<div className="row align-center">
												<i className="fa-solid fa-calendar-days icon"></i>
												<span className="choice">Agendamiento</span>
											</div>
										) : item?.status === "3" ? (
											<div className="row align-center">
												<i className="fa-solid fa-user icon"></i>
												<span className="choice">Datos de titular</span>
											</div>
										) : item?.status === "4" ? (
											<div className="row align-center">
												<i className="fa-solid fa-circle-check icon"></i>
												<span className="choice">
													Confirmación de solicitud
												</span>
											</div>
										) : null}
									</div>
								</Timeline.Item>
							))}
						</Timeline>
					)}
				</div>
			</Container>
		);
	} else {
		return null;
	}
}

const Container = styled.div`
	background: hsla(0, 0%, 0%, 0.5);
	width: 42%;

	h3 {
		color: #5a607f;
		font-size: ${smallSizes.forms.mainTitle};
		font-weight: bold;
	}

	.close-icon {
		font-size: ${smallSizes.forms.closeIcon};
		color: #959595;
	}

	.title {
		font-weight: 400;
		font-size: ${smallSizes.forms.title};
		color: #131523;
	}

	.formContent {
		width: 100%;
		height: 100%;
		display: flex;
		flex-flow: column;
		background: white;
		padding: 5.6% 5.5% 5.6% 5.2%;
	}

	.row {
		margin: 0 !important;
		margin-top: 5px;
		display: flex;
	}

	.row_center {
		justify-content: center;
	}

	.row_start {
		justify-content: flex-start;
	}

	.row_end {
		justify-content: flex-end;
	}

	.space-between {
		justify-content: space-between;
		margin: 0 !important;
	}

	.space-evenly {
		justify-content: space-evenly;
		margin: 0 !important;
	}

	.align-center {
		align-items: center;
	}

	.column {
		display: flex;
		flex-flow: column;
		margin: 0 !important;
	}

	.column_center {
		justify-content: center;
	}

	.flex {
		flex: 1;
	}

	.address {
		font-size: 14px;
		color: #172b4d;
	}

	.ant-timeline.ant-timeline-label .ant-timeline-item-label {
		width: ${smallSizes.historical.timelineLabelWidth};
		text-align: left;
		padding-left: 10px;
		font-size: ${smallSizes.historical.timelineLabel};
		color: #131523;
		font-weight: bold;
	}

	.ant-timeline.ant-timeline-label .ant-timeline-item-head {
		margin-left: -5px;
		left: ${smallSizes.historical.timelineHeadLeft};
		width: ${smallSizes.historical.timelineHeadWidth};
		height: ${smallSizes.historical.timelineHeadHeight};
	}

	.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
		left: ${smallSizes.historical.timelineTailLeft};
		border-left: 2px solid #959595;
	}

	.ant-timeline.ant-timeline-label
		.ant-timeline-item-left
		.ant-timeline-item-content {
		left: ${smallSizes.historical.timelineContentLeft};
		width: 60%;
	}

	.icon {
		font-size: ${smallSizes.historical.addressHistoryIcon};
		color: #2b80ff;
		margin-right: 10px;
	}

	.choice {
		font-size: ${smallSizes.historical.addressHistoryChoice};
		color: #5a607f;
	}

	.operator {
		font-size: ${smallSizes.historical.addressHistoryOperator};
		font-weight: bold;
		color: #2b80ff;
	}

	.plan {
		font-size: ${smallSizes.historical.addressHistoryPlan};
		color: #131523;
	}

	@media screen and (min-width: ${devices.smallDesk}) {
		h3 {
			font-size: ${smallSizes.forms.mainTitle};
		}

		.close-icon {
			font-size: ${smallSizes.forms.closeIcon};
		}

		.title {
			font-size: ${smallSizes.forms.title};
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-label {
			width: ${smallSizes.historical.timelineLabelWidth};
			font-size: ${smallSizes.historical.timelineLabel};
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-head {
			left: ${smallSizes.historical.timelineHeadLeft};
			width: ${smallSizes.historical.timelineHeadWidth};
			height: ${smallSizes.historical.timelineHeadHeight};
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
			left: ${smallSizes.historical.timelineTailLeft};
		}

		.ant-timeline.ant-timeline-label
			.ant-timeline-item-left
			.ant-timeline-item-content {
			left: ${smallSizes.historical.timelineContentLeft};
		}

		.icon {
			font-size: ${smallSizes.historical.addressHistoryIcon};
		}

		.choice {
			font-size: ${smallSizes.historical.addressHistoryChoice};
		}

		.operator {
			font-size: ${smallSizes.historical.addressHistoryOperator};
		}

		.plan {
			font-size: ${smallSizes.historical.addressHistoryPlan};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}) {
		h3 {
			font-size: ${mediumSizes.forms.mainTitle};
		}

		.close-icon {
			font-size: ${mediumSizes.forms.closeIcon};
		}

		.title {
			font-size: ${mediumSizes.forms.title};
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-label {
			width: ${mediumSizes.historical.timelineLabelWidth};
			font-size: ${mediumSizes.historical.timelineLabel};
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-head {
			left: ${mediumSizes.historical.timelineHeadLeft};
			width: ${mediumSizes.historical.timelineHeadWidth};
			height: ${mediumSizes.historical.timelineHeadHeight};
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
			left: ${mediumSizes.historical.timelineTailLeft};
		}

		.ant-timeline.ant-timeline-label
			.ant-timeline-item-left
			.ant-timeline-item-content {
			left: ${mediumSizes.historical.timelineContentLeft};
		}

		.icon {
			font-size: ${mediumSizes.historical.addressHistoryIcon};
		}

		.choice {
			font-size: ${mediumSizes.historical.addressHistoryChoice};
		}

		.operator {
			font-size: ${mediumSizes.historical.addressHistoryOperator};
		}

		.plan {
			font-size: ${mediumSizes.historical.addressHistoryPlan};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}) {
		h3 {
			font-size: ${largeSizes.forms.mainTitle};
		}

		.close-icon {
			font-size: ${largeSizes.forms.closeIcon};
		}

		.title {
			font-size: ${largeSizes.forms.title};
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-label {
			width: ${largeSizes.historical.timelineLabelWidth};
			font-size: ${largeSizes.historical.timelineLabel};
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-head {
			left: ${largeSizes.historical.timelineHeadLeft};
			width: ${largeSizes.historical.timelineHeadWidth};
			height: ${largeSizes.historical.timelineHeadHeight};
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
			left: ${largeSizes.historical.timelineTailLeft};
		}

		.ant-timeline.ant-timeline-label
			.ant-timeline-item-left
			.ant-timeline-item-content {
			left: ${largeSizes.historical.timelineContentLeft};
		}

		.icon {
			font-size: ${largeSizes.historical.addressHistoryIcon};
		}

		.choice {
			font-size: ${largeSizes.historical.addressHistoryChoice};
		}

		.operator {
			font-size: ${largeSizes.historical.addressHistoryOperator};
		}

		.plan {
			font-size: ${largeSizes.historical.addressHistoryPlan};
		}
	}
`;
