import React, { useState, useEffect } from "react";
import { myFetchGet } from "../../../services/services";
import { Timeline } from "antd";
import styled from "styled-components";
import dayjs from "dayjs";
import { LoadingHistory } from "../../../components/loading/Loading";
import { useReduxToken } from "../../../hooks/useReduxToken";
import styles from "./HistoryPanel.module.css";
import Br from "../../../components/Br/Br";

const HistoryPanel = ({ data }) => {
	const token = useReduxToken();

	const { ID } = data;

	const [changes, setChanges] = useState([]);
	const [loading, setLoading] = useState(true);

	const initial = async () => {
		try {
			const response = await myFetchGet(
				`api/v1/operador/${ID}/history/`,
				token
			);
			setChanges(response?.changes.map((e) => e[0]));
			setLoading(false);
		} catch (e) {
			console.log("error", e);
		}
	};

	useEffect(() => {
		initial();
	}, []);

	return (
		<Container>
			<div className={styles["row"]}>
				<span className={styles["history-title"]}>Registro de cambios</span>
			</div>
			<div className={styles["row"]}>
				<span className={styles["history-paragraph"]}>
					Histórico asociado a los cambios realizados en la información del
					operador.
				</span>
			</div>

			<Br />
			<Br />

			{loading ? (
				<LoadingHistory />
			) : (
				<Timeline mode="left" style={{ fontFamily: "Lato" }}>
					{changes.map((e) => (
						<Timeline.Item
							label={dayjs(e?.date, "DD-MM-YYYY HH:mm").format(
								"DD/MM/YYYY h:mm a"
							)}
							key={e?.ID}
							color={"#1D428A"}
						>
							{e?.field}
						</Timeline.Item>
					))}
				</Timeline>
			)}
		</Container>
	);
};

export default HistoryPanel;

const Container = styled.div`
	background-color: white;
	display: flex;
	flex-direction: column;
	padding: 0 31px 0 27px;

	.ant-timeline .ant-timeline-item {
		padding-bottom: 35px;
	}

	.ant-timeline.ant-timeline-label .ant-timeline-item-label {
		width: 21%;
		text-align: left;
		padding-left: 60px;
		font-size: 12px;
		color: #131523;
		font-weight: bold;
	}

	.ant-timeline.ant-timeline-label .ant-timeline-item-head {
		margin-left: -5px;
		left: 22%;
		width: 12px;
		height: 12px;
		border: 2px solid;
	}

	.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
		left: 22%;
		border-left: 2px solid #2b80ff;
	}

	.ant-timeline.ant-timeline-label
		.ant-timeline-item-left
		.ant-timeline-item-content {
		left: 21%;
		width: 60%;
		font-size: 12px;
		color: #131523;
	}

	@media screen and (min-width: 1280px) {
		.ant-timeline.ant-timeline-label .ant-timeline-item-label {
			width: 21%;
			font-size: 12px;
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-head {
			left: 22%;
			width: 12px;
			height: 12px;
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
			left: 22%;
		}

		.ant-timeline.ant-timeline-label
			.ant-timeline-item-left
			.ant-timeline-item-content {
			left: 21%;
			font-size: 12px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-timeline.ant-timeline-label .ant-timeline-item-label {
			width: 21%;
			font-size: 13.5px;
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-head {
			left: 22%;
			width: 13.5px;
			height: 13.5px;
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
			left: 22%;
		}

		.ant-timeline.ant-timeline-label
			.ant-timeline-item-left
			.ant-timeline-item-content {
			left: 21%;
			font-size: 13.5px;
		}
	}

	@media screen and (min-width: 1920px) {
		.ant-timeline.ant-timeline-label .ant-timeline-item-label {
			width: 17%;
			font-size: 15.18px;
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-head {
			left: 16%;
			width: 15.18px;
			height: 15.18px;
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
			left: 16.2%;
		}

		.ant-timeline.ant-timeline-label
			.ant-timeline-item-left
			.ant-timeline-item-content {
			left: 16%;
			font-size: 15.18px;
		}
	}
`;
