import React, { useEffect, useState } from "react";
import { Avatar, Divider, Input, Empty, Popconfirm, notification } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { onLogout } from "../../store/auth/authSlice";
import { useSelector } from "react-redux";
import { myFetchGet } from "../../services/services";
import { useReduxToken } from "../../hooks/useReduxToken";
import dayjs from "dayjs";
import styles from "./Header.module.css";

const Header = () => {
	const token = useReduxToken();
	const history = useHistory();
	const location = useLocation();
	const dataState = useSelector((state) => state.authState);
	const dispatch = useDispatch();

	const [showRoleTag, setShowRoleTag] = useState(true);
	const [isVisible, setIsVisible] = useState(false);
	const [search, setSearch] = useState();
	const [saleList, setSaleList] = useState([]);
	const [userList, setUserList] = useState([]);
	const [prospectList, setProspectList] = useState([]);
	const [leadsList, setLeadsList] = useState([]);
	const [visibleSearchResult, setVisibleSearchResult] = useState(false);
	const [emptySearchResult, setEmptySearchResult] = useState(false);

	const { Search } = Input;

	const searchData = async (search) => {
		try {
			const response = await myFetchGet(`api/v1/search?text=${search}`, token);
			if (Object.keys(response).some((index) => response[index].length > 0)) {
				setSaleList(response.sales);
				setUserList(response.usuarios);
				setProspectList(response.prospects);
				setLeadsList(response.leads);
				setEmptySearchResult(false);
			} else {
				setEmptySearchResult(true);
			}
			setVisibleSearchResult(true);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message: "Ha ocurrido un error inesperado, por favor intente de nuevo",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const onLogOutUser = async () => {
		try {
			await myFetchGet("api/v1/signout", token);
		} catch (e) {
			console.log("error", e);
		}
	};

	const logOut = () => {
		onLogOutUser();
		history.replace("/");
		setIsVisible(false);
		dispatch(onLogout());
	};

	const showRoleTagHandler = () => {
		setShowRoleTag(!showRoleTag);
	};

	const searchHandler = (value) => {
		if (value === "") {
			setSearch(undefined);
		} else {
			setSearch(value);
			searchData(value);
		}
	};

	const searchChangeHandler = (e) => {
		setSearch(e.target.value);
		if (e.target.value === "") {
			setSaleList([]);
			setUserList([]);
			setProspectList([]);
			setLeadsList([]);
			setVisibleSearchResult(false);
		}
	};

	const saleClickHandler = (values) => {
		history.replace("/detalleVentas", {
			params: {
				created: dayjs(values.created).format("DD/MM/YYYY"),
				ID: values.sequential_id,
				id_unique: values.ID,
				is_brujula: values.is_brujula,
				headline:
					values.customer_user.first_name +
					" " +
					values.customer_user.last_name,
			},
		});
	};

	const userClickHandler = (values) => {
		history.replace("detalleUsuario", {
			params: {
				id_unique: values.ID,
				created: dayjs(values.created).format("DD/MM/YYYY"),
			},
		});
	};

	const prospectClickHandler = () => {
		history.replace("/listadoDeProspectos");
	};

	const leadClickHandler = () => {
		history.replace("/listadoDeLeads");
	};

	useEffect(() => {
		if (location.pathname !== "/inicio") {
			setSearch(undefined);
			setSaleList([]);
			setUserList([]);
			setProspectList([]);
			setLeadsList([]);
			setVisibleSearchResult(false);
		}
	}, [location]);

	useEffect(() => {
		let timer;
		if (search) {
			timer = setTimeout(() => {
				searchData(search);
			}, 1000);
		}

		return () => {
			clearTimeout(timer);
		};
	}, [search]);

	return (
		<HeaderContainer>
			<div className={styles["left-content"]}>
				<span className={styles["help-text"]}>
					Ayuda: contacto@multifiber.app
				</span>
			</div>
			<div className={styles["middle-content"]}>
				{location.pathname === "/inicio" && (
					<Search
						allowClear
						onSearch={searchHandler}
						onChange={searchChangeHandler}
					/>
				)}
				{visibleSearchResult && location.pathname === "/inicio" && (
					<div
						className={`animate__animated animate__fadeIn ${styles["search-container"]} ${styles["custom-scroll"]}`}
					>
						{!emptySearchResult ? (
							<div className={styles["row"]}>
								{(saleList.length > 0 ||
									prospectList.length > 0 ||
									leadsList.length > 0) && (
									<>
										<div
											className={styles["column"]}
											style={{ width: userList.length > 0 ? "55%" : "100%" }}
										>
											{saleList.length > 0 && (
												<span className={styles["search-title"]}>Ventas</span>
											)}
											{saleList.length > 0 &&
												saleList.map((item, index) => (
													<div
														className={`${styles["column"]} ${styles["flex"]}`}
														key={index}
														onClick={() => {
															saleClickHandler(item);
														}}
														style={{ paddingBottom: "15px", cursor: "pointer" }}
													>
														<div className={styles["row"]}>
															<div
																className={`${styles["column"]} ${styles["flex"]}`}
															>
																<span className={styles["search-text"]}>
																	{item.customer_user.first_name +
																		" " +
																		item.customer_user.last_name}
																</span>
															</div>
															<div
																className={`${styles["column"]} ${styles["flex"]}`}
															>
																<span className={styles["search-text"]}>
																	{item.customer_user.document_name}{" "}
																	{item.customer_user.document_number}
																</span>
															</div>
														</div>
														<div className={styles["row"]}>
															<span
																className={styles["search-text"]}
																style={{ fontWeight: "300" }}
															>
																{item.address.address}
															</span>
														</div>
														<div className={styles["row"]}>
															<div
																className={`${styles["column"]} ${styles["flex"]}`}
															>
																<span
																	className={styles["search-text"]}
																	style={{ fontWeight: "bold" }}
																>
																	{item.operator.name}
																</span>
															</div>
															<div
																className={`${styles["column"]} ${styles["flex"]}`}
															>
																<span
																	className={styles["search-text"]}
																	style={{ fontWeight: "600" }}
																>
																	{item.plan.tradename}
																</span>
															</div>
														</div>
													</div>
												))}
											{prospectList.length > 0 && (
												<span className={styles["search-title"]}>
													Prospectos
												</span>
											)}
											{prospectList.length > 0 &&
												prospectList.map((item, index) => (
													<div
														className={`${styles["column"]} ${styles["flex"]}`}
														key={index}
														onClick={prospectClickHandler}
														style={{ paddingBottom: "15px", cursor: "pointer" }}
													>
														<div className={styles["row"]}>
															<div
																className={`${styles["column"]} ${styles["flex"]}`}
															>
																<span className={styles["search-text"]}>
																	{item.full_name}
																</span>
															</div>
															<div
																className={`${styles["column"]} ${styles["flex"]}`}
															>
																<span className={styles["search-text"]}>
																	{item.phone}
																</span>
															</div>
														</div>
														<div className={styles["row"]}>
															<span className={styles["search-text"]}>
																{item.email}
															</span>
														</div>
														<div className={styles["row"]}>
															<span
																className={styles["search-text"]}
																style={{ fontWeight: "300" }}
															>
																{item.address}
															</span>
														</div>
													</div>
												))}
											{leadsList.length > 0 && (
												<span className={styles["search-title"]}>Leads</span>
											)}
											{leadsList.length > 0 &&
												leadsList.map((item, index) => (
													<div
														className={`${styles["column"]} ${styles["flex"]}`}
														key={index}
														onClick={leadClickHandler}
														style={{ paddingBottom: "15px", cursor: "pointer" }}
													>
														<div className={styles["row"]}>
															<div
																className={`${styles["column"]} ${styles["flex"]}`}
															>
																<span className={styles["search-text"]}>
																	{item.full_name}
																</span>
															</div>
															<div
																className={`${styles["column"]} ${styles["flex"]}`}
															>
																<span className={styles["search-text"]}>
																	{item.phone}
																</span>
															</div>
														</div>
														<div className={styles["row"]}>
															<span className={styles["search-text"]}>
																{item.email}
															</span>
														</div>
														<div className={styles["row"]}>
															<span
																className={styles["search-text"]}
																style={{ fontWeight: "300" }}
															>
																{item.address}
															</span>
														</div>
													</div>
												))}
										</div>
									</>
								)}
								{userList.length > 0 && (
									<>
										{(saleList.length > 0 ||
											prospectList.length > 0 ||
											leadsList.length > 0) && (
											<div
												className={`${styles["column"]} ${styles["flex"]} ${styles["align-center"]}`}
											>
												<Divider type="vertical" style={{ height: "100%" }} />
											</div>
										)}
										<div
											className={styles["column"]}
											style={{
												width:
													saleList.length > 0 ||
													prospectList.length > 0 ||
													leadsList.length > 0
														? "35%"
														: "100%",
											}}
										>
											<span className={styles["search-title"]}>Usuarios</span>
											{userList.map((item, index) => (
												<div
													className={`${styles["row"]} ${styles["align-center"]}`}
													key={index}
													onClick={() => userClickHandler(item)}
													style={{
														paddingBottom: "15px",
														cursor: "pointer",
														width: "fit-content",
													}}
												>
													<Avatar
														icon={<UserOutlined />}
														size={"small"}
														src={item.img !== "" ? item.img : null}
													/>
													<span
														className={styles["search-text"]}
														style={{
															paddingLeft: "10px",
															flex: "1",
															wordBreak: "break-word",
														}}
													>
														{item.name}
													</span>
												</div>
											))}
										</div>
									</>
								)}
							</div>
						) : (
							<Empty
								description={
									"No se encontraron datos, por favor intente de nuevo"
								}
							/>
						)}
					</div>
				)}
			</div>
			<div className={styles["right-content"]}>
				{showRoleTag && (
					<div
						className={`animate__animated animate__bounceIn ${styles["tag-container"]}`}
					>
						{dataState.rol === "administrador" && (
							<span className={styles["tag-text"]}>Administrador</span>
						)}

						{dataState.rol === "multifiber_comercial" && (
							<span className={styles["tag-text"]}>Multifiber Comercial</span>
						)}

						{dataState.rol === "multifiber" && (
							<span className={styles["tag-text"]}>Multifiber</span>
						)}

						{dataState.rol === "administrador_isp" && (
							<span className={styles["tag-text"]}>Administrador ISP</span>
						)}

						{dataState.rol === "supervisor_comercial" && (
							<span className={styles["tag-text"]}>Supervisor</span>
						)}

						{dataState.rol === "vendedor" && (
							<span className={styles["tag-text"]}>Vendedor</span>
						)}

						<div className={styles["tag-arrow"]}></div>
					</div>
				)}
				<Avatar
					className={styles["header-avatar"]}
					icon={<UserOutlined />}
					onClick={showRoleTagHandler}
				/>

				<div
					className={styles["actions-menu-button"]}
					onClick={() => setIsVisible(!isVisible)}
				>
					<div className={styles["user-name"]}>
						<span>{dataState.userName}</span>
					</div>
					<DownOutlined />
				</div>

				{isVisible === true ? (
					<div className={styles["actions-box"]}>
						<span className={styles["logout-title"]}>Opciones de perfil</span>
						<span
							className={styles["logout-text"]}
							onClick={() => {
								history.push("/security");
								setIsVisible(false);
							}}
						>
							Seguridad
						</span>

						<Divider style={{ margin: "15px 0" }} />

						<Popconfirm
							placement="leftTop"
							icon={""}
							title="¿Cerrar Sesión?"
							description={`Para salir del sistema haz click en "SÍ" o en cancelar para seguir trabajando`}
							onConfirm={logOut}
							okText="Sí"
							cancelText="Cancelar"
						>
							<div className={`${styles["row"]} ${styles["align-center"]}`}>
								<span className={styles["logout"]}>
									<i
										className="fa-solid fa-arrow-right-from-bracket"
										style={{
											color: "#426DA9",
											marginRight: "10px",
											marginLeft: "10px",
										}}
									/>
									Cerrar sesión
								</span>
							</div>
						</Popconfirm>
					</div>
				) : null}
			</div>
		</HeaderContainer>
	);
};

export default Header;

const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	background: white;
	width: 100%;
	height: 65px;
	padding: 0 30px;

	.ant-input-affix-wrapper:not(:last-child) {
		border-radius: 5px 0 0 5px !important;
		border: 1px solid #e0e0e0;
		height: 38px;
	}

	.ant-input-search
		> .ant-input-group
		> .ant-input-group-addon:last-child
		.ant-input-search-button {
		border-radius: 0 5px 5px 0 !important;
		border: 1px solid #e0e0e0;
		height: 38px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@media screen and (min-width: 1280px) {
		height: 65px;
	}

	@media screen and (min-width: 1366px) {
		height: 73.12px;
	}

	@media screen and (min-width: 1920px) {
		height: 82.26px;
	}
`;
