import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Panel, PanelHeader } from "../../components/panel/panel";
import TableData from "../../components/table/TableData";
import { myFetchGet, myFetch, myExportData } from "../../services/services";
import dayjs from "dayjs";
import { Select, DatePicker, notification, Input } from "antd";
import { useHistory } from "react-router-dom";
import { Loading } from "../../components/loading/Loading";
import { useReduxToken } from "../../hooks/useReduxToken";
import { useSelector } from "react-redux";
import ColorCircle from "../../components/colorCircle/ColorCircle";
import TextColored from "../../components/textColored/TextColored";
import TableText from "../../components/tableText/TableText";
import CustomTooltip from "../../components/customTooltip/CustomTooltip";
import fileDownload from "js-file-download";
import styles from "./Users.module.css";
import TablePagination from "../../components/tablePagination/TablePagination";

const Users = () => {
	const token = useReduxToken();
	const dataState = useSelector((state) => state.authState);
	const viewUserApp = dataState.permissions.includes("datatable_view_userapp");

	const [loadingTable, setLoadingTable] = useState(true);
	const [tableColumns, setTableColumns] = useState([]);
	const [totalTableSize, setTotalTableSize] = useState(0);
	const [tableRows, setTableRows] = useState([]);
	const [paginationSettings, setPaginationSettings] = useState([1, 10]);
	const [tableParams, setTableParams] = useState({ offset: 10, start: 0 });

	const [authTypeFilter, setAuthTypeFilter] = useState();
	const [userTypeFilter, setUserTypeFilter] = useState();
	const [startDateFilter, setStartDateFilter] = useState();
	const [endDateFilter, setEndDateFilter] = useState();
	const [searchData, setSearchData] = useState();
	const [sorterOrder, setSorterOrder] = useState("desc");
	const [columnFilterValue, setColumnFilterValue] = useState([]);
	const [exportFilters, setExportFilters] = useState({});

	const clearData = undefined;

	const { RangePicker } = DatePicker;
	const { Search } = Input;

	const initial = async () => {
		const loginMethod = `["login_method","equal","` + authTypeFilter + `"]`;
		const isSeller = `["is_seller","equal","` + userTypeFilter + `"]`;
		const period =
			`["created","gt","` +
			startDateFilter +
			` 00:00"], ["created","lte","` +
			endDateFilter +
			` 23:59"]`;
		const head = `[`;
		const tail = `]`;
		let filters = {};
		if (
			authTypeFilter !== undefined &&
			userTypeFilter === undefined &&
			startDateFilter === undefined &&
			endDateFilter === undefined &&
			searchData === undefined
		) {
			console.log("1");
			filters = {
				filters: head + loginMethod + tail,
				offset: tableParams.offset,
				start: tableParams.start,
			};
		} else if (
			userTypeFilter !== undefined &&
			authTypeFilter === undefined &&
			startDateFilter === undefined &&
			endDateFilter === undefined &&
			searchData === undefined
		) {
			console.log("2");
			filters = {
				filters: head + isSeller + tail,
				offset: tableParams.offset,
				start: tableParams.start,
			};
		} else if (
			startDateFilter !== undefined &&
			endDateFilter !== undefined &&
			userTypeFilter === undefined &&
			authTypeFilter === undefined &&
			searchData === undefined
		) {
			console.log("3");
			filters = {
				filters: head + period + tail,
				offset: tableParams.offset,
				start: tableParams.start,
			};
		} else if (
			searchData !== undefined &&
			authTypeFilter === undefined &&
			userTypeFilter === undefined &&
			startDateFilter === undefined &&
			endDateFilter === undefined
		) {
			console.log("4");
			filters = {
				search: searchData,
				offset: tableParams.offset,
				start: tableParams.start,
			};
		} else if (
			authTypeFilter !== undefined &&
			userTypeFilter !== undefined &&
			startDateFilter === undefined &&
			endDateFilter === undefined &&
			searchData === undefined
		) {
			console.log("1 y 2");
			filters = {
				filters: head + loginMethod + `, ` + isSeller + tail,
				offset: tableParams.offset,
				start: tableParams.start,
			};
		} else if (
			authTypeFilter !== undefined &&
			startDateFilter !== undefined &&
			endDateFilter !== undefined &&
			userTypeFilter === undefined &&
			searchData === undefined
		) {
			console.log("1 y 3");
			filters = {
				filters: head + loginMethod + `, ` + period + tail,
				offset: tableParams.offset,
				start: tableParams.start,
			};
		} else if (
			authTypeFilter !== undefined &&
			searchData !== undefined &&
			userTypeFilter === undefined &&
			startDateFilter === undefined &&
			endDateFilter === undefined
		) {
			console.log("1 y 4");
			filters = {
				filters: head + loginMethod + tail,
				search: searchData,
				offset: tableParams.offset,
				start: tableParams.start,
			};
		} else if (
			userTypeFilter !== undefined &&
			startDateFilter !== undefined &&
			endDateFilter !== undefined &&
			authTypeFilter === undefined &&
			searchData === undefined
		) {
			console.log("2 y 3");
			filters = {
				filters: head + isSeller + `, ` + period + tail,
				offset: tableParams.offset,
				start: tableParams.start,
			};
		} else if (
			userTypeFilter !== undefined &&
			searchData !== undefined &&
			authTypeFilter === undefined &&
			startDateFilter === undefined &&
			endDateFilter === undefined
		) {
			console.log("2 y 4");
			filters = {
				filters: head + isSeller + tail,
				search: searchData,
				offset: tableParams.offset,
				start: tableParams.start,
			};
		} else if (
			startDateFilter !== undefined &&
			endDateFilter !== undefined &&
			searchData !== undefined &&
			authTypeFilter === undefined &&
			userTypeFilter === undefined
		) {
			console.log("3 y 4");
			filters = {
				filters: head + period + tail,
				search: searchData,
				offset: tableParams.offset,
				start: tableParams.start,
			};
		} else if (
			authTypeFilter !== undefined &&
			userTypeFilter !== undefined &&
			startDateFilter !== undefined &&
			endDateFilter !== undefined &&
			searchData === undefined
		) {
			console.log("1, 2 y 3");
			filters = {
				filters: head + loginMethod + `, ` + isSeller + `, ` + period + tail,
				offset: tableParams.offset,
				start: tableParams.start,
			};
		} else if (
			authTypeFilter !== undefined &&
			startDateFilter !== undefined &&
			endDateFilter !== undefined &&
			searchData !== undefined &&
			userTypeFilter === undefined
		) {
			console.log("1, 3 y 4");
			filters = {
				filters: head + loginMethod + `, ` + period + tail,
				search: searchData,
				offset: tableParams.offset,
				start: tableParams.start,
			};
		} else if (
			userTypeFilter !== undefined &&
			startDateFilter !== undefined &&
			endDateFilter !== undefined &&
			searchData !== undefined &&
			authTypeFilter === undefined
		) {
			console.log("2, 3 y 4");
			filters = {
				filters: head + isSeller + `, ` + period + tail,
				search: searchData,
				offset: tableParams.offset,
				start: tableParams.start,
			};
		} else if (
			authTypeFilter !== undefined &&
			userTypeFilter !== undefined &&
			startDateFilter !== undefined &&
			endDateFilter !== undefined &&
			searchData !== undefined
		) {
			console.log("1, 2, 3 y 4");
			filters = {
				filters: head + loginMethod + `, ` + isSeller + `, ` + period + tail,
				search: searchData,
				offset: tableParams.offset,
				start: tableParams.start,
			};
		} else {
			filters = {
				offset: tableParams.offset,
				start: tableParams.start,
			};
		}

		let newFilters = {
			...filters,
			order_field: "ID",
			order_type: sorterOrder,
		};

		if (columnFilterValue.length === 1 && !newFilters.filters) {
			newFilters = {
				...newFilters,
				filters: `[["last_purchase","equal","` + columnFilterValue[0] + `"]]`,
			};
		} else if (
			(columnFilterValue.length === 0 && !newFilters.filters) ||
			(columnFilterValue.length === 0 && newFilters.filters)
		) {
			newFilters = newFilters;
		} else if (columnFilterValue.length === 1 && newFilters.filters) {
			newFilters.filters = newFilters.filters.replace(
				"]",
				`], ` + `["last_purchase","equal","` + columnFilterValue[0] + `"]`
			);
		}

		setExportFilters(newFilters);

		try {
			const response = await myFetch(
				"api/v1/userapp/datatables/",
				token,
				newFilters
			);
			if (response) {
				if (response.size) {
					setTotalTableSize(response.size);
				}
				if (response.data) {
					const rows = response.data.map((row) => {
						const createdFormat = dayjs(row.created).format("DD/MM/YYYY");
						const lastLoginFormat = dayjs(row.last_login).format("DD/MM/YYYY");
						let sellerFormat = "";
						if (row?.is_seller === true) {
							sellerFormat = "Vendedor";
						} else if (row?.is_seller === false) {
							sellerFormat = "Comprador";
						}
						return {
							...row,
							ID: row.id_unique,
							key: row.ID,
							created: createdFormat,
							last_login: lastLoginFormat,
							is_seller: sellerFormat,
						};
					});
					setTableRows(rows);
				}
			}
			setLoadingTable(false);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const updateRowsParams = (keyToUpdate, newValue) => {
		setTableParams((prevState) => ({
			...prevState,
			[keyToUpdate]: newValue,
		}));
	};

	const modifyPagination = (page, offset) => {
		if (offset !== tableParams.offset) {
			updateRowsParams("offset", offset);
		} else {
			updateRowsParams("start", page * offset - offset);
		}
		setPaginationSettings([page, offset]);
	};

	const history = useHistory();

	const rowSelection = (item) => {
		history.push("/detalleUsuario", { params: item });
	};

	const getColumns = async () => {
		try {
			const data = await myFetchGet("api/v1/userapp/datatables_struct", token);
			const columnsTittles = Object.keys(data.columns);
			const columns = [];
			columnsTittles.forEach((columnsTittle) => {
				columns.push({
					onCell:
						columnsTittle === "ID"
							? (record, rowIndex) => {
									return {
										onClick: (event) => {
											rowSelection(record);
										},
										style: { cursor: "pointer" },
										className: "hover-class",
									};
							  }
							: null,
					filters:
						columnsTittle === "Última compra"
							? [
									{ text: "Últimos 7 días", value: 7 },
									{ text: "Últimos 15 días", value: 15 },
									{ text: "Últimos 30 días", value: 30 },
									{ text: "Últimos 60 días", value: 60 },
							  ]
							: null,
					filterMultiple: false,
					sorter: columnsTittle === "ID" ? true : null,
					title: columnsTittle,
					dataIndex: data.columns[columnsTittle].field,
					key: data.columns[columnsTittle].field,
					align: "left",
					width:
						columnsTittle === "ID"
							? "70px"
							: columnsTittle === "Última compra"
							? "210px"
							: "",
					render:
						columnsTittle === "ID"
							? (value) => <TextColored value={value} />
							: columnsTittle === "Última sesión"
							? (value) => {
									if (value !== "Invalid Date") {
										return <TableText value={value} />;
									} else {
										return null;
									}
							  }
							: columnsTittle === "Autenticación"
							? (value) => (
									<span
										style={{
											width: "100%",
											display: "flex",
										}}
									>
										{value === "Facebook" ? (
											<div
												style={{
													display: "flex",
													alignItems: "center",
													width: "100%",
												}}
											>
												<ColorCircle color={"#3B5998"} />
												<TableText value={value} />
											</div>
										) : value === "Gmail" ? (
											<div
												style={{
													display: "flex",
													alignItems: "center",
													width: "100%",
												}}
											>
												<ColorCircle color={"#F53C56"} />
												<TableText value={value} />
											</div>
										) : value === "SMS" ? (
											<div
												style={{
													display: "flex",
													alignItems: "center",
													width: "100%",
												}}
											>
												<ColorCircle color={"#26A805"} />
												<TableText value={value} />
											</div>
										) : (
											<TableText value={value} />
										)}
									</span>
							  )
							: columnsTittle === "Usuario"
							? (value) => (
									<span
										style={{
											display: "flex",
											alignItems: "center",
											width: "100%",
										}}
									>
										{value === "Vendedor" ? (
											<TableText value={value} textColor={"#426DA9"} />
										) : (
											<TableText value={value} />
										)}
									</span>
							  )
							: (value) => <TableText value={value} />,
				});
			});
			setTableColumns(columns);
		} catch (e) {
			console.log("error", e);
		}
	};

	const tableChangeHandler = (pagination, filter, sorter, extra) => {
		if (sorter.order === "ascend") {
			setSorterOrder("asc");
		} else if (sorter.order === "descend" || sorter.order === undefined) {
			setSorterOrder("desc");
		}
		if (filter.last_purchase) {
			setColumnFilterValue(filter.last_purchase);
		} else {
			setColumnFilterValue([]);
		}
	};

	useEffect(() => {
		initial();
		getColumns();
	}, [
		authTypeFilter,
		userTypeFilter,
		startDateFilter,
		endDateFilter,
		searchData,
		tableParams,
		sorterOrder,
		columnFilterValue,
	]);

	useEffect(() => {
		if (!viewUserApp) {
			history.replace("/inicio");
		}
	}, [viewUserApp]);

	const authFilterHandler = (value) => {
		setAuthTypeFilter(value);
	};

	const userTypeFilterHandler = (value) => {
		setUserTypeFilter(value);
	};

	const dateFormat = "DD/MM/YYYY";

	const dateSelectHandler = (dates, dateStrings) => {
		if (dateStrings[0] === "" && dateStrings[1] === "") {
			const clearedDate = undefined;
			setStartDateFilter(clearedDate);
			setEndDateFilter(clearedDate);
		} else {
			setStartDateFilter(dateStrings[0]);
			setEndDateFilter(dateStrings[1]);
		}
	};

	const disabledDate = (current) => {
		return current > dayjs().endOf("day");
	};

	const searchHandler = (value) => {
		if (value === "") {
			setSearchData(clearData);
		} else {
			setSearchData(value);
		}
	};

	const onExportData = async () => {
		try {
			const file = await myExportData(
				"api/v1/userapp/datatables_download/",
				token,
				exportFilters
			);
			fileDownload(file, "Listado de Usuarios.xlsx");
		} catch (e) {
			console.log("error", e);
		}
	};

	return (
		<Panel>
			<PanelHeader>
				<div className={styles["header-container"]}>
					<span className={styles["header-title"]}>Listado de usuarios</span>
					<CustomTooltip
						subtitle={"Listado de usuarios registrados en la aplicación."}
					/>
					<span className={styles["header-breadcrumb"]}>
						Usuarios | Listado de usuarios
					</span>
				</div>
			</PanelHeader>
			<div className={styles["table-container"]}>
				<FilterContainer>
					<span className={styles["filters-title"]}>Opciones de filtrado</span>
					<div className={styles["row"]}>
						<div className={styles["column"]}>
							<Select
								className={styles["select"]}
								placeholder="Autenticación"
								onChange={authFilterHandler}
								allowClear={true}
								onClear={authFilterHandler}
							>
								<Select.Option value={"Gmail"}>Gmail</Select.Option>
								<Select.Option value={"Facebook"}>Facebook</Select.Option>
								<Select.Option value={"SMS"}>SMS</Select.Option>
							</Select>
						</div>
						<div className={styles["column"]}>
							<Select
								className={styles["select"]}
								placeholder="Usuario"
								onChange={userTypeFilterHandler}
								allowClear={true}
								onClear={userTypeFilterHandler}
							>
								<Select.Option value={true}>Vendedor</Select.Option>
								<Select.Option value={false}>Comprador</Select.Option>
							</Select>
						</div>
						<div className={styles["column"]}>
							<RangePicker
								className={styles["date-picker"]}
								placeholder={["Fecha Inicio", "Fecha Fin"]}
								onChange={dateSelectHandler}
								format={dateFormat}
								disabledDate={disabledDate}
							/>
						</div>
						<div className={`${styles["column"]} ${styles["position-end"]}`}>
							<Search
								className={styles["search-input"]}
								placeholder="Realizar búsqueda"
								allowClear
								onSearch={searchHandler}
							/>
						</div>
					</div>
				</FilterContainer>
				{loadingTable ? (
					<Loading />
				) : (
					<>
						<TableData
							rows={tableRows}
							columns={tableColumns}
							onChange={tableChangeHandler}
						/>

						<div
							className={`${styles["row"]} ${styles["pagination-container"]}`}
						>
							<TablePagination
								paginationSettings={paginationSettings}
								totalTableSize={totalTableSize}
								onChange={modifyPagination}
								showTotal={(total, range) => (
									<div className={styles["column"]}>
										<span className={styles["pagination"]}>
											Mostrando del {range[0]} al {range[1]} de un total de{" "}
											{total} registros
										</span>
										<span
											className={styles["export-button"]}
											onClick={onExportData}
										>
											<i
												className={`fa-solid fa-file-excel ${styles["export-button-icon"]}`}
											></i>
											Exportar data
										</span>
									</div>
								)}
							/>
						</div>
					</>
				)}
			</div>
		</Panel>
	);
};

export default Users;

const FilterContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 53px 0 42px;

	.ant-select-selector {
		border-radius: 5px !important;
		border: 1px solid #e0e0e0 !important;
		display: flex;
		align-items: center;
		height: 38px !important;
		font-size: 12px;
	}

	.ant-select-clear {
		display: flex;
		align-items: center;
	}

	.ant-picker .ant-picker-input > input {
		font-size: 12px;
	}

	.ant-input-affix-wrapper:not(:last-child) {
		border-radius: 5px 0 0 5px !important;
		border: 1px solid #e0e0e0;
		height: 38px;
		font-size: 12px;
	}

	.ant-input-affix-wrapper .ant-input-clear-icon {
		display: flex;
	}

	.ant-input-search
		> .ant-input-group
		> .ant-input-group-addon:last-child
		.ant-input-search-button {
		border-radius: 0 5px 5px 0 !important;
		border: 1px solid #e0e0e0;
		height: 38px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
	}

	@media screen and (min-width: 1280px) {
		.ant-select-selector,
		.ant-picker .ant-picker-input > input,
		.ant-input-affix-wrapper:not(:last-child),
		.ant-input-search
			> .ant-input-group
			> .ant-input-group-addon:last-child
			.ant-input-search-button {
			font-size: 12px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-select-selector,
		.ant-picker .ant-picker-input > input,
		.ant-input-affix-wrapper:not(:last-child),
		.ant-input-search
			> .ant-input-group
			> .ant-input-group-addon:last-child
			.ant-input-search-button {
			font-size: 13.5px;
		}
	}

	@media screen and (min-width: 1920px) {
		.ant-select-selector,
		.ant-picker .ant-picker-input > input,
		.ant-input-affix-wrapper:not(:last-child),
		.ant-input-search
			> .ant-input-group
			> .ant-input-group-addon:last-child
			.ant-input-search-button {
			font-size: 15.18px;
		}
	}
`;
