import React, { useEffect, useState } from "react";
import Login from "./pages/login.jsx";
import Layout from "./layout/index.jsx";
import moment from "moment";
import { useSelector } from "react-redux";

import "moment/locale/es";
moment.locale("es");

const App = () => {
	const [isLogged, setIsLogged] = useState();
	const dataState = useSelector((state) => state.authState);

	useEffect(() => {
		if (dataState.token != null || dataState.token != undefined) {
			setIsLogged(true);
		} else {
			setIsLogged(false);
		}
	}, [dataState.token]);

	return isLogged ? <Layout /> : <Login />;
};

export default App;
