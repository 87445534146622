import React from "react";
import styled from "styled-components";
import devices from "../../helpers/sizes";
import { smallSizes, mediumSizes, largeSizes } from "../../helpers/sizes";

export default function TableLink({ value }) {
	return (
		<TableTextContainer>
			<a
				href={value}
				target="_blank"
				className="table-text"
				style={{ color: "#2B80FF" }}
			>
				{value}
			</a>
		</TableTextContainer>
	);
}

const TableTextContainer = styled.div`
	.table-text {
		font-size: ${smallSizes.tableTextContainer.tableText};
	}

	@media screen and (min-width: ${devices.smallDesk}) {
		.table-text {
			font-size: ${smallSizes.tableTextContainer.tableText};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}) {
		.table-text {
			font-size: ${mediumSizes.tableTextContainer.tableText};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}) {
		.table-text {
			font-size: ${largeSizes.tableTextContainer.tableText};
		}
	}
`;
