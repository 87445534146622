import React, { useState, useEffect } from "react";
import logo from "../assets/images/logo.png";
import { Input, Button, notification } from "antd";
import { useHistory } from "react-router";
import { myFetchGet, signIn } from "../services/services";
import { useDispatch } from "react-redux";
import { authenticationGoggle, onLogin } from "../store/auth/authSlice";
import { regex } from "../helpers/regex";
import styles from "./Login.module.css";
import Br from "../components/Br/Br";

const Login = () => {
	const history = useHistory();

	const dispatch = useDispatch();

	const [formValues, setFormValues] = useState({ email: "", password: "" });
	const [errors, setErrors] = useState({ email: "", password: "" });
	const [errorStatus, setErrorStatus] = useState({
		email: false,
		password: false,
	});

	const inputChangeHandler = (event) => {
		const { name, value } = event.target;
		setFormValues({ ...formValues, [name]: value });
	};

	useEffect(() => {
		const { email, password } = formValues;
		let emailError = "";
		let passwordError = "";
		if (!email) {
			emailError = "Ingrese el Correo";
		} else if (regex.regexSimpleEmail.test(email) === false) {
			emailError = "Ingrese una dirección de correo valida";
		}
		if (!password) {
			passwordError = "Ingrese la Contraseña";
		}
		setErrors({ email: emailError, password: passwordError });
	}, [formValues, errorStatus]);

	const validateInput = () => {
		if (errors.email) {
			setErrorStatus((prevState) => ({ ...prevState, email: true }));
		} else {
			setErrorStatus((prevState) => ({ ...prevState, email: false }));
		}

		if (errors.password) {
			setErrorStatus((prevState) => ({ ...prevState, password: true }));
		} else {
			setErrorStatus((prevState) => ({ ...prevState, password: false }));
		}
	};

	async function onSubmit(value) {
		if (errors.email && errors.password) {
			setErrorStatus({ email: true, password: true });
			notification.warning({
				className: "notification",
				message: "Por favor, completa todos los campos",
				placement: "bottomLeft",
				duration: 2.5,
			});
			return;
		}

		setErrorStatus({ email: false, password: false });

		try {
			const response = await signIn(value.email, value.password);
			dispatch(
				onLogin({
					email: value.email,
					token: response.token,
					rol: response.rol,
					permissions: response.permissions,
					userID: response.user_detail.ID,
					userName: response.user_detail.name,
					userLastName: response.user_detail.last_name,
					operatorID: response.operator_datail.ID,
					operatorCreated: response.operator_datail.created,
					operatorName: response.operator_datail.name,
					operatorLogo: response.operator_datail.logo,
					operatorCountryID: response.operator_datail.country_id,
					operatorCountryCoordinates:
						response.operator_datail.default_coordinates,
					operatorMapZoom: response.operator_datail.default_zoom,
					operatorCountryIso: response.operator_datail.country_iso,
					userOperators: response.operators_datail,
				})
			);
			authenticate(response.token);
			setTimeout(() => {
				history.replace("/inicio");
				// window.location.reload();
			}, 1000);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message: "Se ha producido un error inesperado",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	}

	const authenticate = async (token) => {
		try {
			const verify_authentication = await myFetchGet(
				"api/v1/status-authenticator/",
				token
			);
			dispatch(
				authenticationGoggle({
					authentication: verify_authentication.success,
				})
			);
		} catch (e) {
			console.log("error", e);
		}
	};

	return (
		<div className={styles["login-container"]}>
			<div className={styles["left-content"]}>
				<div className={styles["logo"]}>
					<div className={styles["circle"]}>
						<img src={logo} alt="Logo Oráculo"></img>
						<div className={styles["line"]}></div>
					</div>
				</div>
				<div className={styles["text-content"]}>
					<h3>Bienvenido a</h3>
					<b>Oráculo</b>
					<Br />
					<Br />
					<p>
						Sistema de gestión de ventas y promociones; cuyo objetivo principal
						es simplificar el proceso comercial, orientado a la gestión de
						Clientes y procesos de ventas.
					</p>
				</div>
			</div>
			<div className={styles["right-content"]}>
				<div className={styles["right-container"]}>
					<h2 className={styles["main-title"]}>Inicio de sesión </h2>
					<Br />
					<p className={styles["subtitle"]}>
						Registre los datos de su cuenta de Usuario, para ingresar al
						Sistema.
					</p>
					<Br />
					<b className={styles["input-title"]}>
						Correo electrónico <pre>*</pre>
					</b>
					<Input
						onChange={inputChangeHandler}
						onBlur={validateInput}
						name="email"
						className={styles["input"]}
						value={formValues.email}
						status={errorStatus?.email ? "error" : ""}
						maxLength={50}
					/>
					{errorStatus.email && (
						<div className={styles["position-relative"]}>
							<span
								className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
							>
								{errors.email}
							</span>
						</div>
					)}

					<Br />
					<Br />

					<b className={styles["input-title"]}>
						Contraseña <pre>*</pre>
					</b>
					<Input.Password
						onChange={inputChangeHandler}
						onBlur={validateInput}
						name="password"
						className={styles["input"]}
						value={formValues?.password}
						status={errorStatus?.password === true ? "error" : ""}
						maxLength={50}
					/>
					{errorStatus.password && (
						<div className={styles["position-relative"]}>
							<span
								className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
							>
								{errors.password}
							</span>
						</div>
					)}

					<Br />
					<Br />

					<Button
						onClick={() => onSubmit(formValues)}
						className={styles["button"]}
					>
						Ingresar
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Login;
