import React from "react";
import styled from "styled-components";
import { Tooltip } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import devices from "../../helpers/sizes";
import { smallSizes, mediumSizes, largeSizes } from "../../helpers/sizes";

export default function CustomTooltip({
	type = "custom",
	title,
	subtitle,
	position,
	arrow = true,
	iconColor = "lightBlue",
	iconMargin = true,
	children,
}) {
	if (type === "custom") {
		return (
			<Tooltip
				title={
					<TooltipContainer>
						<span className="tooltip_title">Información de ayuda</span>
						<span className="tooltip_subtitle">{subtitle}</span>
						{children}
					</TooltipContainer>
				}
				color="white"
				placement={position ? position : "bottom"}
				arrow={arrow}
			>
				<IconContainer>
					<InfoCircleFilled
						className={`info_icon ${iconColor} ${
							iconMargin ? "icon-margin" : ""
						}`}
					/>
				</IconContainer>
			</Tooltip>
		);
	} else if (type === "classic") {
		return (
			<Tooltip title={title} color="white">
				{children}
			</Tooltip>
		);
	}
}

const TooltipContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 5px;

	.row-line {
		display: flex;
	}

	.align-center {
		align-items: center;
	}

	.tooltip_title {
		color: #868e96;
		font-size: ${smallSizes.tooltipContainer.title};
		margin: 0 0 15px 5px;
	}

	.tooltip_subtitle {
		color: #172b4d;
		font-size: ${smallSizes.tooltipContainer.subtitle};
		margin: 0 0 0 5px;
	}

	.tooltip_dot {
		height: ${smallSizes.tooltipContainer.dotHeight};
		width: ${smallSizes.tooltipContainer.dotWidth};
		border-radius: 50%;
		margin: 0 3px 0 5px;
	}

	@media screen and (min-width: ${devices.smallDesk}) {
		.tooltip_title {
			font-size: ${smallSizes.tooltipContainer.title};
		}

		.tooltip_subtitle {
			font-size: ${smallSizes.tooltipContainer.subtitle};
		}

		.tooltip_dot {
			height: ${smallSizes.tooltipContainer.dotHeight};
			width: ${smallSizes.tooltipContainer.dotWidth};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}) {
		.tooltip_title {
			font-size: ${mediumSizes.tooltipContainer.title};
		}

		.tooltip_subtitle {
			font-size: ${mediumSizes.tooltipContainer.subtitle};
		}

		.tooltip_dot {
			height: ${mediumSizes.tooltipContainer.dotHeight};
			width: ${mediumSizes.tooltipContainer.dotWidth};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}) {
		.tooltip_title {
			font-size: ${largeSizes.tooltipContainer.title};
		}

		.tooltip_subtitle {
			font-size: ${largeSizes.tooltipContainer.subtitle};
		}

		.tooltip_dot {
			height: ${largeSizes.tooltipContainer.dotHeight};
			width: ${largeSizes.tooltipContainer.dotWidth};
		}
	}
`;

const IconContainer = styled.div`
	.info_icon {
		font-size: ${smallSizes.headerContainer.infoIcon};
	}

	.lightBlue {
		color: #426da9;
	}

	.hardBlue {
		color: #1d428a;
	}

	.icon-margin {
		margin: 0 1em;
	}

	@media screen and (min-width: ${devices.mediumDesk}) {
		.info_icon {
			font-size: ${smallSizes.headerContainer.infoIcon};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}) {
		.info_icon {
			font-size: ${mediumSizes.headerContainer.infoIcon};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}) {
		.info_icon {
			font-size: ${largeSizes.headerContainer.infoIcon};
		}
	}
`;
