import React, { useState, useRef, useEffect } from "react";
import { Input, Space, Button, Divider, Select, notification } from "antd";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { myFetch, myFetchGet } from "../../../services/services";
import { useReduxToken } from "../../../hooks/useReduxToken";
import { regex } from "../../../helpers/regex";
import CustomTooltip from "../../../components/customTooltip/CustomTooltip";
import { LoadingForm } from "../../../components/loading/Loading";
import styles from "./FormOperator.module.css";
import Br from "../../../components/Br/Br";

const FormOperator = ({ visible, setVisible, initial }) => {
	const token = useReduxToken();
	const [loading, setLoading] = useState(true);

	/*Data del operador */
	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [currencyList, setCurrencyList] = useState([]);
	const [countryList, setCountryList] = useState([]);
	const [technologyList, setTechnologyList] = useState([]);
	const [selectedCountryName, setSelectedCountryName] = useState();
	const [selectedCountryISO, setSelectedCountryISO] = useState();
	const [country, setCountry] = useState();
	const [countryToShow, setCountryToShow] = useState();
	const [currency, setCurrency] = useState([]);
	const [technology, setTechnology] = useState([]);
	const [status, setStatus] = useState(1);
	const [webSite, setWebSite] = useState();
	const [facebook, setFacebook] = useState("N/A");
	const [instagram, setInstagram] = useState("N/A");
	const [twitter, setTwitter] = useState("N/A");
	const [additionalInformation, setAdditionalInformation] = useState();
	const [coverage, setCoverage] = useState();
	const [logo, setLogo] = useState(null);
	const [tempPhoto, setTempPhoto] = useState(null);

	/*Data de Contacto del Operador */
	const [nameContactData, setNameContactData] = useState();
	const [emailContactData, setEmailContactData] = useState();
	const [countryContactData, setCountryContactData] = useState();
	const [phoneContactData, setPhoneContactData] = useState();
	const [chargeContactData, setChargeContactData] = useState();

	const contactPhone = countryContactData + " " + phoneContactData;

	/*Data de Facturación del Operador */
	const [businessName, setBusinessName] = useState();
	const [documentList, setDocumentList] = useState([]);
	const [documentNumber, setDocumentNumber] = useState();
	const [selectedDocumentTypeName, setSelectedDocumentTypeName] = useState();
	const [documentType, setDocumentType] = useState();
	const [address, setAddress] = useState();
	const [countryBillingData, setCountryBillingData] = useState();
	const [phoneBillingData, setPhoneBillingData] = useState();

	const billingPhone = countryBillingData + " " + phoneBillingData;

	const social_networks = [];

	social_networks.push(facebook, instagram, twitter);

	/*Validaciones */
	const [errors, setErrors] = useState({
		name: "",
		email: "",
		country: "",
		currency: "",
		technology: "",
		webSite: "",
		nameContactData: "",
		emailContactData: "",
		phoneContactData: "",
		chargeContactData: "",
		businessName: "",
		documentType: "",
		documentNumber: "",
		phoneBillingData: "",
		address: "",
	});
	const [errorStatus, setErrorStatus] = useState({
		name: false,
		email: false,
		country: false,
		currency: false,
		technology: false,
		webSite: false,
		nameContactData: false,
		emailContactData: false,
		phoneContactData: false,
		chargeContactData: false,
		businessName: false,
		documentType: false,
		documentNumber: false,
		phoneBillingData: false,
		address: false,
	});

	const { TextArea } = Input;

	const getFormData = async () => {
		try {
			const countries = await myFetchGet("api/v1/country/", token);
			setCountryList(countries);

			const technologies = await myFetchGet("api/v1/technology/", token);
			setTechnologyList(technologies);

			setLoading(false);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	useEffect(() => {
		const getCurrencies = async () => {
			try {
				const currency = await myFetchGet(
					`api/v1/currency/for_country/?country_id=${country}`,
					token
				);
				setCurrencyList(currency);
			} catch (error) {
				console.log("🚀 ~ error:", error);
				notification.error({
					className: "notification",
					message:
						"Se ha producido un error inesperado, por favor intente nuevamente",
					placement: "bottomLeft",
					duration: 2.5,
				});
			}
		};

		if (country) {
			setCurrency([]);
			getCurrencies();
		}
	}, [country]);

	const getDocumentTypes = async () => {
		try {
			const documents = await myFetchGet("api/v1/documents/", token);
			const filteredDocuments = documents.filter(
				(obj) => obj.country_document.name === selectedCountryName
			);
			setDocumentList(filteredDocuments);
		} catch (e) {
			console.log("error", e);
		}
	};

	useEffect(() => {
		let nameError = "";
		let emailError = "";
		let countryError = "";
		let currencyError = "";
		let technologyError = "";
		let webSiteError = "";
		let nameContactDataError = "";
		let emailContactDataError = "";
		let phoneContactDataError = "";
		let chargeContactDataError = "";
		let businessNameError = "";
		let documentTypeError = "";
		let documentNumberError = "";
		let phoneBillingDataError = "";
		let addressError = "";

		if (!name) {
			nameError = "Ingrese el nombre del operador";
		} else if (regex.regexNames.test(name) === false) {
			nameError = "Debe ingresar solo letras";
		}

		if (!email) {
			emailError = "Ingrese una dirección de correo";
		} else if (regex.regexSimpleEmail.test(email) === false) {
			emailError = "Ingrese una dirección de correo valida";
		}

		if (!country) {
			countryError = "Debe seleccionar un país";
		}

		if (currency.length === 0) {
			currencyError = "Debe seleccionar una moneda";
		}

		if (technology.length === 0) {
			technologyError = "Debe seleccionar una tecnología";
		}

		if (!webSite) {
			webSiteError = "Ingrese un sitio web";
		} else if (regex.regexWebsite.test(webSite) === false) {
			webSiteError = "Debe ingresar una URL valida";
		}

		if (!nameContactData) {
			nameContactDataError = "Ingrese su nombre completo";
		} else if (regex.regexNames.test(nameContactData) === false) {
			nameContactDataError = "Debe ingresar solo letras";
		}

		if (!emailContactData) {
			emailContactDataError = "Ingrese una dirección de correo";
		} else if (regex.regexSimpleEmail.test(emailContactData) === false) {
			emailContactDataError = "Ingrese una dirección de correo valida";
		}

		if (!phoneContactData) {
			phoneContactDataError = "Ingrese un número de teléfono";
		} else if (regex.regexNumbers.test(phoneContactData) === false) {
			phoneContactDataError = "Debe ingresar solo números";
		} else if (
			(countryContactData === "+56" || countryContactData === "+51") &&
			phoneContactData.length !== 9
		) {
			phoneContactDataError = "Debe ingresar solo 9 números";
		} else if (countryContactData === "+57" && phoneContactData.length !== 10) {
			phoneContactDataError = "Debe ingresar solo 10 números";
		} else if (countryContactData === "+58" && phoneContactData.length !== 11) {
			phoneContactDataError = "Debe ingresar solo 11 números";
		}

		if (!chargeContactData) {
			chargeContactDataError = "Ingrese el cargo del contacto";
		}

		if (!businessName) {
			businessNameError = "Ingrese la razón social del operador";
		} else if (regex.regexNames.test(businessName) === false) {
			businessNameError = "Debe ingresar solo letras";
		}

		if (!documentType) {
			documentTypeError = "Seleccione el tipo de documento";
		}

		if (!documentNumber) {
			documentNumberError = "Ingrese el número de documento";
		} else if (
			selectedDocumentTypeName === "RUT" &&
			documentNumber.length < 12
		) {
			documentNumberError = "Debe ingresar al menos 12 caracteres";
		} else if (
			selectedDocumentTypeName === "RUN" &&
			documentNumber.length < 12
		) {
			documentNumberError = "Debe ingresar al menos 12 caracteres";
		} else if (
			selectedDocumentTypeName === "DNI" &&
			documentNumber.length < 10
		) {
			documentNumberError = "Debe ingresar al menos 10 caracteres";
		} else if (
			selectedDocumentTypeName === "RUC" &&
			documentNumber.length !== 11
		) {
			documentNumberError = "Debe ingresar 11 caracteres";
		} else if (
			selectedDocumentTypeName === "CE" &&
			selectedCountryISO === "pe" &&
			documentNumber.length < 10
		) {
			documentNumberError = "Debe ingresar al menos 10 caracteres";
		} else if (
			selectedDocumentTypeName === "CDC" &&
			documentNumber.length !== 10
		) {
			documentNumberError = "Debe ingresar 10 caracteres";
		} else if (
			selectedDocumentTypeName === "NUIP" &&
			documentNumber.length !== 10
		) {
			documentNumberError = "Debe ingresar 10 caracteres";
		} else if (
			selectedDocumentTypeName === "NIT" &&
			documentNumber.length < 11
		) {
			documentNumberError = "Debe ingresar 11 caracteres";
		} else if (
			selectedDocumentTypeName === "CE" &&
			selectedCountryISO === "co" &&
			documentNumber.length !== 10
		) {
			documentNumberError = "Debe ingresar 10 caracteres";
		} else if (selectedDocumentTypeName === "Pasaporte" && !documentNumber) {
			documentNumberError = "Ingrese el número del pasaporte";
		}

		if (!phoneBillingData) {
			phoneBillingDataError = "Ingrese un número de teléfono";
		} else if (regex.regexNumbers.test(phoneBillingData) === false) {
			phoneBillingDataError = "Debe ingresar solo números";
		} else if (
			(countryBillingData === "+56" || countryBillingData === "+51") &&
			phoneBillingData.length !== 9
		) {
			phoneBillingDataError = "Debe ingresar solo 9 números";
		} else if (countryBillingData === "+57" && phoneBillingData.length !== 10) {
			phoneBillingDataError = "Debe ingresar solo 10 números";
		} else if (countryBillingData === "+58" && phoneBillingData.length !== 11) {
			phoneBillingDataError = "Debe ingresar solo 11 números";
		}

		if (!address) {
			addressError = "Ingrese una dirección";
		}

		setErrors({
			name: nameError,
			email: emailError,
			country: countryError,
			currency: currencyError,
			technology: technologyError,
			webSite: webSiteError,
			nameContactData: nameContactDataError,
			emailContactData: emailContactDataError,
			phoneContactData: phoneContactDataError,
			chargeContactData: chargeContactDataError,
			businessName: businessNameError,
			documentType: documentTypeError,
			documentNumber: documentNumberError,
			phoneBillingData: phoneBillingDataError,
			address: addressError,
		});
	}, [
		name,
		email,
		country,
		currency,
		technology,
		webSite,
		nameContactData,
		emailContactData,
		phoneContactData,
		chargeContactData,
		businessName,
		documentType,
		documentNumber,
		phoneBillingData,
		address,
		errorStatus,
	]);

	const validateInput = () => {
		if (errors.name) {
			setErrorStatus((prevState) => ({ ...prevState, name: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, name: false }));
		}

		if (errors.email) {
			setErrorStatus((prevState) => ({ ...prevState, email: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, email: false }));
		}

		if (errors.country) {
			setErrorStatus((prevState) => ({ ...prevState, country: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, country: false }));
		}

		if (errors.currency) {
			setErrorStatus((prevState) => ({ ...prevState, currency: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, currency: false }));
		}

		if (errors.technology) {
			setErrorStatus((prevState) => ({ ...prevState, technology: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, technology: false }));
		}

		if (errors.webSite) {
			setErrorStatus((prevState) => ({ ...prevState, webSite: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, webSite: false }));
		}

		if (errors.nameContactData) {
			setErrorStatus((prevState) => ({ ...prevState, nameContactData: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, nameContactData: false }));
		}

		if (errors.emailContactData) {
			setErrorStatus((prevState) => ({ ...prevState, emailContactData: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({
				...prevState,
				emailContactData: false,
			}));
		}

		if (errors.phoneContactData) {
			setErrorStatus((prevState) => ({ ...prevState, phoneContactData: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({
				...prevState,
				phoneContactData: false,
			}));
		}

		if (errors.chargeContactData) {
			setErrorStatus((prevState) => ({
				...prevState,
				chargeContactData: true,
			}));
			return;
		} else {
			setErrorStatus((prevState) => ({
				...prevState,
				chargeContactData: false,
			}));
		}

		if (errors.businessName) {
			setErrorStatus((prevState) => ({ ...prevState, businessName: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, businessName: false }));
		}

		if (errors.documentType) {
			setErrorStatus((prevState) => ({ ...prevState, documentType: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, documentType: false }));
		}

		if (errors.documentNumber) {
			setErrorStatus((prevState) => ({ ...prevState, documentNumber: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, documentNumber: false }));
		}

		if (errors.phoneBillingData) {
			setErrorStatus((prevState) => ({ ...prevState, phoneBillingData: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({
				...prevState,
				phoneBillingData: false,
			}));
		}

		if (errors.address) {
			setErrorStatus((prevState) => ({ ...prevState, address: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, address: false }));
		}

		setErrorStatus({
			name: false,
			email: false,
			country: false,
			currency: false,
			technology: false,
			webSite: false,
			nameContactData: false,
			emailContactData: false,
			phoneContactData: false,
			chargeContactData: false,
			businessName: false,
			documentType: false,
			documentNumber: false,
			phoneBillingData: false,
			address: false,
		});
	};

	useEffect(() => {
		if (country) {
			getDocumentTypes();
		} else {
			getFormData();
		}
	}, [country]);

	const clearTempImage = () => {
		setTempPhoto(null);
		setLogo(null);
	};

	const inputFile = useRef();

	const onSubmit = async (data, dataContact, dataBilling) => {
		if (Object.values(errors).some((value) => value !== "")) {
			validateInput();
			notification.warning({
				className: "notification",
				message: "Por favor, completa todos los campos obligatorios",
				placement: "bottomLeft",
				duration: 2.5,
			});
			return;
		}

		setErrorStatus({
			name: false,
			email: false,
			country: false,
			currency: false,
			technology: false,
			webSite: false,
			nameContactData: false,
			emailContactData: false,
			phoneContactData: false,
			chargeContactData: false,
			businessName: false,
			documentType: false,
			documentNumber: false,
			phoneBillingData,
			address: false,
		});

		try {
			const operator = await myFetch("api/v1/operador/", token, data);
			const payloadContact = {
				...dataContact,
				operator: operator.ID,
			};
			const payloadBilling = {
				...dataBilling,
				operator: operator.ID,
			};
			await myFetch("api/v1/operator-contact/", token, payloadContact);
			await myFetch("api/v1/operator-billing-data/", token, payloadBilling);
			notification.success({
				className: "notification",
				message: "Información guardada exitosamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
			initial();
			setVisible(false);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message: "Se ha producido un error inesperado",
				placement: "bottomLeft",
				duration: 2.5,
			});
		} finally {
			// setLoadding(false);
		}
	};

	const onCloseModal = () => {
		setVisible(false);
	};

	const transformToBase64Handler = (archivos) => {
		setTempPhoto(archivos[0]);

		Array.from(archivos).forEach((archivo) => {
			const reader = new FileReader();
			reader.readAsDataURL(archivo);

			reader.onload = function () {
				const base64 = reader.result;

				const img = new Image();
				img.src = base64;

				img.onload = function () {
					if (img.width > 300 || img.height > 300) {
						notification.error({
							className: "notification",
							message: "La imagen no debe exceder 300px de ancho o alto",
							placement: "bottomLeft",
							duration: 2.5,
						});
						return;
					}

					let arraySplitted = base64.split(",");
					setLogo(arraySplitted[1]);
				};
			};
		});
	};

	const countrySelectHandler = (value) => {
		let tempValue = JSON.stringify(value);
		setCountryToShow(tempValue);
		setSelectedCountryISO(value.iso);
		setSelectedCountryName(value.name);
		setCountry(value.ID);
	};

	const currencySelectHandler = (value) => {
		setCurrency(value);
	};

	const technologySelectHandler = (value) => {
		setTechnology(value);
	};

	const statusSelectHandler = (value) => {
		setStatus(value);
	};

	const contactDataCountrySelectHandler = (value) => {
		setCountryContactData(value);
	};

	const documentTypeSelectHandler = (value) => {
		let tempDocumentType = [];
		setDocumentType(value);
		tempDocumentType = documentList.filter((obj) => obj.ID === value);
		setSelectedDocumentTypeName(tempDocumentType[0].name);
	};

	const formatRUT = (value) => {
		let cleaned = value.replace(/[^0-9kK]/g, "");

		if (cleaned.length > 1) {
			cleaned =
				cleaned.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
				"-" +
				cleaned.slice(-1);
		}

		return cleaned;
	};

	const handleDocumentNumberChange = (e) => {
		let value = e.target.value;

		if (selectedDocumentTypeName === "RUT") {
			value = formatRUT(value);
		}

		setDocumentNumber(value);
		validateInput();
	};

	const countryBillingDataSelectHandler = (value) => {
		setCountryBillingData(value);
	};

	if (visible) {
		return (
			<Container>
				<div
					className={`${styles["form-container"]} animate__animated animate__fadeInRight ${styles["custom-scroll"]}`}
				>
					<div
						className={`${styles["row"]} ${styles["space-between"]} ${styles["align-center"]}`}
					>
						<span className={styles["form-title"]}>Registro de Operador</span>
						<CloseOutlined
							className={styles["close-form-icon"]}
							onClick={onCloseModal}
						/>
					</div>

					<Divider />

					{loading ? (
						<LoadingForm />
					) : (
						<>
							{/*----------------------OPERADOR----------------------*/}
							<span className={styles["form-section-title"]}>
								Información general
							</span>
							<div className={styles["row"]}>
								<div className={`${styles["column"]} ${styles["flex"]}`}>
									<span className={styles["form-input-name"]}>
										Nombre <span className={styles["blue"]}>*</span>
									</span>
									<Input
										className={styles["form-input"]}
										style={{
											width: "95%",
										}}
										placeholder="Ingrese el nombre del operador"
										onChange={(e) => setName(e?.target?.value)}
										onBlur={validateInput}
										status={errorStatus.name ? "error" : ""}
										maxLength={25}
									/>
									{errorStatus.name && (
										<div className={styles["position-relative"]}>
											<span
												className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
											>
												{errors.name}
											</span>
										</div>
									)}
								</div>
								<div className={`${styles["column"]} ${styles["flex"]}`}>
									<span className={styles["form-input-name"]}>
										Correo electrónico <span className={styles["blue"]}>*</span>
									</span>
									<Input
										className={styles["form-input"]}
										placeholder="example@email.com"
										onChange={(e) => setEmail(e?.target?.value)}
										onBlur={validateInput}
										status={errorStatus.email ? "error" : ""}
										maxLength={25}
									/>
									{errorStatus.email && (
										<div className={styles["position-relative"]}>
											<span
												className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
											>
												{errors.email}
											</span>
										</div>
									)}
								</div>
							</div>

							<Br />
							<Br />

							<div className={styles["row"]}>
								<div className={`${styles["column"]} ${styles["flex"]}`}>
									<span className={styles["form-input-name"]}>
										País <span className={styles["blue"]}>*</span>
									</span>
									<Select
										className={styles["form-input"]}
										style={{
											width: "95%",
										}}
										value={countryToShow}
										onChange={(value) =>
											countrySelectHandler(JSON.parse(value))
										}
										onBlur={validateInput}
										bordered={false}
										placeholder="Seleccione"
										status={errorStatus.country ? "error" : ""}
									>
										{countryList.map((country) => (
											<Select.Option
												value={JSON.stringify(country)}
												key={country.ID}
											>
												<img
													src={`https://flagcdn.com/28x21/${country.iso}.png`}
													width="20"
													height="15"
													alt={country.name}
													style={{ margin: "0 5px 5px 0" }}
												/>
												{country.name}
											</Select.Option>
										))}
									</Select>
									{errorStatus.country && (
										<div className={styles["position-relative"]}>
											<span
												className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
											>
												{errors.country}
											</span>
										</div>
									)}
								</div>
								<div className={`${styles["column"]} ${styles["flex"]}`}>
									<div className={styles["row"]}>
										<div className={`${styles["column"]} ${styles["flex"]}`}>
											<span className={styles["form-input-name"]}>
												Moneda <span className={styles["blue"]}>*</span>
											</span>
											<Select
												className={styles["form-input"]}
												style={{
													width: "95%",
												}}
												value={currency}
												onChange={currencySelectHandler}
												onBlur={validateInput}
												bordered={false}
												placeholder="Seleccione"
												status={errorStatus.currency ? "error" : ""}
												mode="multiple"
												disabled={currencyList.length === 0}
												maxTagCount="responsive"
											>
												{currencyList.map((currency) => (
													<Select.Option value={currency.ID} key={currency.ID}>
														{currency.name}
													</Select.Option>
												))}
											</Select>
											{errorStatus.currency && (
												<div className={styles["position-relative"]}>
													<span
														className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
													>
														{errors.currency}
													</span>
												</div>
											)}
										</div>
										<div className={`${styles["column"]} ${styles["flex"]}`}>
											<span className={styles["form-input-name"]}>
												Tecnologías <span className={styles["blue"]}>*</span>
											</span>
											<Select
												mode="multiple"
												maxTagCount="responsive"
												className={styles["form-input"]}
												value={technology}
												onChange={technologySelectHandler}
												onBlur={validateInput}
												bordered={false}
												placeholder="Seleccione"
												status={errorStatus.technology ? "error" : ""}
											>
												{technologyList.map((technology) => (
													<Select.Option
														value={technology.ID}
														key={technology.ID}
													>
														{technology.name}
													</Select.Option>
												))}
											</Select>
											{errorStatus.technology && (
												<div className={styles["position-relative"]}>
													<span
														className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
													>
														{errors.technology}
													</span>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>

							<Br />
							<Br />

							<div className={styles["row"]}>
								<div className={`${styles["column"]} ${styles["flex"]}`}>
									<span className={styles["form-input-name"]}>Estatus</span>
									<Select
										className={styles["form-input"]}
										style={{ width: "95%" }}
										value={status}
										onChange={statusSelectHandler}
										bordered={false}
									>
										<Select.Option value={1}>Activo</Select.Option>
										<Select.Option value={2}>Inactivo</Select.Option>
									</Select>
								</div>

								<div className={`${styles["column"]} ${styles["flex"]}`}>
									<span className={styles["form-input-name"]}>
										Página web <span className={styles["blue"]}>*</span>
									</span>
									<Input
										className={styles["form-input"]}
										placeholder="example.cl"
										onChange={(e) => setWebSite(e?.target?.value)}
										onBlur={validateInput}
										status={errorStatus.webSite ? "error" : ""}
										maxLength={25}
									/>
									{errorStatus.webSite && (
										<div className={styles["position-relative"]}>
											<span
												className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
											>
												{errors.webSite}
											</span>
										</div>
									)}
								</div>
							</div>

							<Br />
							<Br />

							<div className={styles["row"]}>
								<div className={`${styles["column"]} ${styles["flex"]}`}>
									<span className={styles["form-input-name"]}>Facebook</span>
									<Input
										className={styles["form-input"]}
										style={{ width: "95%" }}
										placeholder="@example"
										onChange={(e) => setFacebook(e?.target?.value)}
										maxLength={25}
									/>
								</div>
								<div className={`${styles["column"]} ${styles["flex"]}`}>
									<span className={styles["form-input-name"]}>Instagram</span>
									<Input
										className={styles["form-input"]}
										style={{ width: "95%" }}
										placeholder="@example"
										onChange={(e) => setInstagram(e?.target?.value)}
										maxLength={25}
									/>
								</div>
								<div className={`${styles["column"]} ${styles["flex"]}`}>
									<span className={styles["form-input-name"]}>Twitter</span>
									<Input
										className={styles["form-input"]}
										placeholder="@example"
										onChange={(e) => setTwitter(e?.target?.value)}
										maxLength={25}
									/>
								</div>
							</div>

							<Br />

							{logo === null ? (
								<button
									className={styles["upload-image-box"]}
									onClick={() => inputFile.current.click()}
								>
									<i
										className={`fa-sharp fa-solid fa-upload ${styles["upload-image-icon"]}`}
									></i>
									<span className={styles["form-section-title"]}>
										Logo del operador
									</span>
									<span className={styles["logo-text"]}>
										Soporte para una carga única. Prohibir estrictamente cargar
										datos de la empresa u otros archivos de bandas
									</span>
									<span className={styles["logo-required"]}>
										Imagen requerida
									</span>
									<input
										type="file"
										ref={inputFile}
										accept="image/png, image/jpeg"
										onChange={(e) => transformToBase64Handler(e.target.files)}
										style={{ display: "none" }}
									/>
								</button>
							) : (
								<div className={styles["column"]}>
									<img
										className={`${styles["temporal-image"]} animate__animated animate__fadeIn`}
										src={`data:image/png;base64,${logo}`}
										alt="Imagen seleccionada"
									/>
									<Br />

									<div
										className={`${styles["row"]} ${styles["space-between"]}`}
									>
										<p
											style={{ color: "grey" }}
											className="animate__animated animate__fadeIn"
										>
											{tempPhoto?.name}
										</p>
										<DeleteOutlined
											className={styles["clear-temporal-image-icon"]}
											onClick={clearTempImage}
										/>
									</div>
								</div>
							)}

							<Br />

							<div className={styles["row"]}>
								<div className={`${styles["column"]} ${styles["flex"]}`}>
									<div className={`${styles["row"]} ${styles["align-center"]}`}>
										<span className={styles["form-input-name"]}>
											Información adicional
										</span>
										<CustomTooltip
											subtitle={
												"Información asociada al operador que se desea mostrar en la aplicación."
											}
											position={"right"}
										/>
									</div>
									<TextArea
										rows={3}
										className={styles["form-textarea"]}
										onChange={(e) => setAdditionalInformation(e?.target?.value)}
										maxLength={50}
										showCount
									/>
								</div>
							</div>

							<Br />

							<div className={styles["row"]}>
								<div className={`${styles["column"]} ${styles["flex"]}`}>
									<div className={`${styles["row"]} ${styles["align-center"]}`}>
										<span className={styles["form-input-name"]}>Cobertura</span>
										<CustomTooltip
											subtitle={
												"Información asociada al alcance del operador que se desea mostrar en la aplicación."
											}
											position={"right"}
										/>
									</div>
									<TextArea
										rows={3}
										className={styles["form-textarea"]}
										onChange={(e) => setCoverage(e?.target?.value)}
										maxLength={50}
										showCount
									/>
								</div>
							</div>

							<Br />

							{/*------------------------CONTACTO------------------------*/}
							<span className={styles["form-section-title"]}>
								Información de contacto
							</span>
							<div className={styles["row"]}>
								<div className={`${styles["column"]} ${styles["flex"]}`}>
									<span className={styles["form-input-name"]}>
										Nombre completo <span className={styles["blue"]}>*</span>
									</span>
									<Input
										className={styles["form-input"]}
										style={{
											width: "95%",
										}}
										placeholder="Carlos Andrade"
										onChange={(e) => setNameContactData(e.target.value)}
										onBlur={validateInput}
										status={errorStatus.nameContactData ? "error" : ""}
										maxLength={25}
									/>
									{errorStatus.nameContactData && (
										<div className={styles["position-relative"]}>
											<span
												className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
											>
												{errors.nameContactData}
											</span>
										</div>
									)}
								</div>
								<div className={`${styles["column"]} ${styles["flex"]}`}>
									<span className={styles["form-input-name"]}>
										Correo electrónico <span className={styles["blue"]}>*</span>
									</span>
									<Input
										className={styles["form-input"]}
										placeholder="example@email.com"
										onChange={(e) => setEmailContactData(e.target.value)}
										onBlur={validateInput}
										status={errorStatus.emailContactData ? "error" : ""}
										maxLength={25}
									/>
									{errorStatus.emailContactData && (
										<div className={styles["position-relative"]}>
											<span
												className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
											>
												{errors.emailContactData}
											</span>
										</div>
									)}
								</div>
							</div>

							<Br />
							<Br />

							<div className={`${styles["row"]} ${styles["space-between"]}`}>
								<div className={`${styles["column"]} ${styles["flex"]}`}>
									<span className={styles["form-input-name"]}>
										Número de teléfono <span className={styles["blue"]}>*</span>
									</span>
									<div className={styles["row"]}>
										<Select
											className={styles["form-input"]}
											style={{ width: "30%", marginRight: "5%" }}
											value={countryContactData}
											onChange={contactDataCountrySelectHandler}
											bordered={false}
											placeholder="Seleccione"
										>
											<Select.Option value={"+56"}>
												<img
													src={`https://flagcdn.com/28x21/cl.png`}
													width="20"
													height="15"
													alt="Chile"
													style={{ margin: "0 5px 5px 0" }}
												/>
												+56
											</Select.Option>
											<Select.Option value={"+58"}>
												<img
													src={`https://flagcdn.com/28x21/ve.png`}
													width="20"
													height="15"
													alt="Venezuela"
													style={{ margin: "0 5px 5px 0" }}
												/>
												+58
											</Select.Option>
											<Select.Option value={"+57"}>
												<img
													src={`https://flagcdn.com/28x21/co.png`}
													width="20"
													height="15"
													alt="Colombia"
													style={{ margin: "0 5px 5px 0" }}
												/>
												+57
											</Select.Option>
											<Select.Option value={"+51"}>
												<img
													src={`https://flagcdn.com/28x21/pe.png`}
													width="20"
													height="15"
													alt="Perú"
													style={{ margin: "0 5px 5px 0" }}
												/>
												+51
											</Select.Option>
										</Select>
										<div className={styles["column"]} style={{ width: "60%" }}>
											<Input
												className={styles["form-input"]}
												placeholder="505-XXXXXX"
												onChange={(e) => setPhoneContactData(e.target.value)}
												onBlur={validateInput}
												status={errorStatus.phoneContactData ? "error" : ""}
											/>
											{errorStatus.phoneContactData && (
												<div className={styles["position-relative"]}>
													<span
														className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
													>
														{errors.phoneContactData}
													</span>
												</div>
											)}
										</div>
									</div>
								</div>
								<div className={`${styles["column"]} ${styles["flex"]}`}>
									<span className={styles["form-input-name"]}>
										Cargo <span className={styles["blue"]}>*</span>
									</span>
									<Input
										className={styles["form-input"]}
										placeholder="Jefe de compra"
										onChange={(e) => setChargeContactData(e.target.value)}
										onBlur={validateInput}
										status={errorStatus.chargeContactData ? "error" : ""}
										maxLength={25}
									/>
									{errorStatus.chargeContactData && (
										<div className={styles["position-relative"]}>
											<span
												className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
											>
												{errors.chargeContactData}
											</span>
										</div>
									)}
								</div>
							</div>

							<Br />
							<Br />

							{/*------------------------FACTURACIÓN------------------------*/}
							<span className={styles["form-section-title"]}>
								Información de facturación
							</span>
							<div className={styles["row"]}>
								<div className={`${styles["column"]} ${styles["flex"]}`}>
									<span className={styles["form-input-name"]}>
										Razón social <span className={styles["blue"]}>*</span>
									</span>
									<Input
										className={styles["form-input"]}
										style={{
											width: "95%",
										}}
										placeholder="Carlos Andrade"
										onChange={(e) => setBusinessName(e.target.value)}
										onBlur={validateInput}
										status={errorStatus.businessName ? "error" : ""}
										maxLength={25}
									/>
									{errorStatus.businessName && (
										<div className={styles["position-relative"]}>
											<span
												className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
											>
												{errors.businessName}
											</span>
										</div>
									)}
								</div>
								<div className={`${styles["column"]} ${styles["flex"]}`}>
									<span className={styles["form-input-name"]}>
										Documento de identidad{" "}
										<span className={styles["blue"]}>*</span>
									</span>
									<div
										className={`${styles["row"]} ${styles["space-between"]}`}
									>
										<div className={styles["column"]} style={{ width: "35%" }}>
											<Select
												className={styles["form-input"]}
												value={documentType}
												onChange={documentTypeSelectHandler}
												onBlur={validateInput}
												status={errorStatus.documentType ? "error" : ""}
												bordered={false}
												placeholder="Seleccione"
											>
												{documentList.map((document) => (
													<Select.Option key={document.ID} value={document.ID}>
														{document.name}
													</Select.Option>
												))}
											</Select>
											{errorStatus.documentType && (
												<div className={styles["position-relative"]}>
													<span
														className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
													>
														{errors.documentType}
													</span>
												</div>
											)}
										</div>
										<div className={styles["column"]} style={{ width: "60%" }}>
											<Input
												className={styles["form-input"]}
												placeholder="505-XXXXXX"
												onChange={handleDocumentNumberChange}
												onBlur={validateInput}
												status={errorStatus.documentNumber ? "error" : ""}
												value={documentNumber}
											/>
											{errorStatus.documentNumber && (
												<div className={styles["position-relative"]}>
													<span
														className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
													>
														{errors.documentNumber}
													</span>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>

							<Br />
							<Br />

							<div className={styles["row"]}>
								<div className={`${styles["column"]} ${styles["flex"]}`}>
									<span className={styles["form-input-name"]}>
										Número de Teléfono <span className={styles["blue"]}>*</span>
									</span>
									<div className={styles["row"]}>
										<Select
											className={styles["form-input"]}
											style={{ width: "30%", marginRight: "5%" }}
											onChange={countryBillingDataSelectHandler}
											value={countryBillingData}
											bordered={false}
											placeholder="Seleccione"
										>
											<Select.Option value={"+56"}>
												<img
													src={`https://flagcdn.com/28x21/cl.png`}
													width="20"
													height="15"
													alt="Chile"
													style={{ margin: "0 5px 5px 0" }}
												/>
												+56
											</Select.Option>
											<Select.Option value={"+58"}>
												<img
													src={`https://flagcdn.com/28x21/ve.png`}
													width="20"
													height="15"
													alt="Venezuela"
													style={{ margin: "0 5px 5px 0" }}
												/>
												+58
											</Select.Option>
											<Select.Option value={"+57"}>
												<img
													src={`https://flagcdn.com/28x21/co.png`}
													width="20"
													height="15"
													alt="Colombia"
													style={{ margin: "0 5px 5px 0" }}
												/>
												+57
											</Select.Option>
											<Select.Option value={"+51"}>
												<img
													src={`https://flagcdn.com/28x21/pe.png`}
													width="20"
													height="15"
													alt="Perú"
													style={{ margin: "0 5px 5px 0" }}
												/>
												+51
											</Select.Option>
										</Select>
										<div className={styles["column"]} style={{ width: "60%" }}>
											<Input
												className={styles["form-input"]}
												placeholder="505-XXXXXX"
												onChange={(e) => setPhoneBillingData(e.target.value)}
												onBlur={validateInput}
												status={errorStatus.phoneBillingData ? "error" : ""}
											/>
											{errorStatus.phoneBillingData && (
												<div className={styles["position-relative"]}>
													<span
														className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
													>
														{errors.phoneBillingData}
													</span>
												</div>
											)}
										</div>
									</div>
								</div>
								<div className={`${styles["column"]} ${styles["flex"]}`}>
									<span className={styles["form-input-name"]}>
										Dirección <span className={styles["blue"]}>*</span>
									</span>
									<Input
										className={styles["form-input"]}
										placeholder="Dirección"
										onChange={(e) => setAddress(e.target.value)}
										onBlur={validateInput}
										status={errorStatus.address ? "error" : ""}
										maxLength={50}
									/>
									{errorStatus.address && (
										<div className={styles["position-relative"]}>
											<span
												className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
											>
												{errors.address}
											</span>
										</div>
									)}
								</div>
							</div>

							<Br />
							<Br />

							<div className={`${styles["row"]} ${styles["justify-end"]}`}>
								<Space>
									<Button
										className={`${styles["form-action-button"]} ${styles["form-action-button-cancel"]}`}
										type="delete"
										onClick={() => setVisible(false)}
									>
										Cancelar
									</Button>
									<Button
										className={`${styles["form-action-button"]} ${styles["form-action-button-save"]}`}
										onClick={() => {
											onSubmit(
												{
													name: name,
													email: email,
													logo_base64: logo,
													status: status,
													additional_information: additionalInformation,
													social_networks: social_networks,
													coverage: coverage,
													web_site: webSite,
													country: country,
													currency: currency,
													technology: technology,
												},
												{
													name: nameContactData,
													email: emailContactData,
													phone: contactPhone,
													position: chargeContactData,
												},
												{
													business_name: businessName,
													document_number: documentNumber,
													document_type: documentType,
													address: address,
													phone: billingPhone,
												}
											);
										}}
									>
										Guardar
									</Button>
								</Space>
							</div>
						</>
					)}
				</div>
			</Container>
		);
	} else {
		return null;
	}
};

export default FormOperator;

const Container = styled.div`
	background: hsla(0, 0%, 0%, 0.5);
	width: 42%;

	.ant-select-selector {
		font-size: 12px;
	}

	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		padding: 0;
	}

	.ant-select-multiple .ant-select-selection-item-remove {
		display: flex;
	}

	@media screen and (min-width: 1280px) {
		.ant-select-selector {
			font-size: 12px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-select-selector {
			font-size: 13.5px;
		}
	}

	@media screen and (min-width: 1920px) {
		.ant-select-selector {
			font-size: 15.18px;
		}
	}
`;
