import React from "react";

import styled from "styled-components";
import { Button, Divider, Tooltip } from "antd";
import devices from "../../helpers/sizes";
import { largeSizes, mediumSizes, smallSizes } from "../../helpers/sizes";
import { Link } from "react-router-dom";
import { useClassDashboard } from "../../hooks/useClassDashboard";
import { validationNumber } from "../../helpers/validationNumber";
import CustomTooltip from "../customTooltip/CustomTooltip";

function BottomCards({ TotalOperadores, porcentajeOperadores, operator }) {
	const percentSignoOperator = Math.abs(porcentajeOperadores);

	const operadores = useClassDashboard(porcentajeOperadores);

	const percentSignoOperatorFixed = percentSignoOperator.toFixed(2);

	const sendEmailHandler = (email) => {
		window.location.href = `mailto: ${email}`;
	};

	return (
		<Container>
			<div className="bottom-section">
				<div className="operator">
					<div className="graph-operator">
						<div className="title-graph">
							<p className="title-cards">Relación de operadores</p>
							<CustomTooltip
								subtitle={
									"Total de operadores adscritos activos a la plataforma."
								}
								iconColor="hardBlue"
								position={"bottomLeft"}
								arrow={false}
							/>
						</div>
						<div className="title-graph">
							<span className="text-operator">
								{TotalOperadores} operadores
							</span>
							<span className={operadores?.textCard}>
								(
								{validationNumber(percentSignoOperator)
									? percentSignoOperatorFixed
									: percentSignoOperator}{" "}
								%)
							</span>
						</div>
						<div className="title-graph">
							<span className="text-card-sales">
								Total de operadores asociados
							</span>
						</div>
					</div>

					<div className="scroll-operator custom_scroll">
						<div className="row flex">
							<span
								className="text-card-sales flex"
								style={{ marginLeft: "30px" }}
							>
								Operador
							</span>
							<span className="text-card-sales flex">País</span>
							<span className="text-card-sales flex">Total ventas</span>
							<span className="text-card-sales flex">Estatus</span>
							<span style={{ width: "7%" }}></span>
						</div>
						<Divider />
						{operator?.map((item, i) => (
							<>
								<div key={i} className="operadores">
									<div className="flex">
										<img
											className="icon-size"
											src={`data:image/${item.operator__logo.extension};base64, ${item.operator__logo.base64}`}
											alt={item.operator__name}
										/>
										<span className="name-operator">{item.operator__name}</span>
									</div>

									<span className="sales-operator">
										{item.operator__country__name}
									</span>

									<span className="sales-operator">{item.ventas} Ventas</span>

									<div className="show-status">
										<i
											className={
												item.operator__is_active === true
													? "circle"
													: "circle-red"
											}
										/>
										<span className="status">
											{item.operator__is_active === true
												? "Activo"
												: "Inactivo"}
										</span>
									</div>

									<div className="send-email">
										<Button
											type="link"
											className="email-btn"
											onClick={() => sendEmailHandler(item.operator__email)}
										>
											<i className="fa-regular fa-envelope email-icon"></i>
										</Button>
									</div>
								</div>
								<Divider />
							</>
						))}
					</div>
					<Link to="/operadoresList" className="show_more">
						Ir a listado de operadores
					</Link>
				</div>
			</div>
		</Container>
	);
}

export default BottomCards;

const Container = styled.div`
	.row {
		display: flex;
		margin: 0 !important;
		padding: 0 !important;
	}

	.flex {
		flex: 1;
	}

	.space-between {
		justify-content: space-between;
	}

	.bottom-section {
		width: 100%;
		min-height: 400px;
		display: flex;
		justify-content: space-between;
		margin: 1.3em 0;
	}

	.scroll-operator {
		padding: 20px 0 0;
		max-height: 250px;
		overflow-y: auto;
		margin: 15px 0;
	}

	.operator {
		width: 100%;
		min-height: 400px;
		background: white;
		border-radius: 3px;
		border: 1px solid #dde4eb;
		padding: 34px 60px 34px 30px;
	}

	.operator .graph-operator {
		width: 100%;
		height: 24%;
		display: flex;
		flex-direction: column;
	}

	.operator .graph-operator .title-graph {
		width: 35%;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.title-cards {
		color: #5a607f;
		font-size: ${smallSizes.dashboardBottom.titleCards};
		font-weight: 700;
	}

	.text-operator {
		font-size: ${smallSizes.dashboardBottom.textOperator};
		color: #131523;
		font-weight: 700;
		margin-right: 2.5%;
	}

	.title-graph .percent_up {
		font-size: ${smallSizes.dashboardBottom.percent};
		color: #3dd598;
		margin-right: 1%;
		font-weight: 700;
	}

	.title-graph .percent_equal {
		font-size: ${smallSizes.dashboardBottom.percent};
		color: #2b80ff;
		margin-right: 1%;
		font-weight: 700;
	}

	.title-graph .percent_down {
		font-size: ${smallSizes.dashboardBottom.percent};
		color: #f0142f;
		margin-right: 1%;
		font-weight: 700;
	}

	// -----------------------------------

	.text-card-sales {
		font-size: ${smallSizes.dashboardBottom.textCardSales};
		color: #7f7f7f;
	}

	// -----------------------------------

	.operator .operadores {
		display: flex;
		align-items: center;
		margin: 30px 0 12px 30px;
		justify-content: space-between;
	}

	.operadores .icon-size {
		width: ${smallSizes.dashboardBottom.iconSizeWidth};
		height: ${smallSizes.dashboardBottom.iconSizeHeight};
		z-index: 1000;
	}

	.operadores .name-operator {
		font-size: ${smallSizes.dashboardBottom.nameOperator};
		padding-left: 20px;
	}

	.operadores .sales-operator {
		font-size: ${smallSizes.dashboardBottom.salesOperator};
		color: #7f7f7f;
		flex: 1;
	}

	// ---------------------------

	.show-status {
		display: flex;
		align-items: center;
		flex: 1;
	}

	.show-status .circle {
		background: #26a805;
		width: 10px;
		height: 10px;
		border-radius: 100%;
		margin-right: 8px;
	}

	.show-status .circle-red {
		background: #ff0707;
		width: 10px;
		height: 10px;
		border-radius: 100%;
		margin-right: 8px;
	}

	.show-status .status {
		font-size: ${smallSizes.dashboardBottom.status};
		margin-left: 2%;
	}

	.show_more {
		color: #1458e8;
		font-size: ${smallSizes.dashboardBottom.showMore};
		width: 100%;
		cursor: pointer;
		margin: 0 0 0 30px;
	}

	.send-email {
		display: flex;
		width: 7%;
	}

	.email-btn {
		border: none;
		color: grey;

		:hover {
			color: #2b80ff;
		}
	}

	.email-icon {
		font-size: 24px;
	}

	@media screen and (min-width: ${devices.smallDesk}) {
		.title-cards {
			font-size: ${smallSizes.dashboardBottom.titleCards};
		}

		.text-operator {
			font-size: ${smallSizes.dashboardBottom.textOperator};
		}

		.title-graph .percent {
			font-size: ${smallSizes.dashboardBottom.percent};
		}

		.title-graph .percent_up {
			font-size: ${smallSizes.dashboardBottom.percent};
		}

		.title-graph .percent_equal {
			font-size: ${smallSizes.dashboardBottom.percent};
		}

		.title-graph .percent_down {
			font-size: ${smallSizes.dashboardBottom.percent};
		}

		// -----------------------------------

		.text-card-sales {
			font-size: ${smallSizes.dashboardBottom.textCardSales};
		}

		// --------------------------------------

		.operadores .icon-size {
			width: ${smallSizes.dashboardBottom.iconSizeWidth};
			height: ${smallSizes.dashboardBottom.iconSizeHeight};
		}

		.operadores .name-operator {
			font-size: ${smallSizes.dashboardBottom.nameOperator};
		}

		.operadores .sales-operator {
			font-size: ${smallSizes.dashboardBottom.salesOperator};
		}

		// ------------------------------------------

		.show-status .status {
			font-size: ${smallSizes.dashboardBottom.status};
		}

		.show_more {
			font-size: ${smallSizes.dashboardBottom.showMore};
		}

		.email-icon {
			font-size: 24px;
		}
	}

	// ----------------------------------------

	@media screen and (min-width: ${devices.mediumDesk}) {
		.title-cards {
			font-size: ${mediumSizes.dashboardBottom.titleCards};
		}

		.text-operator {
			font-size: ${mediumSizes.dashboardBottom.textOperator};
		}

		.title-graph .percent {
			font-size: ${mediumSizes.dashboardBottom.percent};
		}

		.title-graph .percent_up {
			font-size: ${mediumSizes.dashboardBottom.percent};
		}

		.title-graph .percent_equal {
			font-size: ${mediumSizes.dashboardBottom.percent};
		}

		.title-graph .percent_down {
			font-size: ${mediumSizes.dashboardBottom.percent};
		}

		// -----------------------------------

		.text-card-sales {
			font-size: ${mediumSizes.dashboardBottom.textCardSales};
		}

		// --------------------------------------

		.operadores .icon-size {
			width: ${mediumSizes.dashboardBottom.iconSizeWidth};
			height: ${mediumSizes.dashboardBottom.iconSizeHeight};
		}

		.operadores .name-operator {
			font-size: ${mediumSizes.dashboardBottom.nameOperator};
		}

		.operadores .sales-operator {
			font-size: ${mediumSizes.dashboardBottom.salesOperator};
		}

		// ------------------------------------------

		.show-status .status {
			font-size: ${mediumSizes.dashboardBottom.status};
		}

		.show_more {
			font-size: ${mediumSizes.dashboardBottom.showMore};
		}

		.email-icon {
			font-size: 27px;
		}
	}

	@media screen and (min-width: ${devices.largeDesk}) {
		.title-cards {
			font-size: ${largeSizes.dashboardBottom.titleCards};
		}

		.text-operator {
			font-size: ${largeSizes.dashboardBottom.textOperator};
		}

		.title-graph .percent {
			font-size: ${largeSizes.dashboardBottom.percent};
		}

		.title-graph .percent_up {
			font-size: ${largeSizes.dashboardBottom.percent};
		}

		.title-graph .percent_equal {
			font-size: ${largeSizes.dashboardBottom.percent};
		}

		.title-graph .percent_down {
			font-size: ${largeSizes.dashboardBottom.percent};
		}

		// -----------------------------------

		.text-card-sales {
			font-size: ${largeSizes.dashboardBottom.textCardSales};
		}

		// --------------------------------------

		.operadores .icon-size {
			width: ${largeSizes.dashboardBottom.iconSizeWidth};
			height: ${largeSizes.dashboardBottom.iconSizeHeight};
		}

		.operadores .name-operator {
			font-size: ${largeSizes.dashboardBottom.nameOperator};
		}

		.operadores .sales-operator {
			font-size: ${largeSizes.dashboardBottom.salesOperator};
		}

		// ------------------------------------------

		.show-status .status {
			font-size: ${largeSizes.dashboardBottom.status};
		}

		.show_more {
			font-size: ${largeSizes.dashboardBottom.showMore};
		}

		.email-icon {
			font-size: 30.37px;
		}
	}
`;
