import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const ProtectedRoute = ({ component: Component, auth, ...rest }) => {
	const { token } = useSelector((state) => state.authState);

	return (
		<Route
			{...rest}
			render={(props) =>
				token ? <Component {...props} /> : <Redirect to="/" />
			}
		/>
	);
};

export default ProtectedRoute;
