import React, { useState, useEffect } from "react";
import {
	Input,
	Space,
	Button,
	Divider,
	Select,
	notification,
	Checkbox,
	Collapse,
	DatePicker,
	Switch,
} from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { myFetch, myFetchGet } from "../../../services/services";
import { FormTabsPanel } from "../../../components/tabs/FormTabs";
import { Tab, TabList, TabPanel } from "react-tabs";
import { urlBase } from "../../../services/services";
import { useSelector } from "react-redux";
import { useReduxToken } from "../../../hooks/useReduxToken";
import { useHistory } from "react-router-dom";
import { regex } from "../../../helpers/regex";
import styles from "./FormProspectToSale.module.css";
import CustomTooltip from "../../../components/customTooltip/CustomTooltip";
import dayjs from "dayjs";
import Br from "../../../components/Br/Br";

const imageBaseURL = urlBase.slice(0, -1);

const FormProspectToSale = ({ data, cancel, visible, setVisible }) => {
	const token = useReduxToken();
	const dataState = useSelector((state) => state.authState);
	const history = useHistory();

	const { id_unique, operator_id } = data;

	const splitName = data.full_name.full_name.split(" ");
	const splitPhone = data.phone.split(" ");

	/*------------------PASO 1------------------*/
	const feasibilityCoordinates = [data.point.longitude, data.point.latitude];
	const [addressName, setAddressName] = useState(data.address);
	const [operatorHasCompass, setOperatorHasCompass] = useState();
	const [selectPlan, setSelectPlan] = useState(true);
	const [plansList, setPlansList] = useState([]);
	const [sortedPlansList, setSortedPlansList] = useState([]);
	const [checkedPlanValue, setCheckedPlanValue] = useState([]);
	const [checkedPromotionValue, setCheckedPromotionValue] = useState([]);

	/*------------------PASO 2------------------*/
	const [name, setName] = useState(splitName[0]);
	const [lastName, setLastName] = useState(
		splitName[1] !== "" ? splitName[1] : splitName[2]
	);
	const [email, setEmail] = useState(data.email);
	const [countryPhoneNumber, setCountryPhoneNumber] = useState(splitPhone[0]);
	const [phoneNumber, setPhoneNumber] = useState(splitPhone[1]);
	const [addressID, setAddressID] = useState();
	const [confirmSale, setConfirmSale] = useState(false);
	const [availableDates, setAvailableDates] = useState();
	const [date, setDate] = useState();
	const [dateToShow, setDateToShow] = useState();
	const [scheduleList, setScheduleList] = useState([]);
	const [scheduleToShow, setScheduleToShow] = useState();
	const [documentList, setDocumentList] = useState([]);
	const [checkedPlanInfo, setCheckedPlanInfo] = useState([]);
	const [checkedPromotionInfo, setCheckedPromotionInfo] = useState([]);
	const [showClientInfo, setShowClientInfo] = useState(false);
	const [selectedDocumentTypeName, setSelectedDocumentTypeName] = useState();
	const [documentType, setDocumentType] = useState();
	const [documentNumber, setDocumentNumber] = useState();
	const [showExtraPhoneNumber, setShowExtraPhoneNumber] = useState(false);
	const [showExtraEmail, setShowExtraEmail] = useState(false);
	const [extraPhoneNumber, setExtraPhoneNumber] = useState();
	const [extraPhoneNumberCountry, setExtraPhoneNumberCountry] = useState();
	const [extraEmail, setExtraEmail] = useState();
	const [loadingOnSubmit, setLoadingOnSubmit] = useState(false);

	const fullPhone = countryPhoneNumber + " " + phoneNumber;

	/*------------------DATOS DE BRÚJULA------------------*/
	const [hourID, setHourID] = useState();
	const [techID, setTechID] = useState();
	const saleAddressCoordinates = data.point;

	const { Panel } = Collapse;

	/*------------------VALIDACIONES------------------*/
	const [errors, setErrors] = useState({
		name: "",
		lastName: "",
		email: "",
		phoneNumber: "",
		extraPhoneNumber: "",
		extraEmail: "",
		documentType: "",
		documentNumber: "",
	});
	const [errorStatus, setErrorStatus] = useState({
		name: false,
		lastName: false,
		email: false,
		phoneNumber: false,
		extraPhoneNumber: false,
		extraEmail: false,
		documentType: false,
		documentNumber: false,
	});

	useEffect(() => {
		let nameError = "";
		let lastNameError = "";
		let emailError = "";
		let phoneNumberError = "";
		let extraPhoneNumberError = "";
		let extraEmailError = "";
		let documentTypeError = "";
		let documentNumberError = "";

		if (confirmSale && !name) {
			nameError = "Ingrese un nombre";
		} else if (regex.regexNames.test(name) === false) {
			nameError = "Debe ingresar solo letras";
		}

		if (confirmSale && !lastName) {
			lastNameError = "Ingrese el apellido";
		} else if (regex.regexNames.test(lastName) === false) {
			lastNameError = "Debe ingresar solo letras";
		}

		if (confirmSale && !email) {
			emailError = "Ingrese una dirección de correo";
		} else if (regex.regexSimpleEmail.test(email) === false) {
			emailError = "Ingrese una dirección de correo válida";
		}

		if (confirmSale && !phoneNumber) {
			phoneNumberError = "Ingrese un número de teléfono";
		} else if (regex.regexNumbers.test(phoneNumber) === false) {
			phoneNumberError = "Ingrese solo números";
		} else if (
			(dataState.operatorCountryIso === "cl" ||
				dataState.operatorCountryIso === "pe") &&
			phoneNumber.length !== 9
		) {
			phoneNumberError = "Debe ingresar solo 9 números";
		} else if (
			dataState.operatorCountryIso === "co" &&
			phoneNumber.length !== 10
		) {
			phoneNumberError = "Debe ingresar solo 10 números";
		}

		if (confirmSale && !documentType) {
			documentTypeError = "Seleccione el tipo de documento";
		}

		if (confirmSale && !documentNumber) {
			documentNumberError = "Ingrese el número de documento";
		} else if (
			selectedDocumentTypeName === "RUT" &&
			documentNumber.length < 12
		) {
			documentNumberError = "Debe ingresar al menos 12 caracteres";
		} else if (
			selectedDocumentTypeName === "RUN" &&
			documentNumber.length < 12
		) {
			documentNumberError = "Debe ingresar al menos 12 caracteres";
		} else if (
			selectedDocumentTypeName === "DNI" &&
			documentNumber.length < 10
		) {
			documentNumberError = "Debe ingresar al menos 10 caracteres";
		} else if (
			selectedDocumentTypeName === "RUC" &&
			documentNumber.length !== 11
		) {
			documentNumberError = "Debe ingresar 11 caracteres";
		} else if (
			selectedDocumentTypeName === "CE" &&
			dataState.operatorCountryIso === "pe" &&
			documentNumber.length < 10
		) {
			documentNumberError = "Debe ingresar al menos 10 caracteres";
		} else if (
			selectedDocumentTypeName === "CDC" &&
			documentNumber.length !== 10
		) {
			documentNumberError = "Debe ingresar 10 caracteres";
		} else if (
			selectedDocumentTypeName === "NUIP" &&
			documentNumber.length !== 10
		) {
			documentNumberError = "Debe ingresar 10 caracteres";
		} else if (
			selectedDocumentTypeName === "NIT" &&
			documentNumber.length < 11
		) {
			documentNumberError = "Debe ingresar 11 caracteres";
		} else if (
			selectedDocumentTypeName === "CE" &&
			dataState.operatorCountryIso === "co" &&
			documentNumber.length !== 10
		) {
			documentNumberError = "Debe ingresar 10 caracteres";
		} else if (selectedDocumentTypeName === "Pasaporte" && !documentNumber) {
			documentNumberError = "Ingrese el número del pasaporte";
		}

		if (
			confirmSale &&
			extraPhoneNumber &&
			regex.regexNumbers.test(extraPhoneNumber) === false
		) {
			extraPhoneNumberError = "Ingrese solo números";
		} else if (
			(dataState.operatorCountryIso === "cl" ||
				dataState.operatorCountryIso === "pe") &&
			confirmSale &&
			extraPhoneNumber &&
			extraPhoneNumber.length !== 9
		) {
			extraPhoneNumberError = "Debe ingresar solo 9 números";
		} else if (
			dataState.operatorCountryIso === "co" &&
			extraPhoneNumber.length !== 10
		) {
			extraPhoneNumber = "Debe ingresar solo 10 números";
		}

		if (
			confirmSale &&
			extraEmail &&
			regex.regexSimpleEmail.test(extraEmail) === false
		) {
			extraEmailError = "Ingrese una dirección de correo válida";
		}

		setErrors({
			name: nameError,
			lastName: lastNameError,
			email: emailError,
			phoneNumber: phoneNumberError,
			extraPhoneNumber: extraPhoneNumberError,
			extraEmail: extraEmailError,
			documentType: documentTypeError,
			documentNumber: documentNumberError,
		});
	}, [
		name,
		lastName,
		email,
		phoneNumber,
		addressID,
		confirmSale,
		documentType,
		documentNumber,
		extraPhoneNumber,
		extraEmail,
		errorStatus,
	]);

	const validateInput = () => {
		if (errors.name) {
			setErrorStatus((prevState) => ({ ...prevState, name: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, name: false }));
		}

		if (errors.lastName) {
			setErrorStatus((prevState) => ({ ...prevState, lastName: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, lastName: false }));
		}

		if (errors.email) {
			setErrorStatus((prevState) => ({ ...prevState, email: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, email: false }));
		}

		if (errors.phoneNumber) {
			setErrorStatus((prevState) => ({ ...prevState, phoneNumber: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, phoneNumber: false }));
		}

		if (errors.extraPhoneNumber) {
			setErrorStatus((prevState) => ({ ...prevState, extraPhoneNumber: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({
				...prevState,
				extraPhoneNumber: false,
			}));
		}

		if (errors.extraEmail) {
			setErrorStatus((prevState) => ({ ...prevState, extraEmail: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, extraEmail: false }));
		}

		if (errors.documentType) {
			setErrorStatus((prevState) => ({ ...prevState, documentType: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, documentType: false }));
		}

		if (errors.documentNumber) {
			setErrorStatus((prevState) => ({ ...prevState, documentNumber: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, documentNumber: false }));
		}

		setErrorStatus({
			name: false,
			lastName: false,
			email: false,
			phoneNumber: false,
			extraPhoneNumber: false,
			extraEmail: false,
			documentType: false,
			documentNumber: false,
		});
	};

	const getAddressInfo = async (address_coordinates) => {
		try {
			const response = await myFetch(
				"api/v1/coordinates_to_address/",
				token,
				address_coordinates
			);
			setAddressName(response.address[0].full_direction);
			setAddressID(response.address[0].id);
		} catch (e) {
			console.log("error", e);
		}
	};

	const getConfig = async () => {
		try {
			await myFetchGet(
				`api/v2/operator-setting/${operator_id}/brujula_is_available`,
				token
			);
			setOperatorHasCompass(true);
		} catch (e) {
			console.log("error", e);
			setOperatorHasCompass(false);
		}
	};

	const getPlans = async (coordinates) => {
		try {
			const response = await myFetch("api/v1/feasibility", token, coordinates);
			if (response.length > 0) {
				setPlansList(response);
				setSortedPlansList(response);
			} else {
				notification.error({
					className: "notification",
					message:
						"La dirección seleccionada no tiene factibilidad en este momento",
					placement: "bottomLeft",
					duration: 2.5,
				});
				onCloseModal();
			}
		} catch (e) {
			console.log("error", e);
		}
	};

	const getAvailability = async (availability) => {
		try {
			const response = await myFetchGet(
				`api/v1/get-availability/?day=${availability}`,
				token
			);
			if (response.list_of_hour_range) {
				setScheduleList(response.list_of_hour_range);
			} else {
				setScheduleList([]);
				setScheduleToShow(null);
			}
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message: "Se ha producido un error inesperado",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const getAvailabilityID = async (data) => {
		const newFormatDate = date.replace(/\//g, "-");
		const payload = {
			start_time: data.start_time,
			end_time: data.end_time,
			date: newFormatDate,
			id_disponibility: data.disponibilidad,
		};
		try {
			const response = await myFetch(
				"api/v1/schedule-availability/",
				token,
				payload
			);
			setHourID(response.id_disponibility_created);
		} catch (e) {
			console.log("error", e);
		}
	};

	const getDocumentTypes = async () => {
		try {
			const response = await myFetchGet(
				`api/v1/documents/?country=${dataState.operatorCountryID}`,
				token
			);
			setDocumentList(response);
		} catch (e) {
			console.log("error", e);
		}
	};

	const getAvailableDays = async () => {
		try {
			const response = await myFetchGet(
				"api/v1/get-dates-availability/",
				token
			);
			setAvailableDates(response.data.map((obj) => obj.dates).flat());
		} catch (e) {
			console.log("error", e);
		}
	};

	const onSubmit = async (data) => {
		setLoadingOnSubmit(true);

		if (!extraPhoneNumber) {
			data.phone2 = undefined;
		} else {
			data.phone2 = extraPhoneNumberCountry + " " + extraPhoneNumber;
		}
		if (checkedPromotionValue.length === 0) {
			data.promotion = undefined;
		} else {
			data.promotion = checkedPromotionValue[0];
		}
		try {
			await myFetch("api/v1/sales/", token, data);
			notification.success({
				className: "notification",
				message: "Venta creada satisfactoriamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
			onCloseModal();
			history.push("/listadoDeVentas", { replace: true });
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message: "Se ha producido un error inesperado",
				placement: "bottomLeft",
				duration: 2.5,
			});
		} finally {
			setLoadingOnSubmit(false);
		}
	};

	useEffect(() => {
		getAddressInfo(data.point);
		getPlans({
			point: feasibilityCoordinates,
			operator: operator_id,
		});
		getConfig();
	}, []);

	const onCloseModal = () => {
		setVisible(false);
		cancel();
	};

	const phoneCountrySelectHandler = (value) => {
		setCountryPhoneNumber(value);
	};

	const planTypeHandler = (list) => {
		if (list.length === 0) {
			setSortedPlansList(plansList);
		} else {
			let sortedList = list.sort(function (a, b) {
				return a - b;
			});
			setSortedPlansList(
				plansList.filter(
					(obj) => obj.category.toString() === sortedList.toString()
				)
			);
		}
	};

	const colors = ["none", "#F7FAFC"];

	const selectPlanHandler = (value) => {
		if (checkedPlanValue !== value) {
			setCheckedPlanValue(value);
			setCheckedPromotionValue([]);
			setCheckedPlanInfo(
				plansList.filter((obj) => obj.ID.toString() === value.toString())
			);
		}
	};

	const selectPromotionHandler = (value, planObject) => {
		if (checkedPlanValue !== planObject.ID) {
			setCheckedPlanValue([planObject.ID]);
			setCheckedPlanInfo(
				plansList.filter(
					(obj) => obj.ID.toString() === planObject.ID.toString()
				)
			);
		}
		setCheckedPromotionValue(value);
		setCheckedPromotionInfo(
			planObject.promotions.filter(
				(obj) => obj.ID.toString() === value.toString()
			)
		);
	};

	const dateFormat = "DD/MM/YYYY";

	const disabledDate = (current) => {
		const currentDate = dayjs().format("YYYY-MM-DD");
		return (
			!availableDates.includes(current.format("YYYY-MM-DD")) ||
			current.format("YYYY-MM-DD") < currentDate
		);
	};

	const dateSelectHandler = (date, dateString) => {
		const newDateString = dateString.replace(/\//g, "-");
		getAvailability(newDateString);
		setDate(dateString);
		setDateToShow(date);
	};

	const schedullingHoursHandler = (value) => {
		setScheduleToShow(JSON.stringify(value));
		getAvailabilityID(value);
		setTechID(value.technician_id);
	};

	const documentTypeHandler = (value) => {
		let tempDocumentType = [];
		setDocumentType(value);
		tempDocumentType = documentList.filter((obj) => obj.ID === value);
		setSelectedDocumentTypeName(tempDocumentType[0].name);
	};

	const formatRUT = (value) => {
		let cleaned = value.replace(/[^0-9kK]/g, "");

		if (cleaned.length > 1) {
			cleaned =
				cleaned.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
				"-" +
				cleaned.slice(-1);
		}

		return cleaned;
	};

	const handleDocumentNumberChange = (e) => {
		let value = e.target.value;

		if (selectedDocumentTypeName === "RUT") {
			value = formatRUT(value);
		}

		setDocumentNumber(value);
		validateInput();
	};

	const extraPhoneHandler = (checked) => {
		setShowExtraPhoneNumber(checked);
		if (checked === false) {
			setExtraPhoneNumberCountry(undefined);
			setExtraPhoneNumber(undefined);
		} else {
			if (dataState.operatorCountryIso === "cl") {
				setExtraPhoneNumberCountry("+56");
			} else if (dataState.operatorCountryIso === "pe") {
				setExtraPhoneNumberCountry("+51");
			} else if (dataState.operatorCountryIso === "co") {
				setExtraPhoneNumberCountry("+57");
			}
		}
	};

	const extraEmailHandler = (checked) => {
		setShowExtraEmail(checked);
		if (checked === false) {
			setExtraEmail(undefined);
		}
	};

	const extraPhoneCountryHandler = (value) => {
		setExtraPhoneNumberCountry(value);
	};

	if (visible) {
		return (
			<Container>
				{/*------------------PASO 1------------------*/}
				{selectPlan && (
					<div
						className={`${styles["form-container"]} animate__animated animate__fadeIn animate__delay-0.5s ${styles["custom-scroll"]}`}
					>
						<div
							className={`${styles["row"]} ${styles["space-between"]} ${styles["align-center"]}`}
						>
							<span className={styles["form-title"]}>
								Selección de servicio a contratar
							</span>
							<CloseOutlined
								className={styles["close-form-icon"]}
								onClick={onCloseModal}
							/>
						</div>

						<Divider />

						<div className={`${styles["row"]} ${styles["justify-end"]}`}>
							<div className={`${styles["row"]} ${styles["align-center"]}`}>
								<span
									className={styles["form-pagination"]}
									style={{ opacity: "23%" }}
								>
									Contratación
								</span>
								<i
									className={`fa-solid fa-angle-right ${styles["form-pagination-icon"]}`}
									style={{ opacity: "23%" }}
								></i>
							</div>
						</div>

						<Br />

						<span className={styles["form-section-title"]}>
							Validación de información de servicio
						</span>
						<div
							className={`${styles["row"]} ${styles["space-between"]}`}
							style={{ paddingBottom: "40px" }}
						>
							<div className={`${styles["row"]} ${styles["align-center"]}`}>
								<span
									className={styles["form-input-name"]}
									style={{ fontWeight: "bold" }}
								>
									Planes disponibles
								</span>
							</div>
							<div className={`${styles["row"]} ${styles["align-center"]}`}>
								<Checkbox.Group onChange={planTypeHandler}>
									<Checkbox value={1}>
										<span className={styles["form-input-name"]}>Internet</span>
									</Checkbox>
									<Checkbox value={2}>
										<span className={styles["form-input-name"]}>
											Televisión
										</span>
									</Checkbox>
									<Checkbox value={3}>
										<span className={styles["form-input-name"]}>Telefonía</span>
									</Checkbox>
								</Checkbox.Group>
							</div>
						</div>

						{sortedPlansList.map((plan, index) => (
							<Checkbox.Group
								onChange={selectPlanHandler}
								value={checkedPlanValue}
								key={plan.ID}
							>
								<div className={styles["row"]} style={{ width: "100%" }}>
									<Collapse
										ghost={true}
										expandIconPosition="end"
										style={{
											width: "100%",
											background: index % 2 === 0 ? colors[1] : colors[0],
											borderRadius: "5px",
											paddingBottom: "20px",
										}}
										collapsible="icon"
										expandIcon={({ isActive }) => (
											<PlusOutlined rotate={isActive ? 90 : 0} />
										)}
									>
										<Panel
											header={
												<div className={styles["row"]}>
													<div className={styles["column"]}>
														<Checkbox value={plan.ID}>
															<img
																src={`${imageBaseURL + plan.photo}`}
																width="116px"
																height="76px"
																className={styles["image-container"]}
															/>
														</Checkbox>
													</div>
													<div
														className={`${styles["column"]} ${styles["flex"]}`}
													>
														{plan.tradename.length > 14 ? (
															<CustomTooltip
																type="classic"
																title={
																	<span className={styles["plan-name"]}>
																		{plan.tradename}
																	</span>
																}
															>
																<span
																	className={`${styles["plan-name"]} ${styles["plan-name-ellipsis"]}`}
																>
																	{plan.tradename}
																</span>
															</CustomTooltip>
														) : (
															<span className={styles["plan-name"]}>
																{plan.tradename}
															</span>
														)}
														<span className={styles["plan-tech"]}>
															Tecnología {plan.technology_name}
														</span>
														{plan.type_plan === 1 ? (
															<span className={styles["plan-type"]}>
																Plan Simple
															</span>
														) : plan.type_plan === 2 ? (
															<span className={styles["plan-type"]}>
																Plan Duo
															</span>
														) : plan.type_plan === 3 ? (
															<span className={styles["plan-type"]}>
																Plan Trio
															</span>
														) : null}
													</div>
													<div
														className={`${styles["column"]} ${styles["align-center"]} ${styles["plan-price-container"]}`}
													>
														<div
															className={`${styles["column"]} ${styles["align-center"]} ${styles["justify-center"]} ${styles["plan-price"]}`}
															style={{
																backgroundColor:
																	plan.promotions.length > 0
																		? "#2B80FF"
																		: "none",
															}}
														>
															<span
																className={styles["price"]}
																style={{
																	color:
																		plan.promotions.length > 0
																			? "#FFFFFF"
																			: "none",
																}}
															>
																${plan.price} / mes
															</span>
															<span
																className={styles["installation-price"]}
																style={{
																	color:
																		plan.promotions.length > 0
																			? "#FFFFFF"
																			: "none",
																}}
															>
																Instalación ${plan.installation_price}
															</span>
														</div>
													</div>
												</div>
											}
										>
											<div className={styles["row"]}>
												<div
													className={`${styles["column"]} ${styles["flex"]}`}
												></div>
												<div
													className={`${styles["column"]} ${styles["plan-description-container"]}`}
													style={{ paddingRight: "6%" }}
												>
													<span className={styles["plan-description"]}>
														{plan.description}
													</span>
													<div className={styles["row"]}>
														{plan.category.toString() === "1" ? (
															<FormTabsPanel selectedTabClassName="is-selected">
																<TabList
																	className="TabList"
																	style={{
																		padding: "0",
																		marginBottom: "0.5em",
																	}}
																>
																	<Tab className="FormTab">Internet</Tab>
																</TabList>

																<TabPanel>
																	<i
																		className={`fa-solid fa-arrow-up ${styles["characteristics-icon"]}`}
																	></i>
																	<span
																		className={
																			styles["internet-characteristics"]
																		}
																	>
																		{plan.megas_upload} mb
																	</span>
																	<i
																		className={`fa-solid fa-arrow-down ${styles["characteristics-icon"]}`}
																	></i>
																	<span
																		className={
																			styles["internet-characteristics"]
																		}
																	>
																		{plan.megas_download} mb
																	</span>
																</TabPanel>
															</FormTabsPanel>
														) : plan.category.toString() === "2" ? (
															<FormTabsPanel selectedTabClassName="is-selected">
																<TabList className="FormTabsList">
																	<Tab className="FormTab">Televisión</Tab>
																</TabList>

																<TabPanel>
																	{plan.options_channels === 2 ? (
																		<span className={styles["sd-channels"]}>
																			Canales {plan.number_channels} <b>SD</b>
																		</span>
																	) : plan.options_channels === 1 ? (
																		<div>
																			<span className={styles["sd-channels"]}>
																				Canales {plan.number_channels} <b>SD</b>
																			</span>
																			<span className={styles["hd-channels"]}>
																				{plan.extra_channels_hd} <b>HD</b>
																			</span>
																		</div>
																	) : null}
																</TabPanel>
															</FormTabsPanel>
														) : plan.category.toString() === "3" ? (
															<FormTabsPanel selectedTabClassName="is-selected">
																<TabList className="FormTabsList">
																	<Tab className="FormTab">Telefonía</Tab>
																</TabList>

																<TabPanel>
																	{plan.unlimited_minutes === true ? (
																		<span className={styles["telephone"]}>
																			Minutos ilimitados
																		</span>
																	) : (
																		<span className={styles["telephone"]}>
																			{plan.phone_minutes} min llamadas locales
																		</span>
																	)}
																</TabPanel>
															</FormTabsPanel>
														) : plan.category.toString() === "1,2" ? (
															<FormTabsPanel selectedTabClassName="is-selected">
																<TabList className="FormTabsList">
																	<Tab className="FormTab">Internet</Tab>
																	<Tab className="FormTab">Televisión</Tab>
																</TabList>

																<TabPanel>
																	<i
																		className={`fa-solid fa-arrow-up ${styles["characteristics-icon"]}`}
																	></i>
																	<span
																		className={
																			styles["internet-characteristics"]
																		}
																	>
																		{plan.megas_upload} mb
																	</span>
																	<i
																		className={`fa-solid fa-arrow-down ${styles["characteristics-icon"]}`}
																	></i>
																	<span
																		className={
																			styles["internet-characteristics"]
																		}
																	>
																		{plan.megas_download} mb
																	</span>
																</TabPanel>
																<TabPanel>
																	{plan.options_channels === 2 ? (
																		<span className={styles["sd-channels"]}>
																			Canales {plan.number_channels} <b>SD</b>
																		</span>
																	) : plan.options_channels === 1 ? (
																		<div>
																			<span className={styles["sd-channels"]}>
																				Canales {plan.number_channels} <b>SD</b>
																			</span>
																			<span className={styles["hd-channels"]}>
																				{plan.extra_channels_hd} <b>HD</b>
																			</span>
																		</div>
																	) : null}
																</TabPanel>
															</FormTabsPanel>
														) : plan.category.toString() === "1,3" ? (
															<FormTabsPanel selectedTabClassName="is-selected">
																<TabList className="FormTabsList">
																	<Tab className="FormTab">Internet</Tab>
																	<Tab className="FormTab">Telefonía</Tab>
																</TabList>

																<TabPanel>
																	<i
																		className={`fa-solid fa-arrow-up ${styles["characteristics-icon"]}`}
																	></i>
																	<span
																		className={
																			styles["internet-characteristics"]
																		}
																	>
																		{plan.megas_upload} mb
																	</span>
																	<i
																		className={`fa-solid fa-arrow-down ${styles["characteristics-icon"]}`}
																	></i>
																	<span
																		className={
																			styles["internet-characteristics"]
																		}
																	>
																		{plan.megas_download} mb
																	</span>
																</TabPanel>
																<TabPanel>
																	{plan.unlimited_minutes === true ? (
																		<span className={styles["telephone"]}>
																			Minutos ilimitados
																		</span>
																	) : (
																		<span className={styles["telephone"]}>
																			{plan.phone_minutes} min llamadas locales
																		</span>
																	)}
																</TabPanel>
															</FormTabsPanel>
														) : plan.category.toString() === "2,3" ? (
															<FormTabsPanel selectedTabClassName="is-selected">
																<TabList className="FormTabsList">
																	<Tab className="FormTab">Televisión</Tab>
																	<Tab className="FormTab">Telefonía</Tab>
																</TabList>

																<TabPanel>
																	{plan.options_channels === 2 ? (
																		<span className={styles["sd-channels"]}>
																			Canales {plan.number_channels} <b>SD</b>
																		</span>
																	) : plan.options_channels === 1 ? (
																		<div>
																			<span className={styles["sd-channels"]}>
																				Canales {plan.number_channels} <b>SD</b>
																			</span>
																			<span className={styles["hd-channels"]}>
																				{plan.extra_channels_hd} <b>HD</b>
																			</span>
																		</div>
																	) : null}
																</TabPanel>
																<TabPanel>
																	{plan.unlimited_minutes === true ? (
																		<span className={styles["telephone"]}>
																			Minutos ilimitados
																		</span>
																	) : (
																		<span className={styles["telephone"]}>
																			{plan.phone_minutes} min llamadas locales
																		</span>
																	)}
																</TabPanel>
															</FormTabsPanel>
														) : plan.category.toString() === "1,2,3" ? (
															<FormTabsPanel selectedTabClassName="is-selected">
																<TabList className="FormTabsList">
																	<Tab className="FormTab">Internet</Tab>
																	<Tab className="FormTab">Televisión</Tab>
																	<Tab className="FormTab">Telefonía</Tab>
																</TabList>

																<TabPanel>
																	<i
																		className={`fa-solid fa-arrow-up ${styles["characteristics-icon"]}`}
																	></i>
																	<span
																		className={
																			styles["internet-characteristics"]
																		}
																	>
																		{plan.megas_upload} mb
																	</span>
																	<i
																		className={`fa-solid fa-arrow-down ${styles["characteristics-icon"]}`}
																	></i>
																	<span
																		className={
																			styles["internet-characteristics"]
																		}
																	>
																		{plan.megas_download} mb
																	</span>
																</TabPanel>
																<TabPanel>
																	{plan.options_channels === 2 ? (
																		<span className={styles["sd-channels"]}>
																			Canales {plan.number_channels} <b>SD</b>
																		</span>
																	) : plan.options_channels === 1 ? (
																		<div>
																			<span className={styles["sd-channels"]}>
																				Canales {plan.number_channels} <b>SD</b>
																			</span>
																			<span className={styles["hd-channels"]}>
																				{plan.extra_channels_hd} <b>HD</b>
																			</span>
																		</div>
																	) : null}
																</TabPanel>
																<TabPanel>
																	{plan.unlimited_minutes === true ? (
																		<span className={styles["telephone"]}>
																			Minutos ilimitados
																		</span>
																	) : (
																		<span className={styles["telephone"]}>
																			{plan.phone_minutes} min llamadas locales
																		</span>
																	)}
																</TabPanel>
															</FormTabsPanel>
														) : null}
													</div>
												</div>
											</div>

											{plan.promotions.length > 0 && (
												<div
													style={{
														background: "#F7FAFC",
														paddingBottom: "3px",
													}}
												>
													<div className={styles["row"]}>
														<span className={styles["promotion-tag"]}>
															Promociones
														</span>
													</div>
													{plan.promotions
														.filter((obj) => obj.deleted === false)
														.map((promotion) => (
															<div
																className={styles["promotion-container"]}
																key={promotion.ID}
															>
																<Checkbox.Group
																	value={checkedPromotionValue}
																	onChange={(value) =>
																		selectPromotionHandler(value, plan)
																	}
																	key={promotion.ID}
																	style={{
																		width: "100%",
																		height: "140px",
																		display: "flex",
																		flexDirection: "column",
																	}}
																>
																	<Checkbox value={promotion.ID}>
																		<div className={styles["row"]}>
																			<div
																				className={styles["column"]}
																				style={{
																					width: "50%",
																				}}
																			>
																				<span
																					className={styles["promotion-name"]}
																				>
																					{promotion.name}
																				</span>

																				<div className={styles["row"]}>
																					{promotion.discount_plan_type &&
																						+promotion.discount_plan_cost >
																							0 && (
																							<div
																								className={`${styles["column"]} ${styles["align-center"]} ${styles["justify-center"]} ${styles["promotion-price"]}`}
																							>
																								<span
																									className={
																										styles["promotion-discount"]
																									}
																								>
																									{promotion.discount_plan_type ===
																										1 &&
																										`$${
																											+plan.price -
																											+promotion.discount_plan_cost
																										} / mes`}
																									{promotion.discount_plan_type ===
																										2 &&
																										`$${
																											((100 -
																												+promotion.discount_plan_cost) *
																												+plan.price) /
																											100
																										} / mes`}
																								</span>
																								<span
																									className={
																										styles[
																											"promotion-type-text"
																										]
																									}
																								>
																									Costo base del plan
																								</span>
																							</div>
																						)}
																					{promotion.discount_installation_type &&
																						+promotion.discount_installation_cost >
																							0 && (
																							<div
																								className={`${styles["column"]} ${styles["align-center"]} ${styles["justify-center"]} ${styles["promotion-price"]}`}
																							>
																								<span
																									className={
																										styles["promotion-discount"]
																									}
																								>
																									{promotion.discount_installation_type ===
																										1 &&
																										`$${
																											+plan.installation_price -
																											+promotion.discount_installation_cost
																										} / PU`}
																									{promotion.discount_installation_type ===
																										2 &&
																										`$${
																											((100 -
																												+promotion.discount_installation_cost) *
																												+plan.installation_price) /
																											100
																										} / PU`}
																								</span>
																								<span
																									className={
																										styles[
																											"promotion-type-text"
																										]
																									}
																								>
																									Costo de instalación
																								</span>
																							</div>
																						)}
																				</div>
																			</div>
																			<div
																				className={`${styles["column"]} ${styles["flex"]}`}
																			>
																				<span
																					className={styles["promotion-period"]}
																				>
																					{promotion.indefinite_promotion
																						? "Periodo de promoción indefinido"
																						: `Desde ${promotion.start_date} hasta ${promotion.end_date}`}
																				</span>

																				<span
																					className={
																						styles["promotion-description"]
																					}
																				>
																					{promotion.description}
																				</span>
																			</div>
																		</div>
																	</Checkbox>
																</Checkbox.Group>
															</div>
														))}
												</div>
											)}
										</Panel>
									</Collapse>
								</div>
							</Checkbox.Group>
						))}

						<Br />

						<div
							className={`${styles["row"]} ${styles["justify-end"]} ${styles["fixed"]}`}
						>
							<Space>
								<Button
									className={`${styles["form-action-button"]} ${styles["form-action-button-cancel"]}`}
									style={{ width: "auto" }}
									type="delete"
									onClick={() => {
										onCloseModal();
									}}
								>
									No deseo contratar
								</Button>
								<Button
									className={`${styles["form-action-button"]} ${styles["form-action-button-save"]}`}
									onClick={() => {
										if (checkedPlanValue.length === 0) {
											notification.warning({
												className: "notification",
												message: "Por favor, selecciona un plan",
												placement: "bottomLeft",
												duration: 2.5,
											});
										} else {
											setSelectPlan(false);
											setConfirmSale(true);
											getDocumentTypes();
											getAvailableDays();
										}
									}}
								>
									Continuar
								</Button>
							</Space>
						</div>
					</div>
				)}

				{/*------------------PASO 2------------------*/}
				{confirmSale && (
					<div
						className={`${styles["form-container"]} animate__animated animate__fadeIn animate__delay-0.5s ${styles["custom-scroll"]}`}
					>
						<div
							className={`${styles["row"]} ${styles["space-between"]} ${styles["align-center"]}`}
						>
							<span className={styles["form-title"]}>
								Contratación de servicio
							</span>
							<CloseOutlined
								className={styles["close-form-icon"]}
								onClick={onCloseModal}
							/>
						</div>
						<div className={styles["row"]}>
							<div className={`${styles["column"]} ${styles["flex"]}`}>
								<span className={styles["address-name"]}>
									Dirección: {addressName}
								</span>
								{data.direction_complementary && (
									<span className={styles["address-name"]}>
										Complemento de dirección: {data.direction_complementary}
									</span>
								)}
							</div>
						</div>

						<Divider />

						<div className={`${styles["row"]} ${styles["align-center"]}`}>
							<Button
								type="link"
								onClick={() => {
									setSelectPlan(true);
									setConfirmSale(false);
								}}
								style={{ padding: 0 }}
							>
								<i
									className={`fa-solid fa-angle-left ${styles["form-pagination-icon"]}`}
								></i>
								<span className={styles["form-pagination"]}>Contratación</span>
							</Button>
						</div>

						{operatorHasCompass && (
							<>
								<Br />
								<span className={styles["form-section-title"]}>
									Agendamiento
								</span>
								<div className={styles["row"]}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Fecha de instalación
											<span className={styles["blue"]}>*</span>
										</span>
										<DatePicker
											className={styles["form-input"]}
											style={{ width: "95%" }}
											placeholder="Seleccione"
											onChange={dateSelectHandler}
											format={dateFormat}
											value={dateToShow}
											disabledDate={disabledDate}
										/>
									</div>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Horario<span className={styles["blue"]}>*</span>
										</span>
										<Select
											className={styles["form-input"]}
											bordered={false}
											placeholder="Seleccione"
											onChange={(value) =>
												schedullingHoursHandler(JSON.parse(value))
											}
											value={scheduleToShow}
										>
											{scheduleList.map((schedule) => (
												<Select.Option
													key={schedule.hours}
													value={JSON.stringify(schedule)}
												>
													{schedule.hours}
												</Select.Option>
											))}
										</Select>
									</div>
								</div>
							</>
						)}

						<Br />

						<span className={styles["form-section-title"]}>
							Detalle de plan seleccionado
						</span>
						{checkedPlanInfo.length > 0 && (
							<div className={styles["row"]}>
								<span className={styles["plan-detail"]}>
									Plan <b>{checkedPlanInfo[0].tradename}</b>
								</span>
								{checkedPromotionInfo.length > 0 &&
								checkedPromotionInfo[0].assignment === 1 ? (
									<span
										className={styles["plan-detail"]}
										style={{ marginLeft: "25px" }}
									>
										${checkedPromotionInfo[0].price_with_discount} / mes
									</span>
								) : (
									<span
										className={styles["plan-detail"]}
										style={{ marginLeft: "25px" }}
									>
										${checkedPlanInfo[0].price} / mes
									</span>
								)}
							</div>
						)}

						<Br />

						<div
							className={`${styles["row"]} ${styles["space-between"]} ${styles["align-baseline"]}`}
						>
							<span className={styles["form-section-title"]}>
								Información titular del servicio
							</span>
							<Button
								type="link"
								className={styles["view-more-button"]}
								onClick={() => {
									setShowClientInfo(!showClientInfo);
								}}
							>
								{!showClientInfo ? (
									<span className={styles["view-more-text"]}>Ver más</span>
								) : (
									<span className={styles["view-more-text"]}>Ocultar</span>
								)}
							</Button>
						</div>

						{showClientInfo && (
							<>
								<div className={styles["row"]}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>Nombre</span>
										<Input
											className={styles["form-input"]}
											style={{
												width: "95%",
											}}
											placeholder="Juan"
											value={name}
											onChange={(e) => setName(e?.target?.value)}
											onBlur={validateInput}
											status={errorStatus.name ? "error" : ""}
											maxLength={25}
										/>
										{errorStatus.name && (
											<div className={styles["position-relative"]}>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.name}
												</span>
											</div>
										)}
									</div>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>Apellido</span>
										<Input
											className={styles["form-input"]}
											placeholder="Gomez"
											value={lastName}
											onChange={(e) => setLastName(e?.target?.value)}
											onBlur={validateInput}
											status={errorStatus.lastName ? "error" : ""}
											maxLength={25}
										/>
										{errorStatus.lastName && (
											<div className={styles["position-relative"]}>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.lastName}
												</span>
											</div>
										)}
									</div>
								</div>

								<Br />
								<Br />

								<div className={styles["row"]}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Correo electrónico
										</span>
										<Input
											className={styles["form-input"]}
											style={{
												width: "95%",
											}}
											placeholder="Example@gmail.com"
											value={email}
											onChange={(e) => setEmail(e?.target?.value)}
											onBlur={validateInput}
											status={errorStatus.email ? "error" : ""}
											maxLength={25}
										/>
										{errorStatus.email && (
											<div className={styles["position-relative"]}>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.email}
												</span>
											</div>
										)}
									</div>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Número de teléfono
										</span>
										<div
											className={`${styles["row"]} ${styles["space-between"]}`}
										>
											<Select
												className={styles["form-input"]}
												style={{ width: "40%" }}
												value={countryPhoneNumber}
												onChange={phoneCountrySelectHandler}
												bordered={false}
												disabled={true}
											>
												<Select.Option value={"+56"}>
													<img
														src={`https://flagcdn.com/28x21/cl.png`}
														width="20"
														height="15"
														alt="Chile"
														style={{ margin: "0 5px 5px 0" }}
													/>
													+56
												</Select.Option>
												<Select.Option value={"+58"}>
													<img
														src={`https://flagcdn.com/28x21/ve.png`}
														width="20"
														height="15"
														alt="Venezuela"
														style={{ margin: "0 5px 5px 0" }}
													/>
													+58
												</Select.Option>
												<Select.Option value={"+55"}>
													<img
														src={`https://flagcdn.com/28x21/br.png`}
														width="20"
														height="15"
														alt="Brasil"
														style={{ margin: "0 5px 5px 0" }}
													/>
													+55
												</Select.Option>
												<Select.Option value={"+51"}>
													<img
														src={`https://flagcdn.com/28x21/pe.png`}
														width="20"
														height="15"
														alt="Perú"
														style={{ margin: "0 5px 5px 0" }}
													/>
													+51
												</Select.Option>
												<Select.Option value={"+57"}>
													<img
														src={`https://flagcdn.com/28x21/co.png`}
														width="20"
														height="15"
														alt="Colombia"
														style={{ margin: "0 5px 5px 0" }}
													/>
													+57
												</Select.Option>
											</Select>
											<Input
												className={styles["form-input"]}
												style={{
													width: "55%",
												}}
												placeholder={
													dataState.operatorCountryIso === "cl" ||
													dataState.operatorCountryIso === "pe"
														? "XXXXXXXXX"
														: dataState.operatorCountryIso === "co" &&
														  "XXXXXXXXXX"
												}
												value={phoneNumber}
												onChange={(e) => setPhoneNumber(e.target.value)}
												onBlur={validateInput}
												status={errorStatus.phoneNumber ? "error" : ""}
											/>
										</div>
										{errorStatus.phoneNumber && (
											<div className={styles["position-relative"]}>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.phoneNumber}
												</span>
											</div>
										)}
									</div>
								</div>

								<Br />
								<Br />
							</>
						)}

						<div className={styles["row"]}>
							<div className={`${styles["column"]} ${styles["flex"]}`}>
								<span className={styles["form-input-name"]}>
									Documento de identidad
								</span>
								<Select
									className={styles["form-input"]}
									style={{
										width: "95%",
									}}
									value={documentType}
									onChange={documentTypeHandler}
									onBlur={validateInput}
									status={errorStatus.documentType ? "error" : ""}
									bordered={false}
									placeholder="Seleccione"
								>
									{documentList.map((document) => (
										<Select.Option value={document.ID}>
											{document.name}
										</Select.Option>
									))}
								</Select>
								{errorStatus.documentType && (
									<div className={styles["position-relative"]}>
										<span
											className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
										>
											{errors.documentType}
										</span>
									</div>
								)}
							</div>
							<div className={`${styles["column"]} ${styles["flex"]}`}>
								<Input
									className={styles["form-input"]}
									style={{
										marginTop: "auto",
									}}
									placeholder={
										selectedDocumentTypeName === "RUT"
											? "XXX.XXX.XXX-X"
											: selectedDocumentTypeName === "RUN"
											? "XXX.XXX.XXX-X"
											: selectedDocumentTypeName === "DNI"
											? "XXXXXXXX-X"
											: selectedDocumentTypeName === "RUC"
											? "XXXXXXXXXXX"
											: selectedDocumentTypeName === "CE" &&
											  dataState.operatorCountryIso === "pe"
											? "XXXXXXXXXX"
											: selectedDocumentTypeName === "CDC"
											? "XXXXXXXXXX"
											: selectedDocumentTypeName === "NUIP"
											? "XXXXXXXXXX"
											: selectedDocumentTypeName === "NIT"
											? "XXXXXXXXX-X"
											: selectedDocumentTypeName === "CE" &&
											  dataState.operatorCountryIso === "co" &&
											  "XXXXXXXXXX"
									}
									onChange={handleDocumentNumberChange}
									onBlur={validateInput}
									status={errorStatus.documentNumber ? "error" : ""}
									value={documentNumber}
								/>
								{errorStatus.documentNumber && (
									<div className={styles["position-relative"]}>
										<span
											className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
										>
											{errors.documentNumber}
										</span>
									</div>
								)}
							</div>
						</div>

						<Br />
						<Br />

						<div className={styles["row"]}>
							<div className={`${styles["column"]} ${styles["flex"]}`}>
								<span className={styles["switch-text"]}>
									<b>Número de teléfono secundario</b>
								</span>
							</div>
							<div className={`${styles["column"]} ${styles["flex"]}`}>
								<div
									className={`${styles["row"]} ${styles["align-center"]} ${styles["justify-center"]}`}
								>
									<span className={styles["switch-text"]}>No</span>
									<Switch
										className={styles["switch-margin"]}
										onChange={extraPhoneHandler}
									/>
									<span className={styles["switch-text"]}>Sí</span>
								</div>
							</div>
							<div className={`${styles["column"]} ${styles["flex"]}`}>
								<span className={styles["switch-text"]}>
									<b>Correo electrónico secundario</b>
								</span>
							</div>
							<div className={`${styles["column"]} ${styles["flex"]}`}>
								<div
									className={`${styles["row"]} ${styles["align-center"]} ${styles["justify-center"]}`}
								>
									<span className={styles["switch-text"]}>No</span>
									<Switch
										className={styles["switch-margin"]}
										onChange={extraEmailHandler}
									/>
									<span className={styles["switch-text"]}>Sí</span>
								</div>
							</div>
						</div>

						{showExtraPhoneNumber === true || showExtraEmail === true ? (
							<>
								<Br />
								<div className={styles["row"]}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										{showExtraPhoneNumber === true ? (
											<div className={styles["row"]}>
												<Select
													className={styles["form-input"]}
													style={{ width: "35%", marginRight: "5%" }}
													value={extraPhoneNumberCountry}
													onChange={extraPhoneCountryHandler}
													bordered={false}
													placeholder="Seleccione"
													disabled={true}
												>
													<Select.Option value={"+56"}>
														<img
															src={`https://flagcdn.com/28x21/cl.png`}
															width="20"
															height="15"
															alt="Chile"
															style={{ margin: "0 5px 5px 0" }}
														/>
														+56
													</Select.Option>
													<Select.Option value={"+58"}>
														<img
															src={`https://flagcdn.com/28x21/ve.png`}
															width="20"
															height="15"
															alt="Venezuela"
															style={{ margin: "0 5px 5px 0" }}
														/>
														+58
													</Select.Option>
													<Select.Option value={"+55"}>
														<img
															src={`https://flagcdn.com/28x21/br.png`}
															width="20"
															height="15"
															alt="Brasil"
															style={{ margin: "0 5px 5px 0" }}
														/>
														+55
													</Select.Option>
													<Select.Option value={"+51"}>
														<img
															src={`https://flagcdn.com/28x21/pe.png`}
															width="20"
															height="15"
															alt="Perú"
															style={{ margin: "0 5px 5px 0" }}
														/>
														+51
													</Select.Option>
													<Select.Option value={"+57"}>
														<img
															src={`https://flagcdn.com/28x21/co.png`}
															width="20"
															height="15"
															alt="Colombia"
															style={{ margin: "0 5px 5px 0" }}
														/>
														+57
													</Select.Option>
												</Select>
												<Input
													className={styles["form-input"]}
													style={{
														width: "55%",
													}}
													placeholder={
														dataState.operatorCountryIso === "cl" ||
														dataState.operatorCountryIso === "pe"
															? "XXXXXXXXX"
															: dataState.operatorCountryIso === "co" &&
															  "XXXXXXXXXX"
													}
													value={extraPhoneNumber}
													onChange={(e) => setExtraPhoneNumber(e.target.value)}
													onBlur={validateInput}
													status={errorStatus.extraPhoneNumber ? "error" : ""}
												/>
											</div>
										) : null}
										{errorStatus.extraPhoneNumber && (
											<div className={styles["position-relative"]}>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.extraPhoneNumber}
												</span>
											</div>
										)}
									</div>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										{showExtraEmail === true ? (
											<Input
												className={styles["form-input"]}
												placeholder="Exaample@gmail.com"
												onChange={(e) => setExtraEmail(e.target.value)}
												value={extraEmail}
												onBlur={validateInput}
												status={errorStatus.extraEmail ? "error" : ""}
												maxLength={25}
											/>
										) : null}
										{errorStatus.extraEmail && (
											<div className={styles["position-relative"]}>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.extraEmail}
												</span>
											</div>
										)}
									</div>
								</div>
							</>
						) : null}

						<Br />
						<Br />
						<Br />

						<div
							className={`${styles["row"]} ${styles["justify-end"]} ${styles["fixed"]}`}
						>
							<Space>
								<Button
									className={`${styles["form-action-button"]} ${styles["form-action-button-cancel"]}`}
									style={{ width: "auto" }}
									type="delete"
									onClick={() => {
										onCloseModal();
									}}
								>
									No deseo contratar
								</Button>
								<Button
									className={`${styles["form-action-button"]} ${styles["form-action-button-save"]}`}
									loading={loadingOnSubmit}
									onClick={() => {
										if (operatorHasCompass) {
											if (Object.values(errors).some((value) => value !== "")) {
												validateInput();
												notification.warning({
													className: "notification",
													message:
														"Por favor, completa todos los campos obligatorios",
													placement: "bottomLeft",
													duration: 2.5,
												});
											} else {
												onSubmit({
													files: [],
													first_name: name,
													last_name: lastName,
													document: documentType,
													document_number: documentNumber,
													email: email,
													email2: extraEmail,
													phone: fullPhone,
													plan: checkedPlanValue[0],
													operator: operator_id,
													user: dataState.userID,
													outside_sales: true,
													technician_id: techID,
													hour_id: hourID,
													coordinates: saleAddressCoordinates,
													address: addressName,
													address_brujula_id: addressID,
													prospect_id: id_unique,
													country: dataState.operatorCountryID,
												});
											}
										} else {
											if (Object.values(errors).some((value) => value !== "")) {
												validateInput();
												notification.warning({
													className: "notification",
													message:
														"Por favor, completa todos los campos obligatorios",
													placement: "bottomLeft",
													duration: 2.5,
												});
											} else {
												onSubmit({
													files: [],
													first_name: name,
													last_name: lastName,
													document: documentType,
													document_number: documentNumber,
													email: email,
													email2: extraEmail,
													phone: fullPhone,
													plan: checkedPlanValue[0],
													operator: operator_id,
													user: dataState.userID,
													outside_sales: false,
													coordinates: saleAddressCoordinates,
													address: addressName,
													address_brujula_id: addressID,
													prospect_id: id_unique,
													country: dataState.operatorCountryID,
												});
											}
										}
									}}
								>
									Guardar
								</Button>
							</Space>
						</div>
					</div>
				)}
			</Container>
		);
	} else {
		return null;
	}
};

export default FormProspectToSale;

const Container = styled.div`
	background: hsla(0, 0%, 0%, 0.5);
	width: 42%;

	.ant-collapse-header {
		padding: 0 !important;
		height: 76px;
		margin: 20px 15px 0;
	}

	.ant-collapse-content-box {
		padding: 0 !important;
	}

	.ant-checkbox + span {
		width: 100%;
	}

	.ant-input-clear-icon {
		display: flex;
	}

	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		padding: 0;
	}

	.ant-select-selector {
		font-size: 12px;
	}

	.ant-picker .ant-picker-input > input {
		font-size: 12px;
	}

	@media screen and (min-width: 1280px) {
		.ant-select-selector,
		.ant-picker .ant-picker-input > input {
			font-size: 12px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-select-selector,
		.ant-picker .ant-picker-input > input {
			font-size: 13.5px;
		}
	}

	@media screen and (min-width: 1920px) {
		.ant-select-selector,
		.ant-picker .ant-picker-input > input {
			font-size: 15.18px;
		}
	}
`;
