import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
	name: "auth",
	initialState: {
		userID: null,
		userName: null,
		userLastName: null,
		operatorID: null,
		operatorCreated: null,
		operatorName: null,
		operatorLogo: null,
		operatorCountryID: null,
		operatorCountryCoordinates: null,
		operatorMapZoom: null,
		operatorCountryIso: null,
		userOperators: null,
		token: null,
		status: "not-authenticated",
		email: null,
		rol: null,
		permissions: null,
		authentication: null,
	},
	reducers: {
		onLogin: (state, action) => {
			state.status = "authenticated";
			state.token = action.payload.token;
			state.email = action.payload.email;
			state.rol = action.payload.rol;
			state.permissions = action.payload.permissions;
			state.userID = action.payload.userID;
			state.userName = action.payload.userName;
			state.userLastName = action.payload.userLastName;
			state.operatorID = action.payload.operatorID;
			state.operatorCreated = action.payload.operatorCreated;
			state.operatorName = action.payload.operatorName;
			state.operatorLogo = action.payload.operatorLogo;
			state.operatorCountryID = action.payload.operatorCountryID;
			state.operatorCountryCoordinates =
				action.payload.operatorCountryCoordinates;
			state.operatorMapZoom = action.payload.operatorMapZoom;
			state.operatorCountryIso =
				action.payload.operatorCountryIso.toLowerCase();
			state.userOperators = action.payload.userOperators;
			state.authentication = action.payload.authentication;
		},
		onLogout: (state) => {
			state.token = null;
			state.status = "not-authenticated";
			state.email = null;
			state.rol = null;
			state.permissions = null;
			state.userID = null;
			state.userName = null;
			state.userLastName = null;
			state.operatorID = null;
			state.operatorCreated = null;
			state.operatorName = null;
			state.operatorLogo = null;
			state.operatorCountryID = null;
			state.operatorCountryCoordinates = null;
			state.operatorMapZoom = null;
			state.operatorCountryIso = null;
			state.userOperators = null;
			state.authentication = null;
		},
		authenticationGoggle: (state, action) => {
			state.authentication = action.payload.authentication;
		},
		onSelectOperator: (state, action) => {
			state.operatorID = action.payload.operatorID;
			state.operatorCountryIso =
				action.payload.operatorCountryIso.toLowerCase();
			state.operatorCountryID = action.payload.operatorCountryID;
			state.operatorCreated = action.payload.operatorCreated;
			state.operatorLogo = action.payload.operatorLogo;
			state.operatorName = action.payload.operatorName;
		},
	},
});

// Action creators are generated for each case reducer function
export const { onLogin, onLogout, authenticationGoggle, onSelectOperator } =
	authSlice.actions;
