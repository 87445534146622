import React from "react";
import { TabsPanel } from "../../components/tabs/Tabs";
import { Panel, PanelHeader } from "../../components/panel/panel";
import { Tab, TabList, TabPanel } from "react-tabs";
import GeneralOperatorPanel from "./components/GeneralOperatorPanel";
import OperatorPlans from "./components/OperatorPlans";
import OperatorCoverage from "./components/OperatorCoverage";
import OperatorPromotions from "./components/OperatorPromotions";
import Sellers from "./components/Sellers";
import HistoryPanel from "./components/HistoryPanel";
import { useLocation, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import CustomTooltip from "../../components/customTooltip/CustomTooltip";
import styles from "./DetailsOperators.module.css";

const DetailsOperators = () => {
	const history = useHistory();
	const { state = {} } = useLocation();

	const dataState = useSelector((state) => state.authState);

	return (
		<Panel>
			<PanelHeader>
				<div className={`${styles["row"]} ${styles["space-between"]}`}>
					<div className={styles["column"]}>
						<div className={`${styles["row"]} ${styles["align-center"]}`}>
							{dataState.rol === "administrador_isp" ||
							dataState.rol === "supervisor_comercial" ? (
								<span className={styles["header-title"]}>
									{dataState.operatorName}
								</span>
							) : (
								<span className={styles["header-title"]}>
									{state.params.name}
								</span>
							)}
							<CustomTooltip
								subtitle={
									"Información detallada del operador según corresponda"
								}
							/>
							<div className={styles["header-breadcrumb"]}>
								Operadores |
								{dataState.rol === "administrador_isp" ||
								dataState.rol === "supervisor_comercial" ? null : (
									<>
										<span
											className={styles["header-breadcrumb-text"]}
											onClick={() => history.push("/operadoresList")}
										>
											Listado de operadores
										</span>
										|
									</>
								)}
								&nbsp;Detalle de operador
							</div>
						</div>
					</div>
					<div className={`${styles["column"]} ${styles["justify-center"]}`}>
						{dataState.rol === "administrador_isp" ||
						dataState.rol === "supervisor_comercial" ? (
							<span className={styles["created-date"]}>
								<b>Fecha de creación:</b>{" "}
								{dayjs(dataState.operatorCreated, "DD-MM-YYYY").format(
									"DD MMMM YYYY"
								)}
							</span>
						) : (
							<span className={styles["created-date"]}>
								<b>Fecha de creación:</b>{" "}
								{dayjs(state.params.created, "DD/MM/YYYY").format(
									"DD MMMM YYYY"
								)}
							</span>
						)}
					</div>
				</div>
			</PanelHeader>

			<TabsPanel selectedTabClassName={`is-selected ${styles["selected-tab"]}`}>
				<div className={styles["details-container"]}>
					<TabList className="TabsList">
						<Tab className="Tab">General</Tab>
						<Tab className="Tab">Planes</Tab>
						<Tab className="Tab">Cobertura</Tab>
						<Tab className="Tab">Promoción</Tab>
						<Tab className="Tab">Vendedores</Tab>
						<Tab className="Tab">Histórico</Tab>
					</TabList>

					<TabPanel>
						<GeneralOperatorPanel
							data={
								Object.keys(state).length
									? state?.params
									: {
											ID: dataState.operatorID,
									  }
							}
						/>
					</TabPanel>
					<TabPanel>
						<OperatorPlans
							data={
								Object.keys(state).length
									? state?.params
									: {
											ID: dataState.operatorID,
									  }
							}
						/>
					</TabPanel>
					<TabPanel>
						<OperatorCoverage
							data={
								Object.keys(state).length
									? state?.params
									: {
											ID: dataState.operatorID,
									  }
							}
						/>
					</TabPanel>
					<TabPanel>
						<OperatorPromotions
							data={
								Object.keys(state).length
									? state?.params
									: {
											ID: dataState.operatorID,
									  }
							}
						/>
					</TabPanel>
					<TabPanel>
						<Sellers
							data={
								Object.keys(state).length
									? state?.params
									: {
											ID: dataState.operatorID,
									  }
							}
						/>
					</TabPanel>
					<TabPanel>
						<HistoryPanel
							data={
								Object.keys(state).length
									? state?.params
									: {
											ID: dataState.operatorID,
									  }
							}
						/>
					</TabPanel>
				</div>
			</TabsPanel>
		</Panel>
	);
};

export default DetailsOperators;
