import { Tabs } from "react-tabs";
import styled from "styled-components";
import { largeSizes, mediumSizes, smallSizes } from "../../helpers/sizes";
import devices from "../../helpers/sizes";

export const FormTabsPanel = styled(Tabs)`
	.FormTabsList {
		width: 100%;
		display: flex;
		flex: 1;
		padding: 0 !important;

		@media (max-width: 800px) {
			width: 100%;
		}
	}

	.FormTab {
		list-style: none;
		padding: 0 15px 0 0;
		font-size: ${smallSizes.tabsPanel.tab};
		font-weight: 400;
		color: #2b80ff;
		cursor: pointer;

		&.is-selected {
			color: #2b80ff;
			font-weight: bold;
			text-decoration-line: underline;
			text-decoration-thickness: 3px;
			background: none;
			text-decoration-color: #2b80ff;
		}
	}

	@media screen and (min-width: ${devices.smallDesk}) {
		.FormTab {
			font-size: ${smallSizes.tabsPanel.tab};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}) {
		.FormTab {
			font-size: ${mediumSizes.tabsPanel.tab};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}) {
		.FormTab {
			font-size: ${largeSizes.tabsPanel.tab};
		}
	}
`;
