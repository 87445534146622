const Menu = [
	{
		path: "/inicio",
		title: "Tablero",
	},
	{
		path: "/listadoDeUsuarios",
		title: "Listado de Usuarios",
	},
	{
		path: "/listadoDeVentas",
		title: "Listado de Ventas",
	},
	{
		path: "#",
		title: "Otras opciones",
	},
	{
		path: "/Operadores",
		title: "Operadores",
		icon: "fa-solid fa-podcast",
		children: [
			{
				path: "/operadoresList",
				title: "Listado de Operadores",
			},
			{
				path: "/coberturaConsolidada",
				title: "Mapa de Cobertura",
			},
		],
	},
	{
		path: "/Ventas",
		title: "Ventas",
		icon: "fa-solid fa-circle-dollar-to-slot",
		children: [
			{
				path: "/listadoDeProspectos",
				title: "Prospectos",
			},
			{
				path: "/listadoDeLeads",
				title: "Leads",
			},
		],
	},
	{
		path: "/Operadores",
		title: "Operadores",
		icon: "fa-solid fa-podcast",
		children: [
			{
				path: "/coberturaConsolidada",
				title: "Mapa de Cobertura",
			},
		],
	},
	{
		path: "/Operadores",
		title: "Operadores",
		icon: "fa-solid fa-podcast",
		children: [
			{
				path: "/detalleOperador",
				title: "Detalle de operador",
			},
			{
				path: "/coberturaConsolidada",
				title: "Mapa de Cobertura",
			},
		],
	},
];

export default Menu;
