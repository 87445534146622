import React, { useState } from "react";
import { Route, Link } from "react-router-dom";
import { PageSettings } from "./../../config/page-settings.js";

export default function SidebarNavList(props) {
	const [state, setState] = useState({
		active: -1,
		clicked: -1,
	});

	const handleExpand = (e, i) => {
		e.preventDefault();

		setState((prevState) => ({
			...prevState,
			active: state.active === i ? -1 : i,
			clicked: 1,
		}));
	};

	const icon = props.data.icon && <i className={props.data.icon}></i>;
	const label = props.data.label && <span>{props.data.label}</span>;
	const title = props.data.title && (
		<span>
			{props.data.title} {label}
		</span>
	);

	return (
		<PageSettings.Consumer>
			{() => (
				<Route
					path={props.data.path}
					exact={props.data.exact}
					children={({ match }) => (
						<li
							className={
								(match ? "active__btn " : "") +
								(props.active || (props.clicked === -1 && match)
									? "expand "
									: "closed ") +
								(props.data.children ? "has-submenu" : "")
							}
						>
							{props.data.children ? (
								<span className="no__hover">
									<span className="ul__row--center">
										<span className="icon">{icon}</span>
										<span className="text">{title}</span>
										<span className="dots" onClick={props.expand}>
											<i className="fa-solid fa-ellipsis"></i>
										</span>
									</span>
								</span>
							) : props.data.title === "Otras opciones" ? (
								<span className="no__link__text">{title}</span>
							) : (
								<Link to={props?.data?.path}>
									<span>{title}</span>
								</Link>
							)}

							{props.data.children && (
								<ul
									className={
										"" +
										(props.active || (props.clicked === -1 && match)
											? "d-block  animate__animated animate__fadeIn"
											: "d-none")
									}
								>
									{props.data.children &&
										props.data.children.map((submenu, i) => {
											return (
												<SidebarNavList
													data={submenu}
													key={i}
													expand={(e) => handleExpand(e, i, match)}
													active={i === state.active}
													clicked={state.clicked}
												/>
											);
										})}
								</ul>
							)}
						</li>
					)}
				/>
			)}
		</PageSettings.Consumer>
	);
}
