import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Input, Space, Divider, Button, notification } from "antd";
import protection_icon from "../../assets/images/protection_icon.png";
import { myFetchGet } from "../../services/services";
import { useReduxToken } from "../../hooks/useReduxToken";
import styles from "./AuthenticationGoogle.module.css";

const AuthenticationGoggle = ({
	visible,
	setVisible,
	confirmResponse,
	cancel = () => {},
	animation,
}) => {
	const token = useReduxToken();

	const [inputToken, setInputToken] = useState();

	const onCloseModal = () => {
		setVisible(false);
		cancel();
	};

	const verifyCode = async () => {
		try {
			const response = await myFetchGet(
				`api/v1/verify-authenticator?code=${inputToken}`,
				token
			);
			if (response?.success === true) {
				confirmResponse();
			} else {
				notification.warning({
					className: "notification",
					message: "No se ha podido realizar la verificacion.",
					placement: "bottomLeft",
					duration: 2.5,
				});
			}
		} catch (error) {
			notification.error({
				className: "notification",
				message: "Se ha producido un error inesperado",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	let animationClass = "animate__animated animate__fadeIn animate__delay-0.5s";

	if (animation === "general") {
		animationClass = "animate__animated animate__fadeInRight";
	}

	if (visible) {
		return (
			<div className={`${styles["form-container"]} ${animationClass}`}>
				<div
					className={`${styles["row"]} ${styles["space-between"]} ${styles["align-center"]}`}
				>
					<span className={styles["form-title"]}>
						Controla y protege tus cambios
					</span>
					<CloseOutlined
						className={styles["close-form-icon"]}
						onClick={onCloseModal}
					/>
				</div>

				<Divider />

				<span className={styles["form-section-title"]}>
					Verificación en dos pasos
				</span>

				<span className={styles["description-verify"]}>
					Este método proporciona un segundo filtro de autenticación de usuario
					a través de un código de 6 dígitos generado por la aplicación móvil
					Google Authenticator.
				</span>
				<div
					className={`${styles["row"]} ${styles["justify-center"]} ${styles["align-center"]}`}
				>
					<img className={styles["image-verify"]} src={protection_icon} />
				</div>
				<span className={styles["form-section-title"]}>
					Código de verificación mediante Google Authenticator
				</span>
				<div className={`${styles["column"]} ${styles["flex"]}`}>
					<span className={styles["form-input-name"]}>
						Ingrese el código <span className={styles["blue"]}>*</span>
					</span>
					<Input
						className={styles["form-input"]}
						onChange={(e) => setInputToken(e?.target?.value)}
					/>
				</div>

				<div className={`${styles["row"]} ${styles["justify-end"]}`}>
					<Space>
						<Button
							className={`${styles["form-action-button"]} ${styles["form-action-button-cancel"]}`}
							type="delete"
							onClick={() => {
								// setVisible(false);
								onCloseModal();
							}}
						>
							Cancelar
						</Button>
						<Button
							className={`${styles["form-action-button"]} ${styles["form-action-button-save"]}`}
							onClick={() => {
								verifyCode();
							}}
						>
							Guardar
						</Button>
					</Space>
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default AuthenticationGoggle;
