import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Select, Space, notification } from "antd";
import { myFetch } from "../../../services/services";
import { useReduxToken } from "../../../hooks/useReduxToken";
import styles from "./ImageForm.module.css";
import Br from "../../../components/Br/Br";

const ImageForm = ({
	modalHeader,
	customerUserID,
	setIsVisible,
	initial,
	data,
}) => {
	const token = useReduxToken();

	const [description, setDescription] = useState(
		data?.description?.description || undefined
	);
	const [type, setType] = useState(data?.description?.type || undefined);
	const [photo, setTempPhoto] = useState(null);
	const [base64Image, setBase64Image] = useState(null);

	/*Validaciones */
	const [errors, setErrors] = useState({
		description: "",
		type: "",
	});
	const [errorStatus, setErrorStatus] = useState({
		description: false,
		type: false,
	});

	const onCloseModal = () => {
		setIsVisible(false);
		initial();
	};

	const clearTempImage = () => {
		setTempPhoto(null);
		setBase64Image(null);
	};

	const inputFile = useRef();

	useEffect(() => {
		let descriptionError = "";
		let typeError = "";

		if (!description) {
			descriptionError = "Ingrese una descripción";
		}

		if (!type) {
			typeError = "Seleccione el tipo de documento";
		}

		setErrors({
			description: descriptionError,
			type: typeError,
		});
	}, [description, type, errorStatus]);

	const validateInput = () => {
		if (errors.description) {
			setErrorStatus((prevState) => ({ ...prevState, description: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, description: false }));
		}

		if (errors.type) {
			setErrorStatus((prevState) => ({ ...prevState, type: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, type: false }));
		}

		setErrorStatus({
			description: false,
			type: false,
		});
	};

	const onSubmit = async (data, ID) => {
		if (Object.values(errors).some((value) => value !== "")) {
			validateInput();
			notification.warning({
				className: "notification",
				message: "Por favor, completa todos los campos obligatorios",
				placement: "bottomLeft",
				duration: 2.5,
			});
			return;
		}

		if (!base64Image) {
			notification.warning({
				className: "notification",
				message: "Por favor, seleccione una imagen",
				placement: "bottomLeft",
				duration: 2.5,
			});
			return;
		}

		setErrorStatus({
			description: false,
			type: false,
		});

		if (ID) {
			try {
				await myFetch(
					`api/v1/customer_user_files/${ID}/`,
					token,
					data,
					"PATCH"
				);
				notification.success({
					className: "notification",
					message: "Documento modificado exitosamente",
					placement: "bottomLeft",
					duration: 2.5,
				});
				onCloseModal();
			} catch (e) {
				console.log("error", e);
				notification.error({
					className: "notification",
					message: "Se ha producido un error inesperado",
					placement: "bottomLeft",
					duration: 2.5,
				});
			}
		} else {
			try {
				await myFetch("api/v1/customer_user_files/", token, data);
				notification.success({
					className: "notification",
					message: "Documento guardado exitosamente",
					placement: "bottomLeft",
					duration: 2.5,
				});
				onCloseModal();
			} catch (e) {
				console.log("error", e);
				notification.error({
					className: "notification",
					message: "Se ha producido un error inesperado",
					placement: "bottomLeft",
					duration: 2.5,
				});
			}
		}
	};

	const transformToBase64 = (archivos) => {
		setTempPhoto(archivos[0]);

		Array.from(archivos).forEach((archivo) => {
			const reader = new FileReader();
			reader.readAsDataURL(archivo);

			reader.onload = function () {
				let arraySplitted = [];
				const base64 = reader.result;

				arraySplitted = base64.split(",");
				setBase64Image(arraySplitted[1]);
			};
		});
	};

	const documentTypeHandler = (value) => {
		setType(value);
	};

	return (
		<Container>
			<div
				className={`${styles["form-container"]} animate__animated animate__fadeInRight ${styles["custom-scroll"]}`}
			>
				<div
					className={`${styles["row"]} ${styles["space-between"]} ${styles["align-center"]}`}
				>
					<span className={styles["form-title"]}>{modalHeader} archivo</span>
					<CloseOutlined
						className={styles["close-form-icon"]}
						onClick={onCloseModal}
					/>
				</div>

				<Divider />

				{!base64Image ? (
					<button
						className={styles["upload-image-box"]}
						onClick={() => inputFile.current.click()}
					>
						<i
							className={`fa-sharp fa-solid fa-upload ${styles["upload-image-icon"]}`}
						/>
						<span className={styles["upload-image-box-title"]}>
							Haga click o arrastre el archivo a esta área para cargarlo
						</span>
						<span className={styles["upload-image-box-text"]}>
							Soporte para una carga única o masiva. Prohibir estrictamente
						</span>
						<span className={styles["upload-image-box-text"]}>
							cargar datos de la empresa u otros archivos de bandas.
						</span>
						<input
							type="file"
							ref={inputFile}
							onChange={(e) => transformToBase64(e.target.files)}
							style={{ display: "none" }}
							accept="image/png, image/jpeg"
						/>
					</button>
				) : (
					<div className={styles["temporal-image-box"]}>
						<img
							className={`${styles["temporal-image"]} animate__animated animate__fadeIn`}
							src={`data:image/png;base64,${base64Image}`}
							alt="Temporal selected base64"
						/>
						<span
							style={{ color: "grey", padding: "0 10px", flex: 1 }}
							className="animate__animated animate__fadeIn title"
						>
							{photo?.name}
						</span>
						<DeleteOutlined
							className={styles["clear-temporal-image-icon"]}
							onClick={clearTempImage}
						/>
					</div>
				)}

				<Br />
				<Br />

				<div className={styles["column"]}>
					<span className={styles["form-input-name"]}>
						Descripción <span className={styles["blue"]}>*</span>
					</span>
					<Input
						className={styles["form-input"]}
						onChange={(e) => setDescription(e.target.value)}
						onBlur={validateInput}
						status={errorStatus.description ? "error" : ""}
						value={description}
						placeholder="Ingresar descripción"
						maxLength={25}
					/>
					{errorStatus.description && (
						<div className={styles["position-relative"]}>
							<span
								className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
							>
								{errors.description}
							</span>
						</div>
					)}
				</div>

				<Br />
				<Br />

				<div className={styles["column"]}>
					<span className={styles["form-input-name"]}>
						Tipo de documento <b className={styles["blue"]}>*</b>
					</span>
					<Select
						className={styles["form-input"]}
						bordered={false}
						value={type}
						placeholder="Seleccione"
						onChange={documentTypeHandler}
						onBlur={validateInput}
						status={errorStatus.type ? "error" : ""}
					>
						<Select.Option key={1} value={1}>
							Documento de identidad
						</Select.Option>
						<Select.Option key={2} value={2}>
							Acuerdo de servicio
						</Select.Option>
					</Select>
					{errorStatus.type && (
						<div className={styles["position-relative"]}>
							<span
								className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
							>
								{errors.type}
							</span>
						</div>
					)}
				</div>

				<Br />
				<Br />

				<div
					className={`${styles["row"]} ${styles["justify-end"]} ${styles["action-buttons-margin"]}`}
				>
					<Space>
						<Button
							className={`${styles["form-action-button"]} ${styles["form-action-button-cancel"]}`}
							onClick={onCloseModal}
						>
							Cancelar
						</Button>
						<Button
							className={`${styles["form-action-button"]} ${styles["form-action-button-save"]}`}
							onClick={() => {
								if (modalHeader === "Subir") {
									onSubmit({
										description: description,
										img_base64: base64Image,
										customer_user: customerUserID,
										document_method: type,
									});
								} else {
									onSubmit(
										{
											description: description,
											img_base64: base64Image,
											document_method: type,
										},
										data?.ID
									);
								}
							}}
						>
							Guardar
						</Button>
					</Space>
				</div>
			</div>
		</Container>
	);
};

export default ImageForm;

const Container = styled.div`
	background: hsla(0, 0%, 0%, 0.5);
	width: 42%;

	.ant-select-selector {
		font-size: 12px;
	}

	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		padding: 0;
	}

	@media screen and (min-width: 1280px) {
		.ant-select-selector {
			font-size: 12px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-select-selector {
			font-size: 13.5px;
		}
	}

	@media screen and (min-width: 1920px) {
		.ant-select-selector {
			font-size: 15.18px;
		}
	}
`;
