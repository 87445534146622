import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Panel, PanelHeader } from "../../components/panel/panel";
import TableData from "../../components/table/TableData";
import { myFetchGet, myFetch, myExportData } from "../../services/services";
import dayjs from "dayjs";
import { DatePicker, notification } from "antd";
import { Loading } from "../../components/loading/Loading";
import { useReduxToken } from "../../hooks/useReduxToken";
import { useSelector } from "react-redux";
import TextColored from "../../components/textColored/TextColored";
import TableText from "../../components/tableText/TableText";
import CustomTooltip from "../../components/customTooltip/CustomTooltip";
import fileDownload from "js-file-download";
import TablePagination from "../../components/tablePagination/TablePagination";
import styles from "./Leads.module.css";

const Leads = () => {
	const token = useReduxToken();
	const dataState = useSelector((state) => state.authState);

	const [loadingTable, setLoadingTable] = useState(true);
	const [tableColumns, setTableColumns] = useState([]);
	const [totalTableSize, setTotalTableSize] = useState(0);
	const [tableRows, setTableRows] = useState([]);
	const [paginationSettings, setPaginationSettings] = useState([1, 10]);
	const [rowsParams, setRowsParams] = useState({ offset: 10, start: 0 });
	const operatorID =
		dataState.rol === "administrador_isp" ||
		dataState.rol === "supervisor_comercial" ||
		dataState.rol === "vendedor"
			? dataState.operatorID
			: undefined;
	const [sorterOrder, setSorterOrder] = useState("desc");
	const [exportFilters, setExportFilters] = useState({});

	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();

	const { RangePicker } = DatePicker;

	const initial = async () => {
		const operator = `["operator","equal","` + operatorID + `"]`;
		const period =
			`["created","gt","` +
			startDate +
			` 00:00"], ["created","lte","` +
			endDate +
			` 23:59"]`;
		const type = `["type", "equal", "1"]`;
		const head = `[`;
		const tail = `]`;
		let filters = {};

		if (
			startDate !== undefined &&
			endDate !== undefined &&
			operatorID === undefined
		) {
			filters = {
				filters: head + type + `, ` + period + tail,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			startDate !== undefined &&
			endDate !== undefined &&
			operatorID !== undefined
		) {
			filters = {
				filters: head + type + `, ` + period + `, ` + operator + tail,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else if (
			startDate === undefined &&
			endDate === undefined &&
			operatorID !== undefined
		) {
			filters = {
				filters: head + type + `, ` + operator + tail,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		} else {
			filters = {
				filters: head + type + tail,
				offset: rowsParams.offset,
				start: rowsParams.start,
			};
		}

		const newFilters = {
			...filters,
			order_field: "ID",
			order_type: sorterOrder,
		};

		setExportFilters(newFilters);

		try {
			const response = await myFetch(
				"api/v1/prospectandleads/datatables/",
				token,
				newFilters
			);
			//const dataFilter = data.filter(obj => !obj.deleted);
			if (response) {
				if (response.size) {
					setTotalTableSize(response.size);
				}
				if (response.data) {
					const rows = response.data.map((row) => {
						let rowID = "";
						if (
							dataState.rol === "administrador_isp" ||
							dataState.rol === "supervisor_comercial" ||
							dataState.rol === "vendedor"
						) {
							rowID = row.ID;
						} else {
							rowID = row.id_unique;
						}
						return {
							...row,
							ID: rowID,
							key: rowID,
						};
					});
					setTableRows(rows);
				}
			}
			setLoadingTable(false);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const updateRowsParams = (keyToUpdate, newValue) => {
		setRowsParams((prevState) => ({
			...prevState,
			[keyToUpdate]: newValue,
		}));
	};

	const modifyPagination = (page, offset) => {
		if (offset !== rowsParams.offset) {
			updateRowsParams("offset", offset);
		} else {
			updateRowsParams("start", page * offset - offset);
		}
		setPaginationSettings([page, offset]);
	};

	const getColumns = async () => {
		try {
			const data = await myFetchGet(
				"api/v1/prospectandleads/datatables_struct",
				token
			);
			const columnsTittles = Object.keys(data.columns);
			const columns = [];
			columnsTittles.forEach((columnsTittle) => {
				columns.push({
					sorter: columnsTittle === "ID" ? true : null,
					title: columnsTittle,
					dataIndex: data.columns[columnsTittle].field,
					key: data.columns[columnsTittle].field,
					align: "left",
					width:
						columnsTittle === "ID"
							? "70px"
							: columnsTittle === "Nombre completo"
							? "180px"
							: columnsTittle === "Registro"
							? "120px"
							: columnsTittle === "Teléfono"
							? "170px"
							: "",
					render:
						columnsTittle === "Dirección"
							? (value) => <TextColored value={value} />
							: (value) => <TableText value={value} />,
				});
			});
			setTableColumns(columns);
		} catch (e) {
			console.log("error", e);
		}
	};

	const tableChangeHandler = (pagination, filter, sorter, extra) => {
		if (sorter.order === "ascend") {
			setSorterOrder("asc");
		} else if (sorter.order === "descend" || sorter.order === undefined) {
			setSorterOrder("desc");
		}
	};

	useEffect(() => {
		initial();
		getColumns();
	}, [startDate, endDate, rowsParams, sorterOrder]);

	const dateFormat = "DD/MM/YYYY";

	const dateSelectHandler = (dates, dateStrings) => {
		if (dateStrings[0] === "" && dateStrings[1] === "") {
			const clearedDate = undefined;
			setStartDate(clearedDate);
			setEndDate(clearedDate);
		} else {
			setStartDate(dateStrings[0]);
			setEndDate(dateStrings[1]);
		}
	};

	const disabledDate = (current) => {
		return current > dayjs().endOf("day");
	};

	const onExportData = async () => {
		try {
			const file = await myExportData(
				"api/v1/prospectandleads/datatables_download/",
				token,
				exportFilters
			);
			fileDownload(file, "Listado de Leads.xlsx");
		} catch (e) {
			console.log("error", e);
		}
	};

	return (
		<Panel>
			<PanelHeader>
				<div className={`${styles["row"]} ${styles["align-center"]}`}>
					<span className={styles["header-title"]}>Listado de leads</span>
					<CustomTooltip
						subtitle={
							"Listado de usuario que realizaron consulta de servicio sin factibilidad."
						}
					/>
					<span className={styles["header-breadcrumb"]}>Ventas | Leads</span>
				</div>
			</PanelHeader>
			<div className={styles["table-container"]}>
				<FilterContainer>
					<span className={styles["filters-title"]}>Opciones de filtrado</span>
					<div className={styles["row"]}>
						<div className={styles["column"]}>
							<RangePicker
								className={styles["date-picker"]}
								placeholder={["Fecha Inicio", "Fecha Fin"]}
								onChange={dateSelectHandler}
								format={dateFormat}
								disabledDate={disabledDate}
							/>
						</div>
					</div>
				</FilterContainer>

				{loadingTable ? (
					<Loading />
				) : (
					<>
						<TableData
							rows={tableRows}
							columns={tableColumns}
							onChange={tableChangeHandler}
						/>

						<div
							className={`${styles["row"]} ${styles["pagination-container"]}`}
						>
							<TablePagination
								paginationSettings={paginationSettings}
								totalTableSize={totalTableSize}
								onChange={modifyPagination}
								showTotal={(total, range) => (
									<div className={styles["column"]}>
										<span className={styles["pagination"]}>
											Mostrando del {range[0]} al {range[1]} de un total de{" "}
											{total} registros
										</span>
										<span
											className={styles["export-button"]}
											onClick={onExportData}
										>
											<i
												className={`fa-solid fa-file-excel ${styles["export-button-icon"]}`}
											></i>
											Exportar data
										</span>
									</div>
								)}
							/>
						</div>
					</>
				)}
			</div>
		</Panel>
	);
};

export default Leads;

const FilterContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 53px 0 42px;

	.ant-select-clear {
		display: flex;
		align-items: center;
	}

	.ant-picker .ant-picker-input > input {
		font-size: 12px;
	}

	@media screen and (min-width: 1280px) {
		.ant-picker .ant-picker-input > input {
			font-size: 12px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-picker .ant-picker-input > input {
			font-size: 13.5px;
		}
	}

	@media screen and (min-width: 1280px) {
		.ant-picker .ant-picker-input > input {
			font-size: 15.18px;
		}
	}
`;
