import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DatePicker, Divider, Checkbox, Button } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

export default function HeaderDashboard({
	showBtn,
	generatePDF,
	setStartDate,
	setEndDate,
	setReportType,
	setTimeFilterStart,
	setTimeFilterEnd,
	setFilteredDaysCount,
}) {
	const [isVisible, setIsVisible] = useState(false);
	const [checkboxSelected, setCheckboxSelected] = useState(1);
	const [timeSelected, setTimeSelected] = useState(dayjs().subtract(7, "days"));
	const [timeFilter, setTimeFilter] = useState(null);
	const [value, setValue] = useState(null);
	const [disabled, setDisabled] = useState(false);
	const [initial, setInitial] = useState(false);
	const [daysCount, setDaysCount] = useState(7);
	const [reportTypeFilter, setReportTypeFilter] = useState("all_sellers");

	const { RangePicker } = DatePicker;

	const dataState = useSelector((state) => state.authState);

	const dateHandler = (dates, dateStrings) => {
		setValue(dates);
		// setTimeFilter(null)
		if (dateStrings[0] === "" && dateStrings[1] === "") {
			setStartDate("");
			setEndDate("");
			setFilteredDaysCount(undefined);
		} else {
			setStartDate(dateStrings[0]);
			setEndDate(dateStrings[1]);
		}
	};

	const handleTimeSelected = (value) => {
		if (value === 1) {
			const currentDate = dayjs().subtract(7, "days");
			setTimeSelected(currentDate);
			setCheckboxSelected(1);
			setDaysCount(7);
		} else if (value === 2) {
			const currentDate = dayjs().subtract(15, "days");
			setTimeSelected(currentDate);
			setCheckboxSelected(2);
			setDaysCount(15);
		} else if (value === 3) {
			const currentDate = dayjs().subtract(30, "days");
			setTimeSelected(currentDate);
			setCheckboxSelected(3);
			setDaysCount(30);
		} else {
			setCheckboxSelected(null);
		}
	};

	const handleReportType = (value) => {
		setReportTypeFilter(value);
	};

	const handlerTimeSeleced = () => {
		if (daysCount === 7) {
			setFilteredDaysCount(7);
		} else if (daysCount === 15) {
			setFilteredDaysCount(15);
		} else if (daysCount === 30) {
			setFilteredDaysCount(30);
		}
		setTimeFilter([timeSelected, dayjs()]);
		setTimeFilterStart(timeSelected.format("YYYY-MM-DD"));
		setTimeFilterEnd(dayjs().format("YYYY-MM-DD"));
		setStartDate("");
		setEndDate("");
		setReportType(reportTypeFilter);
		setIsVisible(false);
		setDisabled(true);
		setInitial(null);
	};

	const handlerClear = () => {
		setCheckboxSelected(null);
		setTimeFilter(null);
		setValue(null);
		setTimeFilterStart("");
		setTimeFilterEnd("");
		setIsVisible(false);
		setDisabled(false);
		setInitial(null);
	};

	const defaultDate = () => {
		setInitial([dayjs().subtract(7, "days"), dayjs()]);
		setDisabled(true);
		setTimeFilterStart(dayjs().subtract(7, "days").format("YYYY-MM-DD"));
		setTimeFilterEnd(dayjs().format("YYYY-MM-DD"));
	};

	useEffect(() => {
		defaultDate();
	}, []);

	return (
		<Container>
			<div className="row space-between">
				<div>
					<div className="row">
						<span className="title-text">Tablero</span>
						<span className="description-text">
							Resumen del día | Histórico de datos
						</span>
					</div>
				</div>

				<div>
					<div className="row">
						<RangePicker
							className="select"
							format="YYYY-MM-DD"
							value={timeFilter || value || initial}
							onChange={dateHandler}
							disabled={disabled}
						/>
					</div>
				</div>
			</div>

			<Divider />

			<span className="row space-between">
				<div className="left">
					<div
						onClick={() => setIsVisible(!isVisible)}
						style={{ cursor: "pointer" }}
					>
						<i className="fa-sharp fa-solid fa-filter fa-lg icon-option"></i>
						<span className="display-options">Opciones de visualización</span>
					</div>
				</div>

				{isVisible === true ? (
					<div className="visible_box animate__animated animate__fadeIn">
						<span className="filter-options">Opciones de filtrado</span>
						<div className="group-checkbox">
							<Checkbox
								value={1}
								checked={checkboxSelected === 1}
								onChange={() => handleTimeSelected(1)}
							>
								<span className="title">Últimos 7 días</span>
							</Checkbox>
							<Checkbox
								value={2}
								checked={checkboxSelected === 2}
								onChange={() => handleTimeSelected(2)}
							>
								<span className="title">Últimos 15 días</span>
							</Checkbox>
							<Checkbox
								value={3}
								checked={checkboxSelected === 3}
								onChange={() => handleTimeSelected(3)}
							>
								<span className="title">Últimos 30 días</span>
							</Checkbox>
						</div>

						{dataState.rol === "vendedor" && (
							<>
								<span className="filter-options" style={{ marginTop: "10px" }}>
									Reportes
								</span>
								<div className="group-checkbox">
									<Checkbox
										value={1}
										checked={reportTypeFilter === "all_sellers"}
										onChange={() => handleReportType("all_sellers")}
									>
										<span className="title">Reporte General</span>
									</Checkbox>
									<Checkbox
										value={2}
										checked={reportTypeFilter === "my_seller"}
										onChange={() => handleReportType("my_seller")}
									>
										<span className="title">Mí Reporte</span>
									</Checkbox>
								</div>
							</>
						)}

						<div className="filter-check">
							<p className="clear-button" onClick={() => handlerClear()}>
								Limpiar
							</p>

							<button
								className="button-ok"
								onClick={() => handlerTimeSeleced()}
							>
								Ok
							</button>
						</div>
					</div>
				) : null}

				{showBtn && (
					<button
						className="button"
						onClick={generatePDF}
						id="pdf_generate_btn"
					>
						<FilePdfOutlined className="pdf_icon" />
						<span className="export_text">Exportar</span>
					</button>
				)}
			</span>
		</Container>
	);
}

const Container = styled.div`
	span {
		margin: 0;
		padding: 0;
	}

	.select {
		height: 40px;
	}

	.row {
		width: 100%;
		display: flex;
		align-items: center;
	}

	.space-between {
		display: flex;
		justify-content: space-between;
	}

	.left {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.percent {
		background: #0060ff;
		color: white;
		width: 40px;
		height: 23px;
		font-size: 12px;
		margin-left: 5px;
		border-radius: 3px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.button {
		width: 99px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		outline: none;
		background: #426da9;
		color: white;
		border-radius: 3px;
		font-weight: 400;
		cursor: pointer;

		:hover {
			background: #1d428a;
			color: white;
		}
	}

	.pdf_icon {
		font-size: 14px;
	}

	.export_text {
		font-size: 14px;
		margin-left: 5px;
	}

	.visible_box {
		width: 165px;
		border-radius: 3px;
		padding: 20px;
		display: flex;
		flex-direction: column;
		background: white;
		position: absolute;
		top: 27%;
		z-index: 1;
		flex-wrap: wrap;
		border: solid #d8d6d6 0.01px;
	}

	.title {
		font-weight: 400;
		font-size: 12px;
		color: #131523;
		margin-left: 12px;
	}

	.title-text {
		font-size: 24px;
		font-weight: 700;
		color: #001737;
	}

	.description-text {
		color: #001737;
		margin-left: 15px;
		font-size: 12px;
	}

	.compared_text {
		font-size: 12px;
		color: #001737;
		margin-left: 15px;
	}

	.icon-option {
		margin-right: 10px;
		color: #5a607f;
		font-size: 12px;
	}

	.display-options {
		color: #2b80ff;
		font-size: 14px;
	}

	.filter-options {
		width: 100%;
		color: #868e96;
		font-size: 13px;
		margin-bottom: 13px;
	}

	.group-checkbox {
		display: flex;
		flex-flow: column;
		gap: 4px;
	}

	.filter-check {
		width: 100%;
		border-top: solid #d8d6d6 0.05px;
		padding-top: 8px;
		margin-top: 6%;
		display: flex;
	}

	.clear-button {
		color: #5a607f;
		font-size: 12px;
		cursor: pointer;
	}

	.button-ok {
		background: #dde4eb;
		margin-left: 46%;
		border: 3px;
		font-size: 10px;
		padding: 3px 7px;
	}

	@media screen and (min-width: 1280px) {
		.title {
			font-size: 12px;
		}

		.title-text {
			font-size: 24px;
		}

		.description-text {
			font-size: 12px;
		}

		.compared_text {
			font-size: 12px;
		}

		.icon-option {
			font-size: 12px;
		}

		.visible_box {
			width: 165px;
		}

		.filter-options {
			font-size: 13px;
		}

		.clear-button {
			font-size: 12px;
		}

		.button-ok {
			font-size: 10px;
		}

		.display-options {
			font-size: 14px;
		}

		.button {
			width: 99px;
			height: 34px;
		}

		.pdf_icon {
			font-size: 14px;
		}

		.export_text {
			font-size: 14px;
		}
	}

	@media screen and (min-width: 1366px) {
		.title {
			font-size: 13.5px;
		}

		.title-text {
			font-size: 27px;
		}

		.description-text {
			font-size: 13.5px;
		}

		.compared_text {
			font-size: 13.5px;
		}

		.icon-option {
			font-size: 13.5px;
		}

		.visible_box {
			width: 185.62px;
		}

		.filter-options {
			font-size: 14.62px;
		}

		.clear-button {
			font-size: 13.5px;
		}

		.button-ok {
			font-size: 11.25px;
		}

		.display-options {
			font-size: 15.75px;
		}

		.button {
			width: 111.37px;
			height: 38.25px;
		}

		.pdf_icon {
			font-size: 15.75px;
		}

		.export_text {
			font-size: 15.75px;
		}
	}

	@media screen and (min-width: 1920px) {
		.title {
			font-size: 15.18px;
		}

		.title-text {
			font-size: 30.37px;
		}

		.description-text {
			font-size: 15.18px;
		}

		.compared_text {
			font-size: 15.18px;
		}

		.icon-option {
			font-size: 15.18px;
		}

		.visible_box {
			width: 208.82px;
		}

		.filter-options {
			font-size: 16.44px;
		}

		.clear-button {
			font-size: 15.18px;
		}

		.button-ok {
			font-size: 12.65px;
		}

		.display-options {
			font-size: 17.71px;
		}

		.button {
			width: 125.29px;
			height: 43.03px;
		}

		.pdf_icon {
			font-size: 17.71px;
		}

		.export_text {
			font-size: 17.71px;
		}
	}
`;
