/* eslint-disable */
import React from "react";
import styled from "styled-components";
import { Loading } from "../loading/Loading";

const PanelStat = React.createContext();

class Panel extends React.Component {
	constructor(props) {
		super(props);

		this.moreItem = () => {
			this.props.setValue(!this.props.value);
		};

		this.state = {
			expand: false,
			collapse: false,
			reload: false,
			remove: false,
			moreItem: this.moreItem,
		};
	}
	render() {
		return (
			<PanelStat.Provider value={this.state}>
				{!this.state.remove && (
					<div
						className={
							"panel panel-" +
							(this.props.theme ? this.props.theme : "inverse") +
							" " +
							(this.state.expand ? "panel-expand " : " ") +
							(this.state.reload ? "panel-loading " : " ") +
							(this.props.className ? this.props.className : "")
						}
					>
						{this.props.loadingData ? <Loading /> : <>{this.props.children}</>}
					</div>
				)}
			</PanelStat.Provider>
		);
	}
}

class PanelHeader extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="grey__bg">
				<ContentPanelHeader>{this.props.children}</ContentPanelHeader>
			</div>
		);
	}
}

const PanelSubHeader = ({
	children,
	//noButton = false,
	//typeButton,
	//buttonText,
}) => {
	return <ContentPanelSubHeader>{children}</ContentPanelSubHeader>;
};

class PanelBody extends React.Component {
	render() {
		return (
			<PanelStat.Consumer>
				{({ collapse, reload }) => (
					<div
						className={
							this.props.className ? this.props.className : "panel__body"
						}
					>
						{this.props.children}

						{reload && (
							<div className="panel-loader">
								<span className="spinner-small"></span>
							</div>
						)}
					</div>
				)}
			</PanelStat.Consumer>
		);
	}
}

class PanelFooter extends React.Component {
	render() {
		return (
			<div className={"panel-footer " + this.props.className}>
				{this.props.children}
			</div>
		);
	}
}

const ContentPanelHeader = styled.div`
	padding-bottom: 0.5em;
	border-bottom: 2px solid #cdd6dc;
`;

const ContentPanelSubHeader = styled.div`
	flex: 1;
	flex-wrap: nowrap;
	display: flex;
	align-items: center;
`;

export { Panel, PanelHeader, PanelBody, PanelFooter, PanelSubHeader };
