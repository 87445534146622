import React, { useState, useEffect } from "react";
import { Table, Space, Button, Popconfirm, notification } from "antd";
import ImageForm from "./ImageForm";
import { myFetch, myFetchGet } from "../../../services/services";
import { useReduxToken } from "../../../hooks/useReduxToken";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import ColorCircle from "../../../components/colorCircle/ColorCircle";
import styles from "./SalesGallery.module.css";
import { saveAs } from "file-saver";
import CustomModal from "../../../components/modal/CustomModal";
import NoPic from "../../../assets/images/no_pic.png";
import styled from "styled-components";
import Br from "../../../components/Br/Br";

const SalesGallery = ({ data }) => {
	const token = useReduxToken();
	const dataState = useSelector((state) => state.authState);

	const changeSale = dataState.permissions.includes("change_sales");

	const { id_unique } = data;

	const [visibleEditForm, setVisibleEditForm] = useState(false);
	const [visibleUploadForm, setVisibleUploadForm] = useState(false);
	const [rows, setRows] = useState([]);
	const [selectItem, setSelectItem] = useState();
	const [customerUserID, setCustomerUserID] = useState();
	const [customerUserName, setCustomerUserName] = useState();

	const initial = async () => {
		try {
			const sale = await myFetchGet(`api/v1/sales/${id_unique}`, token);
			const response = await myFetchGet(
				`api/v1/customer_user_files/?customer_user=${sale?.customer_user}`,
				token
			);
			const responseFiltered = response.filter((item) => !item.deleted);
			const resultSorted = responseFiltered.sort((a, b) => {
				const dateA = dayjs(a.created, "DD-MM-YYYY");
				const dateB = dayjs(b.created, "DD-MM-YYYY");
				return dateA - dateB;
			});
			const rows = resultSorted.map((row) => {
				let typeDocument = {};
				typeDocument = {
					type: row.document_method,
					description: row.description,
					imgUrl: row.img,
				};
				return {
					...row,
					key: row.ID,
					description: typeDocument,
				};
			});
			setRows(rows);
			setCustomerUserID(sale?.customer_user);
			setCustomerUserName(sale?.customer_user_name);
		} catch (e) {
			console.log("error", e);
		}
	};

	const onDeleteImage = async (ID) => {
		try {
			await myFetch(`api/v1/customer_user_files/${ID}/`, token, "", "DELETE");
			notification.success({
				className: "notification",
				message: "Documento borrado exitosamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
			initial();
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message: "Se ha producido un error inesperado",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	useEffect(() => {
		initial();
	}, []);

	const editItem = (item) => {
		setSelectItem(item);
		setVisibleEditForm(true);
	};

	const onDownloadImage = (data) => {
		if (data.type === 1) {
			saveAs(data.imgUrl, "Documento de identidad.png");
		} else {
			saveAs(data.imgUrl, "Acuerdo de servicio.png");
		}
	};

	const onAddImage = () => {
		setVisibleUploadForm(true);
	};

	const columns = [
		{
			dataIndex: "created",
			key: "created",
			width: "140px",
			render: (text) => (
				<div
					className={`${styles["column"]} ${styles["justify-center"]}`}
					style={{ marginTop: "20px" }}
				>
					<span className={styles["date-format"]}>
						{dayjs(text, "DD-MM-YYYY").format("DD/MM/YYYY")}
					</span>
					<span className={styles["user-format"]}>{customerUserName}</span>
				</div>
			),
		},
		{
			dataIndex: "img",
			key: "img",
			width: "150px",
			render: (text) => (
				<img className={styles["image-container"]} src={text} />
			),
		},
		{
			dataIndex: "description",
			key: "description",
			render: (text) => (
				<div className={styles["column"]} style={{ marginTop: "10px" }}>
					<div className={`${styles["row"]} ${styles["align-center"]}`}>
						<ColorCircle color={"#2B80FF"} />
						{text.type === 1 ? (
							<span className={styles["description-text"]}>
								Documento de identidad
							</span>
						) : (
							<span className={styles["description-text"]}>
								Acuerdo de servicio
							</span>
						)}
					</div>
					<span className={styles["description-text"]}>{text.description}</span>
					<div className={`${styles["row"]} ${styles["align-center"]}`}>
						<i
							className={`fa-solid fa-file-arrow-down ${styles["image-download-icon"]}`}
						/>
						<span
							className={styles["blue-text"]}
							onClick={() => onDownloadImage(text)}
						>
							Descargar
						</span>
					</div>
				</div>
			),
		},
		{
			dataIndex: "ID",
			key: "ID",
			width: "225px",
			render: (ID, description) => (
				<div
					className={`${styles["row"]} ${styles["space-between"]}`}
					style={{ marginTop: "30px" }}
				>
					{changeSale && (
						<span
							className={styles["blue-text"]}
							onClick={() => editItem({ ...ID, ...description })}
						>
							Modificar
						</span>
					)}
					{changeSale && (
						<Popconfirm
							placement="topRight"
							icon={""}
							title="¿Eliminar fotografía?"
							description="Si eliminas esta fotografía, se eliminará de forma permanente de tu galería de imágenes"
							onConfirm={() => onDeleteImage(ID)}
							okText="Eliminar"
							cancelText="Cancelar"
						>
							<span className={styles["blue-text"]}>Eliminar</span>
						</Popconfirm>
					)}
				</div>
			),
		},
	];

	const onCloseUploadModal = () => {
		setVisibleUploadForm(false);
	};

	const onCloseModifyModal = () => {
		setVisibleEditForm(false);
	};

	const emptyTableColumns = [
		{
			dataIndex: "created",
			key: "created",
			width: 120,
			render: () => (
				<div className={`${styles["column"]} ${styles["justify-center"]}`}>
					<span className={styles["date-format"]}>Fecha</span>
					<span className={styles["user-format"]}>Usuario</span>
				</div>
			),
		},
		{
			dataIndex: "photo",
			key: "photo",
			width: 180,
			render: (text) => (
				<div className={styles["row"]}>
					<img
						src={text}
						className={styles["image-container"]}
						alt="Imagen de muestra"
					/>
				</div>
			),
		},
		{
			dataIndex: "caption",
			key: "caption",
			render: (text) => (
				<div className={styles["column"]} style={{ marginLeft: 15 }}>
					<span className={styles["caption"]}>{text}</span>
				</div>
			),
		},
	];

	const emptyExampleTable = [{ ID: 1, photo: NoPic, caption: "Cargar imagen" }];

	return (
		<div className={styles["sales-gallery-container"]}>
			<div className={styles["subheader-container"]}>
				<div className={styles["column"]}>
					<span className={styles["subheader-title"]}>
						Listado de documento adjuntos relacionados a la venta.
					</span>
				</div>

				{changeSale === true ? (
					<div className={styles["column"]}>
						<Button
							className={styles["add-image-button"]}
							onClick={onAddImage}
							icon={
								<i
									className={`fa-solid fa-plus ${styles["add-image-button-icon"]}`}
								></i>
							}
						>
							Agregar documento
						</Button>
					</div>
				) : null}
			</div>

			<Br />

			{rows.length === 0 ? (
				<Table
					showHeader={false}
					pagination={false}
					dataSource={emptyExampleTable}
					columns={emptyTableColumns}
				/>
			) : (
				<TableContainer>
					<div className="animate__animated animate__fadeIn">
						<Table
							className="table"
							showHeader={false}
							dataSource={rows}
							columns={columns}
							pagination={false}
							rowClassName={(record, index) =>
								index % 2 === 0
									? styles["table-row-dark"]
									: styles["table-row-light"]
							}
						/>
					</div>
				</TableContainer>
			)}

			{visibleUploadForm && (
				<CustomModal onClick={onCloseUploadModal}>
					<ImageForm
						modalHeader="Subir"
						setIsVisible={setVisibleUploadForm}
						customerUserID={customerUserID}
						initial={initial}
					/>
				</CustomModal>
			)}

			{visibleEditForm && (
				<CustomModal onClick={onCloseModifyModal}>
					<ImageForm
						modalHeader="Editar"
						data={selectItem ? selectItem : {}}
						setIsVisible={setVisibleEditForm}
						initial={initial}
					/>
				</CustomModal>
			)}
		</div>
	);
};

export default SalesGallery;

const TableContainer = styled.div`
	.table {
		.ant-table-tbody tr td:first-child {
			padding-left: 42px !important;
		}

		.ant-table-tbody tr td:last-child {
			padding-right: 42px !important;
		}
	}
`;
