import React, { useState } from "react";

import styled from "styled-components";
import devices from "../../helpers/sizes";
import { largeSizes, mediumSizes, smallSizes } from "../../helpers/sizes";
import { Avatar, Divider, Button } from "antd";
import { AreaChart, Area, ResponsiveContainer } from "recharts";
import { useClassDashboard } from "../../hooks/useClassDashboard";
import { Link } from "react-router-dom";
import { validationNumber } from "../../helpers/validationNumber";
import CustomTooltip from "../customTooltip/CustomTooltip";

const BottomCardsSupervisor = ({
	totalVendedores,
	percentVendedores,
	vendedoresTable,
}) => {
	const percentSignoVendedor = Math.abs(percentVendedores);

	const vendedores = useClassDashboard(percentVendedores);

	const percentSignoVendedorFixed = percentSignoVendedor.toFixed(2);

	const firstLetter = vendedoresTable.map((item) => item.name.charAt(0));

	const getRandomColor = () => {
		const letters = "0123456789ABCDEF";
		let color = "#";
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	};

	const sendEmailHandler = (email) => {
		window.location.href = `mailto: ${email}`;
	};

	return (
		<Container>
			<div className="bottom-section-supervisor">
				<div className="sellers">
					<div className="graph-operator">
						<div className="title-graph">
							<p className="title-cards">Relación de vendedores</p>
							<CustomTooltip
								subtitle={"Total de vendedores activos asociados al operador."}
								iconColor="hardBlue"
								position={"bottomLeft"}
								arrow={false}
							/>
							<span className="text-operator">
								{totalVendedores} vendedores
							</span>
							<span className={vendedores?.textCard}>
								(
								{validationNumber(percentSignoVendedor)
									? percentSignoVendedorFixed
									: percentSignoVendedor}{" "}
								%)
							</span>
						</div>

						<div className="title-graph">
							<span className="text-card-sales">
								Total de vendedores asociados
							</span>
						</div>
					</div>

					<div className="scroll-sales custom_scroll">
						<div style={{ display: "flex" }}>
							<span
								className="text-card-sales"
								style={{ flex: "1", marginLeft: "30px" }}
							>
								Vendedor
							</span>
							<span className="text-card-sales" style={{ flex: "1" }}>
								Total ventas
							</span>
							<span className="text-card-sales" style={{ flex: "1" }}>
								Estatus
							</span>
							<span style={{ width: "7%" }}></span>
						</div>
						<Divider />
						{vendedoresTable.map((item, index) => (
							<div key={item.ID} className="vendedores">
								<div
									style={{ flex: "1", display: "flex", alignItems: "center" }}
								>
									<Avatar
										style={{
											backgroundColor: getRandomColor(),
											fontSize: "11px",
										}}
										size="small"
										src={
											item.img !== null
												? `data:image/${item.img?.extension};base64, ${item.img?.base64}`
												: ""
										}
									>
										{firstLetter[index]}
									</Avatar>
									<span className="name-vendedor">
										{item.name} {item.last_name}
									</span>
								</div>

								<span className="sales-vendedor">
									{item.directa + item.aplicacion} ventas
								</span>

								<div className="show-status">
									<i
										className={
											item.is_active === true ? "circle" : "circle-red"
										}
									/>
									<span className="status">
										{item.is_active === true ? "Activo" : "Inactivo"}
									</span>
								</div>

								<div className="send-email">
									<Button
										type="link"
										className="email-btn"
										onClick={() => sendEmailHandler(item.email)}
									>
										<i className="fa-regular fa-envelope email-icon"></i>
									</Button>
								</div>
							</div>
						))}
					</div>
					<Divider style={{ margin: 15, minWidth: "96%", width: "90%" }} />

					<Link to="detalleOperador" className="show_more">
						Ir a detalle de operador
					</Link>
				</div>
			</div>
		</Container>
	);
};

export default BottomCardsSupervisor;

const Container = styled.div`
	.bottom-section-supervisor {
		width: 100%;
		min-height: 400px;
		display: flex;
		justify-content: space-between;
		margin: 1.3em 0;
	}

	.sellers {
		width: 100%;
		min-height: 400px;
		background: white;
		border-radius: 3px;
		border: 1px solid #dde4eb;
		padding: 34px 60px 34px 30px;
	}

	.sellers .graph-operator {
		width: 100%;
		height: 24%;
		display: flex;
		flex-direction: column;
	}

	.sellers .graph-operator .title-graph {
		width: 35%;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.title-cards {
		color: #5a607f;
		font-size: ${smallSizes.dashboardBottom.titleCards};
		font-weight: 700;
	}

	.text-operator {
		font-size: ${smallSizes.dashboardBottom.textOperator};
		color: #131523;
		font-weight: 700;
		margin-right: 11%;
	}

	.title-graph .percent_up {
		font-size: ${smallSizes.dashboardBottom.percent};
		color: #3dd598;
		margin-right: 1%;
		font-weight: 700;
	}

	.title-graph .percent_equal {
		font-size: ${smallSizes.dashboardBottom.percent};
		color: #2b80ff;
		margin-right: 1%;
		font-weight: 700;
	}

	.title-graph .percent_down {
		font-size: ${smallSizes.dashboardBottom.percent};
		color: #f0142f;
		margin-right: 1%;
		font-weight: 700;
	}

	// --------------------------------------------

	.text-card-sales {
		font-size: ${smallSizes.dashboardBottom.textCardSales};
		color: #7f7f7f;
	}

	// -------------------------------------------------

	.sellers .vendedores {
		display: flex;
		align-items: center;
		margin: 30px 0 12px 30px;
		justify-content: space-between;
	}

	.vendedores .name-vendedor {
		font-size: ${smallSizes.dashboardBottom.nameOperator};
		color: #172b4d;
		padding-left: 20px;
	}

	.vendedores .sales-vendedor {
		font-size: ${smallSizes.dashboardBottom.salesOperator};
		color: #7f7f7f;
		flex: 1;
	}

	// ----------------------------------------------

	.show-status {
		display: flex;
		align-items: center;
		flex: 1;
	}

	.show-status .circle {
		background: #26a805;
		width: 10px;
		height: 10px;
		border-radius: 100%;
		margin-right: 8px;
	}

	.show-status .circle-red {
		background: #ff0707;
		width: 10px;
		height: 10px;
		border-radius: 100%;
		margin-right: 8px;
	}

	.show-status .status {
		font-size: ${smallSizes.dashboardBottom.status};
		margin-left: 2%;
		color: #172b4d;
	}

	.show_more {
		color: #1458e8;
		font-size: ${smallSizes.dashboardBottom.showMore};
		width: 100%;
		cursor: pointer;
		margin: 0 0 30px 35px;
	}

	.send-email {
		display: flex;
		width: 7%;
	}

	.email-btn {
		border: none;
		color: grey;

		:hover {
			color: #2b80ff;
		}
	}

	.email-icon {
		font-size: 24px;
	}

	//--------------------------------------------------------

	.percent-average-up {
		margin-right: 2%;
		font-size: ${smallSizes.dashboardMid.percentAverage};
		color: #3dd598;
	}

	.percent-average-equal {
		margin-right: 2%;
		font-size: ${smallSizes.dashboardMid.percentAverage};
		color: #2b80ff;
	}

	.percent-average-down {
		margin-right: 2%;
		font-size: ${smallSizes.dashboardMid.percentAverage};
		color: #f0142f;
	}

	.arrow_icon_equal {
		color: #2b80ff;
		font-size: ${smallSizes.dashboardMid.arrowIcon};
		margin: 0 10px;
	}

	.arrow_icon_up {
		color: #3dd598;
		font-size: ${smallSizes.dashboardMid.arrowIcon};
		margin: 0 14px;
	}

	.arrow_icon_down {
		color: #f0142f;
		font-size: ${smallSizes.dashboardMid.arrowIcon};
		margin: 0 10px;
	}

	//---------------------------------------------------------

	.scroll-sales {
		padding: 20px 0 0;
		max-height: 250px;
		overflow-y: auto;
		margin: 15px 0;
	}

	//-----------------------------------------------------------

	@media screen and (min-width: ${devices.smallDesk}) {
		.title-cards {
			font-size: ${smallSizes.dashboardBottom.titleCards};
		}

		.text-operator {
			font-size: ${smallSizes.dashboardBottom.textOperator};
		}

		.title-graph .percent_up {
			font-size: ${smallSizes.dashboardBottom.percent};
		}

		.title-graph .percent_equal {
			font-size: ${smallSizes.dashboardBottom.percent};
		}

		.title-graph .percent_down {
			font-size: ${smallSizes.dashboardBottom.percent};
		}

		// --------------------------------------------

		.text-card-sales {
			font-size: ${smallSizes.dashboardBottom.textCardSales};
		}
		// -------------------------------------------------

		.vendedores .name-vendedor {
			font-size: ${smallSizes.dashboardBottom.nameOperator};
		}

		.vendedores .sales-vendedor {
			font-size: ${smallSizes.dashboardBottom.salesOperator};
		}

		// ----------------------------------------------------------
		.show-status .status {
			font-size: ${smallSizes.dashboardBottom.status};
		}

		.show_more {
			font-size: ${smallSizes.dashboardBottom.showMore};
		}

		// --------------------------------------------------------

		.percent-average-up {
			font-size: ${smallSizes.dashboardMid.percentAverage};
		}

		.percent-average-equal {
			font-size: ${smallSizes.dashboardMid.percentAverage};
		}

		.percent-average-down {
			font-size: ${smallSizes.dashboardMid.percentAverage};
		}

		.arrow_icon_equal {
			font-size: ${smallSizes.dashboardMid.arrowIcon};
		}

		.arrow_icon_up {
			font-size: ${smallSizes.dashboardMid.arrowIcon};
		}

		.arrow_icon_down {
			font-size: ${smallSizes.dashboardMid.arrowIcon};
		}

		.email-icon {
			font-size: 24px;
		}
	}

	//-----------------------------------------------------------
	@media screen and (min-width: ${devices.mediumDesk}) {
		.title-cards {
			font-size: ${mediumSizes.dashboardBottom.titleCards};
		}

		.text-operator {
			font-size: ${mediumSizes.dashboardBottom.textOperator};
		}

		.title-graph .percent_up {
			font-size: ${mediumSizes.dashboardBottom.percent};
		}

		.title-graph .percent_equal {
			font-size: ${mediumSizes.dashboardBottom.percent};
		}

		.title-graph .percent_down {
			font-size: ${mediumSizes.dashboardBottom.percent};
		}

		// --------------------------------------------

		.text-card-sales {
			font-size: ${mediumSizes.dashboardBottom.textCardSales};
		}
		// -------------------------------------------------

		.vendedores .name-vendedor {
			font-size: ${mediumSizes.dashboardBottom.nameOperator};
		}

		.vendedores .sales-vendedor {
			font-size: ${mediumSizes.dashboardBottom.salesOperator};
		}

		// ----------------------------------------------------------
		.show-status .status {
			font-size: ${mediumSizes.dashboardBottom.status};
		}

		.show_more {
			font-size: ${mediumSizes.dashboardBottom.showMore};
		}

		// --------------------------------------------------------

		.percent-average-up {
			font-size: ${mediumSizes.dashboardMid.percentAverage};
		}

		.percent-average-equal {
			font-size: ${mediumSizes.dashboardMid.percentAverage};
		}

		.percent-average-down {
			font-size: ${mediumSizes.dashboardMid.percentAverage};
		}

		.arrow_icon_equal {
			font-size: ${mediumSizes.dashboardMid.arrowIcon};
		}

		.arrow_icon_up {
			font-size: ${mediumSizes.dashboardMid.arrowIcon};
		}

		.arrow_icon_down {
			font-size: ${mediumSizes.dashboardMid.arrowIcon};
		}

		.email-icon {
			font-size: 27px;
		}
	}

	//-----------------------------------------------------------
	@media screen and (min-width: ${devices.largeDesk}) {
		.title-cards {
			font-size: ${largeSizes.dashboardBottom.titleCards};
		}

		.text-operator {
			font-size: ${largeSizes.dashboardBottom.textOperator};
		}

		.title-graph .percent_up {
			font-size: ${largeSizes.dashboardBottom.percent};
		}

		.title-graph .percent_equal {
			font-size: ${largeSizes.dashboardBottom.percent};
		}

		.title-graph .percent_down {
			font-size: ${largeSizes.dashboardBottom.percent};
		}

		// --------------------------------------------

		.text-card-sales {
			font-size: ${largeSizes.dashboardBottom.textCardSales};
		}
		// -------------------------------------------------

		.vendedores .name-vendedor {
			font-size: ${largeSizes.dashboardBottom.nameOperator};
		}

		.vendedores .sales-vendedor {
			font-size: ${largeSizes.dashboardBottom.salesOperator};
		}

		// ----------------------------------------------------------
		.show-status .status {
			font-size: ${largeSizes.dashboardBottom.status};
		}

		.show_more {
			font-size: ${largeSizes.dashboardBottom.showMore};
		}

		// --------------------------------------------------------

		.percent-average-up {
			font-size: ${largeSizes.dashboardMid.percentAverage};
		}

		.percent-average-equal {
			font-size: ${largeSizes.dashboardMid.percentAverage};
		}

		.percent-average-down {
			font-size: ${largeSizes.dashboardMid.percentAverage};
		}

		.arrow_icon_equal {
			font-size: ${largeSizes.dashboardMid.arrowIcon};
		}

		.arrow_icon_up {
			font-size: ${largeSizes.dashboardMid.arrowIcon};
		}

		.arrow_icon_down {
			font-size: ${largeSizes.dashboardMid.arrowIcon};
		}

		.email-icon {
			font-size: 30.37px;
		}
	}
`;

const TooltipContainer = styled.div`
	display: flex;
	flex-direction: column;

	.tooltip_title {
		color: #868e96;
		font-size: 14px;
		margin-bottom: 15px;
	}

	.tooltip_subtitle {
		color: #172b4d;
		font-size: 12px;
	}
`;
