import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styles from "./Spinner.module.css";

const Spinner = () => {
	return (
		<Spin indicator={<LoadingOutlined className={styles["spinner"]} spin />} />
	);
};

export default Spinner;
