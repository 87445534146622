import React from "react";
import Dashboard from "../pages/home/Dashboard";
import Login from "../pages/login";
import Users from "../pages/detailsUser/Users";
import Operators from "../pages/detailsOperators/Operators";
import Sales from "../pages/detailsSales/Sales";
import DetailsUser from "../pages/detailsUser/DetailsUser";
import DetailsSales from "../pages/detailsSales/DetailsSales";
import DetailsOperators from "../pages/detailsOperators/DetailsOperators";
import ConsCoverage from "../pages/consCoverage/ConsCoverage";
import Prospects from "../pages/detailProspects/Prospects";
import Leads from "../pages/detailLeads/Leads";
import Security from "../pages/Security";

const routes = [
	{
		path: "/",
		exact: true,
		private: false,
		title: "Login",
		component: () => <Login />,
	},
	{
		path: "/inicio",
		exact: true,
		private: true,
		title: "Dashboard",
		component: () => <Dashboard />,
	},
	{
		path: "/listadoDeUsuarios",
		exact: true,
		private: true,
		title: "Listado de Usuarios",
		component: () => <Users />,
	},
	{
		path: "/detalleUsuario",
		exact: true,
		private: true,
		title: "Detalle de Usuario",
		component: () => <DetailsUser />,
	},
	{
		path: "/operadoresList",
		exact: true,
		private: true,
		title: "Listado de Operadores",
		component: () => <Operators />,
	},
	{
		path: "/detalleOperador",
		exact: true,
		private: true,
		title: "Detalle de Operador",
		component: () => <DetailsOperators />,
	},
	{
		path: "/coberturaConsolidada",
		exact: true,
		private: true,
		title: "Cobertura Consolidada",
		component: () => <ConsCoverage />,
	},
	{
		path: "/listadoDeVentas",
		exact: true,
		private: true,
		title: "Listado de Ventas",
		component: () => <Sales />,
	},
	{
		path: "/detalleVentas",
		exact: true,
		private: true,
		title: "Detalle de Ventas",
		component: () => <DetailsSales />,
	},
	{
		path: "/listadoDeProspectos",
		exact: true,
		private: true,
		title: "Listado de Prospectos",
		component: () => <Prospects />,
	},
	{
		path: "/listadoDeLeads",
		exact: true,
		private: true,
		title: "Listado de Leads",
		component: () => <Leads />,
	},
	{
		path: "/security",
		exact: true,
		private: true,
		title: "seguridad",
		component: () => <Security />,
	},
];

export default routes;
