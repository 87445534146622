import React from "react";
import styled from "styled-components";
import devices from "../../helpers/sizes";
import { smallSizes, mediumSizes, largeSizes } from "../../helpers/sizes";

export default function ColorCircle({ color }) {
	return (
		<ColorCircleContainer>
			<span className="color-circle" style={{ background: color }} />
		</ColorCircleContainer>
	);
}

const ColorCircleContainer = styled.div`
	.color-circle {
		display: flex;
		border-radius: 100%;
		margin-right: 10px;
		width: ${smallSizes.colorCircleContainer.circleWidth};
		height: ${smallSizes.colorCircleContainer.circleHeight};
	}

	@media screen and (min-width: ${devices.smallDesk}) {
		.color-circle {
			width: ${smallSizes.colorCircleContainer.circleWidth};
			height: ${smallSizes.colorCircleContainer.circleHeight};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}) {
		.color-circle {
			width: ${mediumSizes.colorCircleContainer.circleWidth};
			height: ${mediumSizes.colorCircleContainer.circleHeight};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}) {
		.color-circle {
			width: ${largeSizes.colorCircleContainer.circleWidth};
			height: ${largeSizes.colorCircleContainer.circleHeight};
		}
	}
`;
