import React, { useState, useEffect } from "react";
import { Panel, PanelSubHeader } from "../../../components/panel/panel";
import TableData from "../../../components/table/TableData";
import { myFetch, myFetchGet, myExportData } from "../../../services/services";
import moment from "moment/moment";
import CustomModal from "../../../components/modal/CustomModal";
import AddressHistory from "./AddressHistory";
import { notification } from "antd";
import { Loading } from "../../../components/loading/Loading";
import { useReduxToken } from "../../../hooks/useReduxToken";
import TextColored from "../../../components/textColored/TextColored";
import TableText from "../../../components/tableText/TableText";
import fileDownload from "js-file-download";
import styles from "./AddressesPanel.module.css";
import TablePagination from "../../../components/tablePagination/TablePagination";

const AddressesPanel = ({ data }) => {
	const token = useReduxToken();

	const [isVisibleModal, setIsVisibleModal] = useState(false);
	const [loadingTable, setLoadingTable] = useState(true);
	const [tableColumns, setTableColumns] = useState([]);
	const [totalTableSize, setTotalTableSize] = useState(0);
	const [tableRows, setTableRows] = useState([]);
	const [paginationSettings, setPaginationSettings] = useState([1, 10]);
	const [rowsParams, setRowsParams] = useState({ offset: 10, start: 0 });
	const [selectItem, setSelectItem] = useState();
	const [sorterOrder, setSorterOrder] = useState("desc");
	const [exportFilters, setExportFilters] = useState({});

	const { id_unique } = data;

	const initial = async () => {
		let filters = {};

		filters = {
			filters: `[["user", "equal", "` + id_unique + `"]]`,
			offset: rowsParams.offset,
			start: rowsParams.start,
		};

		const newFilters = {
			...filters,
			order_field: "ID",
			order_type: sorterOrder,
		};

		setExportFilters(newFilters);

		try {
			const response = await myFetch(
				"api/v1/addresses/datatables/",
				token,
				newFilters
			);
			if (response) {
				if (response.size) {
					setTotalTableSize(response.size);
				}
				if (response.data) {
					const rows = response.data.map((row) => {
						const createdFormat = moment(row.created).format("DD-MM-YYYY");
						return {
							...row,
							key: row.ID,
							created: createdFormat,
						};
					});
					setTableRows(rows);
				}
			}
			setLoadingTable(false);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const updateRowsParams = (keyToUpdate, newValue) => {
		setRowsParams((prevState) => ({
			...prevState,
			[keyToUpdate]: newValue,
		}));
	};

	const modifyPagination = (page, offset) => {
		if (offset !== rowsParams.offset) {
			updateRowsParams("offset", offset);
		} else {
			updateRowsParams("start", page * offset - offset);
		}
		setPaginationSettings([page, offset]);
	};

	const showHistory = (item) => {
		setSelectItem(item);
		setIsVisibleModal(true);
	};

	const getColumns = async () => {
		try {
			const data = await myFetchGet(
				"api/v1/addresses/datatables_struct",
				token
			);
			const columnsTittles = Object.keys(data.columns);
			const columns = [];
			columnsTittles.forEach((columnsTittle) => {
				columns.push({
					onCell:
						columnsTittle === "ID"
							? (record, rowIndex) => {
									return {
										onClick: (event) => {
											event.stopPropagation();
											showHistory(record);
										},
										style: { cursor: "pointer" },
										className: "hover-class",
									};
							  }
							: null,
					sorter: columnsTittle === "ID" ? true : null,
					title: columnsTittle,
					dataIndex: data.columns[columnsTittle].field,
					key: data.columns[columnsTittle].field,
					align: "left",
					width: columnsTittle === "ID" ? "70px" : "",
					render:
						columnsTittle === "ID"
							? (value) => <TextColored value={value} />
							: columnsTittle === "Operadores con cobertura"
							? (value) => (
									<div style={{ display: "flex" }}>
										{value === 1 ? (
											<TableText value={"1 Operador"} />
										) : value > 1 ? (
											<TableText value={`${value} Operadores`} />
										) : (
											(value === null || value === 0) && (
												<TableText value={"0 Operadores"} />
											)
										)}
									</div>
							  )
							: (value) => <TableText value={value} />,
				});
			});
			setTableColumns(columns);
		} catch (e) {
			console.log("error", e);
		}
	};

	const tableChangeHandler = (pagination, filter, sorter, extra) => {
		if (sorter.order === "ascend") {
			setSorterOrder("asc");
		} else if (sorter.order === "descend" || sorter.order === undefined) {
			setSorterOrder("desc");
		}
	};

	useEffect(() => {
		initial();
		getColumns();
	}, [rowsParams, sorterOrder]);

	const onCloseModal = () => {
		setIsVisibleModal(false);
		setSelectItem();
	};

	const onExportData = async () => {
		try {
			const file = await myExportData(
				"api/v1/addresses/datatables_download/",
				token,
				exportFilters
			);
			fileDownload(file, "Listado de Direcciones.xlsx");
		} catch (e) {
			console.log("error", e);
		}
	};

	return (
		<Panel>
			<PanelSubHeader>
				<div className={styles["subheader-container"]}>
					<span className={styles["subheader-title"]}>
						Listado de direcciones registradas desde la aplicación asociadas al
						perfil de usuario.
					</span>
				</div>
			</PanelSubHeader>
			{isVisibleModal && (
				<CustomModal onClick={onCloseModal}>
					<AddressHistory
						data={selectItem}
						userID={id_unique}
						visible={isVisibleModal}
						setVisible={setIsVisibleModal}
						cancel={() => setSelectItem()}
					/>
				</CustomModal>
			)}
			{loadingTable ? (
				<Loading />
			) : (
				<>
					<TableData
						rows={tableRows}
						columns={tableColumns}
						onChange={tableChangeHandler}
					/>

					<div className={`${styles["row"]} ${styles["pagination-container"]}`}>
						<TablePagination
							paginationSettings={paginationSettings}
							totalTableSize={totalTableSize}
							onChange={modifyPagination}
							showTotal={(total, range) => (
								<div className={styles["column"]}>
									<span className={styles["pagination"]}>
										Mostrando del {range[0]} al {range[1]} de un total de{" "}
										{total} registros
									</span>
									<span
										className={styles["export-button"]}
										onClick={onExportData}
									>
										<i
											className={`fa-solid fa-file-excel ${styles["export-button-icon"]}`}
										></i>
										Exportar data
									</span>
								</div>
							)}
						/>
					</div>
				</>
			)}
		</Panel>
	);
};

export default AddressesPanel;
