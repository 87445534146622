import React, { useEffect, useState } from "react";
import { Panel, PanelBody, PanelHeader } from "../components/panel/panel";
import styled from "styled-components";
import { Button, Input, notification } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { myFetchGet } from "../services/services";
import { CheckCircleFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { authenticationGoggle } from "../store/auth/authSlice";
import { useReduxToken } from "../hooks/useReduxToken";

const Security = () => {
	const token = useReduxToken();

	const [qrCode, setQrCode] = useState("");
	const [code, setCode] = useState("");
	const [inputToken, setInputToken] = useState();
	const [statusToken, setStatusToken] = useState();

	const dataState = useSelector((state) => state.authState);
	const dispatch = useDispatch();

	let classDivQr = "div-qr";

	// Cambio de clase
	if (statusToken === true) {
		classDivQr = "div-qr-activate";
	} else {
		classDivQr = "div-qr";
	}

	// Peticion para generar imagen QR y codigo de autenticacion
	// Verificar el status de la utenticacion del usuario
	async function initial() {
		try {
			const { qr_base64, otp_code } = await myFetchGet(
				"api/v1/generate-authenticator",
				token
			);
			const same = otp_code.split("=");
			const code = same[1].split("&");
			setQrCode(qr_base64);
			setCode(code[0]);
			verifyStatus();
		} catch (error) {
			notification.error({
				className: "notification",
				message: "Se ha producido un error inesperado",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	}

	// Peticion de verificar el codigo ingresado por aplicacion Movil
	const verifyCode = async () => {
		try {
			const response = await myFetchGet(
				`api/v1/verify-authenticator?code=${inputToken}`,
				token
			);
			setStatusToken(response?.success);
			if (response?.success === true) {
				dispatch(
					authenticationGoggle({
						authentication: response.success,
					})
				);
				notification.success({
					className: "notification",
					message: "Configuración realizada con éxito",
					placement: "bottomLeft",
					duration: 2.5,
				});
			} else {
				notification.warning({
					className: "notification",
					message: "No se ha podido realizar la verificación",
					placement: "bottomLeft",
					duration: 2.5,
				});
			}
		} catch (error) {
			notification.error({
				className: "notification",
				message: "Se ha producido un error inesperado",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const verifyStatus = () => {
		const verifyAutenticationLogin = dataState.authentication;
		if (verifyAutenticationLogin === true) {
			setStatusToken(true);
		} else {
			setStatusToken(false);
		}
	};
	const deactivateAuthentication = async () => {
		try {
			const deactivate = await myFetchGet(
				"api/v1/deactivate-authenticator/",
				token
			);

			if (deactivate?.success === true) {
				dispatch(
					authenticationGoggle({
						authentication: false,
					})
				);
				setStatusToken(false);
				notification.success({
					className: "notification",
					message: "Cuenta desactivada",
					placement: "bottomLeft",
					duration: 2.5,
				});
			}
		} catch (error) {
			notification.error({
				className: "notification",
				message: "Se ha producido un error inesperado",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	useEffect(() => {
		initial();
	}, []);
	return (
		<div>
			<div className="col-md-12">
				<Panel>
					<PanelHeader>
						<b
							style={{
								fontSize: "17px",
								marginRight: "10px",
								color: "#001737",
							}}
						>
							Usuario Administrador
						</b>
						<span style={{ fontSize: "12px", color: "#001737" }}>
							Opciones de perfil | Seguridad
						</span>
					</PanelHeader>

					<PanelBody>
						<Container>
							<div className="row space-between">
								<div className="column">
									<div
										className="row align-center"
										style={{ paddingBottom: "20px" }}
									>
										<p
											style={{
												color: "#172B4D",
												fontSize: "17px",
												fontWeight: "bold",
												margin: 0,
												paddingRight: "56px",
											}}
										>
											Ajustes de seguridad avanzada
										</p>
										{statusToken === true ? (
											<div className="row align-center">
												<CheckCircleFilled
													style={{ marginRight: "10px", color: "#8fd14f" }}
												/>
												<span
													style={{
														color: "#1458E8",
														fontSize: "14px",
														fontWeight: "bold",
													}}
												>
													Configuración activa
												</span>
											</div>
										) : null}
									</div>

									<p
										style={{
											color: "#2B80FF",
											fontSize: "14px",
											fontWeight: "bold",
										}}
									>
										Verificación en dos pasos
									</p>
									<p className="text">
										Este método proporciona un segundo filtro de autenticación
										de usuario
									</p>
									<p className="text">
										a través de un código de 6 dígitos generado por la
										aplicación móvil
									</p>
									<b className="text">Google Authenticator.</b>
									<p className="text_steps" style={{ marginTop: "15px" }}>
										Para configurar este método, siga estos pasos.
									</p>
									<p className="text_steps">
										1. Instale{" "}
										<b className="text_steps">Google Authenticator</b> en su
										dispositivo móvil.
									</p>
									<p className="text_steps" style={{ maxWidth: "380px" }}>
										2. Agregue su cuenta de{" "}
										<b className="text_steps">Oráculo</b> a la aplicación Google
										Authenticator. Para hacerlo escanee el código QR en el lado
										derecho de la página o use el código secreto que se muestra
										debajo del QR para la cuenta.
									</p>
									<p className="text_steps" style={{ maxWidth: "355px" }}>
										3. Para probar la configuración, ingrese un código generado
										desde su Google Authenticator en el campo que se muestra a
										continuación:
									</p>

									{statusToken === false ? (
										<div className="row" style={{ height: "50px" }}>
											<Input
												className="security-code"
												placeholder="Ingresar código"
												onChange={(e) => setInputToken(e?.target?.value)}
											/>
											<Button className="btn-verify" onClick={verifyCode}>
												Verificar
											</Button>
										</div>
									) : null}
								</div>

								<div className="column">
									<div className={classDivQr}>
										<p className="title-qr">Código secreto QR</p>
										<p className="title-qr-scan">Escanea el código QR</p>
										<img className="qr-code" src={qrCode} />
										<p
											style={{
												marginLeft: "36px",
												color: "#5A607F",
												fontSize: "12px",
												maxWidth: "300px",
											}}
										>
											o use el código secreto para crear su registro en la
											aplicación de Google Authenticator
										</p>
										<div className="row">
											<label>{code ? code : ""}</label>
											<CopyToClipboard text={code}>
												<Button
													className="fa-solid fa-copy fa-xl"
													style={{
														color: "#426DA9",
														width: "37px",
														height: "34px",
														marginLeft: "8px",
														background: "#DDE4EB",
														borderRadius: "3px",
														padding: "0",
													}}
												/>
											</CopyToClipboard>
											{statusToken === true ? (
												<Button
													onClick={deactivateAuthentication}
													style={{
														background: "#F53C56",
														color: "white",
														marginLeft: "20px",
														width: 319,
													}}
												>
													Desactivar
												</Button>
											) : null}
										</div>
									</div>
								</div>
							</div>
						</Container>
					</PanelBody>
				</Panel>
			</div>
		</div>
	);
};

export default Security;

const Container = styled.div`
	.board {
		display: flex;
		flex-direction: row;
		margin-left: 50px;
	}

	.breadcrumb {
		font-size: 12px;
	}

	.row {
		margin: 0 !important;
		margin-top: 5px;
		display: flex;
	}

	.row_center {
		justify-content: center;
	}
	.row_start {
		justify-content: flex-start;
	}
	.row_end {
		justify-content: flex-end;
	}
	.space-between {
		justify-content: space-between;
		margin: 0 !important;
	}
	.space-evenly {
		justify-content: space-evenly;
		margin: 0 !important;
	}
	.align-center {
		align-items: center;
	}

	.column {
		display: flex;
		flex-flow: column;
		margin: 0 !important;
	}
	.column_center {
		justify-content: center;
	}

	.flex {
		flex: 1;
	}

	.breadcrumb_text {
		margin-left: 0.5em;
		margin-right: 0.5em;
		:hover {
			cursor: pointer;
			color: #426da9;
			text-decoration-line: underline;
		}
	}
	.text {
		color: #5a607f;
		font-size: 14px;
		margin: 0;
	}
	.text_steps {
		color: #7f7f7f;
		font-size: 14px;
	}
	.security-code {
		width: 250px;
		height: 30px;
		background: white;
		border-radius: 5px;
		border: 1px solid #d9d9d9;
		outline: 0;
		padding: 4px 11px 4px;
	}
	.btn-verify {
		width: 84px;
		height: 34px;
		font-size: 12px;
		background: #dde4eb;
		border-radius: 3px;
		margin-left: 15px;
		:hover {
			background: #426da9;
			color: white;
			border: none;
		}
	}
	.div-qr {
		background: #f3f3f3;
		width: 360px;
		height: 400px;
	}
	.div-qr-activate {
		background: #f3f3f3;
		width: 360px;
		height: 431px;
	}

	.title-qr {
		font-size: 18px;
		margin: 31px 36px 5px;
		color: #172b4d;
		font-weight: bold;
	}

	.title-qr-scan {
		font-size: 14px;
		margin-left: 36px;
		color: #5a607f;
		font-weight: bold;
	}

	.qr-code {
		width: 180px;
		height: 180px;
		margin-left: 80px;
		margin-bottom: 20px;
	}
	label {
		background: white;
		font-size: 12px;
		color: #868d96;
		border-radius: 5px;
		width: 275px;
		height: 34px;
		padding: 7px 9px;
		margin-left: 17px;
		border: solid #e1e1e1 1px;
	}
`;
