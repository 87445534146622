import React from "react";
import SidebarNav from "./sidebar-nav.jsx";
import styled from "styled-components";
import LogoImg from "../../assets/images/LogoOráculo.png";
import ConsInfo from "../../assets/images/informacion consolidada.png";
import { useOperator } from "../../hooks/useOperator.js";
import { useSelector } from "react-redux";
import { Avatar, Select } from "antd";
import styles from "./Sidebar.module.css";

const Sidebar = () => {
	const { operators, changeOperator, storedOperatorID } = useOperator();

	const dataState = useSelector((state) => state.authState);

	return (
		<div className="sidebar sidebar_background">
			<div className={styles["logo-container"]}>
				<img className={styles["system-logo"]} src={LogoImg} alt="Oráculo" />
			</div>

			<OperatorContainer>
				{dataState.rol === "administrador_isp" ||
				dataState.rol === "supervisor_comercial" ? (
					<div className={styles["operator-box"]}>
						<div className={styles["row"]} style={{ gap: "10px" }}>
							<Avatar
								className={styles["sidebar-avatar"]}
								style={{ backgroundColor: "#ffffff" }}
								src={dataState.operatorLogo}
							/>
							<div className={styles["column"]}>
								<span className={styles["operator-tag"]}>Operador</span>
								<span className={styles["operator-name"]}>
									{dataState.operatorName}
								</span>
							</div>
						</div>
					</div>
				) : dataState.rol === "vendedor" &&
				  dataState.userOperators.length === 1 ? (
					<div className={styles["operator-box"]}>
						<div className={styles["row"]} style={{ gap: "10px" }}>
							<Avatar
								className={styles["sidebar-avatar"]}
								style={{ backgroundColor: "#ffffff" }}
								src={dataState.operatorLogo}
							/>
							<div className={styles["column"]}>
								<span className={styles["operator-tag"]}>Operador</span>
								<span className={styles["operator-name"]}>
									{dataState.operatorName}
								</span>
							</div>
						</div>
					</div>
				) : dataState.rol === "vendedor" &&
				  dataState.userOperators.length > 1 ? (
					<div className={styles["select-container"]}>
						<Select
							className={styles["operator-select"]}
							bordered={false}
							dropdownStyle={{ borderRadius: 0 }}
							onChange={changeOperator}
							value={storedOperatorID}
						>
							{operators?.map((operator) => (
								<Select.Option key={operator?.ID} value={operator?.ID}>
									<div className={styles["row"]} style={{ gap: "10px" }}>
										<Avatar
											className={styles["sidebar-avatar"]}
											style={{ backgroundColor: "#ffffff" }}
											src={operator.logo}
										/>
										<div className={styles["column"]}>
											<span className={styles["operator-tag"]}>
												Operador &nbsp;
											</span>
											<span className={styles["operator-name"]}>
												{operator?.name}
											</span>
										</div>
									</div>
								</Select.Option>
							))}
						</Select>
					</div>
				) : (
					(dataState.rol === "administrador" ||
						dataState.rol === "multifiber") && (
						<div className={styles["operator-box"]}>
							<div className={styles["row"]} style={{ gap: "20px" }}>
								<Avatar
									className={styles["sidebar-avatar"]}
									style={{ backgroundColor: "#ffffff" }}
									src={ConsInfo}
								/>
								<div className={styles["column"]}>
									<span className={styles["operator-tag"]}>Información</span>
									<span className={styles["operator-name"]}>Consolidada</span>
								</div>
							</div>
						</div>
					)
				)}
			</OperatorContainer>

			<div className={styles["menu-title-container"]}>
				<span className={styles["menu-title"]}>Menu Principal</span>
			</div>
			<SidebarNav />
		</div>
	);
};

export default Sidebar;

const OperatorContainer = styled.div`
	display: flex;
	justify-content: center;

	.ant-select-selection-item {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.ant-select-selector {
		height: 80px !important;
		border-radius: 0px;
		display: flex;
		align-items: center;
		background: #2f4d87 !important;
		font-size: 18px;
		color: white;
	}

	.ant-select-arrow {
		color: white;
		padding-right: 10px;
		font-size: 14px;
	}

	@media screen and (min-width: 1280px) {
		.ant-select-selector {
			height: 80px !important;
		}

		.ant-select-arrow {
			font-size: 14px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-select-selector {
			height: 90px !important;
		}

		.ant-select-arrow {
			font-size: 15.75px;
		}
	}

	@media screen and (min-width: 1920px) {
		.ant-select-selector {
			height: 101.25px !important;
		}

		.ant-select-arrow {
			font-size: 17.71px;
		}
	}
`;
