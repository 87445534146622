import React from "react";
import styled from "styled-components";
import devices from "../../helpers/sizes";
import { smallSizes, mediumSizes, largeSizes } from "../../helpers/sizes";

export default function TextColored({ value }) {
	return (
		<TextColoredContainer>
			<span className="text-colored">{value}</span>
		</TextColoredContainer>
	);
}

const TextColoredContainer = styled.div`
	.text-colored {
		font-size: ${smallSizes.textColoredContainer.textColored};
		font-weight: bold;
		color: #2b80ff;
	}

	@media screen and (min-width: ${devices.smallDesk}) {
		.text-colored {
			font-size: ${smallSizes.textColoredContainer.textColored};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}) {
		.text-colored {
			font-size: ${mediumSizes.textColoredContainer.textColored};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}) {
		.text-colored {
			font-size: ${largeSizes.textColoredContainer.textColored};
		}
	}
`;
