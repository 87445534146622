import React from "react";
import styled from "styled-components";
import { Pagination } from "antd";

const TablePagination = ({
	paginationSettings,
	totalTableSize,
	onChange,
	showTotal,
}) => {
	return (
		<PaginationContainer>
			<Pagination
				showSizeChanger
				defaultPageSize={paginationSettings[1]}
				current={paginationSettings[0]}
				onChange={onChange}
				total={totalTableSize}
				showTotal={showTotal}
			/>
		</PaginationContainer>
	);
};

export default TablePagination;

const PaginationContainer = styled.div`
	display: flex;
	width: 100%;

	.ant-pagination {
		display: flex;
		width: 100%;
	}

	.ant-pagination-total-text {
		margin-right: auto;
	}

	.ant-pagination .ant-pagination-item-active {
		background-color: #0058ff;
		color: #ffffff;
		border: none;
		border-radius: 4px;
	}
`;
