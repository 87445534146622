import React, { useState, useEffect, useRef } from "react";
import {
	Select,
	DatePicker,
	Input,
	Space,
	Divider,
	Button,
	Checkbox,
	InputNumber,
	notification,
} from "antd";
import styled from "styled-components";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { myFetchGet, myFetch, urlBase } from "../../../services/services";
import { useSelector } from "react-redux";
import AuthenticationGoggle from "../../../components/authenticationGoggle/AuthenticationGoggle";
import { useReduxToken } from "../../../hooks/useReduxToken";
import dayjs from "dayjs";
import { regex } from "../../../helpers/regex";
import CustomTooltip from "../../../components/customTooltip/CustomTooltip";
import { LoadingForm } from "../../../components/loading/Loading";
import styles from "./FormOperatorPlans.module.css";
import Br from "../../../components/Br/Br";

const imageBaseURL = urlBase.slice(0, -1);

const FormOperatorPlans = ({
	data,
	setVisible,
	cancel,
	visible,
	modalHeader,
	operatorID,
	initial,
}) => {
	const dataState = useSelector((state) => state.authState);
	const token = useReduxToken();
	const [loading, setLoading] = useState(true);

	/*------------------STATES INICIALES------------------*/
	const [tradename, setTradeName] = useState(data?.tradename || undefined);
	const [matrixPlanID, setMatrixPlanID] = useState();
	const [matrixPlansList, setMatrixPlansList] = useState([]);
	const [dateRange, setDateRange] = useState([]);
	const [startDate, setStartDate] = useState(data?.start_date || undefined);
	const [endDate, setEndDate] = useState(data?.end_date || undefined);
	const [currency, setCurrency] = useState(data?.id_currency || undefined);
	const [price, setPrice] = useState(data?.price || undefined);
	const [installationPrice, setInstallationPrice] = useState(
		data?.installation_price || undefined
	);
	const [description, setDescription] = useState(
		data?.description || undefined
	);
	const [category, setCategory] = useState(data?.category || []);
	const [planType, setPlanType] = useState(data?.type_plan_row || undefined);
	const [technology, setTechnology] = useState(
		data?.technology_id || undefined
	);
	const [megasUpload, setMegasUpload] = useState(
		data?.megas_upload || undefined
	);
	const [megasDownload, setMegasDownload] = useState(data?.megas_download);
	const [numberChannels, setNumberChannels] = useState(
		data?.number_channels || null
	);
	const [extraChannelsHD, setExtraChannelsHD] = useState(
		data?.extra_channels_hd || null
	);
	const [phoneMinutes, setPhoneMinutes] = useState(data?.phone_minutes || null);
	const [status, setStatus] = useState(data?.status || 1);
	const [miniatureLogo, setMiniatureLogo] = useState(
		data?.image_miniature || null
	);
	const [tempMiniatureLogo, setTempMiniatureLogo] = useState(null);
	const [miniatureLogoBase64, setMiniatureLogoBase64] = useState();
	const [detailImage, setDetailImage] = useState(data?.photo || null);
	const [tempDetailImage, setTempDetailImage] = useState(null);
	const [detailImageBase64, setDetailImageBase64] = useState();
	const [indefinitePeriod, setIndefinitePeriod] = useState(
		data?.indefinite_period || false
	);
	const [unlimitedMinutes, setUnlimitedMinutes] = useState(
		data?.unlimited_minutes || false
	);
	const [channelsOptions, setChannelsOptions] = useState(
		data?.options_channels || undefined
	);

	const handleDatePeriod = () => {
		if (!indefinitePeriod && startDate && endDate) {
			setDateRange([
				dayjs(startDate, "YYYY-MM-DD"),
				dayjs(endDate, "YYYY-MM-DD"),
			]);
		}
	};

	/*-----------STATES PARA MANEJAR EL SELECTOR MULTIPLE-----------*/
	const [internet, setInternet] = useState(
		category && category.includes(1) ? true : false
	);
	const [television, setTelevision] = useState(
		category && category.includes(2) ? true : false
	);
	const [telephone, setTelephone] = useState(
		category && category.includes(3) ? true : false
	);

	/*-----------STATE API TIPO DE PLANES-----------*/
	const [kindPlan, setKindPlan] = useState();

	/*-----------STATE API TIPO DE TECNOLOGÍAS-----------*/
	const [technologyType, setTechnologyType] = useState();

	/*-----------STATE API MONEDAS DEL OPERADOR-----------*/
	const [operatorCurrencyList, setOperatorCurrencyList] = useState([]);

	const { RangePicker } = DatePicker;

	const { TextArea } = Input;

	const [visiblePlanForm, setVisiblePlanForm] = useState(true);
	const [visibleVerify, setVisibleVerify] = useState(false);

	/*Validaciones */
	const [errors, setErrors] = useState({
		tradename: "",
		matrixPlanID: "",
		dateRange: "",
		price: "",
		installationPrice: "",
		description: "",
		planType: "",
		category: "",
		technology: "",
		megasUpload: "",
		megasDownload: "",
		channelsOptions: "",
		numberChannels: "",
		extraChannelsHD: "",
		phoneMinutes: "",
	});
	const [errorStatus, setErrorStatus] = useState({
		tradename: false,
		matrixPlanID: false,
		dateRange: false,
		price: false,
		installationPrice: false,
		description: false,
		planType: false,
		category: false,
		technology: false,
		megasUpload: false,
		megasDownload: false,
		channelsOptions: false,
		numberChannels: false,
		extraChannelsHD: false,
		phoneMinutes: false,
	});

	const getFormData = async () => {
		try {
			if (data.plan_id) {
				const plan = await myFetchGet(`api/v1/plans/${data.plan_id}`, token);
				setMatrixPlanID(plan.external_plan_id);
			}

			const matrixPlansList = await myFetchGet(
				`api/v1/plans/get_externals/?operator_id=${operatorID}`,
				token
			);
			setMatrixPlansList(matrixPlansList);

			const kind = await myFetchGet("api/v1/kindplan", token);
			setKindPlan(kind);

			const tech = await myFetchGet(
				`api/v1/technology/${operatorID}/for_operator/`,
				token
			);
			setTechnologyType(tech);

			const currencyList = await myFetchGet(
				"api/v1/currency/for_operator",
				token
			);
			setOperatorCurrencyList(currencyList);

			if (modalHeader === "Agregar") {
				setCurrency(currencyList[0].ID);
			}

			setLoading(false);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	useEffect(() => {
		getFormData();
	}, []);

	useEffect(() => {
		handleDatePeriod();
	}, []);

	useEffect(() => {
		let tradenameError = "";
		let matrixPlanIDError = "";
		let dateRangeError = "";
		let priceError = "";
		let installationPriceError = "";
		let descriptionError = "";
		let planTypeError = "";
		let categoryError = "";
		let technologyError = "";
		let megasUploadError = "";
		let megasDownloadError = "";
		let optionsChannelsError = "";
		let numberChannelsError = "";
		let extraChannelsHDError = "";
		let phoneMinutesError = "";

		if (!tradename) {
			tradenameError = "Ingrese el nombre del plan";
		}

		if (!matrixPlanID) {
			matrixPlanIDError = "Seleccione un plan de Matrix";
		}

		if (!startDate && indefinitePeriod === false) {
			dateRangeError = "Seleccione un rango de fechas";
		}

		if (!price) {
			priceError = "Ingrese el costo del plan";
		} else if (regex.regexAmount.test(price) === false) {
			priceError = "Ingrese solo números";
		}

		if (installationPrice === null || installationPrice === undefined) {
			installationPriceError = "Ingrese el costo de instalación";
		} else if (regex.regexAmount.test(installationPrice) === false) {
			installationPriceError = "Ingrese solo números";
		}

		if (!description) {
			descriptionError = "Ingrese una breve descripción";
		}

		if (!planType) {
			planTypeError = "Seleccione el tipo de plan";
		}

		if (!category) {
			categoryError = "Seleccione la categoría";
		} else if (planType === 2 && category.length < 2) {
			categoryError = "Debe seleccionar dos categorías";
		} else if (planType === 3 && category.length < 3) {
			categoryError = "Debe seleccionar tres categorías";
		}

		if (!technology) {
			technologyError = "Seleccione la tecnología del plan";
		}

		if (!megasUpload && category.includes(1)) {
			megasUploadError = "Ingrese la cantidad de megas de subida";
		} else if (
			category.includes(1) &&
			regex.regexNumbers.test(megasUpload) === false
		) {
			megasUploadError = "Ingrese solo números";
		}

		if (!megasDownload && category.includes(1)) {
			megasDownloadError = "Ingrese la cantidad de megas de bajada";
		} else if (
			category.includes(1) &&
			regex.regexNumbers.test(megasDownload) === false
		) {
			megasDownloadError = "Ingrese solo números";
		}

		if (!channelsOptions && category.includes(2)) {
			optionsChannelsError = "Seleccione el tipo de opción";
		}

		if (!numberChannels && category.includes(2)) {
			numberChannelsError = "Ingrese la cantidad de canales";
		} else if (
			category.includes(2) &&
			regex.regexNumbers.test(numberChannels) === false
		) {
			numberChannelsError = "Ingrese solo números";
		}

		if (!extraChannelsHD && category.includes(2) && channelsOptions === 1) {
			extraChannelsHDError = "Ingrese la cantidad de canales HD";
		} else if (
			category.includes(2) &&
			channelsOptions === 1 &&
			regex.regexNumbers.test(extraChannelsHD) === false
		) {
			extraChannelsHDError = "Ingrese solo números";
		}

		if (!phoneMinutes && category.includes(3) && unlimitedMinutes === false) {
			phoneMinutesError = "Ingrese la cantidad de minutos";
		} else if (
			category.includes(3) &&
			unlimitedMinutes === false &&
			regex.regexNumbers.test(phoneMinutes) === false
		) {
			phoneMinutesError = "Ingrese solo números";
		}

		setErrors({
			tradename: tradenameError,
			matrixPlanID: matrixPlanIDError,
			dateRange: dateRangeError,
			price: priceError,
			installationPrice: installationPriceError,
			description: descriptionError,
			planType: planTypeError,
			category: categoryError,
			technology: technologyError,
			megasUpload: megasUploadError,
			megasDownload: megasDownloadError,
			channelsOptions: optionsChannelsError,
			numberChannels: numberChannelsError,
			extraChannelsHD: extraChannelsHDError,
			phoneMinutes: phoneMinutesError,
		});
	}, [
		tradename,
		matrixPlanID,
		indefinitePeriod,
		startDate,
		endDate,
		price,
		installationPrice,
		description,
		planType,
		category,
		technology,
		megasUpload,
		megasDownload,
		channelsOptions,
		numberChannels,
		extraChannelsHD,
		phoneMinutes,
		errorStatus,
	]);

	const validateInput = () => {
		if (errors.tradename) {
			setErrorStatus((prevState) => ({ ...prevState, tradename: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, tradename: false }));
		}

		if (errors.matrixPlanID) {
			setErrorStatus((prevState) => ({ ...prevState, matrixPlanID: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, matrixPlanID: false }));
		}

		if (errors.dateRange) {
			setErrorStatus((prevState) => ({ ...prevState, dateRange: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, dateRange: false }));
		}

		if (errors.price) {
			setErrorStatus((prevState) => ({ ...prevState, price: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, price: false }));
		}

		if (errors.installationPrice) {
			setErrorStatus((prevState) => ({
				...prevState,
				installationPrice: true,
			}));
			return;
		} else {
			setErrorStatus((prevState) => ({
				...prevState,
				installationPrice: false,
			}));
		}

		if (errors.description) {
			setErrorStatus((prevState) => ({
				...prevState,
				description: true,
			}));
			return;
		} else {
			setErrorStatus((prevState) => ({
				...prevState,
				description: false,
			}));
		}

		if (errors.planType) {
			setErrorStatus((prevState) => ({ ...prevState, planType: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, planType: false }));
		}

		if (errors.category) {
			setErrorStatus((prevState) => ({ ...prevState, category: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, category: false }));
		}

		if (errors.technology) {
			setErrorStatus((prevState) => ({ ...prevState, technology: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, technology: false }));
		}

		if (errors.megasUpload) {
			setErrorStatus((prevState) => ({ ...prevState, megasUpload: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, megasUpload: false }));
		}

		if (errors.megasDownload) {
			setErrorStatus((prevState) => ({ ...prevState, megasDownload: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, megasDownload: false }));
		}

		if (errors.channelsOptions) {
			setErrorStatus((prevState) => ({ ...prevState, channelsOptions: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, channelsOptions: false }));
		}

		if (errors.numberChannels) {
			setErrorStatus((prevState) => ({ ...prevState, numberChannels: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, numberChannels: false }));
		}

		if (errors.extraChannelsHD) {
			setErrorStatus((prevState) => ({ ...prevState, extraChannelsHD: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, extraChannelsHD: false }));
		}

		if (errors.phoneMinutes) {
			setErrorStatus((prevState) => ({ ...prevState, phoneMinutes: true }));
			return;
		} else {
			setErrorStatus((prevState) => ({ ...prevState, phoneMinutes: false }));
		}

		setErrorStatus({
			tradename: false,
			matrixPlanID: false,
			dateRange: false,
			price: false,
			installationPrice: false,
			description: false,
			planType: false,
			category: false,
			technology: false,
			megasUpload: false,
			megasDownload: false,
			channelsOptions: false,
			numberChannels: false,
			extraChannelsHD: false,
			phoneMinutes: false,
		});
	};

	const onSubmit = async (data, ID) => {
		if (Object.values(errors).some((value) => value !== "")) {
			validateInput();
			notification.warning({
				className: "notification",
				message: "Por favor, completa todos los campos obligatorios",
				placement: "bottomLeft",
				duration: 2.5,
			});
			return;
		}

		setErrorStatus({
			tradename: false,
			matrixPlanID: false,
			dateRange: false,
			price: false,
			installationPrice: false,
			description: false,
			planType: false,
			category: false,
			technology: false,
			megasUpload: false,
			megasDownload: false,
			channelsOptions: false,
			numberChannels: false,
			extraChannelsHD: false,
			phoneMinutes: false,
		});

		if (indefinitePeriod === true) {
			data.start_date = undefined;
			data.end_date = undefined;
		}

		if (ID) {
			try {
				await myFetch(`api/v1/plans/${ID}/`, token, data, "PATCH");
				notification.success({
					className: "notification",
					message: "Plan modificado exitosamente",
					placement: "bottomLeft",
					duration: 2.5,
				});
				onCloseModal();
				initial();
			} catch (e) {
				console.log("error", e);
				notification.error({
					className: "notification",
					message: "Se ha producido un error inesperado",
					placement: "bottomLeft",
					duration: 2.5,
				});
			}
		} else {
			try {
				await myFetch("api/v1/plans/", token, data);
				notification.success({
					className: "notification",
					message: "Plan creado exitosamente",
					placement: "bottomLeft",
					duration: 2.5,
				});
				onCloseModal();
				initial();
			} catch (e) {
				console.log("error", e);
				notification.error({
					className: "notification",
					message: "Se ha producido un error inesperado",
					placement: "bottomLeft",
					duration: 2.5,
				});
			}
		}
	};

	const onCloseModal = () => {
		setVisible(false);
		cancel();
	};

	const matrixPlanSelectHandler = (value) => {
		setMatrixPlanID(value);
	};

	const dateFormat = "DD/MM/YYYY";

	const dateSelectHandler = (range) => {
		const startDate = range[0].format("YYYY-MM-DD");
		const endDate = range[1].format("YYYY-MM-DD");

		setStartDate(startDate);
		setEndDate(endDate);
		setDateRange([range[0], range[1]]);
	};

	const planTypeSelectHandler = (value) => {
		setPlanType(value);
		setCategory([]);
	};

	const validateOptions = (selectedOptions, maxCount) => {
		if (selectedOptions.length > maxCount) {
			return false;
		}

		if (planType === 1 && selectedOptions.length > 1) {
			return false;
		}

		if (planType === 2 && selectedOptions.length > 2) {
			return false;
		}

		return true;
	};

	const planCategorySelectHandler = (value) => {
		if (value.includes(1)) {
			setInternet(true);
		} else {
			setInternet(false);
			setMegasUpload(null);
			setMegasDownload(null);
		}
		if (value.includes(2)) {
			setTelevision(true);
		} else {
			setTelevision(false);
			setNumberChannels(null);
			setExtraChannelsHD(null);
		}
		if (value.includes(3)) {
			setTelephone(true);
		} else {
			setTelephone(false);
			setPhoneMinutes(null);
		}
		setCategory(value);
	};

	const technologySelectHandler = (value) => {
		setTechnology(value);
	};

	const statusSelectHandler = (value) => {
		setStatus(value);
	};

	const channelsOptionsHandler = (value) => {
		setChannelsOptions(value);
		if (value === 2) {
			setExtraChannelsHD(null);
		}
	};

	const checkIndefinitePeriodHandler = (e) => {
		setIndefinitePeriod(e.target.checked);
		if (e.target.checked === true) {
			setStartDate(undefined);
			setEndDate(undefined);
			setDateRange([undefined, undefined]);
		}
		validateInput();
	};

	const checkUnlimitedMinutesHandler = (e) => {
		setUnlimitedMinutes(e.target.checked);
		if (e.target.checked === true) {
			setPhoneMinutes(null);
		}
		validateInput();
	};

	const selectCostBefore = (
		<Select
			style={{
				width: 60,
			}}
			value={currency}
			onChange={(value) => setCurrency(value)}
		>
			{operatorCurrencyList.length > 0 &&
				operatorCurrencyList.map((currency) => (
					<Select.Option key={currency.ID + Math.random()} value={currency.ID}>
						{currency.symbol}
					</Select.Option>
				))}
		</Select>
	);

	const selectInstallationBefore = (
		<Select
			style={{
				width: 60,
			}}
			value={currency}
			onChange={(value) => setCurrency(value)}
		>
			{operatorCurrencyList.length > 0 &&
				operatorCurrencyList.map((currency) => (
					<Select.Option key={currency.ID + Math.random()} value={currency.ID}>
						{currency.symbol}
					</Select.Option>
				))}
		</Select>
	);

	const clearTemporalMiniatureHandler = () => {
		setMiniatureLogo(null);
		setTempMiniatureLogo(null);
	};

	const clearTemporalDetailImageHandler = () => {
		setDetailImage(null);
		setTempDetailImage(null);
	};

	const saveMiniatureImage = useRef();

	const transformMiniatureImageToBase64 = (archivos) => {
		setTempMiniatureLogo(archivos[0]);

		Array.from(archivos).forEach((archivo) => {
			const reader = new FileReader();
			reader.readAsDataURL(archivo);

			reader.onload = function () {
				let arraySplitted = [];
				const base64 = reader.result;

				arraySplitted = base64.split(",");
				setMiniatureLogoBase64(arraySplitted[1]);
				setMiniatureLogo(arraySplitted[1]);
			};
		});
	};

	const saveDetailImage = useRef();

	const transformDetailImageToBase64 = (archivos) => {
		setTempDetailImage(archivos[0]);

		Array.from(archivos).forEach((archivo) => {
			const reader = new FileReader();
			reader.readAsDataURL(archivo);

			reader.onload = function () {
				let arraySplitted = [];
				const base64 = reader.result;

				arraySplitted = base64.split(",");
				setDetailImageBase64(arraySplitted[1]);
				setDetailImage(arraySplitted[1]);
			};
		});
	};

	const verifyStatus = () => {
		const verifyAuthenticationLogin = dataState.authentication;
		if (verifyAuthenticationLogin === true) {
			setTimeout(() => {
				setVisibleVerify(true);
				setVisiblePlanForm(false);
			}, 200);
		} else {
			setVisibleVerify(true);
			notification.error({
				className: "notification",
				message: "Activar verificación en dos pasos",
				description: (
					<>
						Para realizar alguna modificación en dicha sección debe activar{" "}
						<b>Google Authenticator</b>
					</>
				),
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	const confirmResponse = () => {
		onSubmit(
			{
				tradename: tradename,
				external_plan_id: matrixPlanID,
				start_date: dayjs(startDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
				end_date: dayjs(endDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
				currency: currency,
				price: price,
				installation_price: installationPrice,
				description: description,
				category: category,
				type_plan: planType,
				technology: technology,
				megas_upload: megasUpload,
				megas_download: megasDownload,
				number_channels: numberChannels,
				extra_channels_hd: extraChannelsHD,
				phone_minutes: phoneMinutes,
				status: status,
				operator: operatorID,
				image_miniature_base64: miniatureLogoBase64,
				photo_base64: detailImageBase64,
				indefinite_period: indefinitePeriod,
				unlimited_minutes: unlimitedMinutes,
				options_channels: channelsOptions,
			},
			data.plan_id
		);
	};

	if (visible) {
		return (
			<Container>
				{visiblePlanForm && (
					<div
						className={`${styles["form-container"]} animate__animated animate__fadeInRight ${styles["custom-scroll"]}`}
					>
						<div
							className={`${styles["row"]} ${styles["space-between"]} ${styles["align-center"]}`}
						>
							<span className={styles["form-title"]}>
								{modalHeader} plan de servicio
							</span>
							<CloseOutlined
								className={styles["close-form-icon"]}
								onClick={onCloseModal}
							/>
						</div>

						<Divider />

						{loading ? (
							<LoadingForm />
						) : (
							<>
								{/* ----------------GENERAL---------------- */}
								<span className={styles["form-section-title"]}>
									Información general
								</span>
								<div className={styles["row"]}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Nombre <span className={styles["blue"]}>*</span>
										</span>
										<Input
											style={{ width: "95%" }}
											className={styles["form-input"]}
											placeholder="Nombre"
											value={tradename}
											onChange={(e) => setTradeName(e?.target?.value)}
											onBlur={validateInput}
											status={errorStatus.tradename ? "error" : ""}
											maxLength={25}
										/>
										{errorStatus.tradename && (
											<div className={styles["position-relative"]}>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.tradename}
												</span>
											</div>
										)}
									</div>

									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<span className={styles["form-input-name"]}>
											Plan Matrix <span className={styles["blue"]}>*</span>
										</span>
										<Select
											className={styles["form-input"]}
											onChange={matrixPlanSelectHandler}
											onBlur={validateInput}
											value={matrixPlanID}
											bordered={false}
											placeholder="Seleccione"
											status={errorStatus.matrixPlanID ? "error" : ""}
										>
											{matrixPlansList.map((matrixPlan) => (
												<Select.Option
													key={matrixPlan.id}
													value={matrixPlan.id}
												>
													{matrixPlan.name}
												</Select.Option>
											))}
										</Select>
										{errorStatus.matrixPlanID && (
											<div className={styles["position-relative"]}>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.matrixPlanID}
												</span>
											</div>
										)}
									</div>
								</div>

								<Br />
								<Br />

								<div className={styles["row"]}>
									<div className={styles["column"]} style={{ width: "45%" }}>
										<div
											className={styles["validity-container"]}
											style={{ width: "92%" }}
										>
											<span className={styles["form-input-name"]}>
												Periodo de vigencia{" "}
												<span className={styles["blue"]}>*</span>
											</span>
											<Checkbox
												className={styles["checkbox"]}
												onChange={checkIndefinitePeriodHandler}
												checked={indefinitePeriod}
											>
												Indefinida
											</Checkbox>
										</div>
										<RangePicker
											className={styles["form-input"]}
											style={{
												width: "92%",
												backgroundColor: indefinitePeriod ? "#f5f5f5" : "white",
											}}
											format={dateFormat}
											placeholder={["Fecha Inicio", "Fecha Fin"]}
											onChange={dateSelectHandler}
											onBlur={validateInput}
											disabled={indefinitePeriod}
											defaultValue={indefinitePeriod ? null : dateRange}
											status={errorStatus.dateRange ? "error" : ""}
											allowClear={false}
										/>
										{errorStatus.dateRange && (
											<div className={styles["position-relative"]}>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.dateRange}
												</span>
											</div>
										)}
									</div>
									<div
										className={styles["column"]}
										style={{ width: "30%", marginTop: "auto" }}
									>
										<span className={styles["form-input-name"]}>
											Costo <span className={styles["blue"]}>*</span>
										</span>
										<InputNumber
											addonBefore={selectCostBefore}
											style={{
												width: "92%",
												borderRadius: "7px",
											}}
											placeholder="17800"
											value={price}
											onChange={(value) => setPrice(value)}
											onBlur={validateInput}
											status={errorStatus.price ? "error" : ""}
										/>
										{errorStatus.price && (
											<div className={styles["position-relative"]}>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.price}
												</span>
											</div>
										)}
									</div>
									<div
										className={styles["column"]}
										style={{ width: "25%", marginTop: "auto" }}
									>
										<span className={styles["form-input-name"]}>
											Instalación <span className={styles["blue"]}>*</span>
										</span>
										<InputNumber
											addonBefore={selectInstallationBefore}
											style={{
												borderRadius: "7px",
											}}
											placeholder="2000"
											value={installationPrice}
											onChange={(value) => setInstallationPrice(value)}
											onBlur={validateInput}
											status={errorStatus.installationPrice ? "error" : ""}
										/>
										{errorStatus.installationPrice && (
											<div className={styles["position-relative"]}>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.installationPrice}
												</span>
											</div>
										)}
									</div>
								</div>

								<Br />
								<Br />

								<div className={`${styles["row"]} ${styles["space-between"]}`}>
									<div className={`${styles["column"]} ${styles["flex"]}`}>
										<div
											className={`${styles["row"]} ${styles["space-between"]}`}
										>
											<span className={styles["form-input-name"]}>
												Descripción <span className={styles["blue"]}>*</span>
											</span>
											<CustomTooltip
												subtitle={
													"Información asociada al plan del operador que se desea mostrar en la aplicación."
												}
												position={"left"}
											/>
										</div>
										<TextArea
											rows={3}
											className={styles["form-textarea"]}
											value={description}
											onChange={(e) => setDescription(e?.target?.value)}
											maxLength={50}
											showCount
											onBlur={validateInput}
											status={errorStatus.description ? "error" : ""}
										/>
										{errorStatus.description && (
											<div className={styles["position-relative"]}>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.description}
												</span>
											</div>
										)}
									</div>
								</div>

								<Br />
								<Br />

								{miniatureLogo === null ? (
									<button
										className={styles["upload-image-box"]}
										onClick={() => saveMiniatureImage.current.click()}
									>
										<div
											className={`${styles["row"]} ${styles["justify-end"]}`}
											style={{ width: "100%" }}
										>
											<CustomTooltip
												subtitle={
													"Imagen miniatura del plan que aparecerá en el listado."
												}
												position={"left"}
											/>
										</div>
										<i
											className={`fa-sharp fa-solid fa-upload ${styles["upload-image-icon"]}`}
										></i>
										<span className={styles["logo-title"]}>
											Listado de planes de operador
										</span>
										<span className={styles["logo-text"]}>
											Subir máximo una imagen
										</span>
										<span className={styles["logo-text"]}>
											Miniatura tamaño max 104x104 px
										</span>
										<input
											type="file"
											ref={saveMiniatureImage}
											accept="image/png, image/jpeg"
											onChange={(e) =>
												transformMiniatureImageToBase64(e.target.files)
											}
											style={{ display: "none" }}
										/>
									</button>
								) : (
									<div className={styles["column"]}>
										{tempMiniatureLogo != null ? (
											<img
												className={`${styles["temporal-image"]} animate__animated animate__fadeIn`}
												src={`data:image/png;base64,${miniatureLogo}`}
												alt="Imagen seleccionada"
											/>
										) : (
											<img
												className={`${styles["temporal-image"]} animate__animated animate__fadeIn`}
												src={`${imageBaseURL + miniatureLogo}`}
												alt="Imagen seleccionada"
											/>
										)}

										<Br />

										<div
											className={`${styles["row"]} ${styles["space-between"]}`}
										>
											{tempMiniatureLogo != null ? (
												<p
													style={{ color: "grey" }}
													className="animate__animated animate__fadeIn"
												>
													{tempMiniatureLogo?.name}
												</p>
											) : (
												<p
													style={{ color: "grey" }}
													className="animate__animated animate__fadeIn"
												>
													{miniatureLogo.slice(7)}
												</p>
											)}

											<DeleteOutlined
												className={styles["clear-temporal-image-icon"]}
												onClick={clearTemporalMiniatureHandler}
											/>
										</div>
									</div>
								)}

								<Br />
								<Br />

								{detailImage === null ? (
									<button
										className={styles["upload-image-box"]}
										onClick={() => saveDetailImage.current.click()}
									>
										<div
											className={`${styles["row"]} ${styles["justify-end"]}`}
											style={{ width: "100%" }}
										>
											<CustomTooltip
												subtitle={
													"Información asociada al plan del operador que se desea mostrar en la aplicación."
												}
												position={"left"}
											/>
										</div>
										<i
											className={`fa-sharp fa-solid fa-upload ${styles["upload-image-icon"]}`}
										></i>
										<span className={styles["logo-title"]}>
											Detalle de plan de operador
										</span>
										<span className={styles["logo-text"]}>
											Subir máximo una imagen
										</span>
										<span className={styles["logo-text"]}>
											Imagen tamaño max 375x412 px
										</span>
										<input
											type="file"
											ref={saveDetailImage}
											accept="image/png, image/jpeg"
											onChange={(e) =>
												transformDetailImageToBase64(e.target.files)
											}
											style={{ display: "none" }}
										/>
									</button>
								) : (
									<div className={styles["column"]}>
										{tempDetailImage != null ? (
											<img
												className={`${styles["temporal-image"]} animate__animated animate__fadeIn`}
												src={`data:image/png;base64,${detailImage}`}
												alt="Imagen seleccionada"
											/>
										) : (
											<img
												className={`${styles["temporal-image"]} animate__animated animate__fadeIn`}
												src={`${imageBaseURL + detailImage}`}
												alt="Imagen seleccionada"
											/>
										)}

										<Br />

										<div
											className={`${styles["row"]} ${styles["space-between"]}`}
										>
											{tempDetailImage != null ? (
												<p
													style={{ color: "grey" }}
													className="animate__animated animate__fadeIn"
												>
													{tempDetailImage?.name}
												</p>
											) : (
												<p
													style={{ color: "grey" }}
													className="animate__animated animate__fadeIn"
												>
													{detailImage.slice(20)}
												</p>
											)}

											<DeleteOutlined
												className={styles["clear-temporal-image-icon"]}
												onClick={clearTemporalDetailImageHandler}
											/>
										</div>
									</div>
								)}

								<Br />
								<Br />

								<span className={styles["form-section-title"]}>
									Datos técnicos del plan
								</span>
								<div className={styles["row"]}>
									<div className={styles["column"]} style={{ width: "70%" }}>
										<span className={styles["form-input-name"]}>
											Tipo <span className={styles["blue"]}>*</span>
										</span>
										<div className={styles["row"]}>
											<div
												className={styles["column"]}
												style={{ width: "25%" }}
											>
												<Select
													className={styles["form-input"]}
													onChange={planTypeSelectHandler}
													onBlur={validateInput}
													value={planType}
													bordered={false}
													placeholder="Seleccione"
													status={errorStatus.planType ? "error" : ""}
												>
													<Select.Option key={1} value={1}>
														Simple
													</Select.Option>
													<Select.Option key={2} value={2}>
														Duo
													</Select.Option>
													<Select.Option key={3} value={3}>
														Trio
													</Select.Option>
												</Select>
												{errorStatus.planType && (
													<div className={styles["position-relative"]}>
														<span
															className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
														>
															{errors.planType}
														</span>
													</div>
												)}
											</div>
											<div
												className={styles["column"]}
												style={{ width: "66%", marginLeft: "4%" }}
											>
												<Select
													mode="multiple"
													className={styles["form-input"]}
													placeholder="Seleccione"
													dropdownStyle={{ borderRadius: 7 }}
													bordered={false}
													maxTagCount="responsive"
													onChange={(value) => {
														if (validateOptions(value, 3)) {
															planCategorySelectHandler(value);
														}
													}}
													onBlur={validateInput}
													value={category}
													status={errorStatus.category ? "error" : ""}
												>
													{kindPlan?.map((item) => (
														<Select.Option key={item?.ID} value={item?.ID}>
															{item?.name}
														</Select.Option>
													))}
												</Select>
												{errorStatus.category && (
													<div className={styles["position-relative"]}>
														<span
															className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
														>
															{errors.category}
														</span>
													</div>
												)}
											</div>
										</div>
									</div>
									<div className={styles["column"]} style={{ width: "30%" }}>
										<span className={styles["form-input-name"]}>
											Tecnología <span className={styles["blue"]}>*</span>
										</span>
										<Select
											className={styles["form-input"]}
											bordered={false}
											onChange={technologySelectHandler}
											onBlur={validateInput}
											value={technology}
											placeholder="Seleccione"
											status={errorStatus.technology ? "error" : ""}
										>
											{technologyType?.map((item) => (
												<Select.Option key={item?.ID} value={item?.ID}>
													{item?.name}
												</Select.Option>
											))}
										</Select>
										{errorStatus.technology && (
											<div className={styles["position-relative"]}>
												<span
													className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
												>
													{errors.technology}
												</span>
											</div>
										)}
									</div>
								</div>

								<div className={styles["row"]}>
									{/* ----------------INTERNET---------------- */}
									{internet && (
										<div
											className={`${styles["column"]} ${styles["flex"]}`}
											style={{ marginTop: "auto" }}
										>
											<Br />
											<Br />
											<span className={styles["form-section-title"]}>
												Características técnicas Internet
											</span>
											<div className={styles["row"]}>
												<div
													className={`${styles["column"]} ${styles["flex"]}`}
												>
													<span className={styles["form-input-name"]}>
														MB de subida{" "}
														<span className={styles["blue"]}>*</span>
													</span>
													<Input
														className={styles["form-input"]}
														style={{
															width: television ? "90%" : "95%",
														}}
														placeholder="500"
														value={megasUpload}
														onChange={(e) => setMegasUpload(e?.target?.value)}
														onBlur={validateInput}
														status={errorStatus.megasUpload ? "error" : ""}
														maxLength={25}
													/>
													{errorStatus.megasUpload && (
														<div className={styles["position-relative"]}>
															<span
																className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
															>
																{errors.megasUpload}
															</span>
														</div>
													)}
												</div>
												<div
													className={`${styles["column"]} ${styles["flex"]}`}
												>
													<span className={styles["form-input-name"]}>
														MB de bajada{" "}
														<span className={styles["blue"]}>*</span>
													</span>
													<Input
														className={styles["form-input"]}
														style={{
															width: television ? "90%" : "100%",
														}}
														placeholder="500"
														value={megasDownload}
														onChange={(e) => setMegasDownload(e?.target?.value)}
														onBlur={validateInput}
														status={errorStatus.megasDownload ? "error" : ""}
														maxLength={25}
													/>
													{errorStatus.megasDownload && (
														<div className={styles["position-relative"]}>
															<span
																className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
															>
																{errors.megasDownload}
															</span>
														</div>
													)}
												</div>
											</div>
										</div>
									)}

									{/* ----------------TELEVISIÓN---------------- */}
									{television && (
										<div className={`${styles["column"]} ${styles["flex"]}`}>
											<Br />
											<Br />
											<span className={styles["form-section-title"]}>
												Características técnicas Televisión
											</span>
											<div className={styles["row"]}>
												<div
													className={styles["column"]}
													style={{ width: "50%" }}
												>
													<span className={styles["form-input-name"]}>
														Canales <span className={styles["blue"]}>*</span>
													</span>
													<Select
														className={styles["form-input"]}
														style={{
															width: internet ? "90%" : "95%",
														}}
														bordered={false}
														placeholder="Seleccione"
														value={channelsOptions}
														onChange={channelsOptionsHandler}
														onBlur={validateInput}
														status={errorStatus.channelsOptions ? "error" : ""}
													>
														<Select.Option key={2} value={2}>
															Total
														</Select.Option>
														<Select.Option key={1} value={1}>
															Detallar calidad
														</Select.Option>
													</Select>
													{errorStatus.channelsOptions && (
														<div className={styles["position-relative"]}>
															<span
																className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
															>
																{errors.channelsOptions}
															</span>
														</div>
													)}
													{errorStatus.numberChannels && (
														<div className={styles["position-relative"]}>
															<span
																className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
															>
																{errors.numberChannels}
															</span>
														</div>
													)}
													{errorStatus.extraChannelsHD && (
														<div className={styles["position-relative"]}>
															<span
																className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
															>
																{errors.extraChannelsHD}
															</span>
														</div>
													)}
												</div>
												{channelsOptions === undefined ||
												channelsOptions === 2 ? (
													<div
														className={styles["column"]}
														style={{ width: "50%" }}
													>
														<span className={styles["form-input-name"]}>
															Cantidad <span className={styles["blue"]}>*</span>
														</span>
														<Input
															className={styles["form-input"]}
															placeholder="60"
															value={numberChannels}
															onChange={(e) =>
																setNumberChannels(e?.target?.value)
															}
															onBlur={validateInput}
															status={errorStatus.numberChannels ? "error" : ""}
															maxLength={25}
														/>
													</div>
												) : (
													<div
														className={styles["row"]}
														style={{ width: "50%" }}
													>
														<div
															className={styles["column"]}
															style={{ width: "50%" }}
														>
															<span className={styles["form-input-name"]}>
																SD <span className={styles["blue"]}>*</span>
															</span>
															<Input
																className={styles["form-input"]}
																style={{
																	width: "90%",
																}}
																placeholder="60"
																value={numberChannels}
																onChange={(e) =>
																	setNumberChannels(e?.target?.value)
																}
																onBlur={validateInput}
																status={
																	errorStatus.numberChannels ? "error" : ""
																}
																maxLength={25}
															/>
														</div>
														<div
															className={styles["column"]}
															style={{ width: "50%" }}
														>
															<span className={styles["form-input-name"]}>
																HD <span className={styles["blue"]}>*</span>
															</span>
															<Input
																className={styles["form-input"]}
																placeholder="10"
																value={extraChannelsHD}
																onChange={(e) =>
																	setExtraChannelsHD(e?.target?.value)
																}
																onBlur={validateInput}
																status={
																	errorStatus.extraChannelsHD ? "error" : ""
																}
																maxLength={25}
															/>
														</div>
													</div>
												)}
											</div>
										</div>
									)}
								</div>

								<Br />
								<Br />

								{/* ----------------TELEFONÍA---------------- */}
								{telephone && (
									<span className={styles["form-section-title"]}>
										Características técnicas Telefonía{" "}
									</span>
								)}
								<div className={styles["row"]}>
									{telephone && (
										<div className={`${styles["column"]} ${styles["flex"]}`}>
											<div
												className={styles["phone-minutes-container"]}
												style={{ width: "95%" }}
											>
												<span className={styles["form-input-name"]}>
													Minutos llamadas locales{" "}
													<span className={styles["blue"]}>*</span>
												</span>
												<Checkbox
													className={styles["checkbox"]}
													onChange={checkUnlimitedMinutesHandler}
													checked={unlimitedMinutes}
												>
													Ilimitado
												</Checkbox>
											</div>
											<Input
												className={styles["form-input"]}
												style={{
													width: "95%",
													backgroundColor: unlimitedMinutes
														? "#f5f5f5"
														: "white",
												}}
												placeholder="500"
												value={phoneMinutes}
												onChange={(e) => setPhoneMinutes(e?.target?.value)}
												onBlur={validateInput}
												status={
													errorStatus.phoneMinutes && !unlimitedMinutes
														? "error"
														: ""
												}
												disabled={unlimitedMinutes}
												maxLength={25}
											/>
											{errorStatus.phoneMinutes && (
												<div className={styles["position-relative"]}>
													<span
														className={`animate__animated animate__fadeInDown ${styles["error-message"]}`}
													>
														{errors.phoneMinutes}
													</span>
												</div>
											)}
										</div>
									)}
									<div
										className={`${styles["column"]} ${styles["flex"]}`}
										style={{ marginTop: "auto" }}
									>
										<span className={styles["form-input-name"]}>Estatus</span>
										<Select
											className={styles["form-input"]}
											bordered={false}
											style={{ width: "100%" }}
											onChange={statusSelectHandler}
											value={status}
										>
											<Select.Option key={1} value={1}>
												Activo
											</Select.Option>
											<Select.Option key={2} value={2}>
												Inactivo
											</Select.Option>
										</Select>
									</div>
								</div>

								<Br />
								<Br />

								<div className={`${styles["row"]} ${styles["justify-end"]}`}>
									<Space>
										<Button
											className={`${styles["form-action-button"]} ${styles["form-action-button-cancel"]}`}
											onClick={() => {
												setVisible(false);
												cancel();
											}}
										>
											Cancelar
										</Button>
										{modalHeader === "Modificar" ? (
											<Button
												className={`${styles["form-action-button"]} ${styles["form-action-button-save"]}`}
												onClick={() => verifyStatus()}
											>
												Confirmar
											</Button>
										) : (
											<Button
												className={`${styles["form-action-button"]} ${styles["form-action-button-save"]}`}
												onClick={() => {
													onSubmit(
														{
															tradename: tradename,
															external_plan_id: matrixPlanID,
															start_date: dayjs(startDate, "YYYY-MM-DD").format(
																"DD-MM-YYYY"
															),
															end_date: dayjs(endDate, "YYYY-MM-DD").format(
																"DD-MM-YYYY"
															),
															currency: currency,
															price: price,
															installation_price: installationPrice,
															description: description,
															category: category,
															type_plan: planType,
															technology: technology,
															megas_upload: megasUpload,
															megas_download: megasDownload,
															number_channels: numberChannels,
															extra_channels_hd: extraChannelsHD,
															phone_minutes: phoneMinutes,
															status: status,
															operator: operatorID,
															image_miniature_base64: miniatureLogoBase64,
															photo_base64: detailImageBase64,
															indefinite_period: indefinitePeriod,
															unlimited_minutes: unlimitedMinutes,
															options_channels: channelsOptions,
														},
														data.plan_id
													);
												}}
											>
												Guardar
											</Button>
										)}
									</Space>
								</div>
							</>
						)}
					</div>
				)}

				{/* Modal Security */}
				{visibleVerify && (
					<div className={styles["authentication-modal-container"]}>
						<AuthenticationGoggle
							visible={visibleVerify}
							setVisible={setVisibleVerify}
							confirmResponse={confirmResponse}
							cancel={() => {
								cancel();
								setVisible(false);
							}}
						/>
					</div>
				)}
			</Container>
		);
	} else {
		return null;
	}
};

export default FormOperatorPlans;

const Container = styled.div`
	background: hsla(0, 0%, 0%, 0.5);
	width: 42%;

	.ant-select-selector {
		font-size: 12px;
	}

	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		padding: 0;
	}

	.ant-select-multiple .ant-select-selection-item-remove {
		display: flex;
	}

	.ant-picker .ant-picker-input >input {
		font-size: 12px;
	}

	.ant-input-number-group-wrapper {
		margin-top: 0.6em;
	}

	.ant-input-number-group-addon {
		height: 40px;
		border-radius: 5px 0 0 5px;
	}

	.ant-input-number {
		height: 40px;
		padding: 4px 0;
		border-radius 0 5px 5px 0;
		font-size: 12px;
	}

	@media screen and (min-width: 1280px) {
		.ant-select-selector, 
		.ant-picker .ant-picker-input >input, 
		.ant-input-number {
			font-size: 12px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-select-selector, 
		.ant-picker .ant-picker-input >input, 
		.ant-input-number {
			font-size: 13.5px;
		}
	}

	@media screen and (min-width: 1920px) {
		.ant-select-selector, 
		.ant-picker .ant-picker-input >input, 
		.ant-input-number {
			font-size: 15.18px;
		}
	}
`;
