import React from "react";
import ReactDOM from "react-dom";
import App from "./app.jsx";
import store from "./store/index.js";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";

// css
// import "antd/dist/antd.css";
// import "antd/dist/antd.less";
import "antd/dist/reset.css";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "./index.css";
import "./scss/react.scss";
import "./scss/custom.scss";
import "./css/Popconfirm.css";
import "./css/ColorPicker.css";
import "axios-progress-bar/dist/nprogress.css";

// ========================================

import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

import { ConfigProvider } from "antd";
import es_ES from "antd/locale/es_ES";

import dayjs from "dayjs";
import "dayjs/locale/es";

dayjs.locale("es");

const persistor = persistStore(store);

//ReactDOM.render(
//	<Provider store={store}>
//		<PersistGate persistor={persistor}>
//			<BrowserRouter>
//				<App />
//			</BrowserRouter>
//		</PersistGate>
//	</Provider>,
//	document.getElementById("root")
//);

createRoot(document.getElementById("root")).render(
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<BrowserRouter>
				<ConfigProvider locale={es_ES}>
					<App />
				</ConfigProvider>
			</BrowserRouter>
		</PersistGate>
	</Provider>
);
