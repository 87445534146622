import React from "react";
import ReactDOM from "react-dom";
import styles from "./Backdrop.module.css";

const portalElement = document.getElementById("backdrop");

const Backdrop = ({ onClose }) => {
	return (
		<>
			{ReactDOM.createPortal(
				<div className={styles["backdrop"]} onClick={onClose}></div>,
				portalElement
			)}
		</>
	);
};

export default Backdrop;
