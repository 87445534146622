import React, { useState, useEffect } from "react";
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import styled from "styled-components";
import {
	MapContainer,
	TileLayer,
	Polygon,
	ZoomControl,
	useMap,
} from "react-leaflet";
import { myFetchGet } from "../../services/services";
import { Select } from "antd";
import { useSelector } from "react-redux";
import { useReduxToken } from "../../hooks/useReduxToken";
import CustomTooltip from "../../components/customTooltip/CustomTooltip";
import styles from "./ConsCoverage.module.css";

const ConsCoverage = () => {
	const token = useReduxToken();
	const dataState = useSelector((state) => state.authState);

	const [consCoverage, setConsCoverage] = useState([]);

	const [center, setCenter] = useState(
		dataState.operatorCountryCoordinates
			? {
					lat: dataState.operatorCountryCoordinates.latitude,
					lng: dataState.operatorCountryCoordinates.longitude,
			  }
			: { lat: -33.4377756, lng: -70.6504502 }
	);
	const [zoom, setZoom] = useState(5);
	const [countryFilter, setCountryFilter] = useState();
	const [countryID, setCountryID] = useState();
	const [regionFilter, setRegionFilter] = useState();
	const [regionID, setRegionID] = useState();
	const [cityFilter, setCityFilter] = useState();
	const [cityID, setCityID] = useState();
	const [operators, setOperators] = useState();
	const [operatorID, setOperatorID] = useState(
		dataState.rol === "administrador_isp" ||
			dataState.rol === "supervisor_comercial" ||
			dataState.rol === "vendedor"
			? dataState.operatorID
			: undefined
	);

	// const [showModal, setShowModal] = useState(false);
	// const [selectedPolygonCenter, setSelectedPolygonCenter] = useState(null);
	// const [polygonAddresses, setPolygonAddresses] = useState([]);

	/*function LocationMarker() {
		const [position, setPosition] = useState(null);
		const map = useMapEvents({
			click() {
				map.locate();
			},
			locationfound(e) {
				setPosition(e.latlng);
				map.flyTo(e.latlng, map.getZoom());
			},
		});

		return position === null ? null : (
			<Marker position={position}>
				<Popup>Estás aquí</Popup>
			</Marker>
		);
	}*/

	const initial = async () => {
		if (operatorID !== undefined) {
			try {
				const response = await myFetchGet(
					`api/v1/coordinates/?operator=${operatorID}`,
					token
				);
				const responseFilter = response
					.filter((obj) => !obj.deleted)
					.filter((obj) => obj.location.type !== "Point");
				const latLon = responseFilter.map((e) => ({
					name: e.name,
					coordinates: e.location.coordinates[0].map((e) => [e[1], e[0]]),
					color: e.color,
					key: e.ID,
					// addresses: e.address_json,
					// center: e.coverage_center,
				}));
				setConsCoverage(latLon);
			} catch (e) {
				console.log("error", e);
			}
		} else {
			try {
				const response = await myFetchGet("api/v1/coordinates/", token);
				const responseFilter = response
					.filter((obj) => !obj.deleted)
					.filter((obj) => obj.location.type !== "Point");
				const latLon = responseFilter.map((e) => ({
					name: e.name,
					coordinates: e.location.coordinates[0].map((e) => [e[1], e[0]]),
					color: e.color,
					key: e.ID,
					// addresses: e.address_json,
					// center: e.coverage_center,
				}));
				setConsCoverage(latLon);
			} catch (e) {
				console.log("error", e);
			}
		}
	};

	const getOperators = async () => {
		try {
			const operators = await myFetchGet("api/v1/operador/", token);
			setOperators(operators);
		} catch (e) {
			console.log("error", e);
		}
	};

	const getOperatorCountryName = async (countryID) => {
		try {
			const operatorCountry = await myFetchGet(
				`api/v1/country/${countryID}`,
				token
			);
			getCountries(operatorCountry.name);
		} catch (e) {
			console.log("error", e);
		}
	};

	const getCountries = async (countryName) => {
		try {
			const country = await myFetchGet(
				`api/v1/filter-coverage/?type=país`,
				token
			);
			if (
				dataState.rol === "administrador_isp" ||
				dataState.rol === "supervisor_comercial" ||
				dataState.rol === "vendedor"
			) {
				const countryFiltered = country.filter(
					(obj) => obj.name === countryName
				);
				selectCountryHandler(countryFiltered[0].ID, {
					coordinates: countryFiltered[0].cordinates,
				});
			} else {
				setCountryFilter(country);
			}
		} catch (e) {
			console.log("error", e);
		}
	};

	const getRegion = async () => {
		try {
			const region = await myFetchGet(
				`api/v1/filter-coverage/?parent=${countryID}`,
				token
			);
			setRegionFilter(region);
		} catch (e) {
			console.log("error", e);
		}
	};

	const getCity = async () => {
		try {
			const city = await myFetchGet(
				`api/v1/filter-coverage/?parent=${regionID}`,
				token
			);
			setCityFilter(city);
		} catch (e) {
			console.log("error", e);
		}
	};

	useEffect(() => {
		initial();
	}, [operatorID]);

	useEffect(() => {
		if (
			dataState.rol !== "administrador_isp" &&
			dataState.rol !== "supervisor_comercial" &&
			dataState.rol !== "vendedor"
		) {
			getOperators();
			getCountries();
		}

		if (
			dataState.rol === "administrador_isp" ||
			dataState.rol === "supervisor_comercial" ||
			dataState.rol === "vendedor"
		) {
			getOperatorCountryName(dataState.operatorCountryID);
		}
	}, []);

	useEffect(() => {
		if (countryID !== undefined) {
			getRegion();
		}
	}, [countryID]);

	useEffect(() => {
		if (regionID !== undefined) {
			getCity();
		}
	}, [regionID]);

	const selectCountryHandler = (value, coordinates) => {
		if (value !== undefined) {
			let newCoordinatesCountry = Object.values(coordinates?.coordinates);
			setCenter({
				lat: newCoordinatesCountry[0],
				lng: newCoordinatesCountry[1],
			});
			setCountryID(value);
		} else {
			setCenter({ lat: -33.4377756, lng: -70.6504502 });
			setRegionID(null);
			setCityID(null);
		}
	};

	const selectRegionHandler = (value, coordinates) => {
		if (value !== undefined) {
			let newCoordinatesRegion = Object.values(coordinates?.coordinates);
			setCenter({ lat: newCoordinatesRegion[0], lng: newCoordinatesRegion[1] });
			setZoom(12);
			setRegionID(value);
		} else {
			setCenter({ lat: -33.4377756, lng: -70.6504502 });
			setRegionID(null);
			setCityID(null);
			setZoom(5);
		}
	};

	const selectCityHandler = (value, coordinates) => {
		if (value !== undefined) {
			let newCoordinatesCity = Object.values(coordinates?.coordinates);
			setCenter({ lat: newCoordinatesCity[0], lng: newCoordinatesCity[1] });
			setZoom(14);
			setCityID(value);
		} else {
			setZoom(12);
			setCityID(null);
		}
	};

	const handleOperatorSelect = (value) => {
		setOperatorID(value);
	};

	//const multipolygon = latlon.map((e) => [e.coordinates]);

	//const purpleOptions = { color: `rgb(${Math.random() * 256},${Math.random() * 256},${Math.random() * 256})` };

	const SetMapOnClick = ({ coords, zoom }) => {
		const map = useMap();
		map.setView(coords, zoom);

		return null;
	};

	// const handlePolygonClick = (e) => {
	// 	setSelectedPolygonCenter(e.target.options.center);
	// 	let newCoordinates = Object.values(e.target.options.center);
	// 	setCenter({
	// 		lat: newCoordinates[0],
	// 		lng: newCoordinates[1],
	// 	});
	// 	setPolygonAddresses(e.target.options.addresses);
	// 	setShowModal(true);
	// };

	// const handleCloseModal = () => {
	// 	setShowModal(false);
	// 	let polygonCenter = Object.values(selectedPolygonCenter);
	// 	setCenter({
	// 		lat: polygonCenter[0],
	// 		lng: polygonCenter[1],
	// 	});
	// };

	// const onAddressClick = (e) => {
	// 	let moveCoordinates = Object.values(e);
	// 	setCenter({
	// 		lat: moveCoordinates[0],
	// 		lng: moveCoordinates[1],
	// 	});
	// };

	return (
		<div>
			<div className="col-md-12">
				<Panel>
					<PanelHeader>
						<div className={`${styles["row"]} ${styles["align-center"]}`}>
							<span className={styles["header-title"]}>
								Cobertura consolidada
							</span>
							<CustomTooltip
								subtitle={
									"Zonas de cobertura de todos los operadores adscritos al sistema."
								}
							/>
							<span className={styles["header-breadcrumb"]}>
								Operadores | Mapa de cobertura
							</span>
						</div>
					</PanelHeader>
					<PanelBody className={"panel__body__cons__coverage"}>
						<FilterContainer>
							<div className={styles["row"]}>
								{dataState.rol === "administrador_isp" ||
								dataState.rol === "supervisor_comercial" ||
								dataState.rol === "vendedor" ? null : (
									<div className={styles["column"]}>
										<Select
											className={styles["select"]}
											placeholder="Operador"
											onChange={handleOperatorSelect}
											allowClear={true}
										>
											{operators?.map((operator) => (
												<Select.Option key={operator?.ID} value={operator?.ID}>
													{operator?.name}
												</Select.Option>
											))}
										</Select>
									</div>
								)}
								{dataState.rol === "administrador_isp" ||
								dataState.rol === "supervisor_comercial" ||
								dataState.rol === "vendedor" ? null : (
									<div className={styles["column"]}>
										<Select
											className={styles["select"]}
											placeholder="País"
											onChange={(value, coordinates) =>
												selectCountryHandler(value, coordinates)
											}
											allowClear={true}
										>
											{countryFilter?.map((country) => (
												<Select.Option
													key={country?.ID}
													value={country?.ID}
													coordinates={country?.cordinates}
												>
													{country?.name}
												</Select.Option>
											))}
										</Select>
									</div>
								)}
								<div className={styles["column"]}>
									<Select
										className={styles["select"]}
										placeholder="Región"
										onChange={(value, coordinates) =>
											selectRegionHandler(value, coordinates)
										}
										value={regionID}
										allowClear={true}
									>
										{regionFilter?.map((region) => (
											<Select.Option
												key={region?.ID}
												value={region?.ID}
												coordinates={region?.cordinates}
											>
												{region?.name}
											</Select.Option>
										))}
									</Select>
								</div>
								<div className={styles["column"]}>
									<Select
										className={styles["select"]}
										placeholder="Ciudad"
										onChange={(value, coordinates) =>
											selectCityHandler(value, coordinates)
										}
										value={cityID}
										allowClear={true}
									>
										{cityFilter?.map((city) => (
											<Select.Option
												key={city?.ID}
												value={city?.ID}
												coordinates={city?.cordinates}
											>
												{city?.name}
											</Select.Option>
										))}
									</Select>
								</div>
							</div>
						</FilterContainer>
						<MapContainer
							center={center}
							zoom={zoom}
							//scrollWheelZoom={false}
							zoomControl={false}
							style={{
								width: "100%",
								height: "70vh",
								position: "relative",
								zIndex: "1",
								top: "-50px",
								marginBottom: "-30px",
							}}
						>
							<TileLayer
								attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							/>
							<ZoomControl position="bottomright" />
							{/*<LocationMarker />*/}
							{consCoverage.map((e) => (
								<Polygon
									pathOptions={{
										color: e.color,
									}}
									key={e.ID}
									positions={e.coordinates}
									// eventHandlers={{ click: handlePolygonClick }}
									// addresses={e.addresses}
									// center={e.center}
								/>
							))}
							<SetMapOnClick coords={center} zoom={zoom} />
							{/* {showModal && (
								<ModalContainer>
									<span className="title">Direcciones que abarca la zona</span>
									{polygonAddresses.map((e) => (
										<div className="row space-between">
											<span className="modal_content">{e.description}</span>
											<Button
												className="address_icon"
												type="link"
												onClick={() => onAddressClick(e.coordinates)}
											>
												<i className="fa-solid fa-arrow-right"></i>
											</Button>
										</div>
									))}
									<Divider style={{ margin: "10px 0" }} />
									<Button className="btn_modal" onClick={handleCloseModal}>
										Cerrar
									</Button>
								</ModalContainer>
							)} */}
						</MapContainer>
					</PanelBody>
				</Panel>
			</div>
		</div>
	);
};

export default ConsCoverage;

const FilterContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: auto;
	margin-left: 20px;
	position: relative;
	z-index: 2;

	.ant-select-selector {
		border-radius: 5px !important;
		border: 1px solid #e0e0e0 !important;
		display: flex;
		align-items: center;
		height: 38px !important;
		font-size: 12px;
	}

	.ant-select-clear {
		display: flex;
		align-items: center;
	}

	.ant-picker .ant-picker-input > input {
		font-size: 12px;
	}

	@media screen and (min-width: 1280px) {
		.ant-select-selector,
		.ant-picker .ant-picker-input > input {
			font-size: 12px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-select-selector,
		.ant-picker .ant-picker-input > input {
			font-size: 13.5px;
		}
	}

	@media screen and (min-width: 1920px) {
		.ant-select-selector,
		.ant-picker .ant-picker-input > input {
			font-size: 15.18px;
		}
	}
`;

const ModalContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	padding: 20px;
	border-radius: 3px;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	border: 1px solid #e0e0e0;
	width: 300px;

	.row {
		margin: 0 !important;
		display: flex;
		word-break: break-word;
	}

	.space-between {
		justify-content: space-between;
	}

	.title {
		color: #868e96;
		font-size: 14px;
		margin-bottom: 15px;
	}

	.modal_content {
		color: #131523;
		font-size: 12px;
		margin: 5px 0 5px 0;
		width: 90%;
	}

	.address_icon {
		margin-left: 10px;
		color: #426da9;
		border: none;
		padding: 0;
		outline: 0 !important;

		:hover {
			color: #426da9;
			border: none;
			outline: 0 !important;
		}
	}

	.btn_modal {
		background-color: #dde4eb;
		color: #001737;
		font-size: 12px;
		height: 25px;
		align-self: end;
		border-radius: 3px;
		display: flex;
		align-items: center;

		:hover {
			background-color: #dde4eb;
			color: #001737;
			border: none;
		}
	}
`;
