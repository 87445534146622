import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { myFetchGet } from "../../../services/services";
import { Timeline, notification } from "antd";
import { useReduxToken } from "../../../hooks/useReduxToken";
import { LoadingHistory } from "../../../components/loading/Loading";
import styles from "./Compass.module.css";
import styled from "styled-components";
import Br from "../../../components/Br/Br";

const Compass = ({ data }) => {
	const token = useReduxToken();
	const [loading, setLoading] = useState(true);

	dayjs.extend(localizedFormat);

	const { id_unique } = data;

	const [changes, setChanges] = useState([]);
	const [saleStatus, setSaleStatus] = useState();
	const [schedulingDate, setSchedulingDate] = useState();
	const [technicianName, setTechnicianName] = useState();

	const initial = async () => {
		try {
			const sale = await myFetchGet(`api/v1/sales/${id_unique}/`, token);
			const bruj_sale = await myFetchGet(
				`api/v1/brujula-history/${sale?.brujula_id}/`,
				token
			);
			setChanges(bruj_sale?.changes.map((e) => e[0]));
			setSaleStatus(sale?.status);
			setSchedulingDate(sale?.scheduling_date);
			setTechnicianName(sale?.technician_name);

			setLoading(false);
		} catch (e) {
			console.log("error", e);
			notification.error({
				className: "notification",
				message:
					"Se ha producido un error inesperado, por favor intente nuevamente",
				placement: "bottomLeft",
				duration: 2.5,
			});
		}
	};

	useEffect(() => {
		initial();
	}, []);

	return (
		<Container>
			<div className={`${styles["row"]} ${styles["space-between"]}`}>
				<div
					className={`${styles["column"]} ${styles["justify-center"]}`}
					style={{ width: "70%" }}
				>
					<span className={styles["section-title"]}>
						Información del agendamiento
					</span>
					<Br />
					<span className={styles["title"]}>
						Linea de tiempo de los cambios de status de la orden de servicio.
					</span>
				</div>

				<div
					className={`${styles["column"]} ${styles["align-center"]}`}
					style={{ width: "28%" }}
				>
					{saleStatus !== 1 ? (
						<span className={styles["title"]}>
							<b>Instalación:</b>{" "}
							{dayjs(schedulingDate, "DD-MM-YYYY").locale("es").format("LL")}
						</span>
					) : null}
					<Br />
					{saleStatus !== 1 ? (
						<span className={styles["title"]}>
							<b>Técnico asignado:</b> {technicianName}
						</span>
					) : null}
				</div>
			</div>

			<Br />
			<Br />

			{loading ? (
				<LoadingHistory />
			) : (
				<Timeline mode="left">
					{changes.map((e) => (
						<Timeline.Item label={e?.date}>{e?.field}</Timeline.Item>
					))}
				</Timeline>
			)}
		</Container>
	);
};

export default Compass;

const Container = styled.div`
	background-color: white;
	display: flex;
	flex-direction: column;
	padding: 0 31px 0 27px;

	.ant-timeline .ant-timeline-item {
		padding-bottom: 35px;
	}

	.ant-timeline.ant-timeline-label .ant-timeline-item-label {
		width: 21%;
		text-align: left;
		padding-left: 60px;
		font-size: 12px;
		color: #131523;
		font-weight: bold;
	}

	.ant-timeline.ant-timeline-label .ant-timeline-item-head {
		margin-left: -5px;
		left: 22%;
		width: 12px;
		height: 12px;
		border: 2px solid;
	}

	.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
		left: 22%;
		border-left: 2px solid #2b80ff;
	}

	.ant-timeline.ant-timeline-label
		.ant-timeline-item-left
		.ant-timeline-item-content {
		left: 21%;
		width: 60%;
		font-size: 12px;
		color: #131523;
	}

	@media screen and (min-width: 1280px) {
		.ant-timeline.ant-timeline-label .ant-timeline-item-label {
			width: 21%;
			font-size: 12px;
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-head {
			left: 22%;
			width: 12px;
			height: 12px;
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
			left: 22%;
		}

		.ant-timeline.ant-timeline-label
			.ant-timeline-item-left
			.ant-timeline-item-content {
			left: 21%;
			font-size: 12px;
		}
	}

	@media screen and (min-width: 1366px) {
		.ant-timeline.ant-timeline-label .ant-timeline-item-label {
			width: 21%;
			font-size: 13.5px;
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-head {
			left: 22%;
			width: 13.5px;
			height: 13.5px;
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
			left: 22%;
		}

		.ant-timeline.ant-timeline-label
			.ant-timeline-item-left
			.ant-timeline-item-content {
			left: 21%;
			font-size: 13.5px;
		}
	}

	@media screen and (min-width: 1920px) {
		.ant-timeline.ant-timeline-label .ant-timeline-item-label {
			width: 17%;
			font-size: 15.18px;
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-head {
			left: 16%;
			width: 15.18px;
			height: 15.18px;
		}

		.ant-timeline.ant-timeline-label .ant-timeline-item-tail {
			left: 16.2%;
		}

		.ant-timeline.ant-timeline-label
			.ant-timeline-item-left
			.ant-timeline-item-content {
			left: 16%;
			font-size: 15.18px;
		}
	}
`;
