import React from "react";
import styled from "styled-components";

import Sidebar from "../components/sidebar/sidebar";
import Header from "../components/header/header";
import Content from "../components/content/content";

const Layout = () => {
	return (
		<LayoutContainer>
			<div className="left__column">
				<Sidebar />
			</div>
			<div className="right__column">
				<Header />
				<Content></Content>
			</div>
		</LayoutContainer>
	);
};

export default Layout;

const LayoutContainer = styled.div`
	min-height: 100%;
	max-width: 100vw;
	display: flex;
	box-sizing: border-box;
	flex: 1;

	.left__column {
		width: 19%;
		//height: 100%;
		box-sizing: border-box;
	}
	.right__column {
		height: 100vh;
		display: flex;
		flex-flow: column;
		box-sizing: border-box;
		flex: 1;
	}
`;
