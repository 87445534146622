import axios from "axios";
import { loadProgressBar } from "axios-progress-bar";

export const urlBase = process.env.REACT_APP_URL_BASE;

loadProgressBar({ showSpinner: false });

export async function signIn(email, password) {
	const url = urlBase + "api/v1/singin";

	const token = await axios({
		url: url,
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
		},
		method: "POST",
		data: {
			email: email,
			password: password,
		},
	});
	return token?.data;
}

export async function myFetch(endpoint, token, payload, method = "POST") {
	let url = urlBase + endpoint;
	const result = await axios({
		url: url,
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: `token ${token}`,
		},
		method: method,
		data: {
			...payload,
		},
	});

	return result?.data;
}

export async function myFetchGet(endpoint, token) {
	let url = urlBase + endpoint;
	const result = await axios({
		url: url,
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: `token ${token}`,
		},
		method: "GET",
	});

	return result?.data;
}

export async function myExportData(endpoint, token, payload, method = "POST") {
	let url = urlBase + endpoint;
	const result = await axios({
		url: url,
		headers: {
			"Content-Type": "application/json",
			Accept: "*/*",
			Authorization: `token ${token}`,
		},
		method: method,
		responseType: "blob",
		data: {
			...payload,
		},
	});

	return result?.data;
}
