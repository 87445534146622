import React from "react";
import { Table } from "antd";
import styled from "styled-components";
import { largeSizes, mediumSizes, smallSizes } from "../../helpers/sizes";
import devices from "../../helpers/sizes";

export default function TableData({ rows, columns, onChange }) {
	return (
		<ContainerTable>
			<Table
				className="table"
				columns={columns}
				dataSource={rows}
				pagination={false}
				onChange={onChange}
			/>
		</ContainerTable>
	);
}

const ContainerTable = styled.div`
	.table {
		text-align: center;
		margin: 2% 0;
		font-family: Lato !important;

		.ant-table-wrapper .ant-table {
			font-family: Lato !important;
		}

		.ant-table-thead tr th:first-child,
		.ant-table-tbody tr td:first-child {
			padding-left: 42px !important;
		}

		.ant-table-thead tr th:last-child,
		.ant-table-tbody tr td:last-child {
			padding-right: 42px !important;
		}

		tr:nth-child(2n) td {
			background-color: #f7fafc;
		}

		tr th,
		tr td {
			text-align: center;
			border: none;
			outline: none;
			font-family: Lato !important;
		}

		tr th {
			background-color: #f5f6fa;
			color: #8898aa;
			border-radius: 0px !important;
			font-size: ${smallSizes.tableData.tableHead};
			padding: 10px 16px 13px 16px;
			font-weight: 400;
		}

		tr td {
			background-color: #fff;
		}

		.ant-table.ant-table-bordered
			> .ant-table-container
			> .ant-table-header
			> table
			> thead
			> tr
			> th {
			border: none !important;
		}

		.ant-table.ant-table-bordered
			> .ant-table-container
			> .ant-table-body
			> table
			> tbody
			> tr
			> td {
			border: none !important;
		}

		.ant-table-cell-row-hover {
			background: #e6ecee !important;
		}

		.hover-class {
			:hover {
				text-decoration: underline;
				text-decoration-color: #2b80ff;
			}
		}

		@media screen and (min-width: ${devices.smallDesk}) {
			tr th {
				font-size: ${smallSizes.tableData.tableHead};
			}
		}

		@media screen and (min-width: ${devices.mediumDesk}) {
			tr th {
				font-size: ${mediumSizes.tableData.tableHead};
			}
		}

		@media screen and (min-width: ${devices.largeDesk}) {
			tr th {
				font-size: ${largeSizes.tableData.tableHead};
			}
		}
	}
`;
